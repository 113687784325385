import {
  GET_SERVICE_CLASSES,
  GET_SERVICE_CLASSES_ERROR,
} from '../constants/action_types';

const initialState = {};

const serviceClassReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SERVICE_CLASSES:
      return {
        ...state,
        error: false,
        serviceClasses: action.payload
      };
    case GET_SERVICE_CLASSES_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default serviceClassReducer;
