import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import ValveEdit from './valveEdit';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  div: {
    marginBottom: 15
  },
  lastGridItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  card: {
    //display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: '0.35rem',
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    flex: '1 0 auto',
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {

    }
  },
  media: {
    objectFit: 'cover',
  },
  cover: {
    width: 151,
  },
  editButton: {
    padding: 5,
    marginLeft: 5,
    height: 26
  },
  editIcon: {
    fontSize: '1rem',
    //display: 'none'
  },
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type
  }
};

class ValvesCard extends React.Component {
  state = {
    edit: false,
    values: {}
  };

  handleEdit() {
    this.setState({
      edit: !this.state.edit
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { edit } = this.state;
    const { data, intl: { formatMessage, formatNumber, formatDate }, intl, messages, classes, loading, role } = this.props;
    console.log(data)
    return (
      <Fragment>
        {loading && edit ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <div className={classes.div}>
          <Grid
            container
            spacing={0}
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            {edit ?
              <Grid item xs={12}>
                <Paper>
                  <ValveEdit
                    intl={intl}
                    messages={messages}
                    initialValues={data}
                    edit={this.handleEdit.bind(this)}
                    fetchCard={this.props.fetchCard}
                    spring={data.spring ? this.capitalize(data.spring) : null}
                    piston={data.pisto ? this.capitalize(data.piston) : null}
                    index={data.id}
                  />
                </Paper>
              </Grid>
              :
              <Grid item xs={12}>
                <Card className={classes.card}>
                  <CardContent >
                    <div className={classes.title}>
                      <Typography variant="h5" component="h5">
                        {data.piston || data.spring ?
                          `${this.capitalize(data.spring)} + ${this.capitalize(data.piston)}`
                          :
                          `Housing`
                        }
                      </Typography>
                      <IconButton aria-label="edit" classes={{ root: classes.editButton }}>
                        <EditIcon classes={{ root: classes.editIcon }} onClick={this.handleEdit.bind(this)} />
                      </IconButton>
                    </div>
                    <Typography component="p">
                      {this.capitalize(data.description)}
                    </Typography>
                    <Typography component="p" color="textSecondary">
                      <span>Advices: </span>
                      {data.advices}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            }
          </Grid>
        </div>
      </Fragment>
    );
  }
};

export default connect(mapStateToProps)(withStyles(styles)(ValvesCard));



{/* <Card className={classes.card} >
  <CardContent classes={{ root: classes.cardContent }}>
    <Typography className={classes.title} color="textSecondary" gutterBottom>
      {this.capitalizeEachWord(register.location)} - Update
                </Typography>
    <Typography variant="h5" component="h2">
      {`${register.initial_flow} / ${register.end_flow}`}
    </Typography>
    <Typography className={classes.pos} color="textSecondary">
      {this.capitalize(register.valve)}
    </Typography>
    <Typography component="p">
      {this.capitalize(register.comments)}
    </Typography>
  </CardContent>
  <CardActions>
    <Button size="small" onClick={this.handleOpen.bind(this)}>Evidence</Button>
  </CardActions>
</Card > */}

{/* <Card className={classes.card}>
  <div className={classes.details}>
    <CardContent className={classes.content}>
      <Typography gutterBottom variant="h5" component="h5">
        {data.piston || data.spring ?
          `${this.capitalize(data.spring)} + ${this.capitalize(data.piston)}`
          :
          `Housing`
        }
      </Typography>
      <Typography component="p">
        {this.capitalize(data.description)}
      </Typography>
      <Typography component="p" color="textSecondary">
        <span>Advices: </span>
        {data.advices}
      </Typography>
    </CardContent>
  </div>
  <CardMedia
    //component="img"
    onClick={this.handleEdit.bind(this)}
    alt="Valve photo"
    className={classes.cover}
    image="/images/valvula-despues.jpg"
    title="Valve photo"
  />
</Card> */}

{/* <CardActions>
  <Button size="small" >Evidence</Button>
  <Button size="small" onClick={this.handleEdit.bind(this)}>
    Edit
                    </Button>
</CardActions> */}
