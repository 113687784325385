// TODO: Cambiar las acciones y data que guardo en state

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getRegisters } from '../../../actions';
import Form from './form/index';
import RegisterCard from './registerCard/index';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/ListAlt';
import AddIcon from '@material-ui/icons/Add';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Fade from '@material-ui/core/Fade';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  root: {
    width: '100%',
    minHeight: "90vh", //Anteriormente 100vh
    [theme.breakpoints.up('sm')]: {
      //height: '132vh',
      overflowY: 'auto',
      height: "calc(100vh - 64px)"
    }
  },
  banner: {
    backgroundColor: 'white',
    paddingBottom: 8,
    paddingTop: 8,
    /* Banner fixed */
    width: '100%',
    top: '56px',
    zIndex: 2,
    position: 'fixed',
    /*--------------*/
    [theme.breakpoints.up('sm')]: {
      /* Banner fixed */
      top: '64px',
      width: 'calc(100% - 250px)',
      /*--------------*/
    }
  },
  title: {
    fontSize: 30
  },
  titleProfile: {
    fontSize: 25,
    //fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#233b54'
  },
  subTitle: {
    fontSize: 17,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal'
  },
  registerArea: {
    marginTop: '68px',
    padding: '15px 15px 76px 15px',
    maxWidth: 1100,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      padding: 15
    }
  },
  divAnimation: {
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out'
  },
  paper: {
    padding: 15,
    marginBottom: 15
  },
  grid: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  infoGridItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  infoDiv: {
    marginTop: 5,
    height: 110,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 500
  },
  bigAvatar: {
    width: 90,
    height: 90,
    [theme.breakpoints.up('sm')]: {
      width: 120,
      height: 120,
      margin: 6
    }
  },
  buttons: {
    width: 250,
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 5,
    [theme.breakpoints.up('sm')]: {
      //display: 'none'
    }
  },
  iconButton: {
    width: 48,
    height: 48
  },
  buttonsLinks: {
    color: 'inherit'
  }
});

function mapDispatchToProps(dispatch) {
  return {
    getRegisters: (token, customer) => dispatch(getRegisters(token, customer))
  }
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    registers: state.register.registers
  }
};

class RegisterArea extends React.Component {
  state = {
    registers: [],
    expanded: 'panel1',
    data: [
      {
        location: 'living room',
        initial_flow: 22,
        end_flow: 30,
        comments: 'Installation of the valve #2',
        valve: 'valve #2',
        initial_photo: '',
        end_photo: '',
        update: true
      },
      {
        location: 'bathroom',
        initial_flow: 42,
        end_flow: 60,
        comments: 'Installation of the valve #1',
        valve: 'valve #1',
        initial_photo: '',
        end_photo: '',
        update: false
      },
      {
        location: 'kitchen',
        initial_flow: 42,
        end_flow: 50,
        comments: 'Installation of the valve #1',
        valve: 'valve #1',
        initial_photo: '',
        end_photo: '',
        update: true
      },
      {
        location: 'basement',
        initial_flow: 11,
        end_flow: 10,
        comments: 'Installation of the valve #2',
        valve: 'valve #2',
        initial_photo: '',
        end_photo: '',
        update: false
      }
    ],
    show: false,
    //show: true
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet(expandId) {
    const id = this.props.match.params.id;
    const token = 'token '.concat(this.props.token);
    await this.props.getRegisters(token, id);
    const show = this.props.registers.length === 0 ? true : false;
    let expanded;
    if (expandId) {
      expanded = `panel${expandId}`;
    } else {
      expanded = 'panel0';
    };
    this.setState({
      registers: this.props.registers,
      show,
      expanded
    });
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleShow() {
    this.setState({
      show: !this.state.show
    });
  };

  render() {
    const { classes, intl: { formatMessage }, intl, messages, customer, role } = this.props;
    const { expanded, registers, show } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.banner}>
          <List component="ul">
            <ListItem >
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                Register
              </Typography>} />
              <ListItemSecondaryAction style={{ marginRight: 10 }}>
                <IconButton aria-label="edit" onClick={this.handleShow.bind(this)}>
                  {role.update ?
                    show ?
                      <ArrowLeftIcon /*onClick={this.fetchGet.bind(this)}*/ />
                      :
                      <AddIcon />
                    :
                    null
                  }
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
        <div className={classes.registerArea}>
          {show ?
            <div className={classes.divAnimation}>
              <Form
                intl={intl}
                messages={messages}
                customer={customer}
                fetchCard={this.fetchGet.bind(this)}
                showCard={this.handleShow.bind(this)}
              />
            </div>
            :
            <div className={classes.divAnimation}>
              {registers.map((register, index) =>
                <Fade in={!show} timeout={500} key={index}>
                  <RegisterCard
                    registerId={register.id}
                    index={index}
                    register={register}
                    change={this.handleChange.bind(this)}
                    intl={intl}
                    messages={messages}
                    expanded={expanded}
                    fetchArea={this.fetchGet.bind(this)}
                  />
                </Fade>
              )}
            </div>
          }
        </div>
      </div>
    )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegisterArea));
