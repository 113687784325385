import React, { Component } from 'react';
import HOC from '../hoc/main';

import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

export default function (ComposedComponent) {
  const styles = theme => ({
    '@keyframes fadeInOut': {
      '0%': {
        opacity: 0
      },
      '100%': {
        opacity: 1
      }
    },
    root: {
      flexGrow: 1
    },
    grid: {
      backgroundColor: 'white',
      //minHeight: '80vh',
      marginTop: 56,
      [theme.breakpoints.up('sm')]: {
        marginTop: 64
      },
      animation: 'fadeInOut 0.6s',
      animationTimingFunction: 'ease-in-out'
    },
    paper: {
      width: '100%',
      border: 'none',
      [theme.breakpoints.up('sm')]: {
        //border: '1px solid rgba(0, 0, 0, 0.14)'
      }
    }
  });

  class CalendarLayout extends Component {
    render() {
      const { classes, intl, messages, history } = this.props
      return (
        <div className={classes.root}>
          <Grid
            container
            spacing={0}
            direction='row'
            justify='center'
            alignItems='center'
            className={classes.grid}
          >
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.paper}>
                <ComposedComponent {...this.props} />
              </Paper>
            </Grid>
          </Grid>
        </div>
      )
    };
  };

  return HOC(withStyles(styles)(CalendarLayout));
};
