import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import axios from 'axios';

import { getCustomers, getCompany, getAnalysisInfo } from '../../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  gridItem: {
    textAlign: 'end'
  },
  list: {
    width: '100%'
  },
  bigValue: {
    fontSize: 27,
    fontWeight: 'bold',
    lineHeight: 'normal',
    color: '#3e8dc9',
  },
  expansionExpanded: {
    margin: '0px 0px 15px'
  },
  expansionTitle: {
    fontSize: 17,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.6,
    color: '#233b54',
  },
  subValue: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.4,
    color: '#000000'
  },
  mediumSubValue: {
    fontSize: 20,
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.6,
    color: '#233b54',
    marginTop: 9
  },
  caption: {
    opacity: '0.5',
    fontSize: 12,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.4,
    color: '#233b54'
  },
  line1: {
    width: '100%',
    height: 2,
    border: 'solid 5px #19d800',
    borderRadius: 10
  },
  line2: {
    width: '100%',
    height: 2,
    border: 'solid 5px #3e8dc9',
    borderRadius: 10
  },
  littleSpan: {
    fontSize: 13
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    company: state.company.company,
    customer: state.customer.customer,
    analysisInfo: state.analysis.data,
    dominio: state.auth.dominio
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getCustomers: (token, company) => dispatch(getCustomers(token, company)),
    getCompany: (token, id) => dispatch(getCompany(token, id)),
    getAnalysisInfo: (token, customer) => dispatch(getAnalysisInfo(token, customer))
  }
};

class AnalysisPayment extends React.Component {
  state = {
    expanded: true,
    analysisInfo: []
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const id = this.props.customer.id;
    const token = 'token '.concat(this.props.token);
    await this.props.getAnalysisInfo(token, id)
    this.setState({
      analysisInfo: this.props.analysisInfo
    });
  };

  handleExpand() {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleClick(details) {
    const token = 'token '.concat(this.props.token)
    const id = this.props.customer.id;
    const url = `http://192.168.0.19:8000/report/${details}/${id}`
    axios.get(url,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        window.open(url)
      })
      .catch(function (error) {
        console.log(error.response)
      })
  };

  render() {
    const { intl, classes, messages, info } = this.props;
    const { expanded, analysisInfo } = this.state;
    const details = analysisInfo[analysisInfo.length - 1];
    console.log(analysisInfo[0])
    if (analysisInfo.length > 0) {
      return (
        <ExpansionPanel
          square={false}
          expanded
          classes={{ expanded: classes.expansionExpanded }}
        >
          <ExpansionPanelSummary onClick={this.handleExpand.bind(this)} >
            <Typography className={classes.expansionTitle}>Payment Forecast</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              spacing={0}
              direction='row'
              justify='space-between'
            >
              <Grid item xs={6}>
                <Typography noWrap className={classes.bigValue}>
                  $ {details.cash_saving}.<span className={classes.littleSpan}>00</span>
                </Typography>
                <Typography noWrap className={classes.subValue}>
                  Monthly Savings Forecast
              </Typography>
              </Grid>
              <Grid item xs={6} className={classes.gridItem}>
                <Typography noWrap className={classes.caption}>
                  Analysis Period
              </Typography>
                <Typography noWrap className={classes.caption}>
                  {details.lecture_number} months
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 25, marginBottom: 16 }}>
                <Typography noWrap className={classes.mediumSubValue}>
                  Monthly Payments
              </Typography>
              </Grid>
              <Grid item xs={6} style={{ maxWidth: '55%', flexBasis: '55%', textAlign: 'center' }}>
                <div className={classes.line1}></div>
                <Typography noWrap className={classes.mediumSubValue}>
                  $ {details.without_ela}.<span className={classes.littleSpan}>00</span>
                </Typography>
                <Typography noWrap className={classes.subValue}>
                  Before ELA
              </Typography>
              </Grid>
              <Grid item xs={5} style={{ maxWidth: '43%', flexBasis: '43%', textAlign: 'center' }}>
                <div className={classes.line2}></div>
                <Typography noWrap className={classes.mediumSubValue}>
                  $ {details.ela_saving}.<span className={classes.littleSpan}>00</span>
                </Typography>
                <Typography noWrap className={classes.subValue}>
                  After ELA
              </Typography>
              </Grid>
              <Grid item xs={12} className={classes.gridItem} >
                <Typography variant='button' noWrap style={{ paddingTop: '4%', cursor: 'pointer', color: '#1562c6' }} onClick={this.handleClick.bind(this, details.id)}>
                  Generate PDF
                </Typography>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )
    } else {
      return (
        <p>Loading data</p>
      )
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AnalysisPayment));
