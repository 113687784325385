import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import messages from '../locales/messages';
import NavBar from '../components/navbar';

import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
//import 'moment/locale/es';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

export default function (ComposedComponent) {
  class Main extends Component {
    state = {
      path: true
    };

    componentWillMount() {
      if (this.props.match.path === '/login' || this.props.match.path === '/recovery') {
        this.setState({
          path: false
        });
      };
    };

    render() {
      const { path } = this.state;
      const { locale } = this.props;
      const pickerLocale = locale === 'en' ? enLocale : esLocale
      return (
        <Fragment>
          {path ?
            <NavBar intl={this.props.intl} messages={messages} history={this.props.history} />
            :
            null
          }
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pickerLocale}>
            <ComposedComponent {...this.props} messages={messages} />
          </MuiPickersUtilsProvider>
        </Fragment>
      )
    };
  };

  function mapStateToProps(state) {
    return {
      authenticated: state.auth.authenticated,
      role: state.auth.role,
      locale: state.intl.locale
    };
  };

  return connect(mapStateToProps)(injectIntl(Main));
};
