import { InlineDateTimePicker } from 'material-ui-pickers';
import React from 'react';

export const universalDateTimePicker = props => {
  const {
    input,
    meta: { touched, error, invalid },
    ...other
  } = props
  return (
    <InlineDateTimePicker
      style={{ width: '100%' }}
      //variant="outlined"
      error={touched && invalid}
      helperText={touched && error}
      onChange={input.onChange}
      format='yyyy/MM/dd HH:mm'
      value={input.value === "" ? null /* new Date(2002, 1, 1) */ : new Date(input.value)}
      animateYearScrolling
      //autoOk
      {...other}
    />
  )
};

export default universalDateTimePicker;
