import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  gridItem: {
    //minHeight: '100vh',
    //display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      //minHeight: '93.5vh',
      backgroundColor: 'white',
    }
  },
  avatar: {
    //display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bigAvatar: {
    //width: 200,
    //height: 200,
    width: '100%',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 0
    }
  },
  name: {
    fontSize: 41,
    padding: '10px 0 15px'
  },

  card: {
    //minWidth: 275,
    marginTop: 20
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0
    }
  },
  titleCard: {
    fontSize: 16,
    textAlign: 'center',
    padding: '10px 0',
    backgroundColor: '#edefed'
  },
  listItem: {
    fontSize: 14
  }
});

function mapStateToProps(state) {
  return {
    username: state.auth.username
  }
};

class ProfileIndex extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        spacing={0}
        direction='row'
        justify='space-evenly'
        style={{ padding: '20px 12px 15px' }}
      >
        <Grid item xs={4} sm={3} className={classes.gridItem}>
          <div>
            <div className={classes.avatar}>
              <Avatar alt="avatar" src="images/no-photo.png" className={classes.bigAvatar} />
            </div>
            <Card className={classes.card}>
              <CardContent classes={{ root: classes.cardContent }}>
                <Typography variant="h5" component="h3" className={classes.titleCard}>
                  Personal information
                </Typography>
                <Divider />
                <List component="nav" >
                  <ListItem >
                    <ListItemText primary={
                      <Typography variant="body1" className={classes.listItem}>
                        Role: Admin
                      </Typography>
                    } />
                  </ListItem>
                  <ListItem >
                    <ListItemText primary={
                      <Typography variant="body1" className={classes.listItem}>
                        Phone: 3015665963
                      </Typography>
                    } />
                  </ListItem>
                  <ListItem >
                    <ListItemText primary={
                      <Typography variant="body1" className={classes.listItem}>
                        Email: 3015665963
                      </Typography>
                    } />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </div>
        </Grid>
        <Grid item xs={7} sm={8} className={classes.gridItem}>
          <div>
            <Typography component="h2" variant="h2" className={classes.name}>
              Luis Hernandez
            </Typography>
            <Typography variant="body1">
              Barranquilla, Colombia &#8226; Joined in November 10 2018
            </Typography>
          </div>
        </Grid>
      </Grid>
    )
  }
};

export default connect(mapStateToProps)(withStyles(styles)(ProfileIndex));
