import {
  GET_INSTALLATION_SUMMARY,
  GET_INSTALLATION_SUMMARY_ERROR,
  CHANGE_CUSTOMERS_STATE,
  GET_CUSTOMERS,
  GET_CUSTOMERS_ERROR,
  GET_CUSTOMER,
  GET_CUSTOMER_ERROR,
  SIGN_UP_CUSTOMER,
  SIGN_UP_CUSTOMER_ERROR,
  UPDATE_CUSTOMER_PROFILE,
  UPDATE_CUSTOMER_PROFILE_ERROR
} from '../constants/action_types';

const initialState = { customers: [], customersState: false };

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_CUSTOMERS_STATE:
      return {
        ...state,
        customersState: !state.customersState
      }
    case GET_INSTALLATION_SUMMARY:
      return {
        ...state,
        installationSummary: action.payload,
      };
    case GET_INSTALLATION_SUMMARY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_CUSTOMERS:
      return {
        ...state,
        error: false,
        customers: action.payload
      };
    case GET_CUSTOMERS_ERROR:
      return {
        ...state,
        error: false,
      };
    case GET_CUSTOMER:
      return {
        ...state,
        error: false,
        customer: action.payload
      };
    case GET_CUSTOMER_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case SIGN_UP_CUSTOMER:
      return {
        ...state,
        error: false
      };
    case SIGN_UP_CUSTOMER_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_CUSTOMER_PROFILE:
      return {
        ...state,
        error: false,
      };
    case UPDATE_CUSTOMER_PROFILE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default customerReducer;
