import React, { Fragment } from "react";
import Select from "react-select";

import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  root: {
    flexGrow: 1,
    //marginTop: 12.4
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  underline: {
    '&:after': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
    '&:before': {
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

/*const renderSelectField = ({ classes, label, input, options, is, meta: { touched, invalid, error }, ...custom }) => (
  <Fragment>
    <Select
      value={input.value}
      defaultInputValue={is ? is : ''}
      //value={is ? options.filter(option => option.label === is) : input.value}
      onChange={input.onChange}
      onBlur={(event) => event.preventDefault()}
      options={options}
      placeholder={is ? is : label}
      //className="form-control"
      //simpleValue
      isSearchable
      styles={
        (touched && invalid ?
          { control: (provided, state) => ({ ...provided, height: '56px', borderColor: '#d93025 !important', borderWidth: 2 }) }
          : is ?
            { control: (provided, state) => ({ ...provided, height: '56px', '&:hover': { borderColor: 'black' } }), placeholder: () => ({ color: 'hsl(0,0%,20%)' }) }
            :
            { control: (provided, state) => ({ ...provided, height: '56px', '&:hover': { borderColor: 'black' } }) }
        )
      }
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: 'rgba(67, 129, 209,0.15)',
          primary: '#1562c6',
        }
      })}
    />
    {touched && error ? <p style={{ fontSize: '0.75rem', margin: '8px 12px 0', color: '#d93025' }}>{error}</p> : null}
  </Fragment>
  /*<div className={classes.root}>
    <NoSsr>
      <Select
        classes={classes}
        styles={{
          input: base => ({
            ...base,
            '& input': {
              font: 'inherit',
            },
          }),
        }}
        options={options}
        defaultInputValue={is ? is : ''}
        //value={is ? options.find(item => item.label === is) : input.onChange}
        components={components}
        //value={input.value}
        onChange={input.onChange}
        placeholder={is ? is : label}
        isClearable
      />
    </NoSsr>
    {touched && error ? <p style={{ fontSize: '0.75rem', marginTop: 12, color: '#d93025' }}>{error}</p> : null}
  </div>
);*/

class renderSelectField extends React.Component {
  render() {
    const { classes, label, input, options, is, meta: { touched, invalid, error }, theme, ...custom } = this.props;
    const customStyles = {
      input: (provided, state) => ({
        ...provided,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit'
        }
      }),
      dropdownIndicator: (base, state) => ({
        ...base,
        padding: 6,
        transition: 'all .2s ease',
        transform: state.isFocused ? 'rotate(90deg)' : null
      })
    };

    const NoOptionsMessage = (props) => {
      return (
        <Typography
          color="textSecondary"
          className={props.selectProps.classes.noOptionsMessage}
          {...props.innerProps}
        >
          {props.children}
        </Typography>
      );
    };

    const inputComponent = ({ inputRef, ...props }) => {
      //console.log(props)
      return <div ref={inputRef} {...props} />;
    };

    const Control = (props) => {
      //console.log(props)
      return (
        <TextField
          fullWidth
          InputProps={{
            classes: { underline: touched && error ? classes.underline : null },
            inputComponent,
            inputProps: {
              className: props.selectProps.classes.input,
              inputRef: props.innerRef,
              children: props.children,
              ...props.innerProps,
            },
          }}
          {...props.selectProps.textFieldProps}
        />
      );
    };

    const Option = (props) => {
      return (
        <MenuItem
          buttonRef={props.innerRef}
          selected={props.isFocused}
          component="div"
          style={{
            fontWeight: props.isSelected ? 500 : 400,
          }}
          {...props.innerProps}
        >
          {props.children}
        </MenuItem>
      );
    };

    const Placeholder = (props) => {
      return (
        <Typography
          color={touched && invalid ? 'error' : "textSecondary"}
          className={props.selectProps.classes.placeholder}
          {...props.innerProps}
        >
          {props.children}
        </Typography>
      );
    };

    const SingleValue = (props) => {
      return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
          {props.children}
        </Typography>
      );
    };

    const ValueContainer = (props) => {
      console.log(props)
      return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
    };

    const Menu = (props) => {
      return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
          {props.children}
        </Paper>
      );
    };
    console.log(options.filter(option => option.label === is)[0])
    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            classes={classes}
            styles={customStyles}
            defaultInputValue={is}
            //defaultValue={options[0]}
            value={input.value}
            options={options}
            components={{
              Control,
              Menu,
              NoOptionsMessage,
              Option,
              Placeholder,
              SingleValue,
              ValueContainer,
            }}
            onChange={input.onChange}
            placeholder={input.value === '' ? null : ''}
            //isClearable
            textFieldProps={{
              label: label,
              //InputLabelProps: this.state.value === '' ? {} : { shrink: true }  //Modified line
              InputLabelProps: input.value === '' ? {} : { shrink: true }  //Modified line
            }}
          />
        </NoSsr>
        {touched && error ? <p style={{ fontSize: '0.75rem', marginTop: 12, color: '#d93025' }}>{error}</p> : null}
      </div>
    )
  };
};

export default withStyles(styles, { withTheme: true })(renderSelectField);
