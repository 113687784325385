import React, { Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import uploadDropZone from '../../../ui/upload_dropZone';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  paper: {
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out'
  },
  form: {
    width: '100%',
    padding: '15px 24px 15px',
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  button: {
    margin: '0 5px'
  }
});

class BillUpload extends React.Component {
  state = {
    files: [],
    preview: []
  };

  submit(values) {
    console.log(values);
    console.log(this.state.files)
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        console.log(key)
      }

      return form_data;
    }
  };

  handleDrop(file) {
    this.setState({
      files: this.state.files.concat(file)
    });

    if (file.length > 1) {
      this.state.files.forEach(item => this.previewFile(item))
    } else {
      this.previewFile(file[0]);
    }
    return this.state.files
  };

  previewFile(file) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      //console.log(reader.result);
      this.setState({
        preview: this.state.preview.concat(reader.result)
      });
    })
    reader.readAsDataURL(file);
    //return this.state.preview
  };

  handleReset() {
    this.setState({
      files: [],
      preview: []
    });
    this.props.reset();
  };

  render() {
    const { messages, intl: { formatMessage }, reset, pristine, submitting, classes, handleSubmit, loading } = this.props;
    return (
      <Paper className={classes.paper}>
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))}>
          <div>
            <Field
              name="pdf_files"
              label="Pdf Files"
              ref="pdf_files"
              component={uploadDropZone}
              handleDrop={this.handleDrop.bind(this)}
              previewFile={this.previewFile.bind(this)}
              files={this.state.files}
              preview={this.state.preview}
            />
          </div>
          <div className={classes.formMeta}>
            <Typography variant='button' noWrap className={classes.link} onClick={this.props.showOptions}>
              Return to options
            </Typography>
            <div>
              <Button className={classes.button} color='primary' variant="outlined" onClick={this.handleReset.bind(this)} disabled={loading || (pristine || submitting)}>
                <Typography variant='button' noWrap>
                  Reset
                </Typography>
              </Button>
              <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
                <Typography variant='button' noWrap>
                  Submit
                </Typography>
              </Button>
            </div>
          </div>
        </form>
      </Paper>
    );
  };
};

const reduxFormUploadPdf = reduxForm({
  form: 'uploadpdffiles'
})(BillUpload);

export default withStyles(styles)(reduxFormUploadPdf);
