import React, { Fragment } from 'react';

import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import PopupState, { bindTrigger, bindToggle, bindPopover } from 'material-ui-popup-state/index';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import ClockIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import StopIcon from '@material-ui/icons/StopRounded';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';

const CustomEvent = ({ handleModal }) => props => {
  const { event, classes } = props;
  return (
    <div>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {popupState => (
          <div>
            <div className={classes.eventDisplayText} {...bindTrigger(popupState)}>
              <strong>{/*moment(event.start).format('ha')*/}</strong>
              <strong>{event.type_booking}</strong>
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Card className={classes.card}>
                <CardHeader
                  className={classes.header}
                  title={
                    <Fragment>
                      <div className={classes.eventModalTitle}>
                        <StopIcon color="primary" />
                        <Typography variant="h5" >{event.type_booking}</Typography>
                      </div>
                      <Typography component="caption" color="textSecondary" style={{ textAlign: 'left', paddingLeft: 24 }}>
                        {event.allDay ?
                          `${moment(event.start).format('MMMM D')} - ${moment(event.end).format('MMMM D, hA')}`
                          :
                          `${moment(event.start_time).format('MMMM')} ${moment(event.start_time).format('DD')},  ${moment(event.start).format('h:mm A')} - ${moment(event.end).format('h:mm A')}`
                        }
                      </Typography>
                    </Fragment>
                  }
                  action={
                    <div className={classes.iconContainer}>
                      <IconButton className={classes.editButton} onClick={() => handleModal({ start: event.start, end: event.end, status: event.status, description: event.description, type_booking: event.type_booking, customer: event.customer, seller: event.seller, id: event.id })}>
                        <EditIcon className={classes.editIcon} />
                      </IconButton>
                      <IconButton className={classes.editButton} {...bindToggle(popupState)}>
                        <CloseIcon className={classes.editIcon} />
                      </IconButton>
                    </div>
                  }
                />
                <CardContent className={classes.content}>
                  <div className={classes.contentItems}>
                    <ClockIcon style={{ marginRight: 5, display: 'none' }} />
                  </div>
                  <Typography component="p">
                    {event.description}
                  </Typography>
                  <Typography component="p">
                    {event.status}
                  </Typography>

                </CardContent>
              </Card>
            </Popover>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default (CustomEvent);
