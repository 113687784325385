import {
  GENERATE_PDF,
  GENERATE_PDF_ERROR
} from '../constants/action_types';

const initialState = { sellers: [] };

const generatePdfReducer = (state = initialState, action) => {
  switch (action.type) {
    case GENERATE_PDF:
      return {
        ...state
      };
    case GENERATE_PDF_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default generatePdfReducer;
