import { CHANGE_SIDE_LIST } from '../constants/action_types';

const initialState = {};

const sideListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SIDE_LIST:
      return {
        ...state,
        page: action.page,
        info: action.info,
        link: action.link
      };
    default:
      return state;
  }
};

export default sideListReducer;
