import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  photo: {
    marginRight: 12,
    borderRadius: 10,
    width: 65,
    height: 65,
    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 80,
    }
  },
  name: {
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem'
    }
  },
  caption: {
    fontSize: '0.45rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.75rem'
    }
  },
  bold: {
    fontWeight: 500,
  }
});

class ListCompanies extends React.Component {
  componentDidMount() {
    this.setState({
      data: this.props.data
    });
  };

  handleRedirect(id) {
    this.props.history.push(`/settings/${id}`)
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { classes, intl, messages, data } = this.props;
    return (
      <List component="ul" id="companies">
        {data.map((company, index) =>
          <Fragment key={company.id}>
            <ListItem button onClick={this.handleRedirect.bind(this, company.id)}>
              <Grid
                container
                spacing={0}
                direction='row'
                justify='space-between'
                alignItems='center'
              >
                <Grid item xs={6} sm={4} style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar alt="avatar" src={company.photo} className={classes.photo} />
                  <div >
                    <Typography variant="h5" component="h3" noWrap className={classes.name}>
                      {this.capitalize(company.name)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" className={classes.caption}>
                      {/*company.identification*/}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} sm={4} style={{ textAlign: 'right' }}>
                  <Typography variant="caption" className={classes.bold} className={classes.caption}>
                    {/*company.city*/}
                  </Typography>
                  <Typography variant="caption" className={classes.bold} className={classes.caption}>
                    {/*company.phone*/}
                  </Typography>
                  <Typography variant="caption" color="textSecondary" className={classes.caption}>
                    {company.url}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            {index === data.length - 1 ? null : <Divider />}
          </Fragment>
        )}
      </List>
    )
  };
};

export default withStyles(styles)(ListCompanies);
