import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import InvoiceEdit from './invoiceEdit';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 18,
    padding: "0px 7px",
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  expansion: {
    marginBottom: '10px',
    '&:before': {
      display: 'none',
    }
  },
  expansionExpanded: {
    margin: '0 0 10px'
  },
  expansionDetails: {
    padding: 0
  },
  expansionTitle: {
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  expansionSummary: {
    padding: 0,
    height: 70
  },
  expandIcon: {
    marginRight: 6
  },
  titleSwitch: {
    height: 45, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
  },
  subTitle: {
    display: "inline"
  },
  summary: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  secondaryHeading: {
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  lists: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  detailsTitle: {
    display: 'inline'
  },
  editButton: {
    padding: 5,
    marginLeft: 5
  },
  editIcon: {
    fontSize: '1rem',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  footerItem: {
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      margin: 0
    }
  },
  lastGridItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  conditionalDivider: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  sectionDivider: {
    margin: '20px 0',
    [theme.breakpoints.up('sm')]: {
      margin: 0
    }
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type
  }
};

class InvoiceCard extends React.Component {
  state = {
    editInvoice: false,
    values: {}
  };

  handleEdit() {
    this.setState({
      editInvoice: !this.state.editInvoice
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  formatValues(invoice) {
    const values = {
      ...invoice,
      date: invoice.date.split('-').join('/'),
      due_date: invoice.due_date.split('-').join('/')
    };
    return values;
  };

  render() {
    const { editInvoice } = this.state;
    const { expanded, invoice, intl: { formatMessage, formatNumber, formatDate }, intl, messages, classes, index, loading, role, match } = this.props;
    return (
      <Fragment>
        {loading && editInvoice ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <ExpansionPanel expanded={expanded === `panel${index}`} onChange={this.props.change(`panel${index}`)} square={false} classes={{ root: classes.expansion, expanded: classes.expansionExpanded }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.summary}>
              {editInvoice ?
                <div className={classes.titleContainer}>
                  <AssignmentIcon className={classes.icon} />
                  <Typography component="h2" variant="h2" className={classes.title}>
                    Update Invoice
                </Typography>
                </div>
                :
                <Fragment>
                  <Typography variant="h3" className={classes.expansionTitle}>
                    <span>
                      {/*formatMessage(messages.customers.abill)*/}
                    </span>
                    <div className={classes.titleSwitch}>
                      <b className={classes.subTitle}>{invoice.number}</b>
                      <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                        Due date: {formatDate(invoice.due_date, { year: "numeric", month: "long", day: "2-digit", timeZone: 'GMT' })}
                      </Typography>
                    </div>
                    {/*<b className={classes.subTitle}>{formatNumber(bill["price_cubic_meters"] * bill["cubic_meters"], { style: "currency", currency: "COP" })}</b>*/}
                  </Typography>
                  <Typography style={{ display: 'flex', alignItems: 'center ' }} className={classes.secondaryHeading} color="textSecondary" noWrap>{formatDate(invoice.date, { year: "numeric", month: "long", day: "2-digit", timeZone: 'GMT' })}</Typography>
                </Fragment>
              }
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              spacing={16}
              direction='row'
              justify='space-between'
              alignItems='flex-start'
            >
              {editInvoice ?
                <Grid item xs={12}>
                  <InvoiceEdit
                    intl={intl}
                    messages={messages}
                    match={match}
                    initialValues={this.formatValues(invoice)}
                    edit={this.handleEdit.bind(this)}
                    fetchArea={this.props.fetchCardArea}
                    index={invoice.id}
                  />
                </Grid>
                :
                <Fragment>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.detailsTitle}>
                      Details
                    </Typography>
                    {role.update ?
                      <IconButton aria-label="edit" classes={{ root: classes.editButton }}>
                        <EditIcon classes={{ root: classes.editIcon }} onClick={this.handleEdit.bind(this)} />
                      </IconButton>
                      :
                      null
                    }
                    {invoice.paid ?
                      <a href='' target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="edit" classes={{ root: classes.editButton }}>
                          <PdfIcon classes={{ root: classes.editIcon }} />
                        </IconButton>
                      </a>
                      :
                      null
                    }
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <List component="ul" className={classes.list}>
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Consumo actual
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>241</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <List component="ul" className={classes.list}>
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Promedio de consumo
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>373</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <List component="ul" className={classes.list}>
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Metros cubicos cobrados
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>66</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider className={classes.conditionalDivider} />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <List component="ul" className={classes.list}>
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Metros cubicos ahorrados
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>132</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} className={classes.sectionDivider}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <List component="ul" className={classes.list}>
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Total uso acueducto
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>132 COP</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <List component="ul" className={classes.list}>
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Total consumo acueducto
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>142.824 COP</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <List component="ul" className={classes.list}>
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Descuento (0%)
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>0 COP</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider className={classes.conditionalDivider} />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <List component="ul" className={classes.list}>
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            IVA (19%)
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>27.162 COP</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid item xs={12} className={classes.sectionDivider}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className={classes.footer}>
                    <div className={classes.footerItem}>
                      <Typography variant="body2">
                        {formatMessage(messages.customers.period)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                        15 de Febrero 2019 al 15 de Marzo 2019
                      </Typography>
                    </div>
                    <div className={classes.footerItem}>
                      <Typography variant="body2">
                        {formatMessage(messages.customers.payDay)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                        04 de abril de 2019
                      </Typography>
                    </div>
                    <div className={classes.footerItem}>
                      <Typography variant="body2">
                        {formatMessage(messages.customers.payReference)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                        67721
                      </Typography>
                    </div>
                  </Grid>
                </Fragment>
              }
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Fragment>
    )
  }
};

export default connect(mapStateToProps)(withStyles(styles)(InvoiceCard));
