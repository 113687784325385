import { SWITCH_LOADER } from '../constants/action_types';

const initialState = { loading: false };

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_LOADER:
      return {
        ...state,
        loading: action.payload
      }
    default:
      return state;
  }
};

export default loaderReducer;
