import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import LangBar from '../components/langbar';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import HOC from '../hoc/main';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function (ComposedComponent) {
  const styles = theme => ({
    root: {
      flexGrow: 1
    },
    grid: {
      backgroundColor: 'white',
      minHeight: '100vh'
    },
    loginForm: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%'
    },
    loader: {
      borderRadius: '3px 3px 0px 0px'
    },
    paper: {
      width: '100%',
      //display: 'flex',
      border: 'none',
      height: 530,
      //flexDirection: 'column',
      //justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        border: '1px solid rgba(0, 0, 0, 0.14)'
      }
    },
    langBar: {
      margin: '0px 24px',
      [theme.breakpoints.up('sm')]: {
        margin: '10px 0px 0px'
      }
    }
  });

  function mapStateToProps(state) {
    return {
      redirectToReferrer: state.auth.redirectToReferrer,
      loading: state.loader.loading
    }
  };
  class LoginLayout extends Component {
    state = {
      loading: false
    };

    componentWillReceiveProps(nextProps) {
      this.setState({
        loading: nextProps.loading
      })
    };

    render() {
      const { loading } = this.state;
      const { classes, redirectToReferrer } = this.props;
      const { from } = this.props.location.state || { from: { pathname: '/' } };
      //console.log(this.props.location);
      if (redirectToReferrer) {
        return (
          <Redirect to={from} />
        )
      }
      return (
        <div className={classes.root}>
          <Grid
            container
            spacing={0}
            direction='row'
            justify='center'
            alignItems='center'
            className={classes.grid}
          >
            <Grid item xs={12} sm={6} md={4} style={{ maxWidth: 500 }}>
              <Paper elevation={0} className={classes.paper}>
                {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
                <div className={classes.loginForm}>
                  <ComposedComponent {...this.props} />
                </div>
              </Paper>
              <LangBar className={classes.langBar} page="login" />
            </Grid>
          </Grid>
        </div>
      )
    }
  }

  return HOC(connect(mapStateToProps)(withStyles(styles)(LoginLayout)));
}
