import { CHANGE_LANGUAGE } from '../constants/action_types';

const initialState = {};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        locale: action.payload
      }
    default:
      return state;
  }
};

export default languageReducer;
