import {
  GET_ACCESSORIES,
  GET_ACCESSORIES_ERROR,
  ADD_ACCESSORY,
  ADD_ACCESSORY_ERROR,
  UPDATE_ACCESSORY,
  UPDATE_ACCESSORY_ERROR
} from '../constants/action_types';

const initialState = {};

const accessoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCESSORIES:
      return {
        ...state,
        accessories: action.payload,
      };
    case GET_ACCESSORIES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_ACCESSORY:
      return {
        ...state,
        error: false,
      };
    case ADD_ACCESSORY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_ACCESSORY:
      return {
        ...state,
        error: false,
      }
    case UPDATE_ACCESSORY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default accessoryReducer;
