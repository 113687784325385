import {
  GET_BUSINESS_TYPES,
  GET_BUSINESS_TYPES_ERROR,
  ADD_BUSINESS_TYPE,
  ADD_BUSINESS_TYPE_ERROR,
  UPDATE_BUSINESS_TYPE,
  UPDATE_BUSINESS_TYPE_ERROR
} from '../constants/action_types';

const initialState = {};

const businessTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUSINESS_TYPES:
      return {
        ...state,
        businessTypes: action.payload
      }
    case GET_BUSINESS_TYPES_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_BUSINESS_TYPE:
      return {
        ...state,
        error: false,
      };
    case ADD_BUSINESS_TYPE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_BUSINESS_TYPE:
      return {
        ...state,
        error: false,
      }
    case UPDATE_BUSINESS_TYPE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default businessTypeReducer;
