import Login from './login';
import Dashboard from './dashboard';
import Customers from './customers';
import Sellers from './sellers';
import Profile from './profile';
import Settings from './settings';
import Appointment from './appointments';
import Calendar from './calendar';
import AdvanceSearch from './advanceSearch';

export const LoginLayout = Login;
export const DashboardLayout = Dashboard;
export const CustomersLayout = Customers;
export const SellersLayout = Sellers;
export const ProfileLayout = Profile;
export const SettingsLayout = Settings;
export const AppointmentLayout = Appointment;
export const CalendarLayout = Calendar;
export const AdvanceSearchLayout = AdvanceSearch;
