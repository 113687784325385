import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { getValves, deleteRegisterDetail, updateRegisterDetail } from '../../../../../actions';

import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../../../ui/text_field';
import renderSelectFieldTest from '../../../../ui/select_field_test';
import renderFileInput from '../../../../ui/upload_field'
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    padding: '15px 24px 15px',
    overflowY: 'scroll'
  },
  title: {
    fontSize: 25
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    margin: '0 5px'
  },
  uploadButton: {
    marginTop: 18
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
});

function mapStateToProps(state) {
  return {
    role: state.auth.user_type,
    token: state.auth.token,
    valves: state.valve.valves,
    dominio: state.auth.dominio,
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getValves: (token, company) => dispatch(getValves(token, company)),
    deleteRegisterDetail: (token, id) => dispatch(deleteRegisterDetail(token, id)),
    updateRegisterDetail: (data, token, id) => dispatch(updateRegisterDetail(data, token, id))
  }
};

class ModalEdit extends React.Component {
  state = {
    valves: []
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getValves(token, this.props.dominio);

    const valves = [];
    this.props.valves.forEach(item => {
      let label;
      if (item.spring && item.piston) {
        label = `${item.spring} + ${item.piston}`
      } else if (item.spring || item.piston) {
        label = item.spring || item.piston
      } else {
        label = 'housing'
      };

      valves.push({
        label: label,
        value: item.url
      })
    });

    this.setState({
      valves: valves
    });
  };

  async submit(values) {
    const val = values;
    console.log(values)

    // if (val.initial_photos) {
    //   delete val.initial_photos
    // };

    // if (val.end_photos) {
    //   delete val.end_photos
    // };

    if (typeof val.initial_photos !== 'object' || val.initial_photos === '{}') {
      delete val.initial_photos
    };

    if (typeof val.end_photos !== 'object' || val.end_photos === '{}') {
      delete val.end_photos
    };

    /* Evitar valored nulos */
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    /*---------------------*/

    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    //const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    const id = this.props.initialValues.id;
    await this.validateErrorsApi(val, token, id);
    this.props.handleClose();
    await this.props.fetchArea();
  };

  validateErrorsApi(data, token, id) {
    return this.props.updateRegisterDetail(data, token, id)
  };

  async deleteData() {
    const token = 'token '.concat(this.props.token);
    const id = this.props.initialValues.id;
    await this.props.deleteRegisterDetail(token, id);
    this.props.handleClose();
    await this.props.fetchArea();
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { open, handleClose, classes, role, handleSubmit, pristine, submitting } = this.props;
    const { valves } = this.state;
    const normalizeNumber = (value, previousValue) => {
      const onlyNums = value.replace(/[^\d]/g, '')
      return onlyNums
    };
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={isWidthDown('sm', this.props.width)}
      >
        <DialogContent style={isWidthDown('sm', this.props.width) ? { paddingTop: 10 } : {}}>
          <div className={classes.closeIcon}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))}>
            <Grid container spacing={24}>
              <Grid item xs={12} className={classes.head}>
                <List component="ul">
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                      Update Register Details
                  </Typography>} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="location"
                  component={renderTextField}
                  label="Location"
                  ref="location"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="q_before"
                  component={renderTextField}
                  label="Q Before"
                  ref="q_before"
                  normalize={normalizeNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="q_after"
                  component={renderTextField}
                  label="Q After"
                  ref="q_after"
                  normalize={normalizeNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="valve"
                  component={renderSelectFieldTest}
                  label="Valve Type"
                  ref="valve"
                  options={valves}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="tools"
                  component={renderTextField}
                  label="Tools"
                  ref="tools"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="observations"
                  component={renderTextField}
                  label="Observation"
                  isTextArea={true}
                  ref="observations"
                />
              </Grid>
              <Grid item xs={12} className={classes.uploadButton}>
                <Field
                  name="initial_photos"
                  component={renderFileInput}
                  ref="initial_photos"
                  label="Initial Photos"
                  inputid="initial_photos"
                />
              </Grid>
              <Grid item xs={12} >
                <Field
                  name="end_photos"
                  component={renderFileInput}
                  ref="end_photos"
                  label="End Photos"
                  inputid="end_photos"
                />
              </Grid>
            </Grid>
            <div className={classes.formMeta}>
              <Button className={classes.button} color='secondary' variant="contained" onClick={this.deleteData.bind(this)}>
                <Typography variant='button' noWrap>
                  Delete
                  </Typography>
              </Button>
              <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={(pristine || submitting)}>
                <Typography variant='button' noWrap>
                  Submit
                </Typography>
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    )
  }
};

const reduxFormUpdateReportDetail = reduxForm({
  form: 'updateReportDetail',
  enableReinitialize: true
})(ModalEdit);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(reduxFormUpdateReportDetail)));
