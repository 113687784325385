import React from 'react';
import axios from 'axios';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { addCommission } from '../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import renderSelectField from '../../ui/select_field';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  banner: {
    paddingBottom: 8,
    paddingTop: 8
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  paper: {
    width: '100%',
    //padding: '0px 24px 15px',
    //height: '80vh',
    //display: 'flex',
    //alignItems: 'center'
  },
  form: {
    padding: '15px 24px 15px',
  },
  head: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 25
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '24px'
  },
  link: {
    color: '#1562c6'
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    token: state.auth.token,
    loading: state.loader.loading
  }
};

function mapDispatchToProps(dispatch) {
  return {
    addCommission: (values, token) => dispatch(addCommission(values, token))
  }
};

const validate = (values, state) => {
  const { intl: { formatMessage }, messages } = state;
  const errors = {}

  if (!values.value) {
    errors.value = formatMessage(messages.signIn.required)
  }

  if (!values.description) {
    errors.description = formatMessage(messages.signIn.required)
  }

  if (!values.userType) {
    errors.userType = formatMessage(messages.signIn.required)
  }
  return errors
};

class CommissionsForm extends React.Component {
  state = {
    userTypes: []
  }

  componentDidMount() {
    const token = 'token '.concat(this.props.token);
    axios.get('https://api.elasustentable.com/rest/user/usertype',
      {
        headers: { Authorization: token }
      })
      .then(response => {
        //console.log(response.data);
        this.setState({
          userTypes: response.data.results
        })
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  };

  async submit(values) {
    //console.log(values)
    const val = {
      ...values,
      company: this.props.company.url,
      user_type: values.user_type.value,
      value: parseInt(values.value, 10)
    };
    //console.log(val)

    //const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/


    await this.validateErrorsApi(val, token);
    this.props.showCard();
    this.props.fetchCard();
  };

  validateErrorsApi(data, token) {
    return this.props.addCommission(data, token)
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading } = this.props;
    const userTypes = [];

    this.state.userTypes.forEach(item =>
      userTypes.push({
        label: this.capitalize(item.name),
        value: item.url
      })
    );
    return (
      <Paper>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} /*enctype="multipart/form-data" id="myForm"*/>
          <Grid container spacing={24} >
            <Grid item xs={12} className={classes.head}>
              <List component="ul">
                <ListItem disableGutters>
                  <ListItemIcon>
                    <AssignmentIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography component="h2" variant="h2" className={classes.title}> Add Commission </Typography>}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="user_type"
                component={renderSelectField}
                options={userTypes}
                clearable={true}
                label="User Type"
                ref="user_type"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="value"
                component={renderTextField}
                label="Value"
                ref="value"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="description"
                component={renderTextField}
                label="Description"
                ref="description"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                {formatMessage(messages.customers.billFormSubmit)}
              </Typography>
            </Button>
          </div>
        </form>
      </Paper>
    )
  }
};

const reduxFormAddCommission = reduxForm({
  form: 'addcommission',
  validate
})(CommissionsForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormAddCommission));
