import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import BusinessTypeEdit from './businessTypeEdit';

import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  title: {
    fontSize: 18,
    padding: "0px 7px",
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  expansion: {
    marginBottom: '10px',
    '&:before': {
      display: 'none',
    }
  },
  expansionExpanded: {
    margin: '0 0 10px'
  },
  expansionDetails: {
    padding: 0
  },
  expansionTitle: {
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  expansionSummary: {
    padding: 0,
    height: 70
  },
  expandIcon: {
    marginRight: 6
  },
  titleSwitch: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  },
  subTitle: {
    display: "inline"
  },
  summary: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  secondaryHeading: {
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  lists: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  billDetailsTitle: {
    display: 'inline'
  },
  editButton: {
    padding: 5,
    marginLeft: 5
  },
  editIcon: {
    fontSize: '1rem',
    display: 'none'
  },
  billFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  billFooterItem: {
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      margin: 0
    }
  },
  lastGridItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
});

function mapStateToProps(state) {
  return {
    loading: state.loader.loading,
    role: state.auth.user_type
  }
};

class BusinessTypeCard extends React.Component {
  state = {
    edit: false,
    values: {}
  };

  handleEdit() {
    this.setState({
      edit: !this.state.edit
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { edit } = this.state;
    const { expanded, type, intl: { formatMessage, formatNumber, formatDate }, intl, messages, classes, index, loading, role } = this.props;
    return (
      <Fragment>
        {loading && edit ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <ExpansionPanel expanded={expanded === `panel${index}`} onChange={this.props.change(`panel${index}`)} square={false} classes={{ root: classes.expansion, expanded: classes.expansionExpanded }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.summary}>
              {edit ?
                <div className={classes.titleContainer}>
                  <AssignmentIcon className={classes.icon} />
                  <Typography component="h2" variant="h2" className={classes.title}>
                    Update Business Type
                  </Typography>
                </div>
                :
                <Fragment>
                  <Typography variant="h3" className={classes.expansionTitle}>
                    {/*<span className={classes.titleSwitch}>
                      Type:
                      </span>*/}
                    <b className={classes.subTitle}>{this.capitalize(type.name)}</b>
                  </Typography>
                </Fragment>
              }
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              spacing={24}
              direction='row'
              justify='space-between'
              alignItems='flex-start'
            >
              {edit ?
                <Grid item xs={12}>
                  <BusinessTypeEdit
                    intl={intl}
                    messages={messages}
                    initialValues={type}
                    edit={this.handleEdit.bind(this)}
                    fetchCard={this.props.fetchCard}
                    index={type.id}
                  />
                </Grid>
                :
                <Fragment>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.billDetailsTitle}>
                      Description
                    </Typography>
                    <IconButton aria-label="edit" classes={{ root: classes.editButton }}>
                      <EditIcon classes={{ root: classes.editIcon }} onClick={this.handleEdit.bind(this)} />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <List component="ul" className={classes.list}>
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Enero
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{type.weight[0]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Febrero
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography primary="body2">
                            <b>{type.weight[1]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Marzo
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{type.weight[2]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Abril
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{type.weight[3]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Mayo
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography primary="body2">
                            <b>{type.weight[4]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Junio
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{type.weight[5]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <List component="ul" className={classes.list}>
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Julio
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{type.weight[6]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Agosto
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography primary="body2">
                            <b>{type.weight[7]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Septiembre
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{type.weight[8]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Octubre
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{type.weight[9]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Noviembre
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{type.weight[10]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Diciembre
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{type.weight[11]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </List>
                  </Grid>
                  {/*<Grid item xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Grid item xs={12} className={classes.billFooter}>
                    <div className={classes.billFooterItem}>
                      <Typography variant="body2">
                        {formatMessage(messages.customers.period)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                        13 diciembre 2017 - 13 enero 2018
                    </Typography>
                    </div>
                    <div className={classes.billFooterItem}>
                      <Typography variant="body2">
                        {formatMessage(messages.customers.payDay)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                        {type.enero}
                      </Typography>
                    </div>
                    <div className={classes.billFooterItem}>
                      <Typography variant="body2">
                        {formatMessage(messages.customers.payReference)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                        {type.enero}
                      </Typography>
                    </div>
                        </Grid>*/}
                  {role.update ?
                    <Grid item xs={12} className={classes.lastGridItem}>
                      <Typography variant='button' noWrap className={classes.link} onClick={this.handleEdit.bind(this)}>
                        Edit
                    </Typography>
                    </Grid>
                    :
                    null
                  }
                </Fragment>
              }
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Fragment>
    )
  }
};

export default connect(mapStateToProps)(withStyles(styles)(BusinessTypeCard));
