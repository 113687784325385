import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from './store';
import axios from 'axios';
import ConnectedIntlProvider from './constants/connect_intl_provider';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AUTHENTICATED, GET_COMPANY } from './constants/action_types';
import { url_companies, url_index } from './constants/links';
import './index.css';
import * as serviceWorker from './serviceWorker';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

addLocaleData([...en, ...es]);

async function checkLogin() {
  const user = localStorage.getItem('user');
  let data;
  const token = `token ${user}`;
  if (user) {
    await axios.get(url_index,
      {
        headers: { Authorization: token }
      })
      .then(response => {
        data = response.data //JSON.parse(response.data)[0].fields;
        store.dispatch({ type: AUTHENTICATED, payload: { token: user, ...data } });
        let url;
        if (response.data.is_superuser) {
          url = `${url_companies}`
        } else {
          const dominio = response.data.dominio ? response.data.dominio : 3
          url = `${url_companies}/${dominio}`
        };
        return axios.get(url, //Guardo la compañia a la que pertenece el usuario conectado
          {
            headers: { Authorization: token }
          })
      })
      .then(response => {
        //console.log(response)
        store.dispatch({ type: GET_COMPANY, payload: response.data });
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  };

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedIntlProvider>
        <Router>
          <App />
        </Router>
      </ConnectedIntlProvider>
    </Provider>
    , document.getElementById('root'));

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
};

checkLogin();
