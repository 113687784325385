import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 18,
    padding: "0px 7px",
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  expansion: {
    marginBottom: '10px'
  },
  expansionExpanded: {
    margin: '0 0 10px'
  },
  expansionDetails: {
    padding: 0
  },
  expansionTitle: {
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  expansionSummary: {
    padding: 0,
    height: 70
  },
  expandIcon: {
    marginRight: 6
  },
  titleSwitch: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  },
  subTitle: {
    [theme.breakpoints.up('sm')]: {
      padding: "0px 7px",
    },
    display: "inline"
  },
  summary: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  secondaryHeading: {
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  lists: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  billDetailsTitle: {
    display: 'inline'
  },
  editButton: {
    padding: 5,
    marginLeft: 5
  },
  editIcon: {
    fontSize: '1rem',
    display: 'none'
  },
  billFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  billFooterItem: {
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      margin: 0
    }
  },
  lastGridItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
});

function mapStateToProps(state) {
  return {
    loading: state.loader.loading,
    role: state.auth.user_type
  }
};

class InfoCard extends React.Component {
  state = {
    edit: false,
    values: {}
  };

  handleEdit() {
    this.setState({
      edit: !this.state.edit
    });
  };

  render() {
    const { edit } = this.state;
    const { intl: { formatMessage, formatNumber, formatDate }, intl, messages, classes, role } = this.props;
    return (
      <Fragment>
        <Grid
          container
          spacing={24}
          direction='row'
          justify='space-between'
          alignItems='flex-start'
        >
          {edit ?
            <Grid item xs={12}>
              {/*<BillEdit
                intl={intl}
                messages={messages}
                //initialValues={this.formatValues(bill)}
                edit={this.handleEdit.bind(this)}
              //fetchCard={this.props.fetchCard}
              //index={bill.id}
              />*/}
              Edit
            </Grid>
            :
            <Fragment>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.billDetailsTitle}>
                  Details
                </Typography>
                <IconButton aria-label="edit" classes={{ root: classes.editButton }}>
                  <EditIcon classes={{ root: classes.editIcon }} onClick={this.handleEdit.bind(this)} />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={5}>
                <List component="ul" className={classes.list}>
                  <ListItem disableGutters>
                    <ListItemText primary={
                      <Typography variant="body2" >
                        {formatMessage(messages.customers.aqueductConsumed)}
                      </Typography>} />
                    <ListItemSecondaryAction>
                      <Typography variant="body2">
                        <b>1000</b>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem disableGutters>
                    <ListItemText primary={
                      <Typography variant="body2" >
                        {formatMessage(messages.customers.aqueductValue)}
                      </Typography>} />
                    <ListItemSecondaryAction>
                      <Typography primary="body2">
                        <b>2000</b>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </List>
              </Grid>
              <Grid item xs={12} sm={5}>
                <List component="ul" className={classes.list}>
                  <ListItem disableGutters>
                    <ListItemText primary={
                      <Typography variant="body2" >
                        {formatMessage(messages.customers.sewerageConsumed)}
                      </Typography>} />
                    <ListItemSecondaryAction>
                      <Typography variant="body2">
                        <b>100</b>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                  <ListItem disableGutters>
                    <ListItemText primary={
                      <Typography variant="body2" >
                        {formatMessage(messages.customers.sewerageValue)}
                      </Typography>} />
                    <ListItemSecondaryAction>
                      <Typography primary="body2">
                        <b>4000</b>
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </List>
              </Grid>
              {/*<Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={12} className={classes.billFooter}>
                <div className={classes.billFooterItem}>
                  <Typography variant="body2">
                    {formatMessage(messages.customers.period)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                    13 diciembre 2017 - 13 enero 2018
                    </Typography>
                </div>
                <div className={classes.billFooterItem}>
                  <Typography variant="body2">
                    {formatMessage(messages.customers.payDay)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                    Fecha
                      </Typography>
                </div>
                <div className={classes.billFooterItem}>
                  <Typography variant="body2">
                    {formatMessage(messages.customers.payReference)}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                    Referencia
                      </Typography>
                </div>
                    </Grid>*/}
              {role.update ?
                <Grid item xs={12} className={classes.lastGridItem}>
                  <Typography variant='button' noWrap className={classes.link} onClick={this.handleEdit.bind(this)}>
                    Edit
                </Typography>
                </Grid>
                :
                null
              }
            </Fragment>
          }
        </Grid>
      </Fragment>
    )
  }
};

export default connect(mapStateToProps)(withStyles(styles)(InfoCard));
