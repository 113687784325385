import {
  GET_INVOICES,
  GET_INVOICES_ERROR,
  ADD_INVOICE,
  ADD_INVOICE_ERROR,
  UPDATE_INVOICE,
  UPDATE_INVOICE_ERROR,
  DELETE_INVOICE,
  DELETE_INVOICE_ERROR
} from '../constants/action_types';

const initialState = {};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case GET_INVOICES_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_INVOICE:
      return {
        ...state,
        error: false,
      };
    case ADD_INVOICE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_INVOICE:
      return {
        ...state,
        error: false,
      }
    case UPDATE_INVOICE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_INVOICE:
      return {
        ...state,
        error: false,
      }
    case DELETE_INVOICE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default invoiceReducer;
