import React, { Fragment } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import ProfileForm from './profileForm';
import ProfileInfo from './profileInfo';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import MapIcon from '@material-ui/icons/Map';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  root: {
    width: '100%',
    minHeight: "90vh", //Anteriormente 100vh
    [theme.breakpoints.up('sm')]: {
      //height: '132vh',
      overflowY: 'auto',
      height: "calc(100vh - 64px)"
    }
  },
  banner: {
    backgroundColor: 'white',
    paddingBottom: 8,
    paddingTop: 8,
    /* Banner fixed */
    width: '100%',
    top: '56px',
    zIndex: 1,
    position: 'fixed',
    /*--------------*/
    [theme.breakpoints.up('sm')]: {
      /* Banner fixed */
      top: '64px',
      width: 'calc(100% - 250px)',
      /*--------------*/
    }
  },
  title: {
    fontSize: 30
  },
  titleProfile: {
    fontSize: 25,
    //fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#233b54'
  },
  subTitle: {
    fontSize: 17,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal'
  },
  profileArea: {
    marginTop: '68px',
    padding: '15px 15px 76px 15px',
    maxWidth: 1100,
    margin: '0 auto'
  },
  divAnimation: {
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out'
  },
  paper: {
    padding: 15,
    marginBottom: 15
  },
  grid: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  infoGridItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  infoDiv: {
    marginTop: 5,
    height: 110,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 500
  },
  bigAvatar: {
    width: 90,
    height: 90,
    [theme.breakpoints.up('sm')]: {
      width: 120,
      height: 120,
      margin: 6
    }
  },
  buttons: {
    width: 250,
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 5,
    [theme.breakpoints.up('sm')]: {
      //display: 'none'
    }
  },
  iconButton: {
    width: 48,
    height: 48
  },
  buttonsLinks: {
    color: 'inherit'
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    employee: state.employee.employee
  }
};

class ProfileCard extends React.Component {
  state = {
    company: {},
    show: false
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    axios.get(this.props.employee.company,
      {
        headers: { Authorization: token }
      })
      .then(response => {
        this.setState({
          company: {
            id: response.data.id,
            name: this.capitalize(response.data.name)
          }
        })
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleShow() {
    this.setState({
      show: !this.state.show
    });
  };

  render() {
    const { intl: { formatMessage }, intl, classes, messages, role, match, employee } = this.props;
    const { show, company } = this.state;
    console.log(this.props.employee)
    return (
      <div className={classes.root}>
        <div className={classes.banner}>
          <List component="ul">
            <ListItem >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                {formatMessage(messages.navBar.profile)}
              </Typography>} />
              <ListItemSecondaryAction style={{ marginRight: 10 }}>
                <IconButton aria-label="edit" onClick={this.handleShow.bind(this)}>
                  {role.update ?
                    show ?
                      <ArrowLeftIcon />
                      :
                      <EditIcon />
                    :
                    null
                  }
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
        <div className={classes.profileArea}>
          {show ?
            <Slide direction="left" in={show} mountOnEnter unmountOnExit timeout={500}>
              <ProfileForm
                history={this.props.history}
                intl={intl}
                messages={messages}
                match={match}
                fetchSellerIndex={this.props.fetchSellerIndex}
                showCard={this.handleShow.bind(this)}
                companyId={company.id}
                initialValues={employee}
              />
            </Slide>
            :
            <div>
              <Paper className={classes.paper}>
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                  className={classes.grid}
                >
                  <Grid item xs={12} className={classes.infoGridItem}>
                    <Avatar alt="avatar" src={employee.photo} className={classes.bigAvatar} />
                    <div className={classes.infoDiv}>
                      <Typography className={classes.titleProfile}>
                        {`${this.capitalize(employee.first_name)} ${this.capitalize(employee.last_name)}`}
                      </Typography>
                      <Typography className={classes.subTitle}>
                        {this.capitalize(employee.identification_type)} {employee.identification}
                      </Typography>
                      <div className={classes.buttons}>
                        <IconButton classes={{ root: classes.iconButton }}>
                          <a href={`mailto: ${employee.email}`} className={classes.buttonsLinks}>
                            <EmailIcon />
                          </a>
                        </IconButton>
                        <IconButton classes={{ root: classes.iconButton }}>
                          <a href={`tel: ${employee.phone}`} className={classes.buttonsLinks}>
                            <PhoneIcon />
                          </a>
                        </IconButton>
                        <IconButton classes={{ root: classes.iconButton }}>
                          <a target="_blank" rel="noopener noreferrer" href={`https://maps.google.com/?q=${employee.__other_information__.city}`} className={classes.buttonsLinks}>
                            <MapIcon />
                          </a>
                        </IconButton>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <ProfileInfo
                  seller={employee}
                  intl={intl}
                  messages={messages}
                  city={this.capitalize(employee.__other_information__.city)}
                  commission={this.capitalize(employee.__other_information__.commission)}
                  company={this.capitalize(employee.__other_information__.company)}
                />
              </Paper>
            </div>
          }
        </div>
      </div>
    )
  }
};

export default connect(mapStateToProps)(withStyles(styles)(ProfileCard));
