import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import messages from './locales';

const language = navigator.language.split(/[-_]/)[0] === 'en' || navigator.language.split(/[-_]/)[0] === 'es' ? navigator.language.split(/[-_]/)[0] : 'en';

const initialState = {
  intl: {
    locale: language,
    messages: messages
  }
};

const store = createStore(rootReducer, initialState, composeWithDevTools(
  applyMiddleware(thunk)
));

export default store;
