import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import BillEdit from './billEdit';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 18,
    padding: "0px 7px",
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  expansion: {
    marginBottom: '10px',
    '&:before': {
      display: 'none',
    }
  },
  expansionExpanded: {
    margin: '0 0 10px'
  },
  expansionDetails: {
    padding: 0
  },
  expansionTitle: {
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  expansionSummary: {
    padding: 0,
    height: 70
  },
  expandIcon: {
    marginRight: 6
  },
  titleSwitch: {
    height: 45, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
  },
  subTitle: {
    display: "inline"
  },
  summary: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  secondaryHeading: {
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  lists: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  billDetailsTitle: {
    display: 'inline'
  },
  editButton: {
    padding: 5,
    marginLeft: 5
  },
  editIcon: {
    fontSize: '1rem',
    //display: 'none'
  },
  billFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  billFooterItem: {
    marginBottom: '5px',
    [theme.breakpoints.up('sm')]: {
      margin: 0
    }
  },
  lastGridItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type
  }
};

class BillCard extends React.Component {
  state = {
    editBill: false,
    values: {}
  };

  handleEdit() {
    this.setState({
      editBill: !this.state.editBill
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  formatValues(bill) {
    const values = {
      ...bill,
      bill_date: bill.bill_date.split('-').join('/'),
      cut_off_date: bill.cut_off_date.split('-').join('/')
    }

    return values;
  };

  render() {
    const { editBill } = this.state;
    const { expanded, bill, intl: { formatMessage, formatNumber, formatDate }, intl, messages, classes, index, loading, role } = this.props;
    console.log(expanded)
    return (
      <Fragment>
        {loading && editBill ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <ExpansionPanel expanded={expanded === `panel${index}`} onChange={this.props.change(`panel${index}`)} square={false} classes={{ root: classes.expansion, expanded: classes.expansionExpanded }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.summary}>
              {editBill ?
                <div className={classes.titleContainer}>
                  <AssignmentIcon className={classes.icon} />
                  <Typography component="h2" variant="h2" className={classes.title}>
                    Update Bill
                </Typography>
                </div>
                :
                <Fragment>
                  <Typography variant="h3" className={classes.expansionTitle}>
                    <span>
                      {/*formatMessage(messages.customers.abill)*/}
                    </span>
                    <div className={classes.titleSwitch}>
                      <b className={classes.subTitle}>{bill["cubic_meters"]} m3</b>
                      <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                        Bill Number: {bill["bill_number"]}
                      </Typography>
                    </div>
                    {/*<b className={classes.subTitle}>{formatNumber(bill["price_cubic_meters"] * bill["cubic_meters"], { style: "currency", currency: "COP" })}</b>*/}
                  </Typography>
                  <Typography style={{ display: 'flex', alignItems: 'center ' }} className={classes.secondaryHeading} color="textSecondary" noWrap>{formatDate(bill["bill_date"], { year: "numeric", month: "long", day: "2-digit", timeZone: 'GMT' })}</Typography>
                </Fragment>
              }
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              spacing={24}
              direction='row'
              justify='space-between'
              alignItems='flex-start'
            >
              {editBill ?
                <Grid item xs={12}>
                  <BillEdit
                    intl={intl}
                    messages={messages}
                    initialValues={this.formatValues(bill)}
                    edit={this.handleEdit.bind(this)}
                    fetchArea={this.props.fetchCard}
                    index={bill.id}
                  />
                </Grid>
                :
                <Fragment>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.billDetailsTitle}>
                      {formatMessage(messages.customers.details)}
                    </Typography>
                    {role.update ?
                      <IconButton aria-label="edit" classes={{ root: classes.editButton }}>
                        <EditIcon classes={{ root: classes.editIcon }} onClick={this.handleEdit.bind(this)} />
                      </IconButton>
                      :
                      null
                    }
                    {bill["bill_support"] ?
                      <a href={`${bill["bill_support"]}`} target="_blank" rel="noopener noreferrer">
                        <IconButton aria-label="edit" classes={{ root: classes.editButton }}>
                          <PdfIcon classes={{ root: classes.editIcon }} />
                        </IconButton>
                      </a>
                      :
                      null
                    }
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <List component="ul" className={classes.list}>
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            {formatMessage(messages.customers.aqueductConsumed)}
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{bill["cubic_meters"]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      {/*<ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            {formatMessage(messages.customers.aqueductValue)}
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography primary="body2">
                            <b>{formatNumber(bill["price_cubic_meters"], { style: "currency", currency: "COP" })}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Total {formatMessage(messages.customers.aqueduct)}
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{formatNumber(bill["price_cubic_meters"] * bill["cubic_meters"], { style: "currency", currency: "COP" })}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                        <Divider />*/}
                    </List>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <List component="ul" className={classes.list}>
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Hydrometer
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{bill["hydrometer"]}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      {/*<ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            {formatMessage(messages.customers.sewerageValue)}
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography primary="body2">
                            <b>{formatNumber(bill["price_cubic_meters_sewerage"], { style: "currency", currency: "COP" })}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                      <ListItem disableGutters>
                        <ListItemText primary={
                          <Typography variant="body2" >
                            Total {formatMessage(messages.customers.sewerage)}
                          </Typography>} />
                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            <b>{formatNumber(bill["price_cubic_meters_sewerage"] * bill["cubic_meters_sewerage"], { style: "currency", currency: "COP" })}</b>
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                        <Divider />*/}
                    </List>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} className={classes.billFooter}>
                    <div className={classes.billFooterItem}>
                      <Typography variant="body2">
                        {formatMessage(messages.customers.period)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                        {this.capitalize(bill["billing_cycle"])}
                      </Typography>
                    </div>
                    <div className={classes.billFooterItem}>
                      <Typography variant="body2">
                        {formatMessage(messages.customers.payDay)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                        {formatDate(bill["cut_off_date"], { year: "numeric", month: "long", day: "2-digit", timeZone: 'GMT' })}
                      </Typography>
                    </div>
                    <div className={classes.billFooterItem}>
                      <Typography variant="body2">
                        {formatMessage(messages.customers.payReference)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                        {bill["bill_reference"]}
                      </Typography>
                    </div>
                  </Grid>
                  {/*<Grid item xs={12} className={classes.lastGridItem}>
                    <Typography variant='button' noWrap className={classes.link} onClick={this.handleEdit.bind(this)}>
                      Edit bill
                    </Typography>
                    {bill["bill_support"] ?
                      <Typography variant='button' noWrap >
                        <a href={`${bill["bill_support"]}`} target="_blank" rel="noopener noreferrer">{formatMessage(messages.customers.link)}</a>
                      </Typography>
                      :
                      null
                    }
                  </Grid>*/}
                </Fragment>
              }
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Fragment>
    )
  }
};

export default connect(mapStateToProps)(withStyles(styles)(BillCard));
