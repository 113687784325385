import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateBooking } from '../../actions';
import Toolbar from './toolbar';
import CustomEvent from './customEvent';

import BigCalendar from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import ModalForm from './modalForm';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = BigCalendar.momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar)

function mapDispatchToProps(dispatch) {
  return {
    updateBooking: (data, token, id) => dispatch(updateBooking(data, token, id))
  }
};

function mapStateToProps(state) {
  return {
    locale: state.intl.locale,
    token: state.auth.token
  }
};

const styles = theme => ({
  card: {
    width: 320,
    position: 'relative'
  },
  header: {
    paddingTop: 8,
    paddingBottom: 0
  },
  iconButton: {
    color: 'white'
  },
  iconContainer: {
    height: 45,
    display: 'flex',
    alignItems: 'center',
    paddingRight: 5,
    paddingTop: 8
  },
  content: {
    padding: '0 40px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 48px',
    }
  },
  contentItems: {
    display: 'flex',
    alignItems: 'center',
    padding: '11px 0'
  },
  eventDisplayText: {
    color: 'white',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '25px'
  },
  eventModalTitle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30
  },
  editButton: {
    padding: 5,
    marginLeft: 5
  },
  editIcon: {
    fontSize: '1.2rem'
  },
});

class CalendarView extends Component {
  state = {
    modal: false,
    startDateCalendar: new Date(),
    date: new Date(),
    id: '',
    start: '',
    end: '',
    description: '',
    type_booking: '',
    status: '',
    customer: '',
    seller: '',
    events: []
  };

  componentWillReceiveProps(nextProps) {
    let events = [];
    nextProps.appointments.map(event =>
      events.push({
        ...event,
        start: new Date(event.start_time),
        end: new Date(event.end_time)
      }));
    this.setState({
      events: events
    });
  };

  componentDidMount() {
    const { locale, appointments } = this.props;
    moment.locale(locale);
  };

  handleModal(values) {
    //console.log(values)
    if (values) {
      this.setState({
        id: values.id,
        start: values.start,
        end: values.end,
        description: values.description,
        type_booking: values.type_booking,
        status: values.status,
        customer: values.customer,
        seller: values.seller,
        modal: !this.state.modal
      });
    } else {
      this.setState({
        id: '',
        start: this.state.start,
        end: this.state.end,
        description: '',
        type_booking: '',
        status: '',
        customer: '',
        seller: '',
        modal: !this.state.modal
      });
    };
  };

  handleSelect({ start, end }) {
    this.setState({
      start: start,
      end: end
    });
    this.handleModal();
  };

  deleteValues() {
    this.setState({
      start: '',
      end: ''
    });
  };

  moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {
    const { events } = this.state

    const idx = events.indexOf(event)
    let allDay = event.allDay

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const updatedEvent = { ...event, start, end, allDay, start_time: moment(start).format(), end_time: moment(end).format() }

    const nextEvents = [...events]
    nextEvents.splice(idx, 1, updatedEvent);

    this.setState({
      events: nextEvents,
    });

    const token = 'token '.concat(this.props.token);
    this.props.updateBooking(updatedEvent, token, updatedEvent.id);
  };

  // eventStyleGetter(event, start, end, isSelected) {
  //   console.log(event);
  //   const backgroundColor = '#' + event.hexColor;
  //   const style = {
  //     backgroundColor: backgroundColor,
  //     borderRadius: '0px',
  //     opacity: 0.8,
  //     color: 'black',
  //     border: '0px',
  //     display: 'block'
  //   };
  //   return {
  //     style: style
  //   };
  // };

  render() {
    const { start, end, description, type_booking, status, customer, seller, modal, date, startDateCalendar, events, id } = this.state;
    const { intl, theme, appointments } = this.props;
    const handleModal = this.handleModal.bind(this);
    return (
      <div style={{ height: '82vh', position: 'relative' }}>
        <DragAndDropCalendar
          selectable
          localizer={localizer}
          events={events}
          defaultView={BigCalendar.Views.MONTH}
          views={{
            month: true,
            week: true,
            day: true,
            agenda: false
          }}
          scrollToTime={new Date(1970, 1, 1, 6)}
          min={new Date(2008, 0, 1, 8, 0)}
          max={new Date(2008, 0, 1, 17, 0)}
          date={startDateCalendar}
          onNavigate={(date) => { this.setState({ startDateCalendar: date }) }}
          onEventDrop={this.moveEvent.bind(this)}
          onSelectSlot={this.handleSelect.bind(this)}
          components={{
            toolbar: Toolbar,
            event: withStyles(styles)(CustomEvent({ handleModal }))
          }}
          eventPropGetter={
            (event, start, end, isSelected) => {
              let newStyle = {
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                borderRadius: "5px",
                border: "none",
              };
              return {
                className: "",
                style: newStyle
              };
            }
          }
        />
        {modal ?
          <ModalForm
            open={modal}
            handleClose={this.handleModal.bind(this)}
            initialValues={{ start, end, status, type_booking, description, customer, seller }} identification={id} intl={intl}
            //add={this.addEvents.bind(this)}
            fetchCalendar={this.props.fetchCalendar}
          />
          :
          null
        }
      </div>
    );
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(CalendarView));
