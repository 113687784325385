import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import ToolBarTitle from '../../toolbar';
import { url_companies } from '../../../constants/links';
import ListCompanies from './list';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  root: {
    padding: '5px 10px 15px',
    maxWidth: 1200,
    margin: '0 auto'
  },
  toolbar: {
    //padding: theme.spacing.unit,
    padding: 0,
    whiteSpace: 'nowrap'
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    marginLeft: 10,
    fontSize: 30
  },
  card: {
    margin: '3px'
  },
  arrowIcon: {
    fontSize: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px'
    }
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    dominio: state.auth.dominio
  }
};

class SettingsRoot extends React.Component {
  state = {
    data: [],
    count: 0,
    loading: true,
    page_size: 5,
    next: `${url_companies}?page_size=10`,
    previous: '',
    scrolling: false
  };

  componentWillMount() {
    if (this.props.role.label !== 'root') {
      this.props.history.push(`/settings/${this.props.dominio}`)
    };
  };

  componentDidMount() {
    this.fetchGet();
    window.addEventListener('scroll', this.handleScroll);

    //console.log(document.querySelector('div#list'))
  };

  componentWillUnmount() {
    //console.log('desmontado');
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = () => {
    const { scrolling, count, data } = this.state;
    //if (scrolling) return console.log('ya');
    if (count <= data.length) return
    const lastItem = document.querySelector('ul#companies > div:last-child');
    const lastItemOffset = lastItem.offsetTop + lastItem.clientHeight;
    const pageOffset = window.pageYOffset + window.innerHeight;
    let bottomOffset = 200;
    if (pageOffset > lastItemOffset - bottomOffset) this.loadMore();
  };

  fetchGet() {
    const { page_size, data, next } = this.state;
    const token = 'token '.concat(this.props.token);
    axios.get(next,
      {
        headers: { Authorization: token }
      })
      .then(response => {
        this.setState({
          data: [...data, ...response.data.results],
          page_size: page_size + 5,
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          scrolling: false,
          loading: false
        })
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  };

  loadMore() {
    this.setState(prevState => ({
      scrolling: true
    }), this.fetchGet)
  };

  render() {
    const { classes, intl, messages, match, history } = this.props;
    const { data } = this.state;
    return (
      <div className={classes.root} >
        <ToolBarTitle url={`${match.url}/addCompany`} title="Companies" tooltip="Add Company" />
        <Paper className={classes.card} id="list">
          <ListCompanies intl={intl} messages={messages} history={history} data={data} />
        </Paper>
      </div>
    )
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SettingsRoot));
