import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme => ({
  langBar: {
    fontSize: 12,
    margin: 0
    /*margin: '0px 24px',
    [theme.breakpoints.up('sm')]: {
      margin: '10px 0px 0px'
    }*/
  }
});

function mapStateToProps(state) {
  return {
    loading: state.loader.loading
  }
};

class PricesCard extends React.Component {
  state = {
    year: 'Year',
    stratum: 'Stratum'
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  render() {
    const { intl: { formatMessage, formatNumber, formatDate }, intl, messages, classes } = this.props;
    return (
      <Fragment>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Select
            value={this.state.year}
            onChange={this.handleChange.bind(this)}
            name="year"
            renderValue={value => `${value}`}
            className={classes.langBar}
          >
            <MenuItem value='2018'>
              <Typography variant='caption'>
                2018
            </Typography>
            </MenuItem>
            <MenuItem value='2019'>
              <Typography variant='caption'>
                2019
            </Typography>
            </MenuItem>
          </Select>
          <Select
            value={this.state.stratum}
            onChange={this.handleChange.bind(this)}
            name="stratum"
            renderValue={value => `${value}`}
            className={classes.langBar}
          >
            <MenuItem value='1'>
              <Typography variant='caption'>
                1
            </Typography>
            </MenuItem>
            <MenuItem value='2'>
              <Typography variant='caption'>
                2
            </Typography>
            </MenuItem>
            <MenuItem value='3'>
              <Typography variant='caption'>
                3
            </Typography>
            </MenuItem>
            <MenuItem value='4'>
              <Typography variant='caption'>
                4
            </Typography>
            </MenuItem>
            <MenuItem value='5'>
              <Typography variant='caption'>
                5
            </Typography>
            </MenuItem>
            <MenuItem value='6'>
              <Typography variant='caption'>
                6
            </Typography>
            </MenuItem>
          </Select>
        </div>
        <div>
          <Typography paragraph>
            Price
          </Typography>
        </div>
      </Fragment>
    )
  }
};

export default connect(mapStateToProps)(withStyles(styles)(PricesCard));
