import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  gridItem: {
    textAlign: 'end'
  },
  list: {
    width: '100%'
  },
  rootExpansion: {
    //border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    /*'&:not(:last-child)': {
      borderBottom: 0,
    },*/
    '&:before': {
      display: 'none',
    }
  },
  expanded: {
    margin: '0px'
  },
  expansionTitle: {
    //fontSize: 17,
    //fontWeight: 'bold',
    //fontStyle: 'normal',
    //fontStretch: 'normal',
    //lineHeight: 'normal',
    //letterSpacing: 0.6,
    //color: '#233b54',
    //display: 'none',
    //[theme.breakpoints.up('sm')]: {
    //display: 'block'
    //}
    fontSize: 25
  },
  subValue: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.4
  }
});

class ProfileInfo extends React.Component {
  state = {
    expanded: true
  };

  handleExpand() {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  capitalize(string) {
    if (string) {
      const array = string.split(' ');
      let upperCaseArray = [];

      if (array.length > 1) {
        array.forEach(item =>
          upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
        );
        return upperCaseArray.join(' ');
      }

      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  render() {
    const { intl, classes, messages, seller, city, commission, company } = this.props;
    const { expanded } = this.state;
    return (
      <ExpansionPanel
        expanded={expanded}
        classes={{ root: classes.rootExpansion, expanded: classes.expanded }}
      >
        <ExpansionPanelSummary onClick={this.handleExpand.bind(this)} expandIcon={<ExpandMoreIcon />}>
          <Typography component="h2" variant="h2" className={classes.expansionTitle}>Basic Information</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid
            container
            direction='row'
            justify='space-between'
            alignItems='center'
            className={classes.grid}
          >
            <Grid item xs={12} sm={5}>
              <List component="ul" className={classes.list}>
                <ListItem disableGutters >
                  <Grid
                    container
                    spacing={0}
                    direction='row'
                  >
                    {seller.email ?
                      <Fragment>
                        <Grid item xs={4}>
                          <Typography noWrap component="p">
                            Email
                      </Typography>
                        </Grid>
                        <Grid item xs={8} className={classes.gridItem}>
                          <Typography noWrap component="p" style={{ fontWeight: 450 }}>
                            {seller.email ? this.capitalize(seller.email) : ''}
                          </Typography>
                        </Grid>
                      </Fragment>
                      :
                      null
                    }
                  </Grid>
                </ListItem>
                <ListItem disableGutters >
                  <Grid
                    container
                    spacing={0}
                    direction='row'
                  >
                    <Grid item xs={4}>
                      <Typography noWrap component="p">
                        City
                      </Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.gridItem}>
                      <Typography noWrap component="p" style={{ fontWeight: 450 }}>
                        {city}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem disableGutters >
                  <Grid
                    container
                    spacing={0}
                    direction='row'
                  >
                    <Grid item xs={4}>
                      <Typography noWrap component="p">
                        Address
                      </Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.gridItem}>
                      <Typography noWrap component="p" style={{ fontWeight: 450 }}>
                        {this.capitalize(seller.address)}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={5}>
              <List component="ul" className={classes.list}>
                <ListItem disableGutters >
                  <Grid
                    container
                    spacing={0}
                    direction='row'
                  >
                    <Grid item xs={4}>
                      <Typography noWrap component="p">
                        Phone
                      </Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.gridItem}>
                      <Typography noWrap component="p" style={{ fontWeight: 450 }}>
                        {seller.phone}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem disableGutters >
                  <Grid
                    container
                    spacing={0}
                    direction='row'
                  >
                    <Grid item xs={4}>
                      <Typography noWrap component="p">
                        Commission
                      </Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.gridItem}>
                      <Typography noWrap component="p" style={{ fontWeight: 450 }}>
                        {commission}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem disableGutters >
                  <Grid
                    container
                    spacing={0}
                    direction='row'
                  >
                    <Grid item xs={4}>
                      <Typography noWrap component="p">
                        Company
                      </Typography>
                    </Grid>
                    <Grid item xs={8} className={classes.gridItem}>
                      <Typography noWrap component="p" style={{ fontWeight: 450 }}>
                        {company}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
};

export default withStyles(styles)(ProfileInfo);
