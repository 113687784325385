import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import BuildingIcon from '@material-ui/icons/AccountBalance';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import InputBase from '@material-ui/core/InputBase';

import data4 from '../../data4.json';

const styles = theme => ({
  root: {
    //marginTop: theme.spacing.unit * 3,
    [theme.breakpoints.up('sm')]: {
      padding: '5px 24px 15px',
    }
  },
  table: {
    //minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  toolbar: {
    padding: theme.spacing.unit,
    whiteSpace: 'nowrap'
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    marginLeft: 10,
    fontSize: 30
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    transition: theme.transitions.create('width'),
    width: 0,
    '&:focus': {
      width: 50,
      [theme.breakpoints.up(370)]: {
        width: 90
      },
      [theme.breakpoints.up('sm')]: {
        width: 200
      }
    }
  },
  speedDialRoot: {
    width: 48,
    height: 48,
    display: 'block',
    zIndex: 0
  },
  speedDialFab: {
    color: 'grey',
    backgroundColor: 'white',
    width: 48,
    height: 48,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    },
    '&:focus': {
      //backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  },
  speedDialActions: {
    alignItems: 'center'
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.role
  }
};

class TableSellers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      next: '',
      previous: '',
      sort: [
        {
          title: 'name', order: 'asc'
        },
        {
          title: 'email', order: 'asc'
        },
        {
          title: 'phone', order: 'asc'
        }],
      data: [],
      page: 0,
      rowsPerPage: 5,
      exact: {
        title: 'name',
        order: 'desc'
      },
      loading: true,
      menu: false
    };
    this.textInput = React.createRef();
  };

  componentDidMount() {
    this.setState({
      data: data4,
      loading: false
    });
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleRedirect(id) {
    this.props.history.push(`/sellers/${id}`)
  };

  handleSort(title) {
    const type = this.handleTitle(title);
    const index = this.state.sort.findIndex(item => item.title === type.title);

    if (type.order === 'asc') {
      this.setState({
        sort: this.state.sort.map((item, i) => i === index ? { title: title, order: 'desc' } : item),
        exact: type
      })
    } else {
      this.setState({
        sort: this.state.sort.map((item, i) => i === index ? { title: title, order: 'asc' } : item),
        exact: type
      })
    }
  };

  handleTitle(title) {
    const type = this.state.sort.find((item, index) => { return item.title === title });
    return type
  };

  handleTypeSort() {
    const type = this.state.exact;
    const sortData = this.state.data;

    if (type.order === 'asc') {
      if (type.title === 'name') {
        return sortData.sort((a, b) => a.name > b.name ? 1 : -1)
      } else if (type.title === 'email') {
        return sortData.sort((a, b) => a.nit > b.nit ? 1 : -1)
      } else if (type.title === 'phone') {
        return sortData.sort((a, b) => a.nit > b.nit ? 1 : -1)
      }
    } else {
      if (type.title === 'name') {
        return sortData.sort((a, b) => a.name < b.name ? 1 : -1)
      } else if (type.title === 'email') {
        return sortData.sort((a, b) => a.nit < b.nit ? 1 : -1)
      } else if (type.title === 'phone') {
        return sortData.sort((a, b) => a.nit < b.nit ? 1 : -1)
      }
    }
  };

  handleChangePage = (event, page) => {
    this.setState({ loading: true });
    console.log('cambio de pagina');
    if (page > this.state.page) {
      console.log(this.state.next);
      const token = 'token '.concat(this.props.token);
      axios.get(this.state.next,
        {
          headers: { Authorization: token }
        })
        .then(response => {
          console.log(response.data);
          this.setState({
            data: response.data.results,
            loading: false,
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            page: page
          })
        })
        .catch((error) => {
          console.log('error ' + error);
        });
    } else {
      console.log(this.state.previous);
      const token = 'token '.concat(this.props.token);
      axios.get(this.state.previous,
        {
          headers: { Authorization: token }
        })
        .then(response => {
          console.log(response.data);
          this.setState({
            data: response.data.results,
            loading: false,
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            page: page
          })
        })
        .catch((error) => {
          console.log('error ' + error);
        });
    }
  };

  handleChangeRowsPerPage = event => {
    this.setState({ loading: true });
    const token = 'token '.concat(this.props.token);
    axios.get('http://192.168.0.17:8000/rest/customer/customer',
      {
        headers: { Authorization: token },
        params: { page: this.state.page + 1, page_size: event.target.value }
      })
      .then(response => {
        console.log(response.data.results);
        this.setState({
          data: response.data.results,
          loading: false,
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          rowsPerPage: event.target.value
        })
      })
      .catch((error) => {
        console.log('error ' + error);
        this.setState({ loading: false });
      });
  };

  handleSearch(event) {
    event.persist();
    this.setState({ loading: true });
    let params;
    const token = 'token '.concat(this.props.token);
    if (event.target.value === '') {
      params = {
        headers: { Authorization: token },
        params: { page_size: this.state.rowsPerPage }
      }
    } else {
      params = {
        headers: { Authorization: token },
        params: { query: event.target.value }
      }
    }
    axios.get('http://192.168.0.17:8000/rest/customer/customer', params)
      .then(response => {
        console.log(response.data.results);
        this.setState({
          data: response.data.results,
          loading: false,
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous
        })
      })
      .catch((error) => {
        console.log('error ' + error);
        this.setState({ loading: false });
      });
  };

  focus() {
    console.log('intentando')
    this.textInput.current.focus();
    this.toggleMenu(false)
  };

  toggleMenu() {
    this.setState({
      menu: !this.state.menu
    })
  };

  render() {
    const { intl: { formatMessage }, classes, match, messages, role } = this.props;
    const { rowsPerPage, loading } = this.state;
    const name = formatMessage(messages.customers.formName);
    const email = formatMessage(messages.general.email);
    const sellers = formatMessage(messages.navBar.sellers);
    const phone = formatMessage(messages.general.phone);
    const table_titles = [
      {
        title: name,
        label: 'name'
      },
      {
        title: email,
        label: 'email'
      },
      {
        title: phone,
        label: 'phone'
      }
    ];

    return (
      <div className={classes.root} >
        <Toolbar className={classes.toolbar}>
          <BuildingIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
          <Typography component="h2" variant="h2" className={classes.title}>
            {sellers}
          </Typography>
          <div className={classes.spacer} />
          {role === 'admin' || role === 'root' ?
            <Tooltip title="Add customer">
              <Link to={`${match.url}/signup`}>
                <IconButton aria-label="Add customer">
                  <AddIcon />
                </IconButton>
              </Link>
            </Tooltip>
            :
            null
          }
          {/*<InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={(event) => this.handleSearch(event)}
            inputRef={this.textInput}
          />*/}
          {/*<Tooltip title="Sort">
            <IconButton aria-label="Sort" >
              <FilterListIcon onClick={() => this.toggleMenu()} />
            </IconButton>
          </Tooltip>*/}
          {/*<SpeedDial
            ariaLabel="Sort"
            className={classes.speedDial}
            hidden={false}
            icon={<Tooltip title="Sort">
              <FilterListIcon />
            </Tooltip>}
            //onBlur={this.handleClose}
            onClick={() => this.toggleMenu()}
            //onClose={this.handleClose}
            //onFocus={this.handleOpen}
            //onMouseEnter={this.handleOpen}
            //onMouseLeave={() => this.toggleMenu(false)}
            open={menu}
            direction='down'
            classes={{
              root: classes.speedDialRoot,
              fab: classes.speedDialFab,
              actions: classes.speedDialActions,
            }}
          >
            <SpeedDialAction
              icon={<AddIcon />}
              tooltipTitle="Search"
              tooltipOpen
              onClick={() => this.focus()}

            />
          </SpeedDial>*/}
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading ?
            <CircularProgress style={{ position: "absolute", top: "50%", margin: '0 50%' }} />
            :
            <Table className={classes.table} aria-labelledby="tableTitle" padding="default">
              <TableHead>
                <TableRow>
                  {table_titles.map((item, index) =>
                    <TableCell
                      align='right'
                      sortDirection={this.handleTitle(item.label).order}
                      key={index}
                    >
                      <Tooltip
                        title="Sort"
                        placement="bottom-start"
                        enterDelay={500}
                      >
                        <TableSortLabel
                          active={true}
                          direction={this.handleTitle(item.label).order}
                          onClick={this.handleSort.bind(this, item.label)}
                        >
                          {item.title}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {this.handleTypeSort().slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage)
                  .map((n, index) =>
                    <TableRow
                      hover
                      onClick={this.handleRedirect.bind(this, n.id)}
                      tabIndex={-1}
                      key={n.id}
                    >
                      <TableCell component="th" scope="row" align="left">
                        {this.capitalize(n.name)}
                      </TableCell>
                      <TableCell align="right">{n.email}</TableCell>
                      <TableCell align="right">{n.phone}</TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          }
        </div>
        {/*loading ? null
          :
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={this.state.count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          */}
      </div>
    );
  }
}


export default connect(mapStateToProps)(withStyles(styles)(TableSellers));
