import {
  GET_VALVE,
  GET_VALVE_ERROR,
  GET_VALVES,
  GET_VALVES_ERROR,
  ADD_VALVE,
  ADD_VALVE_ERROR,
  UPDATE_VALVE,
  UPDATE_VALVE_ERROR
} from '../constants/action_types';

const initialState = {};

const valveReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VALVE:
      return {
        ...state,
        valve: action.payload
      };
    case GET_VALVE_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case GET_VALVES:
      return {
        ...state,
        valves: action.payload,
      };
    case GET_VALVES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_VALVE:
      return {
        ...state,
        error: false,
      };
    case ADD_VALVE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_VALVE:
      return {
        ...state,
        error: false,
      }
    case UPDATE_VALVE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default valveReducer;
