import {
  GET_COMMISSIONS,
  GET_COMMISSIONS_ERROR,
  ADD_COMMISSION,
  ADD_COMMISSION_ERROR,
  UPDATE_COMMISSION,
  UPDATE_COMMISSION_ERROR
} from '../constants/action_types';

const initialState = {};

const commissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMISSIONS:
      return {
        ...state,
        commissions: action.payload
      };
    case GET_COMMISSIONS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_COMMISSION:
      return {
        ...state,
        error: false,
      };
    case ADD_COMMISSION_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_COMMISSION:
      return {
        ...state,
        error: false,
      }
    case UPDATE_COMMISSION_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default commissionReducer;
