import React from 'react';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import LineChart from 'recharts/lib/chart/LineChart';
import Line from 'recharts/lib/cartesian/Line';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ChartIcon from '@material-ui/icons/BarChart';
import Typography from '@material-ui/core/Typography';

const data = [
  { name: 'Enero', ganancia: 2400 },
  { name: 'Febrero', ganancia: 1398 },
  { name: 'Marzo', ganancia: 9800 },
  { name: 'Abril', ganancia: 3908 },
  { name: 'Mayo', ganancia: 4800 },
  { name: 'Junio', ganancia: 3800 },
  { name: 'Agosto', ganancia: 4300 },
];

class ChartCard extends React.Component {
  render() {
    const { title, intl: { formatMessage }, ele, banner } = this.props
    return (
      <Paper style={{ width: '100%' }} elevation={ele ? 1 : 0}>
        {banner ?
          <List component="ul">
            <ListItem >
              <ListItemIcon>
                <ChartIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography component="h2" variant="h6" >
                {formatMessage(title)}
              </Typography>} />
            </ListItem>
          </List>
          :
          null
        }
        <ResponsiveContainer width="99%" height={213}>
          <LineChart width={400} height={213} data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="ganancia" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </ResponsiveContainer>
      </Paper>
    );
  }
}

export default ChartCard;
