import { RECOVERY_SUCCESS, RECOVERY_ERROR } from '../constants/action_types';

const initialState = {};

const recoveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECOVERY_SUCCESS:
      return state
    case RECOVERY_ERROR:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state;
  }
};

export default recoveryReducer;
