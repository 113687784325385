import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
    button: {
      textTransform: 'none',
      color: 'none'
    },
  },
  palette: {
    primary: {
      dark: '#0e448a',
      main: '#1562c6',
      light: '#4381d1',
      contrastText: '#fff'
    },
    error: {
      dark: '#972119',
      main: '#d93025',
      light: '#e05950'
    },
    secondary: {
      dark: '#972119',
      main: '#d93025',
      light: '#e05950'
    },
    newGrey: {
      dark: 'rgb(0, 0, 0, 0)',
      main: 'rgba(0, 0, 0, 0.60)',
      light: 'rgba(0, 0, 0, 0.30)'
    },
    primaryRgba: {
      light: 'rgba(21, 98, 198, 0.1)'
    }
  },
  breakpoints: {
    values: {
      sm: 740
    }
  },
  overrides: {
    MuiTypography: {
      h2: {
        color: '#484848'
      },
      h5: {
        color: '#484848'
      },
      h6: {
        color: '#484848'
      },
      caption: {
        color: '#484848'
      },
      body1: {
        color: '#484848'
      },
      body2: {
        color: '#484848'
      },
      subtitle1: {
        color: '#484848'
      },
      colorTextSecondary: {
        color: 'rgba(0, 0, 0, 0.44)'
      }
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
        letterSpacing: '.25px',
        height: 35,
        textTransform: 'none',
        padding: '5px 20px',
        fontWeight: '500'
      },
      contained: {
        boxShadow: 'none'
      }
    },
    MuiFormHelperText: {
      error: {
        color: '#d93025 !important'
      }
    },
    MuiFormLabel: {
      error: {
        color: '#d93025 !important'
      },
      focused: {
        color: '#1562c6 !important'
      }
    },
    MuiOutlinedInput: {
      error: {
        '& $notchedOutline': {
          borderColor: '#d93025 !important',
          borderWidth: '2px'
        }
      },
      focused: {
        '& $notchedOutline': {
          borderColor: '#1562c6 !important',
          borderWidth: '2px'
        }
      },
      notchedOutline: {
        borderWidth: '1px'
      }
    },
    MuiListItemText: {
      root: {
        padding: 0
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiSelect: {
      select: {
        paddingRight: '25px',
        borderBottom: 'none',
        '&:focus': {
          background: 'none',
        }
      }
    },
    MuiInputLabel: {
      outlined: {
        zIndex: 0
      }
    },
    /*MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: 'none'
        },
        '&:after': {
          borderBottom: 'none',
        },
        '&:before': {
          borderBottom: 'none',
        },
      }
    },*/
    /*MuiCardContent: {
      root: {
        '@media (min-width: 600px)': {
          paddingLeft: 0,
          paddingRight: 0
        }
      }
    },*/
    MuiTableCell: {
      root: {
        paddingRight: 8,
        paddingLeft: 10
      }
    },
    MuiTableSortLabel: {
      active: {
        color: 'inherit'
      }
    },
    MuiBottomNavigationAction: {
      root: {
        minWidth: 70
      }
    }
  }
});
