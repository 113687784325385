import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  signUpEmployee,
  getCities,
  getCommissions,
  getCompany
} from '../../../actions';
import SelectCompany from '../../selectCompany';
import validate from './validate';

import { withStyles } from '@material-ui/core/styles';
import renderTextField from '../../ui/text_field';
import renderSelectFieldTest from '../../ui/select_field_test';
import renderFileInput from '../../ui/upload_field';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  paper: {
    width: '100%',
    margin: '0 auto',
    padding: '5px 24px 15px',
    maxWidth: 1200
  },
  head: {
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    marginTop: 30,
    fontSize: 30
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.primary.main
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.customer.error,
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type,
    dominio: state.auth.dominio,
    cities: state.city.cities,
    commissions: state.commission.commissions,
    company: state.company.company
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getCities: (token, company) => dispatch(getCities(token, company)),
    getCompany: (token, id) => dispatch(getCompany(token, id)),
    getCommissions: (token, company) => dispatch(getCommissions(token, company)),
    signUpEmployee: (values, token, history) => dispatch(signUpEmployee(values, token, history))
  }
};

class Form extends React.Component {
  state = {
    cities: [],
    commissions: [],
    company: '',
    selectCompany: 3
  };

  componentDidMount() {
    this.fetchGet(this.state.selectCompany);
  };

  async fetchGet(dominio) {
    const token = 'token '.concat(this.props.token);

    if (this.props.dominio) {
      this.setState({
        selectCompany: this.props.dominio
      });
    } else {
      this.setState({
        selectCompany: dominio
      });
    };

    await Promise.all([
      this.props.getCompany(token, this.state.selectCompany),
      this.props.getCities(token, this.state.selectCompany),
      this.props.getCommissions(token, this.state.selectCompany)
    ]);

    const cities = [];
    const commissions = [];

    this.props.cities.forEach(city =>
      cities.push({
        label: this.capitalize(city.name),
        value: city.url
      })
    );
    this.props.commissions.forEach(item =>
      commissions.push({
        label: this.props.intl.formatNumber(item.value / 100, { style: "percent" }),
        value: item.url
      })
    );

    this.setState({
      cities: cities,
      commissions: commissions,
      company: this.props.company
    })
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        console.log(key)
      }

      return form_data;
    }
  };

  submit(values) {
    const val = {
      ...values,
      commission: values.commission ? values.commission.value : '',
      company: this.state.company.url
    };

    const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    return this.props.signUpEmployee(data, token, this.props.history);
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleChange(event) {
    this.fetchGet(event.target.value);
  };

  render() {
    const { intl: { formatMessage, formatNumber }, messages, pristine, submitting, classes, handleSubmit, loading, role } = this.props;
    const { cities, commissions } = this.state;
    const normalizeNumber = (value, previousValue) => {
      const onlyNums = value.replace(/[^\d]/g, '')
      return onlyNums
    }
    return (
      <div className={classes.paper}>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form onSubmit={handleSubmit(this.submit.bind(this))} encType="multipart/form-data">
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.head}>
              <Typography component="h2" variant="h2" className={classes.title}>
                {formatMessage(messages.customers.formTitle)} Seller
              </Typography>
            </Grid>
            {role.select ?
              <Grid item xs={12} sm={6}>
                <SelectCompany
                  select={this.state.selectCompany}
                  handleChange={this.handleChange.bind(this)}
                />
              </Grid>
              :
              null
            }
            <Grid item xs={12} sm={6}>
              <Field
                name="first_name"
                component={renderTextField}
                label="First Name"
                ref="first_name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="last_name"
                component={renderTextField}
                label="Last Name"
                ref="last_name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="identification_type"
                component={renderTextField}
                label="Identification Type"
                ref="identification_type"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="identification"
                component={renderTextField}
                label={formatMessage(messages.customers.formIdentification)}
                ref="identification"
                normalize={normalizeNumber}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Field
                name="address"
                component={renderTextField}
                label={formatMessage(messages.customers.formAddress)}
                ref="address"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="email"
                component={renderTextField}
                label={formatMessage(messages.general.email)}
                ref="email"
                type='email'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="phone"
                component={renderTextField}
                label={formatMessage(messages.general.phone)}
                ref="phone"
                normalize={normalizeNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="city"
                component={renderSelectFieldTest}
                options={cities}
                label={formatMessage(messages.customers.formCity)}
                ref="city"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="commission"
                component={renderSelectFieldTest}
                options={commissions}
                label="Commission"
                ref="commission"
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name="photo"
                component={renderFileInput}
                ref="photo"
                label="Photo"
                inputid="photo"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Link to="/sellers">
              <Typography variant='button' noWrap className={classes.link}>
                Sellers List
              </Typography>
            </Link>
            <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                {formatMessage(messages.customers.formTitle)}
              </Typography>
            </Button>
          </div>
        </form>
      </div>
    );
  }
};

const reduxFormSellerSignUp = reduxForm({
  form: 'sellersignup',
  validate
})(Form);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormSellerSignUp));
