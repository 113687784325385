import {
  GET_CITIES,
  GET_CITIES_ERROR,
} from '../constants/action_types';

const initialState = {};

const cityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CITIES:
      return {
        ...state,
        error: false,
        cities: action.payload
      };
    case GET_CITIES_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default cityReducer;
