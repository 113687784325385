import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { getBills, addInvoice } from '../../../../actions';
import validate from './validate';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../../ui/text_field';
import renderSelectFieldTest from '../../../ui/select_field_test';
import renderFileInput from '../../../ui/upload_field';
import universalDatePicker from '../../../ui/universal_date_picker';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';


const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  paper: {
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out'
  },
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    padding: '15px 24px 15px',
  },
  title: {
    //margin: '30px 0px 30px 10px',
    fontSize: 25
  },
  expansionTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    token: state.auth.token,
    loading: state.loader.loading,
    bills: state.bill.bills
  }
};

function mapDispatchToProps(dispatch) {
  return {
    addInvoice: (values, token) => dispatch(addInvoice(values, token)),
    getBills: (token, customer) => dispatch(getBills(token, customer))
  }
};

class InvoiceForm extends React.Component {
  state = {
    bills: []
  }

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const id = this.props.match.params.id;
    const token = 'token '.concat(this.props.token);
    await this.props.getBills(token, id);

    const bills = [];
    this.props.bills.forEach(item =>
      bills.push({
        label: item.bill_date,
        value: item.url
      })
    );

    this.setState({
      bills
    });
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        //console.log(key)
      }

      return form_data;
    }
  };

  async submit(values) {
    const val = {
      ...values,
      date: values.date.toISOString().slice(0, 10),
      due_date: values.due_date.toISOString().slice(0, 10),
    };
    console.log(val)

    /* Evitar valores nulos */
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    /*---------------------*/

    //const data = this.convertToFormData(val);
    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    //console.log(data)
    const token = 'token '.concat(this.props.token);
    await this.validateErrorsApi(val, token);
    this.props.showCard();
    this.props.fetchCard();
  };

  validateErrorsApi(data, token) {
    return this.props.addInvoice(data, token)
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading } = this.props;
    const { bills } = this.state;
    return (
      <Paper className={classes.paper}>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))}
        //encType="multipart/form-data" id="myForm"
        >
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.head}>
              <List component="ul">
                <ListItem disableGutters>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                    {formatMessage(messages.customers.billFormTitle)}
                  </Typography>} />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="utility_bill"
                component={renderSelectFieldTest}
                options={bills}
                label="Utility Bill"
                ref="utility_bill"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="number"
                component={renderTextField}
                label="Number"
                ref="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="date"
                component={universalDatePicker}
                label="Date"
                ref="date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="due_date"
                component={universalDatePicker}
                label="Due Date"
                ref="due_date"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Typography variant='button' noWrap className={classes.link} onClick={this.props.showCard}>
              Return to options
            </Typography>
            <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                {formatMessage(messages.customers.billFormSubmit)}
              </Typography>
            </Button>
          </div>
        </form>
      </Paper>
    )
  }
};

const reduxFormAddInvoice = reduxForm({
  form: 'addinvoice',
  validate
})(InvoiceForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormAddInvoice));
