import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { changeSideList } from '../../../actions';
import { connect } from 'react-redux';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import EmailIcon from '@material-ui/icons/Email';
import BookIcon from '@material-ui/icons/ChromeReaderMode';
import PhoneIcon from '@material-ui/icons/Phone';
import MapIcon from '@material-ui/icons/Map';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Divider from '@material-ui/core/Divider';
import BallotIcon from '@material-ui/icons/Ballot';
import PageIcon from '@material-ui/icons/Pages';

const styles = theme => ({
  link: {
    color: 'inherit'
  },
  customerCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 250
  },
  customerDetails: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center'
  },
  customerContent: {
    flex: '1 0 auto',
  },
  customerAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  customerBigAvatar: {
    margin: 10,
    width: 120,
    height: 120
  },
  customerLinks: {
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    padding: '5px 0px',
  },
  customerLink: {
    padding: '15px'
  },
  customerButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingBottom: '24px'
  },
  iconButton: {
    width: 48,
    height: 48
  },
  customerButtonsLinks: {
    color: 'inherit'
  },
  customerList: {
    width: '100%'
  }
});

function mapDispatchToProps(dispatch) {
  return {
    changeSideList: (page, info) => dispatch(changeSideList(page, info))
  }
};

function mapStateToProps(state) {
  return {
    info: state.sideList.info,
    link: state.sideList.link
  }
};

class CustomersSideList extends React.Component {
  state = {
    info: [],
    url: ''
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      //info: nextProps.info,
      url: nextProps.link,
      info: {
        photo: nextProps.info.photo,
        name: this.capitalize(nextProps.info.name),
        identification: nextProps.info.identification,
        email: nextProps.info.email,
        phone: nextProps.info.phone
      }
    })
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { classes, intl: { formatMessage }, messages } = this.props;
    const { info, url } = this.state;

    const profile = formatMessage(messages.navBar.profile);
    const bills = formatMessage(messages.customers.bills);

    const icons = [<BallotIcon />, <PageIcon />, <BookIcon />];
    const list = [
      {
        text: bills,
        url: 'bills',
      },
      {
        text: 'Invoice',
        url: 'invoice'
      },
      {
        text: 'Booking',
        url: 'booking'
      }
    ];

    const sideList = (
      <Card className={classes.customerCard} elevation={0} square>
        <div className={classes.customerLinks} onClick={this.props.handleCloseDrawer}>
          <Link to="/customers" className={classes.customerLink} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
            <ArrowLeftIcon />
          </Link>
          <Typography variant="subtitle1">
            <Link to={`${url}/profile`} className={classes.customerLink} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
              {profile}
            </Link>
          </Typography>
        </div>
        <div className={classes.customerAvatar} onClick={this.props.handleCloseDrawer}>
          <Link to={`${url}/profile`}>
            <Avatar alt="avatar" src={info.photo} className={classes.customerBigAvatar} />
          </Link>
        </div>
        <div className={classes.customerDetails}>
          <CardContent className={classes.customerContent}>
            <Typography component="h2" variant="h5">
              {info.name}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              #Nit: {info.identification}
            </Typography>
          </CardContent>
        </div>
        <div className={classes.customerButtons} onClick={this.props.handleCloseDrawer}>
          <IconButton classes={{ root: classes.iconButton }}>
            <a href={`mailto: ${info.email}`} className={classes.customerButtonsLinks}>
              <EmailIcon />
            </a>
          </IconButton>
          <IconButton classes={{ root: classes.iconButton }}>
            <a href={`tel: ${info.phone}`} className={classes.customerButtonsLinks}>
              <PhoneIcon />
            </a>
          </IconButton>
          <IconButton classes={{ root: classes.iconButton }}>
            <a target="_blank" rel="noopener noreferrer" href="https://maps.google.com/?q=barranquilla" className={classes.customerButtonsLinks}>
              <MapIcon />
            </a>
          </IconButton>
        </div>
        <List className={classes.customerList} onClick={this.props.handleCloseDrawer}>
          {list.map((item, index) => (
            <Fragment key={index}>
              <Divider />
              <Link to={`${url}/${item.url}`}>
                <ListItem button  >
                  <ListItemIcon>{icons[index]}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            </Fragment>
          ))}
        </List>
      </Card>
    )
    return sideList;
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomersSideList));
