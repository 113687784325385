import React from 'react';
import Button from '@material-ui/core/Button';
import AttachFileIcon from '@material-ui/icons/Attachment';
import CheckIcon from '@material-ui/icons/Check';

const adaptFileEventToValue = delegate =>
  e => delegate(e.target.files[0])

const renderFileInput = ({
  input: {
    value: omitValue,
    onChange,
    onBlur,
    ...inputProps,
  },
  meta: omitMeta,
  ...props,
}) => {
  //console.log(omitMeta)
  return (
    <div>
      {/*<input
        accept="image/*"
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        {...inputProps}
        {...props}
      />*/}
      <input
        accept=".pdf,image/*"
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        id={props.inputid}
        type="file"
        style={{ display: 'none' }}
        {...inputProps}
        {...props}
      />
      <label htmlFor={props.inputid}>
        <Button variant="contained" component="span" style={omitMeta.touched && omitMeta.error ? { border: '1px solid #d93025', borderWidth: 2, color: '#d93025' } : null}>
          {document.getElementById(props.inputid) === null ? <AttachFileIcon style={{ marginRight: 8 }} /> : document.getElementById(props.inputid).value ? <CheckIcon style={{ margin: '0 8px' }} /> : <AttachFileIcon style={{ margin: '0 8px' }} />}
          {props.label}
        </Button>
        <span style={{ marginLeft: 10 }}>{document.getElementById(props.inputid) === null ? '' : document.getElementById(props.inputid).value.replace('fakepath', '').replace(/\\/, '')}</span>
        {omitMeta.touched && omitMeta.error ? <p style={{ fontSize: '0.75rem', margin: '8px 12px 0', color: '#d93025' }}>{omitMeta.error}</p> : null}
      </label>
    </div>
  )
}


export default renderFileInput;
