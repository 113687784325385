import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getAccessories } from '../../../actions';
import InventoryForm from './inventoryForm';
import InventoryCard from './inventoryCard';

import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InventoryIcon from '@material-ui/icons/Layers';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: "90vh",
    [theme.breakpoints.up('sm')]: {
      //height: '132vh',
      overflowY: 'auto',
      height: "calc(100vh - 64px)"
    }
  },
  bannerRoot: {
    backgroundColor: 'white',
    paddingBottom: 8,
    paddingTop: 8,
    /* Banner fixed */
    width: '100%',
    top: '56px',
    zIndex: 1,
    position: 'fixed',
    /*--------------*/
    [theme.breakpoints.up('sm')]: {
      /* Banner fixed */
      top: '64px',
      width: 'calc(100% - 250px)',
      /*--------------*/
    }
  },
  banner: {
    backgroundColor: 'white',
    paddingBottom: 8,
    paddingTop: 8,
    maxWidth: 1200,
    /* Banner fixed */
    width: '100%',
    top: '56px',
    zIndex: 1,
    position: 'fixed',
    /*--------------*/
    [theme.breakpoints.up('sm')]: {
      top: '64px'
    }
  },
  title: {
    fontSize: 30
  },
  profileArea: {
    marginTop: '68px',
    padding: '15px 15px 76px 15px',
    maxWidth: 1100,
    margin: '0 auto'
  }
});

function mapDispatchToProps(dispatch) {
  return {
    getAccessories: (token, company) => dispatch(getAccessories(token, company))
  }
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    accessories: state.accessory.accessories
  }
};

class Inventory extends React.Component {
  state = {
    show: false,
    data: [],
    loading: true
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const id = this.props.match.params.id;
    const token = 'token '.concat(this.props.token);
    await this.props.getAccessories(token, id);
    const show = this.props.accessories.length === 0 ? true : false;
    this.setState({
      data: this.props.accessories,
      loading: false,
      show
    });
  };

  handleShow() {
    this.setState({
      show: !this.state.show
    });
  };

  render() {
    const { classes, match, intl, messages, role } = this.props;
    const { show, loading, data } = this.state;
    return (
      <div className={classes.root}>
        <div /*className={role.select ? classes.bannerRoot : classes.banner}*/ className={classes.bannerRoot}>
          <List component="ul">
            <ListItem >
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                Inventory
              </Typography>} />
              <ListItemSecondaryAction style={{ marginRight: 10 }}>
                <IconButton aria-label="edit" onClick={this.handleShow.bind(this)}>
                  {role.create ?
                    show ?
                      <ArrowLeftIcon />
                      :
                      <AddIcon />
                    :
                    null
                  }
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
        <div className={classes.profileArea}>
          {loading ?
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
              <CircularProgress />
            </div>
            :
            show ?
              <Slide direction="left" in={show} mountOnEnter unmountOnExit timeout={500}>
                <InventoryForm
                  intl={intl}
                  messages={messages}
                  company={this.props.company}
                  fetchCard={this.fetchGet.bind(this)}
                  showCard={this.handleShow.bind(this)}
                />
              </Slide>
              :
              <Fragment>
                {data.map((valve, index) =>
                  <Fade in={!show} timeout={500} key={valve.id}>
                    <InventoryCard
                      data={valve}
                      intl={intl}
                      messages={messages}
                      fetchCard={this.fetchGet.bind(this)}
                    />
                  </Fade>
                )}
              </Fragment>
          }
        </div>
      </div>
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Inventory));
