import React from 'react';
import ReactDOM from "react-dom";
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText >{touched && error}</FormHelperText>
  }
};

class renderSelectFieldTest extends React.Component {
  state = {
    labelWidth: 0
  };

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth
    });
  };

  render() {
    const { is, input, label, meta: { touched, invalid, error }, options, ...custom } = this.props;
    return (
      <FormControl style={{ width: '100%' }} error={touched && invalid}>
        <InputLabel
          htmlFor={label}
          error={touched && invalid}
          ref={ref => {
            this.InputLabelRef = ref;
          }}
        >
          {label}
        </InputLabel>
        <Select
          inputProps={{
            name: label,
            id: label
          }}
          value={is ? options.find(item => item.value === is) : input.onChange}
          //value={this.state.select}
          //renderValue={value => `${value}`}
          onChange={input.onChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 180
              },
            }
          }}
          {...input}
          {...custom}
        >
          {options.map((item, index) =>
            <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
          )}
        </Select>
        {renderFromHelper({ touched, error })}
      </FormControl>
    )
  };
};

export default renderSelectFieldTest;
