import React from 'react';
import { addBill } from '../../../../actions'
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { getUtilityCompanies } from '../../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../../ui/text_field';
import renderSelectFieldTest from '../../../ui/select_field_test';
import renderFileInput from '../../../ui/upload_field';
import universalDatePicker from '../../../ui/universal_date_picker';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';


const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  paper: {
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out'
  },
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    padding: '15px 24px 15px',
  },
  title: {
    //margin: '30px 0px 30px 10px',
    fontSize: 25
  },
  expansionTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    token: state.auth.token,
    loading: state.loader.loading,
    utilityCompanies: state.utilityCompany.utilityCompanies
  }
};

function mapDispatchToProps(dispatch) {
  return {
    addBill: (values, token) => dispatch(addBill(values, token)),
    getUtilityCompanies: (token) => dispatch(getUtilityCompanies(token))
  }
};

const validate = (values, state) => {
  const { intl: { formatMessage }, messages } = state;
  const errors = {}

  if (!values["bill_number"]) {
    errors["bill_number"] = formatMessage(messages.signIn.required)
  }

  if (!values["bill_reference"]) {
    errors["bill_reference"] = formatMessage(messages.signIn.required)
  }

  if (!values["bill_date"]) {
    errors["bill_date"] = formatMessage(messages.signIn.required)
  }

  if (!values["cut_off_date"]) {
    errors["bill_date"] = formatMessage(messages.signIn.required)
  }

  if (!values["billing_cycle"]) {
    errors["billing_cycle"] = formatMessage(messages.signIn.required)
  }

  if (!values["cubic_meters"]) {
    errors["cubic_meters"] = formatMessage(messages.signIn.required)
  }

  if (!values["utility_company"]) {
    errors["utility_company"] = formatMessage(messages.signIn.required)
  }

  return errors
};

class BillForm extends React.Component {
  state = {
    utilityCompanies: [],
  }

  componentDidMount() {
    this.fetchGet();
  };


  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getUtilityCompanies(token);

    const utilityCompanies = [];
    this.props.utilityCompanies.forEach(item =>
      utilityCompanies.push({
        label: this.capitalize(item.name),
        value: item.url
      })
    );

    this.setState({
      utilityCompanies: utilityCompanies
    });
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        //console.log(key)
      }

      return form_data;
    }
  };

  async submit(values) {
    const val = {
      ...values,
      customer: this.props.customer.url,
      bill_date: values["bill_date"].toISOString().slice(0, 10),
      cut_off_date: values["cut_off_date"].toISOString().slice(0, 10),
      for_analysis: false,
      cubic_meters: parseInt(values.cubic_meters, 10),
      charges: '{ "a": "a" }'
    };
    console.log(val)

    /* Evitar valores nulos */
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    /*---------------------*/

    const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    //console.log(data)
    await this.validateErrorsApi(data, token);
    this.props.showCard();
    this.props.fetchCard();
  };

  validateErrorsApi(data, token) {
    return this.props.addBill(data, token)
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading, utilityCompanyValue } = this.props;
    const { utilityCompanies } = this.state;
    const normalizeNumber = (value, previousValue) => {
      const onlyNums = value.replace(/[^\d]/g, '')
      return onlyNums
    };
    return (
      <Paper className={classes.paper}>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} encType="multipart/form-data" id="myForm">
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.head}>
              <List component="ul">
                <ListItem disableGutters>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                    {formatMessage(messages.customers.billFormTitle)}
                  </Typography>} />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="bill_number"
                component={renderTextField}
                label="Bill Number"
                ref="bill_number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="bill_reference"
                component={renderTextField}
                label="Bill Reference"
                ref="bill_reference"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="bill_date"
                //component={renderDateField}
                component={universalDatePicker}
                label="Bill Date"
                ref="bill_date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="cut_off_date"
                //component={renderDateField}
                component={universalDatePicker}
                label="Cut Off Date"
                ref="cut_off_date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="billing_cycle"
                component={renderTextField}
                label="Billing Cycle"
                ref="billing_cycle"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="cubic_meters"
                component={renderTextField}
                label={formatMessage(messages.customers.billFormCubic)}
                ref="cubic_meters"
                normalize={normalizeNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="hydrometer"
                component={renderTextField}
                label="Hydrometer"
                ref="hydrometer"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="utility_company"
                component={renderSelectFieldTest}
                options={utilityCompanies}
                clearable={true}
                label={formatMessage(messages.customers.billFormUtility)}
                ref="utility_company"
                is={utilityCompanyValue}
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name="bill_support"
                component={renderFileInput}
                ref="bill_support"
                label="Bill Support"
                inputid="bill_support"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Typography variant='button' noWrap className={classes.link} onClick={this.props.showOptions}>
              Return to options
            </Typography>
            <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                {formatMessage(messages.customers.billFormSubmit)}
              </Typography>
            </Button>
          </div>
        </form>
      </Paper>
    )
  }
};

const reduxFormAddBill = reduxForm({
  form: 'addbill',
  validate
})(BillForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormAddBill));
