import React, { Fragment } from 'react';
import ModalImages from './modal/modalImages';
import ModalEdit from './modal/modalEdit';

import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  card: {
    //minWidth: 225,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardContent: {
    paddingRight: 16,
    paddingLeft: 16,
    paddingTop: 16,
    paddingBottom: 16,
    [theme.breakpoints.up('sm')]: {
      paddingRight: 24,
      paddingLeft: 24
    }
  }
});

class RegisterDetailCard extends React.Component {
  state = {
    openImages: false,
    openEdit: false,
    initialValues: [],
    registerValues: {}
  };

  componentWillReceiveProps(nextProps) {
    //const values = nextProps.initialValues;
    let values = [];

    if (nextProps.initialValues) {
      nextProps.initialValues.registers.map((register, index) =>
        values.push({
          id: register.id,
          installation_report_id: register.installation_report_id,
          location: register.location,
          tools: register.tools,
          q_before: register.q_before,
          q_after: register.q_after,
          observations: register.observations,
          valve: register.valve
        })
      );
    }

    this.setState({
      initialValues: values
    });
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  capitalizeEachWord(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleImagesOpen() {
    this.setState({ openImages: true });
  };

  handleImagesClose() {
    this.setState({ openImages: false });
  };

  handleEditModal(id) {
    if (id) {
      const values = this.state.initialValues.filter(register => register.id === id);
      this.setState({
        openEdit: !this.state.openEdit,
        registerValues: values[0]
      });
    } else {
      this.setState({
        openEdit: !this.state.openEdit
      });
    };
  };

  render() {
    const { openImages, openEdit, initialValues, registerValues } = this.state;
    const { classes, data, withPadding, collapseOpen } = this.props;
    return (
      <Fragment>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openImages}
          onClose={this.handleImagesClose.bind(this)}
        >
          <ModalImages />
        </Modal>
        <ModalEdit open={openEdit} fetchArea={this.props.fetchArea} handleClose={this.handleEditModal.bind(this)} initialValues={registerValues} />
        <Grid
          container
          spacing={16}
          direction='row'
          justify='flex-start'
          alignItems='flex-start'
          style={{ padding: withPadding, marginTop: collapseOpen }}
        >
          {data.map((register, index) =>
            <Grid item xs={12} sm={6} key={index}>
              <Card className={classes.card}>
                <CardContent classes={{ root: classes.cardContent }}>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {this.capitalizeEachWord(register.location)} - Update
                </Typography>
                  <Typography variant="h5" component="h2">
                    {`${register.q_before} / ${register.q_after}`}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    {register.valve__piston || register.valve__spring ?
                      `${this.capitalize(register.valve__spring)} + ${this.capitalize(register.valve__piston)}`
                      :
                      `Housing`
                    }
                  </Typography>
                  <Typography component="p">
                    {this.capitalize(register.observations)}
                  </Typography>
                </CardContent>
                <CardActions>
                  {register.initial_photos !== '{}' || register.end_photos !== '{}' ?
                    <Button size="small" onClick={this.handleImagesOpen.bind(this)}>Evidence</Button>
                    :
                    null
                  }
                  <Button size="small" onClick={this.handleEditModal.bind(this, register.id)}>Edit</Button>
                </CardActions>
              </Card>
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  };
}

export default withStyles(styles)(RegisterDetailCard);
