import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { signIn } from '../../actions';

import { withStyles } from '@material-ui/core/styles';
import renderTextField from '../ui/text_field';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';

const styles = theme => ({
  title: {
    padding: '20px 0px 5px',
    textAlign: 'center'
  },
  subtitle: {
    padding: '5px 0px 20px',
    textAlign: 'center'
  },
  subtitleError: {
    padding: '5px 0px 20px',
    textAlign: 'center',
    color: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: '10px',
    padding: '5px 24px 15px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  formField: {
    margin: '15px 0'
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center'
  },
  logoImg: {
    width: 130,
    height: 53
  },
  link: {
    color: theme.palette.primary.main
  }
});

function mapStateToProps(state) {
  return {
    errorState: state.auth.error
  }
};

function mapDispatchToProps(dispatch) {
  return {
    signIn: (values, history) => dispatch(signIn(values, history))
  }
};

const validate = (values, state) => {
  const { intl: { formatMessage }, messages } = state;
  const errors = {}

  if (!values.email) {
    errors.email = formatMessage(messages.signIn.required)
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = formatMessage(messages.general.invalidMail);
  }

  if (!values.password) {
    errors.password = formatMessage(messages.signIn.required)
  }
  return errors
};

class Form extends React.Component {
  submit(values) {
    this.props.signIn(values, this.props.history);
  };

  render() {
    const { handleSubmit, pristine, submitting, intl: { formatMessage }, classes, messages, errorState } = this.props;
    return (
      <Fragment>
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))}>
          <div className={classes.logo}>
            <img src="/images/ela-logo-2.png" alt="Ela Logo" alt="Ela Logo" className={classes.logoImg} />
          </div>
          <Typography component="h1" variant="h5" className={classes.title}>
            {formatMessage(messages.signIn.title)}
          </Typography>
          <Typography variant="subtitle1" className={classes.subtitle}>
            {formatMessage(messages.signIn.subtitle)}
          </Typography>
          {errorState ?
            <Fade in={errorState}>
              <Typography variant="subtitle1" className={classes.subtitleError}>
                {formatMessage(messages.signIn.error)}
              </Typography>
            </Fade>
            :
            null}
          <div className={classes.formField}>
            <Field
              name="email"
              type='email'
              component={renderTextField}
              label={formatMessage(messages.general.email)}
              ref='email'
              autoFocus
              newstyle='login'
            />
          </div>
          <div className={classes.formField}>
            <Field
              name="password"
              type="password"
              component={renderTextField}
              label={formatMessage(messages.general.password)}
              ref='password'
              autoComplete='new-password'
              newstyle='login'
            />
          </div>
          <div className={classes.formMeta}>
            <Link to="/recovery">
              <Typography variant='button' noWrap className={classes.link}>
                {formatMessage(messages.signIn.link)}
              </Typography>
            </Link>
            <Button color='primary' variant="contained" type="submit" disabled={pristine || submitting}>
              <Typography variant='button' noWrap>
                {formatMessage(messages.signIn.title)}
              </Typography>
            </ Button>
          </div>
        </form>
      </Fragment>
    );
  }
};

const reduxFormLogIn = reduxForm({
  form: 'login',
  validate
})(Form);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormLogIn));
