import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { getCities } from '../../../actions';
import SelectCompany from '../../selectCompany';
import validate from './validate';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import renderSelectField from '../../ui/select_field';
import renderSelectFieldTest from '../../ui/select_field_test';
import renderFileInput from '../../ui/upload_field';

const styles = theme => ({
  form: {
    //padding: '5px 24px 15px',
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.primary.main
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.customer.error,
    token: state.auth.token,
    role: state.auth.user_type,
    dominio: state.auth.dominio,
    cities: state.city.cities
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getCities: (token, company) => dispatch(getCities(token, company))
  }
};

class FormFirstPage extends Component {
  state = {
    cities: [],
    status: [],
    error: '',
    selectCompany: 3,
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectCompany: nextProps.dominio
    });
  };

  componentDidMount() {
    if (this.props.dominio) {
      this.setState({
        selectCompany: this.props.dominio
      });
    };
    this.fetchGet(this.state.selectCompany);
  };

  async fetchGet(dominio) {
    const token = 'token '.concat(this.props.token);
    await this.props.getCities(token, dominio);
    /* Status fetch */
    axios.get('https://api.elasustentable.com/rest/general/status',
      {
        headers: { Authorization: token }
      })
      .then(response => {
        console.log(response.data)
        const status = [];
        response.data.results.forEach(item =>
          status.push({
            label: this.capitalize(item.name),
            value: item.url
          })
        );
        this.setState({
          status: status
        })
      })
      .catch((error) => {
        console.log('error ' + error);
      });
    /*-----------------------*/

    const cities = [];

    this.props.cities.forEach(city =>
      cities.push({
        label: this.capitalize(city.name),
        value: city.url
      })
    );

    this.setState({
      cities: cities
    })
  };

  handleChange(event) {
    this.fetchGet(event.target.value);
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { intl: { formatMessage }, messages, pristine, submitting, classes, handleSubmit, role } = this.props;
    const { cities, status } = this.state;
    const normalizeNumber = (value, previousValue) => {
      const onlyNums = value.replace(/[^\d]/g, '')
      return onlyNums
    }
    return (
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={24}>
          {role.select ?
            <Grid item xs={12} sm={12}>
              <SelectCompany
                select={this.state.selectCompany}
                handleChange={this.handleChange.bind(this)}
              />
            </Grid>
            :
            null
          }
          <Grid item xs={12} sm={6}>
            <Field
              name="status"
              component={renderSelectFieldTest}
              options={status}
              label='Status'
              ref="status"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="name"
              component={renderTextField}
              label={formatMessage(messages.customers.formName)}
              ref="name"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Field
              name="email"
              component={renderTextField}
              label={formatMessage(messages.general.email)}
              ref="email"
              type='email'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="identification_type"
              component={renderTextField}
              label="Identification Type"
              ref="identification_type"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="identification"
              normalize={normalizeNumber}
              component={renderTextField}
              label={formatMessage(messages.customers.formIdentification)}
              ref="identification"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="phone"
              normalize={normalizeNumber}
              component={renderTextField}
              label={formatMessage(messages.general.phone)}
              ref="phone"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="city"
              component={renderSelectFieldTest}
              options={cities}
              label={formatMessage(messages.customers.formCity)}
              ref="city"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="address"
              component={renderTextField}
              label={formatMessage(messages.customers.formAddress)}
              ref="address"
            />
          </Grid>
          <Grid item xs={12} className={classes.uploadButton}>
            <Field
              name="photo"
              component={renderFileInput}
              ref="photo"
              label="Photo"
              inputid="photo"
            />
          </Grid>
        </Grid>
        <div className={classes.formMeta}>
          <Link to="/customers">
            <Typography variant='button' noWrap className={classes.link}>
              {formatMessage(messages.customers.formLink)}
            </Typography>
          </Link>
          <Button color='primary' variant="contained" type="submit" disabled={(pristine || submitting)}>
            <Typography variant='button' noWrap>
              Next
            </Typography>
          </Button>
        </div>
      </form >
    );
  }
};

const reduxFormSignUp = reduxForm({
  form: 'signupCustomer',
  destroyOnUnmount: false,
  validate
})(FormFirstPage);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormSignUp));
