import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const renderTextField = ({ isTextArea, label, input, meta: { touched, invalid, error }, ...custom }) => (
  <FormControl fullWidth >
    <TextField
      //InputLabelProps={{ shrink: true }}
      multiline={isTextArea ? true : false}
      rows={isTextArea ? 2 : 1}
      placeholder={label}
      variant={custom.newstyle ? 'outlined' : 'standard'}
      label={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  </FormControl>
);

export default renderTextField;
