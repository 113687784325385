import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProfileIndex from './profileIndex';


class Profile extends React.Component {
  render() {
    const { intl, messages, match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.path}`} render={(props) => <ProfileIndex {...props} intl={intl} messages={messages} />} />
      </Switch>
    );
  }
};

export default Profile;
