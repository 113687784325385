import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { changeLanguage } from '../../../actions';
import SelectLanguage from '../../selectLanguage';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import BuildingIcon from '@material-ui/icons/AccountBalance';
import GlobeIcon from '@material-ui/icons/Public';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import CompanyIcon from '@material-ui/icons/BusinessCenter';
import BusinessIcon from '@material-ui/icons/Business';
import ValveIcon from '@material-ui/icons/DeviceHub';
import CommissionIcon from '@material-ui/icons/AccountBalanceWallet';
import InventoryIcon from '@material-ui/icons/Layers';
import { Typography } from '@material-ui/core';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const styles = theme => ({
  '@keyframes imgFadeIn': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  img: {
    maxWidth: 90,
    maxHeight: 50,
    width: 'auto',
    height: 'auto'
  },
  link: {
    color: 'inherit'
  },
  list: {
    width: 250,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'space-between'
  },
  bottomList: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bottomButton: {
    color: theme.palette.newGrey.main,
    height: 56,
    width: '100%'
  }
});

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (value) => dispatch(changeLanguage(value))
  }
};

function mapStateToProps(state) {
  return {
    role: state.auth.user_type,
    dominio: state.auth.dominio,
    token: state.auth.token,
    company: state.company.company,
    loading: state.loader.loading
  }
};

class MainSideList extends React.Component {
  state = {
    company: { name: '' },
    menu: false
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      company: nextProps.company
    });
  };

  handleClick(url) {
    this.props.handleDrawer(false);
    setTimeout(
      function () { this.props.history.push(url) }.bind(this),
      100
    )
    //this.props.history.push(url);
  };

  handleLanguage(option) {
    console.log(option)
    this.props.changeLanguage(option);
  };

  toggleMenu(open) {
    console.log(`${open}`)
    this.setState({
      menu: open
    })
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { classes, intl: { formatMessage }, messages, role, dominio } = this.props;
    const { company } = this.state;

    const dashboard = formatMessage(messages.navBar.dashboard);
    const customers = formatMessage(messages.navBar.customers);
    const sellers = formatMessage(messages.navBar.sellers);
    const accounting = formatMessage(messages.navBar.accounting);

    const list = [
      {
        text: dashboard,
        url: 'dashboard',
        icon: <DashboardIcon />
      },
      {
        text: customers,
        url: 'customers',
        icon: <GroupIcon />
      },
      {
        text: sellers,
        url: 'sellers',
        icon: <BuildingIcon />
      },
      {
        text: accounting,
        url: 'accounting',
        icon: <MoneyIcon />
      }
    ];

    const list2 = [
      {
        text: 'Utility Companies',
        url: `settings/${dominio}/utilityCompanies`,
        icon: <CompanyIcon />
      },
      {
        text: 'Business Types',
        url: `settings/${dominio}/businessTypes`,
        icon: <BusinessIcon />
      },
      {
        text: 'Valves',
        url: `settings/${dominio}/valves`,
        icon: <ValveIcon />
      },
      {
        text: 'Commissions',
        url: `settings/${dominio}/commissions`,
        icon: <CommissionIcon />
      },
      {
        text: 'Inventory',
        url: `settings/${dominio}/inventory`,
        icon: <InventoryIcon />
      }
    ];

    const bottomList = [
      {
        url: '/profile',
        icon: <AccountCircle />
      },
      {
        url: '/dashboard',
        icon: <SettingsIcon />
      },
      {
        url: '#language',
        icon: <GlobeIcon />
      }
    ];

    return (
      <div className={classes.list}>
        <div>
          <List>
            {role.label !== 'root' ?
              <div style={{ padding: '20px 16px', display: 'flex', alignItems: 'center' }} >
                {company.photo ?
                  <Link to="/settings">
                    <img src={company.photo} alt="Logo" className={classes.img} />
                  </Link>
                  :
                  null
                }
                <Typography className={classes.link} style={{ marginLeft: 10, fontSize: 20 }}>
                  {this.capitalize(company.name)}
                </Typography>
              </div>
              :
              null
            }
            {list.map((item, index) => (
              <ListItem button key={index} onClick={this.handleClick.bind(this, `/${item.url}`)} disabled={item.url === 'accounting' ? true : false}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={<Typography className={classes.link} >{item.text}</Typography>} />
              </ListItem>
            ))}
          </List>
          {role.label !== 'root' ?/* propiedad secondary en ListItemText y Link con style={{ color: 'rgba(0, 0, 0, 0.60)' }}*/
            <List>
              {list2.map((item, index) => (
                <ListItem button key={index} onClick={this.handleClick.bind(this, `/${item.url}`)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={<Typography className={classes.link} >{item.text}</Typography>} />
                </ListItem>
              ))}
            </List>
            :
            null
          }
        </div>
        <div>
          <Divider />
          {/*<div className={classes.bottomList}>
            {bottomList.map((item, index) =>
              <Button className={classes.bottomButton} key={index} buttonRef={node => { this.anchorEl = node }}>
                {item.url === '#language' ?
                  <Fragment>
                    <span className={classes.link} onClick={this.toggleMenu.bind(this, true)}>
                      {item.icon}
                    </span>
                  </Fragment>
                  :
                  <Link to={item.url} className={classes.link}>
                    {item.icon}
                  </Link>
                }
              </Button>
            )}}
              </div>*/}
          <SelectLanguage />
          <Popper open={this.state.menu} anchorEl={this.anchorEl} transition style={{ zIndex: 1300 }}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-language-grow"
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.toggleMenu.bind(this, false)}>
                    <MenuList onClick={this.toggleMenu.bind(this, false)}>
                      <MenuItem value='es' onClick={this.handleLanguage.bind(this, 'es')}>
                        <Typography variant='caption'>
                          Español
                      </Typography>
                      </MenuItem>
                      <MenuItem value='en' onClick={this.handleLanguage.bind(this, 'en')}>
                        <Typography variant='caption'>
                          English
                      </Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainSideList));
