import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { changeCustomersState } from '../../../actions';
import ListCustomers from './listCustomers';
import ListStatus from './listStatus';

function mapDispatchToProps(dispatch) {
  return {
    changeCustomersState: (state) => dispatch(changeCustomersState(state))
  }
};

function mapStateToProps(state) {
  return {
    customersState: state.customer.customersState
  }
};

class List extends React.Component {
  handleToggle() {
    this.props.changeCustomersState();
  };

  render() {
    const { customersState } = this.props;
    return (
      <Fragment>
        {this.props.customersState ?
          <ListStatus {...this.props} toggle={customersState} handleToggle={this.handleToggle.bind(this)} />
          :
          <ListCustomers {...this.props} toggle={customersState} handleToggle={this.handleToggle.bind(this)} />
        }
      </Fragment>
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
