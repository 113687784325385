import React from 'react';
import axios from 'axios';
import { getEmployees, addRegister, getValves } from '../../../../actions';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import RegisterForm from './registerForm';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import renderSelectFieldTest from '../../../ui/select_field_test';
import renderTextField from '../../../ui/text_field';
import universalDatePicker from '../../../ui/universal_date_picker';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  paper: {
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out'
  },
  head: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  form: {
    width: '100%',
    padding: '15px 24px 15px',
  },
  title: {
    fontSize: 25
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    margin: '0 10px 0 0'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  }
});

function mapStateToProps(state) {
  return {
    //error: state.customer.error,
    loading: state.loader.loading,
    token: state.auth.token,
    role: state.auth.user_type,
    sellers: state.employee.sellers,
    dominio: state.auth.dominio
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getEmployees: (token, company) => dispatch(getEmployees(token, company)),
    addRegister: (values, token) => dispatch(addRegister(values, token)),
    getValves: (token, company) => dispatch(getValves(token, company))
  }
};

class Form extends React.Component {
  state = {
    sellers: [],
    base64: []
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getEmployees(token);
    await this.props.getValves(token, this.props.dominio);

    const sellers = [];

    this.props.sellers.forEach(item =>
      sellers.push({
        label: this.capitalize(item.first_name) + ' ' + this.capitalize(item.last_name),
        value: item.url
      })
    );

    this.setState({
      sellers: sellers
    })
  };

  getBase64(file) {
    return new Promise(resolve => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
    })
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        if (key === 'registers') {
          fields[key].forEach(async (register, index) => {
            for (let item in register) {
              if (item === 'initial_photos' || item === 'end_photos') {
                const file = await this.getBase64(register[item]);
                form_data.append(`registers[${index}][${item}]`, file)
                console.log(`registers[${index}][${item}]`, file)
              } else {
                form_data.append(`registers[${index}][${item}]`, register[item])
                //console.log(register[item])
              }
            };
          })
        } else {
          form_data.append(key, fields[key])
        }
      }

      return form_data;
    }
  };

  async submit(values) {
    // values.registers.forEach(async item => {
    //   const file1 = await this.getBase64(item.initial_photos);
    //   const file2 = await this.getBase64(item.end_photos);
    //   item.initial_photos = file1;
    //   item.end_photos = file2;
    // });

    const val = {
      ...values,
      customer: this.props.customer.url,
      report_date: values.report_date ? values.report_date.toISOString().slice(0, 10) : '',
    };
    console.log(values)
    console.log(val)

    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    //const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);

    await this.validateErrorsApi(val, token);

    this.props.showCard();
    this.props.fetchCard();
  };

  validateErrorsApi(data, token) {
    return this.props.addRegister(data, token)
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  clickButton() {
    document.querySelector('button[id="prueba"]').click();
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading, error } = this.props;
    const { sellers } = this.state;
    return (
      <Paper className={classes.paper}>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} //encType="multipart/form-data"
        >
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.head}>
              <List component="ul">
                <ListItem disableGutters>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                    Add Registers
                  </Typography>} />
                </ListItem>
              </List>
            </Grid>
            {error ?
              <Grid item xs={12}>
                <Typography color="error">
                  {error}
                </Typography>
              </Grid>
              :
              null
            }
            <Grid item xs={12} sm={4}>
              <Field
                name="seller"
                component={renderSelectFieldTest}
                options={sellers}
                label='Employee'
                ref="seller"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="report_date"
                component={universalDatePicker}
                label="Report Date"
                ref="report_date"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="contact_name"
                component={renderTextField}
                label="Contact Name"
                ref="contact_name"
              />
            </Grid>
            <Grid item xs={12}>
              <FieldArray name="registers" component={RegisterForm} />
            </Grid>
            {/*count.map((number, index) =>
              <Fragment key={index}>
                <Grid item xs={12} className={classes.subContainer}>
                  <div className={classes.subtitleContainer}>
                    <div className={classes.icon}>
                      <Typography variant="body2" className={classes.iconText}>
                        {index + 1}
                      </Typography>
                    </div>
                    <Typography variant="body2" className={classes.subtitle}>
                      Register
                  </Typography>
                  </div>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name={`contact_name[${index}]`}
                    component={renderTextField}
                    label="Contact Name"
                    ref={`contact_name[${index}]`}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name={`location[${index}]`}
                    component={renderTextField}
                    label="Location"
                    ref={`location[${index}]`}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name={`q_before[${index}]`}
                    component={renderTextField}
                    label="Q Before"
                    ref={`q_before[${index}]`}
                    normalize={normalizeNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name={`q_after[${index}]`}
                    component={renderTextField}
                    label="Q After"
                    ref={`q_after[${index}]`}
                    normalize={normalizeNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name={`vlv_type[${index}]`}
                    component={renderTextField}
                    label="Valve Type"
                    ref={`vlv_type[${index}]`}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name={`tools[${index}]`}
                    component={renderTextField}
                    label="Tools"
                    ref={`tools[${index}]`}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`observations[${index}]`}
                    component={renderTextField}
                    label="Observation"
                    isTextArea={true}
                    ref={`observations[${index}]`}
                  />
                </Grid>
                <Grid item xs={12} className={classes.uploadButton}>
                  <Field
                    name={`initial_photos[${index}]`}
                    component={renderFileInput}
                    ref={`initial_photos[${index}]`}
                    label="Initial Photos"
                    inputid={`initial_photos[${index}]`}
                  />
                </Grid>
                <Grid item xs={12} className={classes.uploadButton}>
                  <Field
                    name={`end_photos[${index}]`}
                    component={renderFileInput}
                    ref={`end_photos[${index}]`}
                    label="End Photos"
                    inputid={`end_photos[${index}]`}
                  />
                </Grid>
              </Fragment>
            )*/}
            {/*<Grid item xs={12} className={classes.subContainer}>
              <div className={classes.subtitleContainer}>
                <div className={classes.icon}>
                  <Typography variant="body2" className={classes.iconText}>
                    1
                  </Typography>
                </div>
                <Typography variant="body2" className={classes.subtitle}>
                  Register
                  </Typography>
              </div>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`contact_name`}
                component={renderTextField}
                label="Contact Name"
                ref={`contact_name`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`location`}
                component={renderTextField}
                label="Location"
                ref={`location`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`q_before`}
                component={renderTextField}
                label="Q Before"
                ref={`q_before`}
                normalize={normalizeNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`q_after`}
                component={renderTextField}
                label="Q After"
                ref={`q_after`}
                normalize={normalizeNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`vlv_type`}
                component={renderTextField}
                label="Valve Type"
                ref={`vlv_type`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`tools`}
                component={renderTextField}
                label="Tools"
                ref={`tools`}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={`observations`}
                component={renderTextField}
                label="Observation"
                isTextArea={true}
                ref={`observations`}
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name={`initial_photos`}
                component={renderFileInput}
                ref={`initial_photos`}
                label="Initial Photos"
                inputid={`initial_photos`}
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name={`end_photos`}
                component={renderFileInput}
                ref={`end_photos`}
                label="End Photos"
                inputid={`end_photos`}
              />
            </Grid>*/}
          </Grid>
          <div className={classes.formMeta}>
            <Button className={classes.button} color='primary' variant="outlined" onClick={this.clickButton.bind(this)}>
              Add
            </Button>
            <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                Submit
              </Typography>
            </Button>
          </div>
        </form>
      </Paper>
    )
  }
};

const reduxFormAddRegister = reduxForm({
  form: 'addregister'
})(Form);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormAddRegister));
