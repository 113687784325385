import { Switch } from "@material-ui/core";
import { GET_ANALYSIS_INFO, GET_ANALYSIS_INFO_ERROR } from '../constants/action_types'

const initialState = {};
const analysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYSIS_INFO:
      return {
        ...state,
        data: action.payload
      }
    case GET_ANALYSIS_INFO_ERROR:
      return {
        ...state,
        date: action.payload
      }
    default:
      return state;
  }
};
export default analysisReducer;
