const validate = (values, state) => {
  const { intl: { formatMessage }, messages } = state;
  const errors = {}

  if (!values.name) {
    errors.name = formatMessage(messages.signIn.required)
  }

  /*if (!values.email) {
    errors.email = formatMessage(messages.signIn.required)
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = formatMessage(messages.general.invalidMail);
  }

  if (!values.address) {
    errors.address = formatMessage(messages.signIn.required)
  }
  
  if (!values.identification_type) {
    errors.identification_type = formatMessage(messages.signIn.required)
  }

if (!values.utility_reference) {
    errors.utility_reference = formatMessage(messages.signIn.required)
  }

  if (!values.identification) {
    errors.identification = formatMessage(messages.signIn.required)
  }*/

  if (!values.city) {
    errors.city = formatMessage(messages.signIn.required)
  }

  if (!values.status) {
    errors.status = formatMessage(messages.signIn.required)
  }


  if (!values.bussines_type) {
    errors.bussines_type = formatMessage(messages.signIn.required)
  }

  if (!values.utility_company) {
    errors.utility_company = formatMessage(messages.signIn.required)
  }

  if (!values.seller) {
    errors.seller = formatMessage(messages.signIn.required)
  }

  if (!values.cubic_meters_avg) {
    errors.cubic_meters_avg = formatMessage(messages.signIn.required)
  }

  if (!values.service_class) {
    errors.service_class = formatMessage(messages.signIn.required)
  }

  return errors
};

export default validate;
