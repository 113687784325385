import {
  GET_REGISTERS,
  GET_REGISTERS_ERROR,
  ADD_REGISTER,
  ADD_REGISTER_ERROR,
  UPDATE_REGISTER,
  UPDATE_REGISTER_ERROR,
  DELETE_REGISTER,
  DELETE_REGISTER_ERROR
} from '../constants/action_types';

const initialState = {};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REGISTERS:
      return {
        ...state,
        registers: action.payload,
      };
    case GET_REGISTERS_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_REGISTER:
      return {
        ...state,
        error: false,
      };
    case ADD_REGISTER_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_REGISTER:
      return {
        ...state,
        error: false,
      }
    case UPDATE_REGISTER_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_REGISTER:
      return {
        ...state,
        error: false,
      }
    case DELETE_REGISTER_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default registerReducer;
