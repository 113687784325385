import React from 'react';
import { Field, reduxForm } from 'redux-form';
import {
  updateEmployeeProfile,
  deleteEmployee,
  getCities,
  getCommissions,
  getCompany
} from '../../../actions';
import SelectCompany from '../../selectCompany';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import renderTextField from '../../ui/text_field';
import renderSelectFieldTest from '../../ui/select_field_test';
import renderFileInput from '../../ui/upload_field';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputBase from '@material-ui/core/InputBase';

import ModalDialog from '../../modal';

const styles = theme => ({
  '@keyframes growScale': {
    '0%': {
      transform: 'scale(0)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  },
  '@keyframes fadeScale': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  paper: {
    animation: 'fadeScale 0.6s',
    animationTimingFunction: 'ease'
  },
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    padding: '15px 24px 15px',
  },
  title: {
    //margin: '30px 0px 30px 10px',
    fontSize: 25
  },
  expansionTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    margin: '0 5px'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.customer.error,
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type,
    dominio: state.auth.dominio,
    cities: state.city.cities,
    commissions: state.commission.commissions,
    company: state.company.company
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getCities: (token, company) => dispatch(getCities(token, company)),
    getCompany: (token, id) => dispatch(getCompany(token, id)),
    getCommissions: (token, company) => dispatch(getCommissions(token, company)),
    updateEmployeeProfile: (values, token, id) => dispatch(updateEmployeeProfile(values, token, id)),
    deleteEmployee: (token, id, history) => dispatch(deleteEmployee(token, id, history))
  }
};

class ProfileForm extends React.Component {
  state = {
    cities: [],
    commissions: [],
    company: '',
    open: false,
    selectCompany: 3
  };

  componentDidMount() {
    this.fetchGet(this.state.selectCompany);
  };

  async fetchGet(dominio) {
    const token = 'token '.concat(this.props.token);

    if (this.props.dominio) {
      this.setState({
        selectCompany: this.props.dominio
      });
    } else {
      this.setState({
        selectCompany: this.props.companyId
      });
    };

    await Promise.all([
      this.props.getCompany(token, this.state.selectCompany),
      this.props.getCities(token, this.state.selectCompany),
      this.props.getCommissions(token, this.state.selectCompany)
    ]);

    const cities = [];
    const commissions = [];

    this.props.cities.forEach(city =>
      cities.push({
        label: this.capitalize(city.name),
        value: city.url
      })
    );
    this.props.commissions.forEach(item =>
      commissions.push({
        label: this.props.intl.formatNumber(item.value / 100, { style: "percent" }),
        value: item.url
      })
    );

    this.setState({
      cities: cities,
      commissions: commissions,
      company: this.props.company
    })
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        console.log(key)
      }

      return form_data;
    }
  };

  handleOpen(open) {
    this.setState({
      open: open
    })
  };

  deleteData() {
    this.handleOpen(false)
    const token = 'token '.concat(this.props.token);
    const id = this.props.match.params.id;
    return this.props.deleteEmployee(token, id, this.props.history);
  };

  async submit(values) {
    console.log(values)
    const val = values;

    if (val.city) {
      val.city = values.city.value
    };

    if (val.commission) {
      val.commission = values.commission.value
    };

    if (typeof val.photo !== 'object') {
      delete val.photo
    }

    /* Evitar valores nulos */
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    console.log(val);
    /*---------------------*/

    const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    const id = this.props.match.params.id;

    await this.validateErrorsApi(data, token, id);
    this.props.showCard();
    this.props.fetchCard();
    this.props.fetchSellerIndex();
  };

  validateErrorsApi(data, token, id) {
    return this.props.updateEmployeeProfile(data, token, id);
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleChange(event) {
    this.fetchGet(event.target.value);
  };

  render() {
    const { messages, intl: { formatMessage, formatNumber }, pristine, submitting, classes, handleSubmit, loading, role } = this.props;
    const { open, cities, commissions } = this.state;
    const normalizeNumber = (value, previousValue) => {
      const onlyNums = value.replace(/[^\d]/g, '')
      return onlyNums
    }
    return (
      <Paper className={classes.paper}>
        <ModalDialog
          open={open}
          title="Are you sure you want to delete this seller ?"
          description="This action directly affects the database and is irreversible."
          actionName="Delete"
          handleOpen={this.handleOpen.bind(this)}
          handleAction={this.deleteData.bind(this)}
        />
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} encType="multipart/form-data" id="myForm">
          <Grid container spacing={16}>
            <Grid item xs={12} className={classes.head}>
              <List component="ul">
                <ListItem disableGutters>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                    Update profile
                  </Typography>} />
                </ListItem>
              </List>
            </Grid>
            {role.select ?
              <Grid item xs={12} sm={6}>
                <SelectCompany
                  select={this.state.selectCompany}
                  handleChange={this.handleChange.bind(this)}
                />
              </Grid>
              :
              null
            }
            <Grid item xs={12} sm={6}>
              <Field
                name="first_name"
                component={renderTextField}
                label="First Name"
                ref="first_name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="last_name"
                component={renderTextField}
                label="Last Name"
                ref="last_name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="email"
                component={renderTextField}
                label={formatMessage(messages.general.email)}
                ref="email"
                type='email'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="identification_type"
                component={renderTextField}
                label="Identification Type"
                ref="identification_type"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="identification"
                component={renderTextField}
                label={formatMessage(messages.customers.formIdentification)}
                ref="identification"
                normalize={normalizeNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="address"
                component={renderTextField}
                label={formatMessage(messages.customers.formAddress)}
                ref="address"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="phone"
                component={renderTextField}
                label={formatMessage(messages.general.phone)}
                ref="phone"
                normalize={normalizeNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="city"
                component={renderSelectFieldTest}
                options={cities}
                label={formatMessage(messages.customers.formCity)}
                ref="city"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="commission"
                component={renderSelectFieldTest}
                options={commissions}
                label="Commission"
                ref="commission"
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name="photo"
                component={renderFileInput}
                ref="photo"
                label="Photo"
                inputid="photo"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            {role.delete ?
              <Button className={classes.button} color='secondary' variant="contained" onClick={this.handleOpen.bind(this, true)} disabled={loading}>
                <Typography variant='button' noWrap>
                  Delete
            </Typography>
              </Button>
              :
              null
            }
            <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                Update
              </Typography>
            </Button>
            <InputBase style={{ display: "none" }} />
          </div>
        </form>
      </Paper>
    )
  }
};

const reduxFormUpdateEmployeeProfile = reduxForm({
  form: 'updateemployeeprofile'
})(ProfileForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateEmployeeProfile));
