import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { getBookings } from '../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import DateIcon from '@material-ui/icons/DateRange';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  secondary: {
    marginRight: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    padding: 5,
    marginLeft: 4
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    padding: 5,
    marginLeft: 4
  }
});

function mapDispatchToProps(dispatch) {
  return {
    getBookings: (token, customer, company) => dispatch(getBookings(token, customer, company))
  }
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    dominio: state.auth.dominio,
    bookings: state.booking.bookings
  }
};

class AppointmentCard extends React.Component {
  state = {
    appointments: [],
    data: [],
    name: [],
    photo: [],
    expanded: false
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getBookings(token, '', this.props.dominio);
    this.setState({
      appointments: this.props.bookings
    })
    this.state.appointments.map(item => axios.get(item.customer,
      {
        headers: { Authorization: token }
      })
      .then(response => {
        //console.log(response.data.name)
        this.setState({
          name: [...this.state.name, this.capitalize(response.data.name)],
          photo: [...this.state.photo, response.data.photo]
        })
      })
      .catch((error) => {
        console.log('error ' + error);
      })
    )
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleRedirect() {
    this.props.history.push(`/calendar`)
  };

  handleExpand() {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  render() {
    const { intl: { formatMessage, formatNumber, formatDate }, classes } = this.props;
    const { appointments, data, name, photo } = this.state;
    return (
      <Paper className={classes.paper} elevation={1}>
        <List component="ul">
          <ListItem >
            <ListItemIcon>
              <DateIcon />
            </ListItemIcon>
            <ListItemText primary={<Typography component="h2" variant="h6">
              Appointments
            </Typography>} />
          </ListItem>
        </List>
        <List component="ul" dense disablePadding>
          {appointments.length > 0 ?
            appointments.map((appointment, index) =>
              index < 5 ?
                <Fragment key={index}>
                  <ListItem >
                    <ListItemAvatar>
                      <Avatar alt="photo" src={photo[index]} />
                    </ListItemAvatar>
                    <ListItemText secondary={
                      <Typography variant="body2">
                        {name[index]}
                      </Typography>
                    } />
                    <ListItemSecondaryAction className={classes.secondary}>
                      <div style={{ textAlign: 'center' }}>
                        <Typography variant="body2" color="textSecondary">
                          {formatDate(new Date(appointment.start_time))}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {this.capitalize(appointment.type_booking)}
                        </Typography>
                      </div>
                      <IconButton
                        className={this.state.expanded ? classes.expandOpen : classes.expand}
                        onClick={this.handleExpand.bind(this)}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more"
                      >
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </Fragment>
                :
                null
            )
            :
            <ListItem >
              <Typography>
                No registered appointments
              </Typography>
            </ListItem>
          }
        </List>
        <List component="ul" disablePadding>
          <ListItem button onClick={this.handleRedirect.bind(this)}>
            <ListItemText primary={<Typography variant="subtitle1">
              Appointments
            </Typography>} />
            <ListItemIcon >
              <ArrowIcon />
            </ListItemIcon>
          </ListItem>
        </List>
      </Paper>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppointmentCard));
