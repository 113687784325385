import {
  GET_COMPANY,
  GET_COMPANY_ERROR,
  GET_COMPANIES,
  GET_COMPANIES_ERROR,
  ADD_COMPANY,
  ADD_COMPANY_ERROR,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ERROR
} from '../constants/action_types';

const initialState = {};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANY:
      return {
        ...state,
        company: action.payload,
      }
    case GET_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      }
    case GET_COMPANIES_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_COMPANY:
      return {
        ...state,
        error: false,
      };
    case ADD_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_COMPANY:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default companyReducer;
