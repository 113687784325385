import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import UpArrowIcon from '@material-ui/icons/ArrowDropUp';

const styles = theme => ({
  card: {
    margin: '3px'
  },
  photo: {
    marginRight: 12,
    borderRadius: 10,
    width: 65,
    height: 65,
    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 80,
    }
  },
  customerSaving: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  title: {
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem'
    }
  },
  caption: {
    fontSize: '0.45rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.75rem'
    }
  },
  bold: {
    fontWeight: 500,
  },
  center: {
    textAlign: 'center'
  },
  state: {
    textAlign: "end",
    textDecoration: 'underline',
    color: 'green'
  },
  arrowIcon: {
    fontSize: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px'
    }
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token
  }
};

class ListCard extends React.Component {
  state = {
    data: []
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data
    });
  };

  componentDidMount() {
    this.setState({
      data: this.props.data
    })
  };

  handleRedirect(id) {
    this.props.history.push(`/customers/${id}`)
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { classes } = this.props;
    const { data } = this.state;
    return (
      <Paper className={classes.card} >
        <List component="ul" id="customers">
          {data.map((customer, index) =>
            <Fragment key={customer.id}>
              <ListItem button onClick={this.handleRedirect.bind(this, customer.id)}>
                <Grid
                  container
                  spacing={0}
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <Grid item xs={6} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                    {customer.photo ?
                      <Avatar alt="avatar" src={customer.photo} className={classes.photo} />
                      :
                      <Avatar alt="avatar" src="/images/no-photo.png" className={classes.photo} />
                    }
                    <div >
                      <Typography variant="h5" component="h3" noWrap className={classes.title}>
                        {this.capitalize(customer.name)}
                      </Typography>
                      <Typography variant="caption" color="textSecondary" className={classes.caption}>
                        {`${customer.identification_type.toUpperCase()} ${customer.identification}`}
                      </Typography>
                      <Typography variant="caption" color="textSecondary" className={classes.caption}>
                        {this.capitalize(customer.__other_information__.bussines_type)}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={1}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: 60, justifyContent: 'center' }}>
                      {/*<Typography variant="body1" noWrap className={classes.state}>
                        Good
                      </Typography>*/}
                      {customer.alegra_id ?
                        <img src="/images/alegra_green.png" width="70" alt="alegra" />
                        :
                        null
                      }
                    </div>
                  </Grid>
                </Grid>
              </ListItem>
              {index === data.length - 1 ? null : <Divider />}
            </Fragment>
          )}
        </List>
      </Paper>
    )
  };
};

export default connect(mapStateToProps)(withStyles(styles)(ListCard));
