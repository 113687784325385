import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Link } from 'react-router-dom';
import { getUtilityCompanies } from '../../../actions';
import Form from './form';
import ListUtilityCompany from './list/list';
import ProfileUtilityCompany from './profile/profile';

import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CompanyIcon from '@material-ui/icons/BusinessCenter';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: "90vh",
    [theme.breakpoints.up('sm')]: {
      //height: '132vh',
      overflowY: 'auto',
      height: "calc(100vh - 64px)"
    }
  },
  bannerRoot: {
    backgroundColor: 'white',
    paddingBottom: 8,
    paddingTop: 8,
    /* Banner fixed */
    width: '100%',
    top: '56px',
    zIndex: 1,
    position: 'fixed',
    /*--------------*/
    [theme.breakpoints.up('sm')]: {
      /* Banner fixed */
      top: '64px',
      width: 'calc(100% - 250px)',
      /*--------------*/
    }
  },
  banner: {
    backgroundColor: 'white',
    paddingBottom: 8,
    paddingTop: 8,
    maxWidth: 1200,
    /* Banner fixed */
    width: '100%',
    top: '56px',
    zIndex: 1,
    position: 'fixed',
    /*--------------*/
    [theme.breakpoints.up('sm')]: {
      top: '64px'
    }
  },
  title: {
    fontSize: 30
  },
  profileArea: {
    marginTop: '68px',
    padding: '15px 15px 76px 15px',
    maxWidth: 1100,
    margin: '0 auto',
    overflowY: 'auto',
    position: 'relative'
  },
});

function mapStateToProps(state) {
  return {
    role: state.auth.user_type,
    token: state.auth.token,
    utilityCompanies: state.utilityCompany.utilityCompanies
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getUtilityCompanies: (token) => dispatch(getUtilityCompanies(token))
  }
};

class UtilityCompany extends React.Component {
  state = {
    show: false,
    utilityCompanies: [],
    loading: true
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      utilityCompanies: nextProps.utilityCompanies,
      loading: false
    });
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getUtilityCompanies(token);
    this.setState({
      utilityCompanies: this.props.utilityCompanies,
      loading: false
    })
  };

  handleShow(reload) {
    if (reload) this.fetchGet.bind(this);
    this.setState({
      show: !this.state.show
    });
  };

  render() {
    const { classes, match, intl, messages, role } = this.props;
    const { show, utilityCompanies, loading } = this.state;
    return (
      <div className={classes.root}>
        <div /*className={role.select ? classes.bannerRoot : classes.banner}*/ className={classes.bannerRoot}>
          <List component="ul">
            <ListItem >
              <ListItemIcon>
                <CompanyIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                Utility Companies
              </Typography>} />
              <ListItemSecondaryAction style={{ marginRight: 10 }}>
                <IconButton aria-label="add" >
                  {show ?
                    <ArrowLeftIcon onClick={this.handleShow.bind(this, true)} />
                    :
                    match.url === window.location.pathname && role.create ?
                      <AddIcon onClick={this.handleShow.bind(this, false)} />
                      :
                      <Link to={`${match.url}`} style={{ height: 24, color: 'inherit' }}>
                        <ArrowLeftIcon onClick={this.fetchGet.bind(this)} />
                      </Link>
                  }
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
        <div className={classes.profileArea}>
          {loading ?
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
              <CircularProgress />
            </div>
            :
            show ?
              <Slide direction="left" in={show} mountOnEnter unmountOnExit timeout={500}>
                <Form intl={intl} messages={messages} showCard={this.handleShow.bind(this)} fetchCard={this.fetchGet.bind(this)} />
              </Slide>
              :
              <Switch>
                <Route exact path={`${match.path}`} render={(props) => <ListUtilityCompany {...props} intl={intl} messages={messages} data={utilityCompanies} />} />
                <Route path={`${match.path}/:id`} render={(props) => <ProfileUtilityCompany {...props} intl={intl} messages={messages} />} />
              </Switch>
          }
        </div>
      </div>
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UtilityCompany));
