import {
  GET_EMPLOYEE,
  GET_EMPLOYEE_ERROR,
  SIGN_UP_EMPLOYEE,
  SIGN_UP_EMPLOYEE_ERROR,
  UPDATE_EMPLOYEE_PROFILE,
  UPDATE_EMPLOYEE_PROFILE_ERROR,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_ERROR,
  GET_SELLER_SELECT,
  GET_SELLER_SELECT_ERROR,
} from '../constants/action_types';

const initialState = { sellers: [] };

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEE:
      return {
        ...state,
        error: false,
        employee: action.payload
      };
    case GET_EMPLOYEE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case SIGN_UP_EMPLOYEE:
      return {
        ...state,
        error: false
      };
    case SIGN_UP_EMPLOYEE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_EMPLOYEE_PROFILE:
      return {
        ...state,
        error: false,
      };
    case UPDATE_EMPLOYEE_PROFILE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_EMPLOYEE:
      return {
        ...state,
        error: false,
      };
    case DELETE_EMPLOYEE_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case GET_SELLER_SELECT:
      return {
        ...state,
        error: false,
        sellers: action.payload
      };
    case GET_SELLER_SELECT_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default employeeReducer;
