const validate = (values, state) => {
  const { intl: { formatMessage }, messages } = state;
  const errors = {}

  if (!values.utility_bill) {
    errors.utility_bill = formatMessage(messages.signIn.required)
  }

  if (!values.date) {
    errors.date = formatMessage(messages.signIn.required)
  }

  if (!values.due_date) {
    errors.due_date = formatMessage(messages.signIn.required)
  }

  return errors
};

export default validate;
