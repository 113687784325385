import React, { Fragment } from 'react';
import ModalForm from './modal';
import SelectStatus from './select';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getStatusList, getCustomers, updateCustomerStatus } from '../../../actions';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import GroupIcon from '@material-ui/icons/Group';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MoreOptions from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    padding: '5px 10px 15px'
  },
  gridContainer: {
    flexWrap: 'wrap',
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap',
      overflowY: 'scroll'
    }
  },
  gridItem: {
    [theme.breakpoints.up('sm')]: {
      minWidth: 300
    }
  },
  divider: {
    border: '0.5px solid rgba(0, 0, 0, 0.05)',
    padding: 0,
    margin: '50px 5px 0',
    display: 'none'
  },
  list: {
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 0,
    maxHeight: 'calc(78vh - 64px)',
    minHeight: 50,
    overflowY: 'auto'
  },
  item: {
    padding: 8,
    margin: `0 0 8px 0`,
    background: 'white',
    borderRadius: 4,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)'
  },
  nullItem: {
    height: 104,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listTitle: {
    padding: '8px 16px',
    width: '100%',
    background: 'rgba(0, 0, 0, 0.05)',
    zIndex: 2,
    borderRadius: '4px 4px 0px 0px',
    position: '-webkit - sticky',
    position: 'sticky',
    top: 0
  },
  listBottomTitle: {
    padding: 8,
    width: '100%',
    background: 'rgba(0, 0, 0, 0.05)',
    zIndex: 2,
    borderRadius: '0px 0px 4px 4px',
    position: '-webkit - sticky',
    position: 'sticky',
    top: 0
  },
  listContent: {
    padding: 8
  },
  name: {
    fontSize: '0.8rem'
  },
  caption: {
    fontSize: '0.65rem'
  },
  photo: {
    marginRight: 12,
    borderRadius: 10,
    width: 65,
    height: 65
  },
  // Estilos toolbar
  icon: {
    color: theme.palette.newGrey.main,
    marginLeft: '3px'
  },
  toolbar: {
    top: 0,
    width: 'calc(100% - 10px)',
    padding: 0,
    whiteSpace: 'nowrap',
    zIndex: 3,
    background: 'white'
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    marginLeft: 10,
    fontSize: 30
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconTable: {
    color: theme.palette.newGrey.main,
    fontSize: '1.2rem'
  }
});

function mapDispatchToProps(dispatch) {
  return {
    getStatusList: (token) => dispatch(getStatusList(token)),
    getCustomers: (token, company) => dispatch(getCustomers(token, company)),
    updateCustomerStatus: (data, token, id) => dispatch(updateCustomerStatus(data, token, id))
  }
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    statusList: state.status.statusList,
    customers: state.customer.customers,
    dominio: state.auth.dominio
  }
};

class ListStatus extends React.Component {
  state = {
    customers: [],
    columnsCopy: [],
    columns: [
      {
        customers: []
      },
      {
        customers: []
      }
    ],
    data: {},
    loading: true,
    modal: false,
    idSelect: 'all'
  };

  componentDidMount() {
    window.addEventListener("resize", this.resizeElement.bind(this));
    this.fetchGet();
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeElement.bind(this));
  };

  resizeElement() {
    const difference = window.innerWidth - 740;
    if ((difference) > 1 && (difference) < 150) {
      this.fetchGet();
    };
  };

  handleChange(event) { //Encuentro la columna a dibujar al seleccionar
    // if (event.target.value === 'all') {
    //   const columnsCopy = this.state.columnsCopy;
    //   this.setState({
    //     columns: columnsCopy,
    //     idSelect: event.target.value
    //   });
    // } else 
    if (this.state.columns.length === 1) {
      const column = this.state.columnsCopy.find(column => column.id === event.target.value);
      this.setState({
        columns: [
          column
        ],
        idSelect: column.id
      });
    } else {
      const columnsCopy = this.state.columns;
      const column = columnsCopy.find(column => column.id === event.target.value);
      this.setState({
        columns: [
          column
        ],
        columnsCopy,
        idSelect: column.id
      });
    };
  };

  handleModal(customer) {
    this.setState({
      data: customer,
      modal: !this.state.modal
    });
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);

    await Promise.all([
      this.props.getStatusList(token),
      this.props.getCustomers(token, this.props.dominio)
    ]);

    const columns = [];
    this.props.statusList.map((item, index) => {
      columns.push({
        name: this.capitalize(item.name),
        id: item.id,
        url: item.url,
        label: `droppable${index + 1}`,
        customers: []
      });
    });

    columns.map(column =>
      this.props.customers.map((customer, index) => {
        if (customer.status === column.url) {
          column.customers.push(customer)
        };
      })
    );

    if (window.innerWidth < 740) {
      this.setState({
        customers: this.props.customers,
        columns: [columns[0]],
        loading: false,
        idSelect: columns[0].id,
        columnsCopy: columns
      });
    } else {
      this.setState({
        customers: this.props.customers,
        columns,
        loading: false,
        idSelect: 'all',
        columnsCopy: columns
      });
    };
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleRedirect(id) {
    this.props.history.push(`/customers/${id}`)
  };

  getList(id, returnList) {
    const list = this.state.columns.find((column, index) => column.label === id);
    return returnList ? list : list.customers
  };

  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  move(source, destination, droppableSource, droppableDestination) {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);
    const result = [];

    result.push({
      label: droppableSource.droppableId,
      data: sourceClone
    });
    result.push({
      label: droppableDestination.droppableId,
      data: destClone
    });

    return [result, removed];
  };

  async onDragEnd(result) {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    };

    if (source.droppableId === destination.droppableId) { // If drag item is in same list
      const customers = this.reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let newColumns = this.state.columns.map(column =>
        column.label === source.droppableId ?
          {
            ...column,
            customers: customers
          }
          :
          column
      );

      this.setState({
        columns: newColumns
      })

    } else { // If drag item isn't in the same list
      const result = this.move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      /*
      result is an array with 2 elements.
      1. Array of objects: Array with the new data for the columns.
      2. The customer to update.
      */

      const columns = this.state.columns;
      let newResult = columns.map((column, index) => {
        const columna = result[0].find(item => item.label === column.label);
        if (columna) {
          return {
            ...column,
            customers: columna.data
          };
        } else {
          return column
        };
      })

      this.setState({
        columns: newResult
      });

      const statusUrl = this.getList(destination.droppableId, true).url;
      const updatedCustomer = {
        ...result[1],
        status: statusUrl
      };

      if (typeof updatedCustomer.photo !== 'object') {
        delete updatedCustomer.photo
      };

      if (typeof updatedCustomer.agreement !== 'object') {
        delete updatedCustomer.agreement
      };

      const token = 'token '.concat(this.props.token);
      const id = updatedCustomer.id;
      await this.props.updateCustomerStatus(updatedCustomer, token, id);
    }
  };

  render() {
    const { columns, loading, modal, data } = this.state;
    const { classes, intl: { formatMessage }, intl, messages, role, token } = this.props;
    const customers = formatMessage(messages.navBar.customers);
    return (
      <div className={classes.root}>
        <ModalForm
          open={modal}
          handleModal={this.handleModal.bind(this)}
          data={data}
          intl={intl}
          token={token}
          messages={messages}
        />
        <Toolbar className={classes.toolbar}>
          <GroupIcon className={classes.icon} />
          <Typography component="h2" variant="h2" className={classes.title}>
            {customers}
          </Typography>
          <div className={classes.spacer} />
          <Switch
            checked={this.props.toggle}
            onChange={this.props.handleToggle}
            color="primary"
          />
          {role.create ?
            <Tooltip title="Add customer">
              <Link to={`advancesearch`}>
                <IconButton aria-label="Add customer">
                  <AddIcon />
                </IconButton>
              </Link>
            </Tooltip>
            :
            null
          }
        </Toolbar>
        <SelectStatus handleChange={this.handleChange.bind(this)} idSelect={this.state.idSelect} />
        <Grid container spacing={8} className={classes.gridContainer}>
          {loading ?
            <CircularProgress style={{ position: "absolute", top: "50%", margin: '0 50%' }} />
            :
            <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
              {columns.map((column, index) =>
                <Fragment key={index}>
                  <Grid item xs={12} key={index} className={classes.gridItem}>
                    <Typography variant="h6" className={classes.listTitle}>
                      {column.name}
                    </Typography>
                    <Droppable droppableId={`droppable${index + 1}`}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className={classes.list}
                        >
                          <div className={classes.listContent}>
                            {column.customers.map((customer, index) => (
                              <Draggable
                                key={customer.id}
                                draggableId={customer.id}
                                index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={classes.item}>
                                    <Grid
                                      container
                                      spacing={0}
                                      direction='row'
                                      justify='space-between'
                                      alignItems='center'
                                    >
                                      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                        {customer.photo ?
                                          <Avatar alt="avatar" src={customer.photo} className={classes.photo} />
                                          :
                                          <Avatar alt="avatar" src="/images/no-photo.png" className={classes.photo} />
                                        }
                                        <div style={{ width: '100%' }}>
                                          <Typography variant="h5" component="h3" noWrap className={classes.name}>
                                            {this.capitalize(customer.name)}
                                          </Typography>
                                          <Typography variant="caption" color="textSecondary" className={classes.caption}>
                                            {`${this.capitalize(customer.identification_type)} ${customer.identification}`}
                                          </Typography>
                                          <div className={classes.bottom}>
                                            <Typography variant="caption" color="textSecondary" className={classes.caption}>
                                              {this.capitalize(customer.__other_information__.bussines_type)}
                                            </Typography>
                                            <div>
                                              <IconButton style={{ padding: 2 }} aria-label="Profile" onClick={this.handleRedirect.bind(this, customer.id)}>
                                                <VisibilityIcon className={classes.iconTable} />
                                              </IconButton>
                                              <IconButton style={{ padding: 2 }} onClick={this.handleModal.bind(this, customer)}>
                                                <MoreOptions aria-label="More Options" className={classes.iconTable} />
                                              </IconButton>
                                            </div>
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Typography variant="h6" color="textSecondary" className={classes.listBottomTitle}>
                      <AddIcon style={{ verticalAlign: 'text-bottom' }} />
                      Drag customer
                  </Typography>
                  </Grid>
                  <div className={classes.divider} ></div>
                </Fragment>
              )}
            </DragDropContext>
          }
        </Grid>
      </div>
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(ListStatus)));
