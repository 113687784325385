import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { addUtilityCompany, getCities } from '../../../actions';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AssignmentIcon from '@material-ui/icons/Assignment';
import renderTextField from '../../ui/text_field';
import renderSelectField from '../../ui/select_field';
import renderFileInput from '../../ui/upload_field';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  paper: {
    width: '100%',
    //padding: '0px 24px 15px',
    //height: '80vh',
    //display: 'flex',
    //alignItems: 'center'
  },
  form: {
    padding: '15px 24px 15px',
  },
  grid: {
    //padding: '5px 24px 0px',
  },
  head: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 25
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '24px'
  },
  link: {
    color: theme.palette.primary.main
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.utilityCompany.error,
    token: state.auth.token,
    loading: state.loader.loading,
    cities: state.city.cities,
    dominio: state.auth.dominio
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getCities: (token, company) => dispatch(getCities(token, company)),
    addUtilityCompany: (values, token) => dispatch(addUtilityCompany(values, token))
  }
};

const validate = (values, state) => {
  const { intl: { formatMessage }, messages } = state;
  const errors = {}

  if (!values.name) {
    errors.name = formatMessage(messages.signIn.required)
  }

  if (!values.email) {
    errors.email = formatMessage(messages.signIn.required)
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = formatMessage(messages.general.invalidMail);
  }

  if (!values.address) {
    errors.address = formatMessage(messages.signIn.required)
  }

  if (!values.identification) {
    errors.identification = formatMessage(messages.signIn.required)
  }

  if (!values.identification_type) {
    errors.identification_type = formatMessage(messages.signIn.required)
  }

  if (!values.website) {
    errors.website = formatMessage(messages.signIn.required)
  }

  if (!values.phone) {
    errors.phone = formatMessage(messages.signIn.required)
  }

  if (!values.city) {
    errors.city = formatMessage(messages.signIn.required)
  }
  return errors
};

class Form extends React.Component {
  state = {
    cities: [],
    error: ''
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getCities(token, this.props.dominio ? this.props.dominio : '');

    const cities = [];
    this.props.cities.forEach(city =>
      cities.push({
        label: this.capitalize(city.name),
        value: city.url
      })
    );

    this.setState({
      cities: cities
    })
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        //console.log(key)
      }

      return form_data;
    }
  };

  async submit(values) {
    const val = {
      ...values,
      city: values.city.value,
      charges: '{ "a": "a" }'
    };
    //console.log(val)

    const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);

    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    await this.validateErrorsApi(data, token);
    this.props.showCard();
    this.props.fetchCard();
  };

  validateErrorsApi(data, token) {
    return this.props.addUtilityCompany(data, token)
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { intl: { formatMessage }, messages, pristine, submitting, classes, handleSubmit, loading } = this.props;
    const { cities } = this.state;
    return (
      <Paper className={classes.paper}>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} encType="multipart/form-data">
          <Grid container spacing={24} className={classes.grid}>
            <Grid item xs={12} className={classes.head}>
              <List component="ul">
                <ListItem disableGutters>
                  <ListItemIcon>
                    <AssignmentIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography component="h2" variant="h2" className={classes.title}> Add Utility Company </Typography>}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="name"
                component={renderTextField}
                label={formatMessage(messages.customers.formName)}
                ref="name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="email"
                component={renderTextField}
                label={formatMessage(messages.general.email)}
                ref="email"
                type='email'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="website"
                component={renderTextField}
                label="Website"
                ref="website"
                type="url"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="address"
                component={renderTextField}
                label={formatMessage(messages.customers.formAddress)}
                ref="address"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="identification_type"
                component={renderTextField}
                label="Identification Type"
                ref="identification_type"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="identification"
                component={renderTextField}
                label={formatMessage(messages.customers.formIdentification)}
                ref="identification"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="phone"
                component={renderTextField}
                label={formatMessage(messages.general.phone)}
                ref="phone"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="city"
                component={renderSelectField}
                options={cities}
                clearable={true}
                label={formatMessage(messages.customers.formCity)}
                ref="city"
                required
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name="logo"
                component={renderFileInput}
                ref="logo"
                label="Logo"
                inputid="logo"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                {formatMessage(messages.customers.formTitle)}
              </Typography>
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
};

const reduxFormAddUtilityCompany = reduxForm({
  form: 'addutilitycompany',
  validate
})(Form);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormAddUtilityCompany));
