import React, { Fragment } from 'react';
import AnalysisForm from './analysisForm';
import AnalysisCard from './card/analysisCard';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import BookIcon from '@material-ui/icons/ChromeReaderMode';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  root: {
    width: '100%',
    minHeight: "90vh",
    [theme.breakpoints.up('sm')]: {
      //height: '132vh',
      overflowY: 'auto',
      height: "calc(100vh - 64px)"
    }
  },
  banner: {
    backgroundColor: 'white',
    paddingBottom: 8,
    paddingTop: 8,
    width: '100%',
    top: '56px',
    zIndex: 2,
    position: 'fixed',
    [theme.breakpoints.up('sm')]: {
      top: '64px',
      width: 'calc(100% - 250px)',
    }
  },
  title: {
    fontSize: 30
  },
  analysisArea: {
    marginTop: '68px',
    maxWidth: 1100,
    margin: '0 auto',
    padding: '15px 15px 76px 15px',
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out',
    [theme.breakpoints.up('sm')]: {
      padding: 15
    }
  },
  paper: {
    padding: 15,
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    textAlign: 'center'
  }
});

class AnalysisArea extends React.Component {
  state = {
    show: false,
    info: false
  };

  handleShow(show) {
    this.setState({
      show: !this.state.show
    });
  };

  handleInfo(info) {
    this.setState({
      info: !this.state.info
    });
  };

  render() {
    const { intl, classes, messages, roleå } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.banner}>
          <List component="ul">
            <ListItem >
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                Analysis
              </Typography>} />
              {/*
              <ListItemSecondaryAction style={{ marginRight: 10 }}>
                <IconButton aria-label="edit" onClick={this.handleShow.bind(this)}>
                  {role === 'admin' ?
                    show ?
                      <ArrowLeftIcon /*onClick={this.fetchGet.bind(this)}/>
                      :
                      <EditIcon />
                    :
                    null
                  }
                </IconButton>
              </ListItemSecondaryAction>
                */}
            </ListItem>
          </List>
        </div>
        <div className={classes.analysisArea}>
          {this.state.show ?
            <AnalysisForm intl={intl} messages={messages} handleShow={this.handleShow.bind(this)} handleInfo={this.handleInfo.bind(this)} />
            :
            this.state.info ?
              <AnalysisCard intl={intl} messages={messages} />
              :
              <Paper className={classes.paper}>
                <Typography variant="body1">
                  No analysis generated, please register information to develop an analysis
                </Typography>
                <Button color="primary" variant="contained" onClick={this.handleShow.bind(this)}>
                  <Typography variant='button' noWrap >
                    Generate
                </Typography>
                </Button>
              </Paper>
          }
        </div>
      </div>
    )
  }
};

export default withStyles(styles)(AnalysisArea);
