import React, { Fragment } from 'react';
import { updateBusinessType, deleteBusinessType } from '../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import renderTextField from '../../ui/text_field';
import Button from '@material-ui/core/Button';
import ModalDialog from '../../modal';

const styles = theme => ({
  paper: {
    border: 'none',
    [theme.breakpoints.up('sm')]: {
      border: '1px solid rgba(0, 0, 0, 0.14)'
    }
  },
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    //padding: '0px 24px 15px',
  },
  expansionTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  button: {
    margin: '0 5px'
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type
  }
};

function mapDispatchToProps(dispatch) {
  return {
    updateBusinessType: (values, token, id) => dispatch(updateBusinessType(values, token, id)),
    deleteBusinessType: (token, id) => dispatch(deleteBusinessType(token, id))
  }
};

class BusinessTypeEdit extends React.Component {
  state = {
    open: false
  };

  async submit(values) {
    let val = values;

    if (val.weight) {
      const arrayWeight = val.weight.split(',');
      const weight = arrayWeight.map(item => parseInt(item, 10));
      val = {
        ...values,
        weight: weight
      };
    };

    /* Evitar valores nulos*/
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    //console.log(val);
    /*---------------------*/

    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    //const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;

    await this.validateErrorsApi(val, token, id);
    this.props.edit();
    this.props.fetchCard();
  };

  validateErrorsApi(val, token, id) {
    return this.props.updateBusinessType(val, token, id);
  };

  handleOpen(open) {
    this.setState({
      open: open
    })
  };

  async deleteData() {
    this.handleOpen(false)
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;
    await this.props.deleteBusinessType(token, id);
    this.props.fetchCard();
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading, userType, role } = this.props;
    const { open } = this.state;
    return (
      <Fragment>
        <ModalDialog
          open={open}
          title="Are you sure you want to delete this business type ?"
          description="This action directly affects the database and is irreversible."
          actionName="Delete"
          handleOpen={this.handleOpen.bind(this)}
          handleAction={this.deleteData.bind(this)}
        />
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} /*encType="multipart/form-data" id="myForm"*/>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <Field
                name="name"
                component={renderTextField}
                label="Name"
                ref="name"
              />
            </Grid>
            <Grid item xs={6} >
              <Field
                name="description"
                component={renderTextField}
                label="Description"
                ref="description"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="weight"
                component={renderTextField}
                label="Weight"
                ref="weight"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Typography variant='button' noWrap className={classes.link} onClick={this.props.edit}>
              Return to Business Type
            </Typography>
            <div>
              {role.delete ?
                <Button className={classes.button} color='secondary' variant="contained" onClick={this.handleOpen.bind(this, true)} disabled={loading}>
                  <Typography variant='button' noWrap>
                    Delete
                </Typography>
                </Button>
                :
                null
              }
              <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
                <Typography variant='button' noWrap>
                  Update
                </Typography>
              </Button>
            </div>
          </div>
        </form>
      </Fragment>
    )
  }
};

const reduxFormUpdateBusinessType = reduxForm({
  form: 'updatebusinesstype',
})(BusinessTypeEdit);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateBusinessType));
