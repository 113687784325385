import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

const styles = theme => ({
  card: {
    margin: '3px'
  },
  photo: {
    marginRight: 12,
    borderRadius: 10,
    width: 65,
    height: 65,
    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 80,
    }
  },
  customerSaving: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  title: {
    fontSize: '0.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem'
    }
  },
  caption: {
    fontSize: '0.45rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '0.75rem'
    }
  },
  bold: {
    fontWeight: 500,
  },
  center: {
    textAlign: 'center'
  },
  state: {
    textAlign: "end",
    textDecoration: 'underline',
    color: 'green'
  },
  arrowIcon: {
    fontSize: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px'
    }
  }
});

class ListCard extends React.Component {
  state = {
    data: []
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: nextProps.data
    })
  };

  componentDidMount() {
    this.setState({
      data: this.props.data
    })
  };

  handleRedirect(id) {
    this.props.history.push(`/sellers/${id}`)
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { classes } = this.props;
    const { data } = this.state;
    return (
      <Paper className={classes.card} >
        <List component="ul" id="sellers">
          {data.map((seller, index) =>
            <Fragment key={seller.id}>
              <ListItem button onClick={this.handleRedirect.bind(this, seller.id)}>
                <Grid
                  container
                  spacing={0}
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <Grid item xs={6} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar alt="avatar" src={seller.photo} className={classes.photo} />
                    <div >
                      <Typography variant="h5" component="h3" noWrap className={classes.title}>
                        {`${this.capitalize(seller.first_name)} ${this.capitalize(seller.last_name)}`}
                      </Typography>
                      <Typography variant="caption" color="textSecondary" className={classes.caption}>
                        {`${this.capitalize(seller.identification_type)} ${seller.identification}`}
                      </Typography>
                    </div>
                  </Grid>
                  {/*<Grid item xs={2} sm={1} className={classes.customerSaving}>
                    <div className={classes.center}>
                      <Typography variant="body1" noWrap >
                        20%
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        Savings
                      </Typography>
                    </div>
                    <UpArrowIcon className={classes.arrowIcon} style={{ color: 'green' }} />
                  </Grid>
                  <Grid item xs={4} sm={2}>
                    <div className={classes.center}>
                      <Typography variant="caption" className={classes.bold} className={classes.caption}>
                        $20.000.000 COP
                      </Typography>
                      <Typography variant="caption" className={classes.bold} className={classes.caption}>
                        20 December 2018
                      </Typography>
                      <Typography variant="caption" color="textSecondary" className={classes.caption}>
                        Last Bill
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={2} sm={1}>
                    <Typography variant="body1" noWrap className={classes.state}>
                      Good
                    </Typography>
                  </Grid>*/}
                </Grid>
              </ListItem>
              {index === data.length - 1 ? null : <Divider />}
            </Fragment>
          )}
        </List>
      </Paper>
    )
  };
};

export default withStyles(styles)(ListCard);
