import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { addCompany } from '../../actions'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AssignmentIcon from '@material-ui/icons/Assignment';
import renderTextField from '../ui/text_field';
import renderFileInput from '../ui/upload_field';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  paper: {
    width: '100%',
    //padding: '0px 24px 15px',
    //height: '80vh',
    //display: 'flex',
    //alignItems: 'center'
  },
  form: {
    padding: '5px 24px 15px',
  },
  title: {
    margin: '30px 0px 30px 10px',
    fontSize: 30
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.primary.main
  }
});

function mapStateToProps(state) {
  return {
    error: state.company.error,
    token: state.auth.token,
    loading: state.loader.loading
  }
};

function mapDispatchToProps(dispatch) {
  return {
    addCompany: (values, token, history) => dispatch(addCompany(values, token, history))
  }
};

const validate = (values, state) => {
  const { intl: { formatMessage }, messages } = state;
  const errors = {}

  if (!values.name) {
    errors.name = formatMessage(messages.signIn.required)
  }

  if (!values.email) {
    errors.email = formatMessage(messages.signIn.required)
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = formatMessage(messages.general.invalidMail);
  }

  if (!values.address) {
    errors.address = formatMessage(messages.signIn.required)
  }

  if (!values.photo) {
    errors.photo = formatMessage(messages.signIn.required)
  }

  if (!values.phone) {
    errors.phone = formatMessage(messages.signIn.required)
  }

  if (!values.sequency) {
    errors.sequency = formatMessage(messages.signIn.required)
  }

  if (!values.token_alegra) {
    errors.token_alegra = formatMessage(messages.signIn.required)
  }

  if (!values.user_name_alegra) {
    errors.user_name_alegra = formatMessage(messages.signIn.required)
  }


  return errors
};

class CompanyForm extends React.Component {
  state = {
    cities: [],
    utilityCompanies: [],
    bussinesTypes: [],
    status: [],
    sellers: [],
    error: ''
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        console.log(key)
      }

      return form_data;
    }
  };

  submit(values) {
    const val = {
      ...values,
      sequency: parseInt(values.sequency, 10)
    }
    console.log(val)

    const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    return this.props.addCompany(data, token, this.props.history);
  };

  render() {
    const { intl: { formatMessage }, messages, pristine, submitting, classes, handleSubmit, loading } = this.props;
    return (
      <div className={classes.paper}>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} enctype="multipart/form-data">
          <Grid container spacing={16}>
            <Grid item xs={12} className={classes.head}>
              <AssignmentIcon className={classes.icon} />
              <Typography component="h2" variant="h2" className={classes.title}>
                {formatMessage(messages.customers.formTitle)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="name"
                component={renderTextField}
                label={formatMessage(messages.customers.formName)}
                ref="name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="email"
                component={renderTextField}
                label={formatMessage(messages.general.email)}
                ref="email"
                type='email'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="address"
                component={renderTextField}
                label={formatMessage(messages.customers.formAddress)}
                ref="address"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="phone"
                component={renderTextField}
                label={formatMessage(messages.general.phone)}
                ref="phone"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="sequency"
                component={renderTextField}
                label="Sequency"
                ref="sequency"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="token_alegra"
                component={renderTextField}
                label="Token Alegra"
                ref="token_alegra"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="user_name_alegra"
                component={renderTextField}
                label="Username Alegra"
                ref="user_name_alegra"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="photo"
                component={renderFileInput}
                ref="photo"
                label="Photo"
                inputid="photo"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="company_description"
                component={renderTextField}
                multiline={true}
                label="Company description"
                ref="company_description"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="company_vision"
                component={renderTextField}
                multiline={true}
                label="Company vision"
                ref="company_vision"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="company_proposal"
                component={renderTextField}
                multiline={true}
                label="Company proposal"
                ref="company_proposal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="company_model"
                component={renderTextField}
                multiline={true}
                label="Company model"
                ref="company_model"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Link to="/settings">
              <Typography variant='button' noWrap className={classes.link}>
                Company List
              </Typography>
            </Link>
            <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                {formatMessage(messages.customers.formTitle)}
              </Typography>
            </Button>
          </div>
        </form>
      </div>
    );
  }
};

const reduxFormAddCompany = reduxForm({
  form: 'addCompany',
  validate
})(CompanyForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormAddCompany));
