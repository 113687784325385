import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  gridItem: {
    textAlign: 'end'
  },
  list: {
    width: '100%'
  },
  rootExpansion: {
    //border: '1px solid rgba(0,0,0,.125)',
    boxShadow: 'none',
    /*'&:not(:last-child)': {
      borderBottom: 0,
    },*/
    '&:before': {
      display: 'none',
    }
  },
  expandedExpansion: {
    margin: 'auto',
  },
  expansionTitle: {
    fontSize: 17,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.6,
    color: '#233b54',
  },
  subValue: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.4,
    color: '#000000'
  }
});

class AnalysisInfo extends React.Component {
  state = {
    expanded: false
  };

  handleExpand() {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { intl, classes, messages, info } = this.props;
    const { expanded } = this.state;
    return (
      <ExpansionPanel
        expanded={expanded}
        classes={{ root: classes.rootExpansion }}
      >
        <ExpansionPanelSummary onClick={this.handleExpand.bind(this)} expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.expansionTitle}></Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <List component="ul" className={classes.list}>
            {info.map((item, index) =>
              <ListItem disableGutters key={index}>
                <Grid
                  container
                  spacing={0}
                  direction='row'
                >
                  <Grid item xs={6}>
                    <Typography noWrap className={classes.subValue}>
                      {item.label}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.gridItem}>
                    <Typography noWrap className={classes.subValue} style={{ fontWeight: 'bold' }}>
                      {item.value}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            )}
          </List>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
};

export default withStyles(styles)(AnalysisInfo);
