import React from 'react';
import Paper from '@material-ui/core/Paper';
import Map from './map'

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MapIcon from '@material-ui/icons/Map';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  mapDashboard: {
    width: '100%',
    height: 213
  },
  mapProfile: {
    width: '100%',
    height: 170
  }
});

class MapCard extends React.Component {
  render() {
    const { intl: { formatMessage }, title, on, classes } = this.props;
    return (
      <Paper style={{ height: '100%' }}>
        {title ?
          <List component="ul">
            <ListItem >
              <ListItemIcon>
                <MapIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography component="h2" variant="h6" >
                {formatMessage(title)}
              </Typography>} />
            </ListItem>
          </List>
          :
          null
        }
        <Map
          on={on === 'profile' ? classes.mapProfile : classes.mapDashboard}
          id="myMap"
          options={{
            center: { lat: 10.9685400, lng: -74.7813200 },
            zoom: 10
          }}
          onMapLoad={map => {
            const marker = new window.google.maps.Marker({
              position: { lat: 10.9685400, lng: -74.7813200 },
              map: map,
              title: 'Barranquilla'
            });
            /*const request = new window.google.maps.places.PlacesService({
              query: 'barranquilla',
              map: map
            })*/
          }}
        />
      </Paper>
    );
  }
};

export default withStyles(styles)(MapCard);
