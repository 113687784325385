const general = {
    email: {
        id: "form.email"
    },
    password: {
        id: "form.password"
    },
    phone: {
        id: "form.phone"
    },
    send: {
        id: "form.send"
    },
    invalidMail: {
        id: 'form.invalidMail'
    }
};

const signIn = {
    title: {
        id: 'formLogin.title'
    },
    subtitle: {
        id: 'formLogin.subtitle'
    },
    error: {
        id: 'formLogin.error'
    },
    link: {
        id: 'formLogin.link'
    },
    required: {
        id: 'formLogin.required'
    }
};

const recovery = {
    title: {
        id: 'formRecovery.title'
    },
    subtitle: {
        id: 'formRecovery.subtitle'
    }
};

const navBar = {
    dashboard: {
        id: 'navBar.dashboard'
    },
    search: {
        id: 'navBar.search'
    },
    customers: {
        id: 'navBar.customers'
    },
    sellers: {
        id: 'navBar.sellers'
    },
    accounting: {
        id: 'navBar.accounting'
    },
    profile: {
        id: 'navBar.profile'
    },
    logout: {
        id: 'navBar.logout'
    }
}

const dashboard = {
    greetingOne: {
        id: 'dashboard.greetingOne'
    },
    greetingTwo: {
        id: 'dashboard.greetingTwo'
    },
    greetingThree: {
        id: 'dashboard.greetingThree'
    },
    cardSellers: {
        id: 'dashboard.cardSellers'
    },
    cardSavings: {
        id: 'dashboard.cardSavings'
    },
    cardLate: {
        id: 'dashboard.cardLate'
    },
    cardChart: {
        id: 'dashboard.cardChart'
    },
    cardMap: {
        id: 'dashboard.cardMap'
    }
}

const customers = {
    formTitle: {
        id: 'formSignUp.title'
    },
    formName: {
        id: 'formSignUp.name'
    },
    formAddress: {
        id: 'formSignUp.address'
    },
    formNit: {
        id: 'formSignUp.nit'
    },
    formCity: {
        id: 'formSignUp.city'
    },
    formState: {
        id: 'formSignUp.state'
    },
    formLink: {
        id: 'formSignUp.link'
    },
    formIdentification: {
        id: "formSignUp.identification"
    },
    bills: {
        id: "customer.bills"
    },
    abill: {
        id: "customer.bill"
    },
    details: {
        id: "customerCard.details"
    },
    aqueductConsumed: {
        id: "customerCard.aqueductConsumed"
    },
    aqueductValue: {
        id: "customerCard.aqueductValue"
    },
    aqueduct: {
        id: "customerCard.aqueduct"
    },
    sewerageConsumed: {
        id: "customerCard.sewerageConsumed"
    },
    sewerageValue: {
        id: "customerCard.sewerageValue"
    },
    sewerage: {
        id: "customerCard.sewerage"
    },
    period: {
        id: "customerCard.period"
    },
    payDay: {
        id: "customerCard.payDay"
    },
    payReference: {
        id: "customerCard.payReference"
    },
    link: {
        id: "customerCard.link"
    },
    billFormTitle: {
        id: "customerBill.Formtitle"
    },
    billFormUtility: {
        id: "customerBill.FormUtilityCompany"
    },
    billFormCubic: {
        id: "customerBill.FormCubic"
    },
    billFormPrice: {
        id: "customerBill.FormPrice"
    },
    billFormCubicSewerage: {
        id: "customerBill.FormCubicSewerage"
    },
    billFormPriceSewerage: {
        id: "customerBill.FormPriceSewerage"
    },
    billFormSubmit: {
        id: "customerBill.FormSubmit"
    }
}

const messages = {
    general,
    signIn,
    recovery,
    navBar,
    dashboard,
    customers
}

export default messages
