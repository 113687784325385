import React, { Fragment } from 'react';
import IndexRoutes from './routes';

import CssBaseline from '@material-ui/core/CssBaseline';
import { theme } from './constants/constants';
import 'moment/locale/es'; //locale for the calendar
import { MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';

class App extends React.Component {
  render() {
    return (
      <Fragment>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <IndexRoutes />
        </ MuiThemeProvider>
      </Fragment>
    )
  }
};

export default App;
