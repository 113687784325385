import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SettingsRoot from './settingsRoot';
import SettingsIndex from './settingsIndex';
import CompanyForm from './form';

class Settings extends React.Component {
  render() {
    const { intl, messages, match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.path}`} render={(props) => <SettingsRoot {...props} intl={intl} messages={messages} />} />
        <Route path={`${match.path}/addCompany`} render={(props) => <CompanyForm {...props} intl={intl} messages={messages} />} />
        <Route path={`${match.path}/:id`} render={(props) => <SettingsIndex {...props} intl={intl} messages={messages} />} />
      </Switch>
    );
  }
};

export default Settings;
