import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { changeSideList } from '../../actions';
import CustomerCard from './cardList/customer';
import SellerCard from './cardList/seller';
import AppointmentCard from './cardList/appointments';

import Grid from '@material-ui/core/Grid';
import MapCard from '../mapcard';
import ChartCard from '../chartCard';

function mapDispatchToProps(dispatch) {
  return {
    changeSideList: (page, info, link) => dispatch(changeSideList(page, info, link))
  }
};

function mapStateToProps(state) {
  return {
    role: state.auth.user_type
  }
};
class Dashboard extends React.Component {
  render() {
    const { intl, messages, role, history } = this.props;
    //this.props.changeSideList("dashboard", '');
    const list = [
      <CustomerCard intl={intl} messages={messages} history={history} />,
      <AppointmentCard intl={intl} messages={messages} history={history} />,
      <SellerCard intl={intl} messages={messages} history={history} />
    ];
    return (
      <Grid
        container
        spacing={16}
        direction='row'
        justify='flex-start'
        alignItems='flex-start'
      >
        {role.label === 'root' ?
          <Fragment>
            <Grid item xs={12} sm={6}>
              <ChartCard intl={intl} title={messages.dashboard.cardChart} ele={true} banner={true} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MapCard intl={intl} title={messages.dashboard.cardMap} />
            </Grid>
          </Fragment>
          :
          null
        }
        <Grid item xs={12} sm={6}>
          <Grid
            container
            spacing={16}
            direction='column'
            justify='flex-start'
          >
            {list.map((item, index) =>
              index % 2 === 0 ?
                <Grid item xs={12} key={index}>
                  {item}
                </Grid>
                :
                null
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid
            container
            spacing={16}
            direction='column'
            justify='flex-start'
          >
            {list.map((item, index) =>
              index % 2 !== 0 ?
                <Grid item xs={12} key={index}>
                  {item}
                </Grid>
                :
                null
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
