import {
  GET_UTILITY_COMPANIES,
  GET_UTILITY_COMPANIES_ERROR,
  ADD_UTILITY_COMPANY,
  ADD_UTILITY_COMPANY_ERROR,
  UPDATE_UTILITY_COMPANY,
  UPDATE_UTILITY_COMPANY_ERROR,
  DELETE_UTILITY_COMPANY,
  DELETE_UTILITY_COMPANY_ERROR
} from '../constants/action_types';

const initialState = { utilityCompanies: [{ name: '', id: 1 }] };

const utilityCompanyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UTILITY_COMPANIES:
      return {
        ...state,
        utilityCompanies: action.payload,
      };
    case GET_UTILITY_COMPANIES_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_UTILITY_COMPANY:
      return {
        ...state,
        error: false,
      };
    case ADD_UTILITY_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_UTILITY_COMPANY:
      return {
        ...state,
        error: false,
      }
    case UPDATE_UTILITY_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_UTILITY_COMPANY:
      return {
        ...state,
        error: false,
      };
    case DELETE_UTILITY_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default utilityCompanyReducer;
