import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  paper: {
    margin: 'calc(50vh - 185px) auto',
    maxWidth: theme.spacing.unit * 50,
    //backgroundColor: theme.palette.background.paper,
    //boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
    outline: 'none',
  }
});

class ModalImages extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper} >
        <Carousel transitionTime={1000} showThumbs={false}>
          <div>
            <img src="/images/valvula-antes.jpeg" />
          </div>
          <div>
            <img src="/images/valvula-despues.jpg" />
          </div>
        </Carousel>
      </Paper>
    );
  }
};

export default withStyles(styles)(ModalImages);
