import React from 'react';
import { addBooking, getEmployees } from '../../../actions';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import renderTextField from '../../ui/text_field';
import renderSelectFieldTest from '../../ui/select_field_test';
import universalDateTimePicker from '../../ui/universal_date_time_picker';
import moment from 'moment';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  paper: {
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out'
  },
  head: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  form: {
    width: '100%',
    padding: '15px 24px 15px',
  },
  title: {
    fontSize: 25
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
});

function mapStateToProps(state) {
  return {
    error: state.booking.error,
    token: state.auth.token,
    loading: state.loader.loading,
    sellers: state.employee.sellers,
    dominio: state.auth.dominio
  }
};

function mapDispatchToProps(dispatch) {
  return {
    addBooking: (values, token) => dispatch(addBooking(values, token)),
    getEmployees: (token, company) => dispatch(getEmployees(token, company))
  }
};

const validate = (values, state) => {
  const { intl: { formatMessage }, messages } = state;
  const errors = {}

  if (!values.date) {
    errors.date = formatMessage(messages.signIn.required)
  }

  return errors
};

class BookingForm extends React.Component {
  state = {
    sellers: [],
    options: [
      {
        label: 'Pending',
        value: 'Pending'
      },
      {
        label: 'Done',
        value: 'Done'
      }
    ]
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getEmployees(token, this.props.dominio);

    const sellers = [];
    this.props.sellers.forEach(item =>
      sellers.push({
        label: this.capitalize(item.first_name) + ' ' + this.capitalize(item.last_name),
        value: item.url
      })
    );

    this.setState({
      sellers
    });
  };

  async submit(values) {
    const val = {
      ...values,
      customer: this.props.customer.url,
      seller: values.seller,
      start_time: moment(values.start).format(),
      end_time: moment(values.end).format()
      //status: values.status.value
    };
    console.log(values)
    console.log(val)
    const token = 'token '.concat(this.props.token);
    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    await this.validateErrorsApi(val, token);
    this.props.showCard();
    this.props.fetchCard();
  };

  validateErrorsApi(data, token) {
    return this.props.addBooking(data, token)
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading, error } = this.props;
    const { options, sellers } = this.state;
    return (
      <Paper className={classes.paper}>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} /*encType="multipart/form-data"*/>
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.head}>
              <List component="ul">
                <ListItem disableGutters>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                    Add Bookings
                  </Typography>} />
                </ListItem>
              </List>
              <Link to={`/calendar`}>
                <Typography variant='button' noWrap className={classes.link}>
                  Calendar view
              </Typography>
              </Link>
            </Grid>
            {error ?
              <Grid item xs={12}>
                <Typography color="error">
                  {error}
                </Typography>
              </Grid>
              :
              null
            }
            {/*<Grid item xs={12} sm={6}>
              <Field
                name="status"
                component={renderSelectField}
                options={options}
                clearable={true}
                label="Status"
                ref="status"
              />
            </Grid>*/}
            <Grid item xs={12}>
              <Field
                name="type_booking"
                component={renderTextField}
                label="Type of booking"
                ref="type_booking"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="description"
                component={renderTextField}
                label="Description"
                ref="description"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="status"
                component={renderTextField}
                label="Status"
                ref="status"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="seller"
                component={renderSelectFieldTest}
                options={sellers}
                label="Seller"
                ref="seller"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="start"
                component={universalDateTimePicker}
                label='Start Date'
                ref="start"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="end"
                component={universalDateTimePicker}
                label='End Date'
                ref="end"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Typography variant='button' noWrap className={classes.link} onClick={this.props.showCard}>
              Return to Bookings
            </Typography>
            <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                {formatMessage(messages.customers.billFormSubmit)}
              </Typography>
            </Button>
          </div>
        </form>
      </Paper>
    )
  }
};

const reduxFormAddBooking = reduxForm({
  form: 'addbooking',
  validate
})(BookingForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormAddBooking));
