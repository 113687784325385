import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import InventoryEdit from './inventoryEdit';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  paper: {
    padding: 16,
    marginBottom: 15
  },
  lastGridItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type
  }
};

class InventoryCard extends React.Component {
  state = {
    edit: false,
    values: {}
  };

  handleEdit() {
    this.setState({
      edit: !this.state.edit
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { edit } = this.state;
    const { data, intl, messages, classes, loading, role } = this.props;
    return (
      <Fragment>
        {loading && edit ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={0}
            direction='row'
            justify='space-between'
            alignItems='center'
          >
            {edit ?
              <Grid item xs={12}>
                <InventoryEdit
                  intl={intl}
                  messages={messages}
                  initialValues={data}
                  edit={this.handleEdit.bind(this)}
                  fetchCard={this.props.fetchCard}
                  index={data.id}
                />
              </Grid>
              :
              <Fragment>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h5" style={{ margin: '20px 0' }}>
                    {this.capitalize(data.name)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body2">
                    {this.capitalize(data.description)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography variant="body2">
                    Quantity {data.quantity}
                  </Typography>
                </Grid>
                {role.update ?
                  <Grid item xs={12} className={classes.lastGridItem}>
                    <Typography variant='button' noWrap className={classes.link} onClick={this.handleEdit.bind(this)}>
                      Edit
                    </Typography>
                  </Grid>
                  :
                  null
                }
              </Fragment>
            }
          </Grid>
        </Paper>
      </Fragment>
    );
  }
};

export default connect(mapStateToProps)(withStyles(styles)(InventoryCard));
