import React from 'react';

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const renderCheckboxField = ({ input, label, disabled }) => (
  <div>
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={disabled ? true : input.value ? true : false}
          onChange={input.onChange}
          disabled={disabled ? true : false}
        />
      }
      label={label}
    />
  </div>
)

export default renderCheckboxField;
