import React, { Fragment } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { updateCommission, deleteCommission } from '../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import renderSelectField from '../../ui/select_field';
import Button from '@material-ui/core/Button';
import ModalDialog from '../../modal';

const styles = theme => ({
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    //padding: '0px 24px 15px',
  },
  expansionTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  button: {
    margin: '0 5px'
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type
  }
};

function mapDispatchToProps(dispatch) {
  return {
    updateCommission: (values, token, id) => dispatch(updateCommission(values, token, id)),
    deleteCommission: (token, id) => dispatch(deleteCommission(token, id))
  }
};

class CommissionEdit extends React.Component {
  state = {
    userTypes: [],
    open: false
  };

  componentDidMount() {
    const token = 'token '.concat(this.props.token);
    axios.get('https://api.elasustentable.com/rest/user/usertype',
      {
        headers: { Authorization: token }
      })
      .then(response => {
        //console.log(response.data);
        this.setState({
          userTypes: response.data.results
        })
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  };

  async submit(values) {
    const val = values;

    if (val.userType) {
      val.userType = values.userType.value
    };

    if (val.value) {
      val.value = parseInt(values.value, 10)
    };

    /* Evitar valored nulos*/
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    //console.log(val);
    /*---------------------*/

    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    //const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;

    await this.validateErrorsApi(val, token, id);
    this.props.edit();
    this.props.fetchCard();
  };

  validateErrorsApi(val, token, id) {
    return this.props.updateCommission(val, token, id);
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleOpen(open) {
    this.setState({
      open: open
    })
  };

  async deleteData() {
    this.handleOpen(false)
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;
    await this.props.deleteCommission(token, id);
    this.props.fetchCard();
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading, userType, role } = this.props;
    const { open } = this.state;
    const userTypes = [];

    this.state.userTypes.forEach(item =>
      userTypes.push({
        label: this.capitalize(item.name),
        value: item.url
      })
    );
    return (
      <Fragment>
        <ModalDialog
          open={open}
          title="Are you sure you want to delete this commission ?"
          description="This action directly affects the database and is irreversible."
          actionName="Delete"
          handleOpen={this.handleOpen.bind(this)}
          handleAction={this.deleteData.bind(this)}
        />
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} /*encType="multipart/form-data" id="myForm"*/>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <Field
                name="user_type"
                component={renderSelectField}
                options={userTypes}
                clearable={true}
                label="User Type"
                ref="user_type"
                is={userType}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="value"
                component={renderTextField}
                label="Value"
                ref="value"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="description"
                component={renderTextField}
                label="Description"
                ref="description"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Typography variant='button' noWrap className={classes.link} onClick={this.props.edit}>
              Return to Bill
            </Typography>
            <div>
              {role.delete ?
                <Button className={classes.button} color='secondary' variant="contained" onClick={this.handleOpen.bind(this, true)} disabled={loading}>
                  <Typography variant='button' noWrap>
                    Delete
                </Typography>
                </Button>
                :
                null
              }
              <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
                <Typography variant='button' noWrap>
                  Update
                </Typography>
              </Button>
            </div>
          </div>
        </form>
      </Fragment>
    )
  }
};

const reduxFormUpdateCommission = reduxForm({
  form: 'updatecommission',
})(CommissionEdit);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateCommission));
