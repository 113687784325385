const validate = (values, state) => {
  const { intl: { formatMessage }, messages } = state;
  const errors = {}

  if (!values.company) {
    errors.company = formatMessage(messages.signIn.required)
  }

  if (!values.first_name) {
    errors.first_name = formatMessage(messages.signIn.required)
  }

  if (!values.last_name) {
    errors.last_name = formatMessage(messages.signIn.required)
  }

  /*if (!values.email) {
    errors.email = formatMessage(messages.signIn.required)
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = formatMessage(messages.general.invalidMail);
  }*/

  if (!values.address) {
    errors.address = formatMessage(messages.signIn.required)
  }

  if (!values.identification) {
    errors.identification = formatMessage(messages.signIn.required)
  }

  /*if (!values.phone) {
    errors.phone = formatMessage(messages.signIn.required)
  }*/

  if (!values.city) {
    errors.city = formatMessage(messages.signIn.required)
  }

  if (!values.identification_type) {
    errors.identification_type = formatMessage(messages.signIn.required)
  }

  return errors
};

export default validate;
