import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getStatusList } from '../../../../actions';

import withStyles from '@material-ui/core/styles/withStyles';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';

const styles = theme => ({
  langBar: {
    fontSize: 12,
    margin: 0,
    display: 'block',
    marginBottom: 10,
    width: '35%',
    marginLeft: 3,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  input: {
    '&:after': {
      borderBottom: 'none',
    },
    '&:before': {
      borderBottom: 'none',
    }
  }
});

function mapDispatchToProps(dispatch) {
  return {
    getStatusList: (token) => dispatch(getStatusList(token))
  }
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    statusList: state.status.statusList,
    dominio: state.auth.dominio
  }
};

class SelectStatus extends React.Component {
  state = {
    statusList: [],
    id: ''
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.idSelect
    });
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getStatusList(token);

    const statusList = [];
    this.props.statusList.map((item, index) => {
      statusList.push({
        id: item.id,
        value: item.url,
        label: this.capitalize(item.name)
      });
    });

    this.setState({
      statusList
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { classes } = this.props;
    const { statusList } = this.state;
    return (
      <Select
        disableUnderline
        value={this.state.id}
        name="status"
        onChange={this.props.handleChange.bind(this)}
        className={classes.langBar}
        input={<Input classes={{ underline: classes.input }} />}
      >
        {/* <MenuItem value="all">
          <Typography variant='caption'>
            All
          </Typography>
        </MenuItem> */}
        {statusList.map((item, index) =>
          <MenuItem value={item.id} key={index}>
            <Typography variant='caption'>
              {item.label}
            </Typography>
          </MenuItem>
        )}
      </Select>
    )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)((SelectStatus)));
