import {
  AUTHENTICATED,
  AUTHENTICATION_ERROR,
  UNAUTHENTICATED,
  RECOVERY_SUCCESS,
  RECOVERY_ERROR,
  CHANGE_LANGUAGE,
  CHANGE_SIDE_LIST,
  GET_SELLER_SELECT,
  GET_SELLER_SELECT_ERROR,
  GET_EMPLOYEE,
  GET_EMPLOYEE_ERROR,
  SIGN_UP_EMPLOYEE,
  SIGN_UP_EMPLOYEE_ERROR,
  UPDATE_EMPLOYEE_PROFILE,
  UPDATE_EMPLOYEE_PROFILE_ERROR,
  DELETE_EMPLOYEE,
  DELETE_EMPLOYEE_ERROR,
  GET_CUSTOMERS,
  GET_CUSTOMERS_ERROR,
  GET_CUSTOMER,
  GET_CUSTOMER_ERROR,
  SIGN_UP_CUSTOMER,
  SIGN_UP_CUSTOMER_ERROR,
  UPDATE_CUSTOMER_PROFILE,
  UPDATE_CUSTOMER_PROFILE_ERROR,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_ERROR,
  SWITCH_LOADER,
  GET_BILLS,
  GET_BILLS_ERROR,
  ADD_BILL,
  ADD_BILL_ERROR,
  UPDATE_BILL,
  UPDATE_BILL_ERROR,
  DELETE_BILL,
  DELETE_BILL_ERROR,
  ADD_UTILITY_COMPANY,
  ADD_UTILITY_COMPANY_ERROR,
  UPDATE_UTILITY_COMPANY,
  UPDATE_UTILITY_COMPANY_ERROR,
  GET_COMPANY,
  GET_COMPANY_ERROR,
  GET_COMPANIES,
  GET_COMPANIES_ERROR,
  ADD_COMPANY,
  ADD_COMPANY_ERROR,
  UPDATE_COMPANY,
  UPDATE_COMPANY_ERROR,
  DELETE_UTILITY_COMPANY,
  DELETE_UTILITY_COMPANY_ERROR,
  GET_COMMISSIONS,
  GET_COMMISSIONS_ERROR,
  ADD_COMMISSION,
  ADD_COMMISSION_ERROR,
  UPDATE_COMMISSION,
  UPDATE_COMMISSION_ERROR,
  DELETE_COMMISSION,
  DELETE_COMMISSION_ERROR,
  ADD_BUSINESS_TYPE,
  ADD_BUSINESS_TYPE_ERROR,
  UPDATE_BUSINESS_TYPE,
  UPDATE_BUSINESS_TYPE_ERROR,
  DELETE_BUSINESS_TYPE,
  DELETE_BUSINESS_TYPE_ERROR,
  GET_VALVE,
  GET_VALVE_ERROR,
  GET_VALVES,
  GET_VALVES_ERROR,
  ADD_VALVE,
  ADD_VALVE_ERROR,
  UPDATE_VALVE,
  UPDATE_VALVE_ERROR,
  DELETE_VALVE,
  DELETE_VALVE_ERROR,
  GET_ACCESSORIES,
  GET_ACCESSORIES_ERROR,
  ADD_ACCESSORY,
  ADD_ACCESSORY_ERROR,
  UPDATE_ACCESSORY,
  UPDATE_ACCESSORY_ERROR,
  DELETE_ACCESSORY,
  DELETE_ACCESSORY_ERROR,
  GET_CITIES,
  GET_CITIES_ERROR,
  GET_BUSINESS_TYPES,
  GET_BUSINESS_TYPES_ERROR,
  GET_STATUS_LIST,
  GET_STATUS_LIST_ERROR,
  GET_STATUS,
  GET_STATUS_ERROR,
  GET_SERVICE_CLASSES,
  GET_SERVICE_CLASSES_ERROR,
  GET_UTILITY_COMPANIES,
  GET_UTILITY_COMPANIES_ERROR,
  GET_BOOKINGS,
  GET_BOOKINGS_ERROR,
  ADD_BOOKING,
  ADD_BOOKING_ERROR,
  UPDATE_BOOKING,
  UPDATE_BOOKING_ERROR,
  DELETE_BOOKING,
  DELETE_BOOKING_ERROR,
  GET_ANALYSIS_INFO,
  GET_ANALYSIS_INFO_ERROR,
  GET_REGISTERS,
  GET_REGISTERS_ERROR,
  ADD_REGISTER,
  ADD_REGISTER_ERROR,
  UPDATE_REGISTER,
  UPDATE_REGISTER_ERROR,
  DELETE_REGISTER,
  DELETE_REGISTER_ERROR,
  GET_VALVE_PARTS,
  GET_VALVE_PARTS_ERROR,
  ADD_VALVE_PART,
  ADD_VALVE_PART_ERROR,
  UPDATE_VALVE_PART,
  UPDATE_VALVE_PART_ERROR,
  DELETE_VALVE_PART,
  DELETE_VALVE_PART_ERROR,
  GET_INVOICES,
  GET_INVOICES_ERROR,
  ADD_INVOICE,
  ADD_INVOICE_ERROR,
  UPDATE_INVOICE,
  UPDATE_INVOICE_ERROR,
  DELETE_INVOICE,
  DELETE_INVOICE_ERROR,
  UPDATE_INSTALLATION_REPORT_DETAIL,
  UPDATE_INSTALLATION_REPORT_DETAIL_ERROR,
  DELETE_INSTALLATION_REPORT_DETAIL,
  DELETE_INSTALLATION_REPORT_DETAIL_ERROR,
  GET_INSTALLATION_SUMMARY,
  GET_INSTALLATION_SUMMARY_ERROR,
  GENERATE_PDF,
  GENERATE_PDF_ERROR,
  CHANGE_CUSTOMERS_STATE
} from '../constants/action_types';

import {
  url_signIn,
  url_customers,
  url_bills,
  url_companies,
  url_utilityCompanies,
  url_commissions,
  url_businessTypes,
  url_valves,
  url_accessories,
  url_cities,
  url_employees,
  url_serviceClasses,
  url_bookings,
  url_analysis,
  url_registers,
  url_valve_parts,
  url_status,
  url_invoices,
  url_installation_location,
  url_installation_summary
} from '../constants/links';

import axios from 'axios';
import { SubmissionError } from 'redux-form';

export function signIn({ email, password }, history) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_signIn, { username: email, password })
      .then(function (response) {
        //console.log(response.data);
        dispatch({ type: AUTHENTICATED, payload: response.data });
        localStorage.setItem('user', response.data.token);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        let url;
        if (response.data.is_superuser) {
          url = `${url_companies}`
        } else {
          const dominio = response.data.dominio ? response.data.dominio : 3
          url = `${url_companies}/${dominio}`
        };
        return axios.get(url,//Guardo la compañia a la que pertenece el usuario conectado
          {
            headers: { Authorization: `token ${response.data.token}` }
          })
      })
      .then(function (response) {
        //console.log(response)
        dispatch({
          type: GET_COMPANY,
          payload: response.data
        })
      })
      .catch(function (error) {
        dispatch({
          type: AUTHENTICATION_ERROR,
          payload: true
        });
        console.log(error);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
  }
}

export function signOut() {
  localStorage.clear();
  return {
    type: UNAUTHENTICATED
  };
}

export function recoverMail({ email }) {
  if (email.length > 1) {
    return {
      type: RECOVERY_SUCCESS
    }
  } else {
    return {
      type: RECOVERY_ERROR,
      payload: true
    }
  }
}

export function changeLanguage(lang) {
  return {
    type: CHANGE_LANGUAGE,
    payload: lang
  }
}

export function changeCustomersState() {
  return {
    type: CHANGE_CUSTOMERS_STATE
  }
}

export function changeSideList(page, info, link) {
  return {
    type: CHANGE_SIDE_LIST,
    page,
    info,
    link
  }
}

export function getEmployee(token, id) {
  return async (dispatch) => {
    await axios.get(`${url_employees}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_EMPLOYEE, payload: response.data });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_EMPLOYEE_ERROR,
          payload: true
        });
      })
  }
}

export function signUpEmployee(data, token, history) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_employees, data,
      {
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: SIGN_UP_EMPLOYEE });
        history.goBack();
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
        dispatch({
          type: SIGN_UP_EMPLOYEE_ERROR,
          payload: true
        });
      })
  }
}

export function updateEmployeeProfile(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_employees}/${id}`, data,
      {
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_EMPLOYEE_PROFILE });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        dispatch({
          type: UPDATE_EMPLOYEE_PROFILE_ERROR,
          payload: true
        });
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function deleteEmployee(token, id, history) {
  return async (dispatch) => {
    await axios.delete(`${url_employees}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_EMPLOYEE });
        history.push('/sellers');
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_EMPLOYEE_ERROR,
          payload: true
        });
      })
  }
}

export function getCustomers(token, company) {
  return async (dispatch) => {
    await axios.get(url_customers,
      {
        headers: { Authorization: token },
        params: { page_size: 100, company: company }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_CUSTOMERS, payload: response.data.results });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_CUSTOMERS_ERROR,
          payload: true
        });
      })
  }
}

export function getCustomer(token, id) {
  return async (dispatch) => {
    await axios.get(`${url_customers}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_CUSTOMER, payload: response.data });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_CUSTOMER_ERROR,
          payload: true
        });
      })
  }
}

export function signUpCustomer(data, token, history) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_customers, data,
      {
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        console.log(response);
        dispatch({ type: SIGN_UP_CUSTOMER });
        history.push(`/customers/${response.data.id}`)
        //history.goBack();
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response);
        /*if (!error.response) {
          dispatch(destroy('signupCustomer'));
        };*/
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        dispatch({
          type: SIGN_UP_CUSTOMER_ERROR,
          payload: error.response.data
        });
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function updateCustomerProfile(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_customers}/${id}`, data,
      {
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        console.log(response);
        dispatch({ type: UPDATE_CUSTOMER_PROFILE });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        dispatch({
          type: UPDATE_CUSTOMER_PROFILE_ERROR,
          payload: true
        });
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function updateCustomerStatus(data, token, id) {
  return async (dispatch) => {
    //dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_customers}/${id}`, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_CUSTOMER_PROFILE });
        //dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        //console.log(error.response.data);
        //dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        dispatch({
          type: UPDATE_CUSTOMER_PROFILE_ERROR,
          payload: true
        });
      })
  }
}

export function deleteCustomer(token, id, history) {
  return async (dispatch) => {
    await axios.delete(`${url_customers}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_CUSTOMER });
        history.push('/customers');
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_CUSTOMER_ERROR,
          payload: true
        });
      })
  }
}

export function getBills(token, customer) {
  return async (dispatch) => {
    await axios.get(url_bills,
      {
        headers: { Authorization: token },
        params: { customer: customer }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_BILLS, payload: response.data.results });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_BILLS_ERROR,
          payload: true
        });
      })
  }
};

export function addBill(data, token) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_bills, data,
      {
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: ADD_BILL });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
        dispatch({
          type: ADD_BILL_ERROR,
          payload: true
        });
      })
  }
}

export function updateBill(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_bills}/${id}`, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_BILL });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
        dispatch({
          type: UPDATE_BILL_ERROR,
          payload: true
        });
      })
  }
}

export function deleteBill(token, id) {
  return async (dispatch) => {
    await axios.delete(`${url_bills}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_BILL });
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_BILL_ERROR,
          payload: true
        });
      })
  }
}

export function getCompany(token, id) {
  return async (dispatch) => {
    await axios.get(`${url_companies}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_COMPANY, payload: response.data });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_COMPANY_ERROR,
          payload: true
        });
      })
  }
}

export function getCompanies(token) {
  return async (dispatch) => {
    await axios.get(url_companies,
      {
        headers: { Authorization: token },
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_COMPANIES, payload: response.data.results });
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({
          type: GET_COMPANIES_ERROR,
          payload: true
        });
      })
  }
}

export function addCompany(data, token, history) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_companies, data,
      {
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: ADD_COMPANY });
        history.goBack();
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
        dispatch({
          type: ADD_COMPANY_ERROR,
          payload: true
        });
      })
  }
}

export function updateCompany(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_companies}/${id}`, data,
      {
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_COMPANY });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
        dispatch({
          type: UPDATE_COMPANY_ERROR,
          payload: true
        });
      })
  }
}

export function getUtilityCompanies(token) {
  return async (dispatch) => {
    await axios.get(url_utilityCompanies,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_UTILITY_COMPANIES, payload: response.data.results });
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({
          type: GET_UTILITY_COMPANIES_ERROR,
          payload: true
        });
      })
  }
}

export function addUtilityCompany(data, token) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_utilityCompanies, data,
      {
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: ADD_UTILITY_COMPANY });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
        dispatch({
          type: ADD_UTILITY_COMPANY_ERROR,
          payload: true
        });
      })
  }
}

export function updateUtilityCompany(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_utilityCompanies}/${id}`, data,
      {
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_UTILITY_COMPANY });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
        dispatch({
          type: UPDATE_UTILITY_COMPANY_ERROR,
          payload: true
        });
      })
  }
}

//Update delete utilityCompanies to call getUtilityCompanies when success
export function deleteUtilityCompany(token, id) {
  return async (dispatch) => {
    await axios.delete(`${url_utilityCompanies}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_UTILITY_COMPANY });
        return axios.get(url_utilityCompanies,
          {
            headers: { Authorization: token }
          })
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_UTILITY_COMPANIES, payload: response.data.results });
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_UTILITY_COMPANY_ERROR,
          payload: true
        });
      })
  }
}

export function getCommissions(token, company) {
  return async (dispatch) => {
    await axios.get(url_commissions,
      {
        headers: { Authorization: token },
        params: { company: company }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_COMMISSIONS, payload: response.data.results });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_COMMISSIONS_ERROR,
          payload: true
        });
      })
  }
}

export function addCommission(data, token) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_commissions, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: ADD_COMMISSION });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: ADD_COMMISSION_ERROR,
          payload: true
        });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function updateCommission(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_commissions}/${id}`, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_COMMISSION });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        dispatch({
          type: UPDATE_COMMISSION_ERROR,
          payload: true
        });
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function deleteCommission(token, id) {
  return async (dispatch) => {
    await axios.delete(`${url_commissions}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_COMMISSION });
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_COMMISSION_ERROR,
          payload: true
        });
      })
  }
}

export function getBusinessTypes(token, company) {
  return async (dispatch) => {
    await axios.get(url_businessTypes,
      {
        headers: { Authorization: token },
        params: { company: company }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_BUSINESS_TYPES, payload: response.data.results });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_BUSINESS_TYPES_ERROR,
          payload: true
        });
      })
  }
}

export function addBusinessType(data, token) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_businessTypes, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: ADD_BUSINESS_TYPE });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: ADD_BUSINESS_TYPE_ERROR,
          payload: true
        });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function updateBusinessType(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_businessTypes}/${id}`, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_BUSINESS_TYPE });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        dispatch({
          type: UPDATE_BUSINESS_TYPE_ERROR,
          payload: true
        });
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function deleteBusinessType(token, id) {
  return async (dispatch) => {
    await axios.delete(`${url_businessTypes}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_BUSINESS_TYPE });
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_BUSINESS_TYPE_ERROR,
          payload: true
        });
      })
  }
}

export function getValve(token, company, id) {
  return async (dispatch) => {
    await axios.get(`${url_valves}/${id}`,
      {
        headers: { Authorization: token },
        params: { company: company }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_VALVE, payload: response.data });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_VALVE_ERROR,
          payload: true
        });
      })
  }
}

export function getValves(token, company) {
  return async (dispatch) => {
    await axios.get(url_valves,
      {
        headers: { Authorization: token },
        params: { company: company }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_VALVES, payload: response.data.results });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_VALVES_ERROR,
          payload: true
        });
      })
  }
}

export function addValve(data, token) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_valves, data,
      {
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: ADD_VALVE });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: ADD_VALVE_ERROR,
          payload: true
        });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function updateValve(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_valves}/${id}`, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_VALVE });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        dispatch({
          type: UPDATE_VALVE_ERROR,
          payload: true
        });
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function deleteValve(token, id) {
  return async (dispatch) => {
    await axios.delete(`${url_valves}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_VALVE });
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_VALVE_ERROR,
          payload: true
        });
      })
  }
}

export function getAccessories(token, company) {
  return async (dispatch) => {
    await axios.get(url_accessories,
      {
        headers: { Authorization: token },
        params: { company: company }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_ACCESSORIES, payload: response.data.results });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_ACCESSORIES_ERROR,
          payload: true
        });
      })
  }
}

export function addAccessory(data, token) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_accessories, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: ADD_ACCESSORY });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: ADD_ACCESSORY_ERROR,
          payload: true
        });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function updateAccessory(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_accessories}/${id}`, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_ACCESSORY });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        dispatch({
          type: UPDATE_ACCESSORY_ERROR,
          payload: true
        });
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function deleteAccessory(token, id) {
  return async (dispatch) => {
    await axios.delete(`${url_accessories}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_ACCESSORY });
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_ACCESSORY_ERROR,
          payload: true
        });
      })
  }
}

export function getBookings(token, customer, company) {
  return async (dispatch) => {
    const params = {
      customer: customer ? customer : '',
      company: company ? company : ''
    }
    await axios.get(url_bookings,
      {
        headers: { Authorization: token },
        params: params
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_BOOKINGS, payload: response.data.results });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_BOOKINGS_ERROR,
          payload: true
        });
      })
  };
};

export function addBooking(data, token) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_bookings, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: ADD_BOOKING });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
        dispatch({
          type: ADD_BOOKING_ERROR,
          payload: true
        });
      })
  }
}

export function updateBooking(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_bookings}/${id}`, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_BOOKING });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
        dispatch({
          type: UPDATE_BOOKING_ERROR,
          payload: true
        });
      })
  }
}

export function deleteBooking(token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.delete(`${url_bookings}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_BOOKING });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_BOOKING_ERROR,
          payload: true
        });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
  }
}

export function getRegisters(token, customer) {
  return async (dispatch) => {
    await axios.get(url_registers,
      {
        headers: { Authorization: token },
        params: { customer: customer }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_REGISTERS, payload: response.data.results });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_REGISTERS_ERROR,
          payload: true
        });
      })
  }
};

export function addRegister(data, token) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_registers, data,
      {
        headers: { Authorization: token, /*'Content-Type': 'multipart/form-data'*/ }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: ADD_REGISTER });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
        dispatch({
          type: ADD_REGISTER_ERROR,
          payload: true
        });
      })
  }
}

export function updateRegister(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_registers}/${id}`, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_REGISTER });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
        dispatch({
          type: UPDATE_REGISTER_ERROR,
          payload: true
        });
      })
  }
}

export function deleteRegister(token, id) {
  return async (dispatch) => {
    await axios.delete(`${url_registers}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_REGISTER });
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_REGISTER_ERROR,
          payload: true
        });
      })
  }
}

export function getStatusList(token) {
  return async (dispatch) => {
    await axios.get(url_status,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_STATUS_LIST, payload: response.data.results });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_STATUS_LIST_ERROR,
          payload: true
        });
      })
  }
}

export function getStatus(token, id) {
  return async (dispatch) => {
    await axios.get(`${url_status}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_STATUS, payload: response.data });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_STATUS_ERROR,
          payload: true
        });
      })
  }
}

//SECCION NO SEGURA DE IMPLEMENTARSE
export function getValveParts(token, company) {
  return async (dispatch) => {
    await axios.get(url_valve_parts,
      {
        headers: { Authorization: token },
        params: { company: company }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_VALVE_PARTS, payload: response.data.results });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_VALVE_PARTS_ERROR,
          payload: true
        });
      })
  }
}

export function addValvePart(data, token) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_valve_parts, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: ADD_VALVE_PART });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: ADD_VALVE_PART_ERROR,
          payload: true
        });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function updateValvePart(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_valve_parts}/${id}`, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_VALVE_PART });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        dispatch({
          type: UPDATE_VALVE_PART_ERROR,
          payload: true
        });
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function deleteValvePart(token, id) {
  return async (dispatch) => {
    await axios.delete(`${url_valve_parts}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_VALVE_PART });
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_VALVE_PART_ERROR,
          payload: true
        });
      })
  }
}

export function getInvoices(token, customer) {
  return async (dispatch) => {
    await axios.get(url_invoices,
      {
        headers: { Authorization: token },
        params: { customer: customer }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_INVOICES, payload: response.data.results });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_INVOICES_ERROR,
          payload: true
        });
      })
  }
}

export function addInvoice(data, token) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.post(url_invoices, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: ADD_INVOICE });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: ADD_INVOICE_ERROR,
          payload: true
        });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function updateInvoice(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_invoices}/${id}`, data,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_INVOICE });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        dispatch({
          type: UPDATE_INVOICE_ERROR,
          payload: true
        });
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function deleteInvoice(token, id) {
  return async (dispatch) => {
    await axios.delete(`${url_invoices}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_INVOICE });
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_INVOICE_ERROR,
          payload: true
        });
      })
  }
}

export function updateRegisterDetail(data, token, id) {
  return async (dispatch) => {
    dispatch({ type: SWITCH_LOADER, payload: true });
    await axios.put(`${url_installation_location}/${id}`, data,
      {
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: UPDATE_INSTALLATION_REPORT_DETAIL });
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({ type: SWITCH_LOADER, payload: false }); //turn off loader
        dispatch({
          type: UPDATE_INSTALLATION_REPORT_DETAIL_ERROR,
          payload: true
        });
        if (error.response.status === 400) {
          throw new SubmissionError({ ...error.response.data })
        }
      })
  }
}

export function deleteRegisterDetail(token, id, history) {
  return async (dispatch) => {
    await axios.delete(`${url_installation_location}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: DELETE_INSTALLATION_REPORT_DETAIL });
      })
      .catch(function (error) {
        console.log(error.response.data);
        dispatch({
          type: DELETE_INSTALLATION_REPORT_DETAIL_ERROR,
          payload: true
        });
      })
  }
}

export function getInstallationSummary(token, customer) {
  return async (dispatch) => {
    await axios.get(url_installation_summary,
      {
        headers: { Authorization: token },
        params: { customer: customer }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_INSTALLATION_SUMMARY, payload: response.data.results });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GET_INSTALLATION_SUMMARY_ERROR,
          payload: true
        });
      })
  }
}

export function generatePdf(url, token, filename) {
  return async (dispatch) => {
    await axios.get(url,
      {
        headers: { Authorization: token }
      })
      .then(async function (response) {
        //console.log(response);
        //window.open("data:application/IMG," + escape(response.data));
        const aTag = document.createElement('a');
        aTag.href = 'data:application/pdf;charset=utf-8,' + encodeURIComponent(response.data);
        aTag.download = filename;
        aTag.click();
        dispatch({
          type: GENERATE_PDF
        });
      })
      .catch(function (error) {
        //console.log(error.response);
        dispatch({
          type: GENERATE_PDF_ERROR,
          payload: true
        });
      })
  }
}

//
/* Select options */
export function getCities(token, company) {
  return async (dispatch) => {
    await axios.get(url_cities,
      {
        headers: { Authorization: token },
        params: { company: company }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_CITIES, payload: response.data.results });
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({
          type: GET_CITIES_ERROR,
          payload: true
        });
      })
  }
}

/*
export function getStatus(token, company) {
  return async (dispatch) => {
    await axios.get('https://api.elasustentable.com/rest/general/status',
      {
        headers: { Authorization: token },
        params: { company: company }
      })
      .then(function (response) {
        console.log(response);
        dispatch({ type: GET_STATUS, payload: response.data.results });
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({
          type: GET_STATUS_ERROR,
          payload: true
        });
      })
  }
}*/

export function getEmployees(token, company) {
  return async (dispatch) => {
    await axios.get(url_employees,
      {
        headers: { Authorization: token },
        params: { company: company }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_SELLER_SELECT, payload: response.data.results });
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({
          type: GET_SELLER_SELECT_ERROR,
          payload: true
        });
      })
  }
}

export function getServiceClasses(token, company) {
  return async (dispatch) => {
    await axios.get(url_serviceClasses,
      {
        headers: { Authorization: token },
        params: { company: company }
      })
      .then(function (response) {
        //console.log(response);
        dispatch({ type: GET_SERVICE_CLASSES, payload: response.data.results });
      })
      .catch(function (error) {
        console.log(error.response);
        dispatch({
          type: GET_SERVICE_CLASSES_ERROR,
          payload: true
        });
      })
  }
}

export function getAnalysisInfo(token, customer) {
  return async (dispatch) => {
    await axios.get(url_analysis, {
      headers: { Authorization: token },
      params: { customer: customer }
    })
      .then(function (response) {
        dispatch({ type: GET_ANALYSIS_INFO, payload: response.data.results })
      })
      .catch(function (error) {
        console.log(error.response)
        dispatch({
          type: GET_ANALYSIS_INFO_ERROR,
          payload: true
        })
      })
  }
}
