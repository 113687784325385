import React from 'react';
import { Route, Switch } from "react-router-dom";
import DashboardPage from '../components/dashboard';
import Routes from './routes';
import ProtectedRoute from './protectedRoute';

function IndexRoutes(props) {
  return (
    <Switch>
      <ProtectedRoute exact path='/' component={DashboardPage} />
      {Routes.map((prop, key) => {
        let { path, component } = prop;
        let route;

        path === '/login' || path === '/recovery' ?
          route = <Route path={path} component={component} key={key} />
          :
          path === '' ?
            route = <Route component={component} key={key} />
            :
            route = <ProtectedRoute path={path} component={component} key={key} />

        return route
      })}
    </Switch>
  )
};

export default IndexRoutes;
