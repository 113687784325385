import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { updateValve,deleteValve } from '../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import renderSelectFieldTest from '../../ui/select_field_test';
import renderCheckboxField from '../../ui/checkbox_field';
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ModalDialog from '../../modal';

const styles = theme => ({
  icon: {
    color: theme.palette.newGrey.main
  },
  banner: {
    paddingBottom: 8,
    paddingTop: 8
  },
  title: {
    fontSize: 25
  },
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    padding: '0px 24px 15px',
  },
  expansionTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    color: '#1562c6',
    cursor: 'pointer'
  },
  button: {
    margin: '0 5px'
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type
  }
};

function mapDispatchToProps(dispatch) {
  return {
    updateValve: (values, token, id) => dispatch(updateValve(values, token, id)),
    deleteValve: (token, id) => dispatch(deleteValve(token, id))
  }
};

class ValveEdit extends React.Component {
  state = {
    open: false,
    valveParts: {
      springs: [
        {
          label: "Spring 1",
          value: "spring 1"
        },
        {
          label: "Spring 2",
          value: "spring 2"
        }
      ],
      pistons: [
        {
          label: "Piston 1",
          value: "piston 1"
        },
        {
          label: "Piston 2",
          value: "piston 2"
        }
      ]
    }
  };

  async submit(values) {
    let val = values;

    if (val.composition_valve === typeof '') {
      const arrayCompositionValve = values.composition_valve.split(',');
      const compositionValve = arrayCompositionValve.map(item => parseInt(item, 10));
      val = {
        ...values,
        composition_valve: compositionValve
      };
    };

    /* Evitar valores nulos*/
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    console.log(val);
    /*---------------------*/

    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    //const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;

    await this.validateErrorsApi(val, token, id);
    this.props.edit();
    this.props.fetchCard();
  };

  validateErrorsApi(val, token, id) {
    return this.props.updateValve(val, token, id);
  };

  handleOpen(open) {
    this.setState({
      open: open
    })
  };

  async deleteData() {
    this.handleOpen(false)
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;
    await this.props.deleteValve(token, id);
    this.props.fetchCard();
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading, userType, role, piston, spring } = this.props;
    const { open, valveParts } = this.state;
    return (
      <Fragment>
        <ModalDialog
          open={open}
          title="Are you sure you want to delete this valve ?"
          description="This action directly affects the database and is irreversible."
          actionName="Delete"
          handleOpen={this.handleOpen.bind(this)}
          handleAction={this.deleteData.bind(this)}
        />
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} /*encType="multipart/form-data" id="myForm"*/>
          <div item xs={12} className={classes.banner}>
            <List component="ul">
              <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                <ListItemIcon>
                  <AssignmentIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography component="h2" variant="h2" className={classes.title}> Add Valve </Typography>}
                />
              </ListItem>
            </List>
          </div>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Field name="body" component={renderCheckboxField} label="Body" ref="body" disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="spring"
                component={renderSelectFieldTest}
                options={valveParts.springs}
                label='Springs'
                ref="spring"
                is={spring}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="piston"
                component={renderSelectFieldTest}
                options={valveParts.pistons}
                label='Pistons'
                ref="piston"
                is={piston}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="description"
                component={renderTextField}
                label="Description"
                isTextArea={true}
                ref="description"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="advices"
                component={renderTextField}
                label="Advices"
                isTextArea={true}
                ref="advices"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Typography variant='button' noWrap className={classes.link} onClick={this.props.edit}>
              Return to Valve
            </Typography>
            <div>
              {role.delete ?
                <Button className={classes.button} color='secondary' variant="contained" onClick={this.handleOpen.bind(this, true)} disabled={loading}>
                  <Typography variant='button' noWrap>
                    Delete
              </Typography>
                </Button>
                :
                null
              }
              <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
                <Typography variant='button' noWrap>
                  Update
                </Typography>
              </Button>
            </div>
          </div>
        </form>
      </Fragment>
    )
  }
};

const reduxFormUpdateValve = reduxForm({
  form: 'updatevalve',
})(ValveEdit);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateValve));
