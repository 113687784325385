import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  updateUtilityCompany,
  deleteUtilityCompany,
  getCities
} from '../../../../actions';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AssignmentIcon from '@material-ui/icons/Assignment';
import renderTextField from '../../../ui/text_field';
import renderSelectField from '../../../ui/select_field';
import renderFileInput from '../../../ui/upload_field';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import ModalDialog from '../../../modal';

const styles = theme => ({
  paper: {
    width: '100%',
    //padding: '0px 24px 15px',
    //height: '80vh',
    //display: 'flex',
    //alignItems: 'center'
  },
  form: {
    padding: '5px 24px 15px',
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.primary.main
  },
  button: {
    margin: '0 5px'
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.utilityCompany.error,
    role: state.auth.user_type,
    token: state.auth.token,
    loading: state.loader.loading,
    cities: state.city.cities,
    dominio: state.auth.dominio
  }
};

function mapDispatchToProps(dispatch) {
  return {
    updateUtilityCompany: (values, token, id) => dispatch(updateUtilityCompany(values, token, id)),
    deleteUtilityCompany: (token, id) => dispatch(deleteUtilityCompany(token, id)),
    getCities: (token, company) => dispatch(getCities(token, company))
  }
};

class ProfileForm extends React.Component {
  state = {
    cities: [],
    error: '',
    open: false
  };

  componentDidMount() {
    this.fetchGet();
  };

  async deleteData() {
    this.handleOpen(false);
    const token = 'token '.concat(this.props.token);
    const id = this.props.match.params.id;
    await this.props.deleteUtilityCompany(token, id);
    this.props.history.goBack();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getCities(token);

    const cities = [];
    this.props.cities.forEach(city =>
      cities.push({
        label: this.capitalize(city.name),
        value: city.url
      })
    );

    this.setState({
      cities: cities
    })
  };

  async submit(values) {
    const val = values;

    if (val.city) {
      val.city = values.city.value
    };

    if (typeof val.logo !== 'object') {
      delete val.logo
    }

    if (val.charges) {
      val.charges = '{ "a": "a" }'
    };

    /* Evitar valores nulos */
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    /*---------------------*/
    const id = this.props.match.params.id;
    const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);

    await this.validateErrorsApi(data, token, id);
    this.props.showCard();
    this.props.fetchCard();
  };

  validateErrorsApi(data, token, id) {
    return this.props.updateUtilityCompany(data, token, id);
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleOpen(open) {
    this.setState({
      open: open
    })
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        //console.log(key)
      }

      return form_data;
    }
  };

  render() {
    const { intl: { formatMessage }, messages, pristine, submitting, classes, handleSubmit, loading, city, role } = this.props;
    const { open, cities } = this.state;
    return (
      <div className={classes.paper}>
        <ModalDialog
          open={open}
          title="Are you sure you want to delete this Utility Company ?"
          description="This action directly affects the database and is irreversible."
          actionName="Delete"
          handleOpen={this.handleOpen.bind(this)}
          handleAction={this.deleteData.bind(this)}
        />
        {/*loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>*/}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} encType="multipart/form-data">
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <Field
                name="name"
                component={renderTextField}
                label={formatMessage(messages.customers.formName)}
                ref="name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="email"
                component={renderTextField}
                label={formatMessage(messages.general.email)}
                ref="email"
                type='email'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="website"
                component={renderTextField}
                label="Website"
                ref="website"
                type="url"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="address"
                component={renderTextField}
                label={formatMessage(messages.customers.formAddress)}
                ref="address"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="identification_type"
                component={renderTextField}
                label="Identification Type"
                ref="identification_type"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="identification"
                component={renderTextField}
                label={formatMessage(messages.customers.formIdentification)}
                ref="identification"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="phone"
                component={renderTextField}
                label={formatMessage(messages.general.phone)}
                ref="phone"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="city"
                component={renderSelectField}
                options={cities}
                clearable={true}
                label={formatMessage(messages.customers.formCity)}
                ref="city"
                is={city}
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name="logo"
                component={renderFileInput}
                ref="logo"
                label="Logo"
                inputid="logo"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            {role.delete ?
              <Button className={classes.button} color='secondary' variant="contained" onClick={this.handleOpen.bind(this, true)} disabled={loading}>
                <Typography variant='button' noWrap>
                  Delete
              </Typography>
              </Button>
              :
              null
            }
            <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                Update
              </Typography>
            </Button>
          </div>
        </form>
      </div>
    );
  }
};

const reduxFormUpdateUtilityCompany = reduxForm({
  form: 'updateutilitycompany',
})(ProfileForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateUtilityCompany));
