import React, { Fragment } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { url_utilityCompanies } from '../../../../constants/links';
import ProfileForm from './profileForm';
import InfoCard from './info';
import PricesCard from './prices';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';

import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import MapCard from '../../../mapcard';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import MapIcon from '@material-ui/icons/Map';

const styles = theme => ({
  paper: {
    marginBottom: 20
  },
  banner: {
    backgroundColor: 'white',
    paddingBottom: 8,
    paddingTop: 8
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  title: {
    fontSize: 25
  },
  profileArea: {
    padding: '15px 15px 76px 15px',
    maxWidth: 1100,
    margin: '0 auto'
  },
  grid: {
    padding: 15
  },
  profileGridItem: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  bold: {
    fontWeight: 500
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bigAvatar: {
    width: 120,
    height: 120,
    [theme.breakpoints.up('sm')]: {
      width: 170,
      height: 170,
      margin: '10px 10px 10px 0'
    }
  },
  customerTitle: {
    fontSize: 30,
    padding: '10px 0 5px',
    [theme.breakpoints.up('sm')]: {
      //fontSize: 40
    }
  },
  gridItem: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'start'
    }
  },

  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 5,
    [theme.breakpoints.up('sm')]: {
      //display: 'none'
    }
  },
  iconButton: {
    width: 48,
    height: 48
  },
  buttonsLinks: {
    color: 'inherit'
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    loading: state.loader.loading
  }
};

class ProfileUtilityCompany extends React.Component {
  state = {
    utilityCompany: {},
    location: {},
    show: false
  };

  componentDidMount() {
    this.fetchGet();
  };

  fetchGet() {
    const id = this.props.match.params.id;
    const token = 'token '.concat(this.props.token);
    axios.get(`${url_utilityCompanies}/${id}`,
      {
        headers: { Authorization: token }
      })
      .then(response => {
        //console.log(response.data);
        const data = response.data;
        this.setState({
          utilityCompany: data
        })
        return axios.get(this.state.utilityCompany.city,
          {
            headers: { Authorization: token }
          })
      })
      .then(response => {
        //console.log(response.data);
        this.setState({
          location: {
            state: this.capitalize(response.data.state),
            name: this.capitalize(response.data.name)
          }
        })
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  formatValues(utilityCompany) {
    const values = {
      ...utilityCompany
    }
    return values;
  };

  handleShow() {
    this.setState({
      show: !this.state.show
    });
  };

  render() {
    const { intl: { formatMessage }, intl, classes, messages, role, match, loading } = this.props;
    const { location, show, utilityCompany } = this.state;
    return (
      <Fragment>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <Paper className={classes.paper}>
          <div className={classes.banner}>
            <List component="ul">
              <ListItem disableGutters style={{ padding: '19px 24px', }}>
                <ListItemIcon>
                  {show ?
                    <AssignmentIcon className={classes.icon} />
                    :
                    <PersonIcon className={classes.icon} />
                  }
                </ListItemIcon>
                <ListItemText
                  primary={
                    show ?
                      <Typography component="h2" variant="h2" className={classes.title}>
                        Update Profile
                      </Typography>
                      :
                      <Typography component="h2" variant="h2" className={classes.title}>
                        Profile
                      </Typography>
                  } />
                <ListItemSecondaryAction style={{ marginRight: 10 }}>
                  <IconButton aria-label="add" onClick={this.handleShow.bind(this)}>
                    {show ?
                      <ArrowLeftIcon />
                      :
                      role.update ?
                        <EditIcon />
                        :
                        null
                    }
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>
          <Fragment>
            {show ?
              <ProfileForm
                intl={intl}
                messages={messages}
                match={match}
                history={this.props.history}
                showCard={this.handleShow.bind(this)}
                fetchCard={this.fetchGet.bind(this)}
                city={location.name}
                initialValues={this.formatValues(utilityCompany)}
              />
              :
              <Grid
                container
                spacing={16}
                direction='row'
                justify='space-between'
                alignItems='center'
                className={classes.grid}
              >
                <Grid item xs={12} sm={8} className={classes.profileGridItem}>
                  <div className={classes.avatar}>
                    <Avatar alt="avatar" src={utilityCompany.logo} className={classes.bigAvatar} />
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Typography component="h2" variant="h2" className={classes.customerTitle}>
                      {utilityCompany.name}
                    </Typography>
                    <Typography variant="body1" >
                      #Nit: {utilityCompany.identification}
                    </Typography>
                    <Typography variant="body1">
                      {utilityCompany.address}
                    </Typography>
                    <Typography variant="body1">
                      {location.name}, {location.state}
                    </Typography>
                    <div className={classes.buttons}>
                      <IconButton classes={{ root: classes.iconButton }}>
                        <a href={`mailto: ${utilityCompany.email}`} className={classes.buttonsLinks}>
                          <EmailIcon />
                        </a>
                      </IconButton>
                      <IconButton classes={{ root: classes.iconButton }}>
                        <a href={`tel: ${utilityCompany.phone}`} className={classes.buttonsLinks}>
                          <PhoneIcon />
                        </a>
                      </IconButton>
                      <IconButton classes={{ root: classes.iconButton }}>
                        <a target="_blank" rel="noopener noreferrer" href={`https://maps.google.com/?q=${location.name}`} className={classes.buttonsLinks}>
                          <MapIcon />
                        </a>
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              </Grid>
            }
          </Fragment>
        </Paper>
        <Paper className={classes.paper} style={{ padding: 15 }}>
          <InfoCard intl={intl} messages={messages} />
        </Paper>
        <Paper className={classes.paper} style={{ padding: 15 }}>
          <PricesCard intl={intl} messages={messages} />
        </Paper>
      </Fragment>
    )
  }
};

export default connect(mapStateToProps)(withStyles(styles)(ProfileUtilityCompany));
