import React from 'react';
import { connect } from 'react-redux';

import { getCustomers, getAnalysisInfo } from '../../../../actions';

import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';



function mapStateToProps(state) {
  return {
    token: state.auth.token,
    customer: state.customer.customer,
    analysisInfo: state.analysis.data,
    dominio: state.auth.dominio
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getCustomers: (token, company) => dispatch(getCustomers(token, company)),
    getAnalysisInfo: (token, customer) => dispatch(getAnalysisInfo(token, customer))
  }
};


class AnalysisChart extends React.Component {

  state = {
    analysisInfo: []
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const id = this.props.customer.id;
    const token = 'token '.concat(this.props.token);
    await this.props.getAnalysisInfo(token, id)
    this.setState({
      analysisInfo: this.props.analysisInfo
    });
  };

  render() {
    const { analysisInfo } = this.state;
    const details = analysisInfo[analysisInfo.length - 1];

    if (analysisInfo.length > 0) {
      const data = [
        {
          name: 'January', uv: details.ela_january, pv: details.regular_january,
        },
        {
          name: 'February', uv: details.ela_february, pv: details.regular_february
        },
        {
          name: 'March', uv: details.ela_march, pv: details.regular_march
        },
        {
          name: 'April', uv: details.ela_april, pv: details.regular_april
        },
        {
          name: 'May', uv: details.ela_may, pv: details.regular_may
        },
        {
          name: 'June', uv: details.ela_june, pv: details.regular_june
        },
        {
          name: 'July', uv: details.ela_july, pv: details.regular_july
        },
        {
          name: 'August', uv: details.ela_august, pv: details.regular_august
        },
        {
          name: 'September', uv: details.ela_september, pv: details.regular_september
        },
        {
          name: 'October', uv: details.ela_october, pv: details.regular_october
        },
        {
          name: 'November', uv: details.ela_november, pv: details.regular_november
        },
        {
          name: 'December', uv: details.ela_december, pv: details.regular_december
        },
      ];
      return (
        <ResponsiveContainer width="99%" height={250}>
          <AreaChart width={600} height={250} data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor='#3e8dc9' stopOpacity={0.8} />
                <stop offset="95%" stopColor='#3e8dc9' stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#19d800" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#19d800" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" tick={{ fontSize: 12 }} />
            <YAxis tick={{ fontSize: 12 }} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area type="monotone" dataKey="uv" stroke='#3e8dc9' strokeWidth={3} fillOpacity={1} fill="url(#colorUv)" />
            <Area type="monotone" dataKey="pv" stroke="#19d800" strokeWidth={3} fillOpacity={0.5} fill="url(#colorPv)" />
          </AreaChart>
        </ResponsiveContainer>
      )
    } else {
      return (<p>Loading data ...</p>)
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisChart);
