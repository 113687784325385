import React from 'react';
import Dropzone from 'react-dropzone';
import { Document, Page } from 'react-pdf';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  dropZone: {
    width: '50%',
    height: 200,
    border: `0.5px dashed ${theme.palette.newGrey.main}`,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto'
  },
  pdf: {
    marginRight: 12,
    borderRadius: 10,
    width: 65,
    height: 65,
    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 80,
    }
  },
});

class uploadDropZone extends React.Component {
  /*state = {
    files: [],
    preview: []
  };

  /*componentWillReceiveProps(nextProps) {
    this.setState({
      files: this.state.files.concat(nextProps.input.value)
    });
    this.previewFile(nextProps.input.value[0]);
  };*/

  /*handleDrop(file) {
    this.setState({
      files: this.state.files.concat(file)
    });

    if (file.length > 1) {
      this.state.files.forEach(item => this.previewFile(item))
    } else {
      this.previewFile(file[0]);
    }
    return this.state.files
  };

  previewFile(file) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      //console.log(reader.result);
      this.setState({
        preview: this.state.preview.concat(reader.result)
      });
    })
    reader.readAsDataURL(file);
    //return this.state.preview
  };

  handleDelete(index) {
    this.setState({
      files: this.state.files.filter((item, i) => i !== index),
      preview: this.state.preview.filter((item, i) => i !== index)
    });
  };*/

  render() {
    const { input, name, id, meta: { touched, error }, files, preview, classes } = this.props;
    //const { files, preview } = this.state;
    console.log(this.state);
    return (
      <div>
        <Dropzone
          name={name}
          onDrop={filesToUpload => input.onChange(this.props.handleDrop(filesToUpload))}
          multiple={true}
          accept=".pdf"
        >
          {
            ({ getRootProps, getInputProps }) => {
              return (
                <div {...getRootProps()} className={classes.dropZone} style={{ marginBottom: 15 }}>
                  <input {...getInputProps()} />
                  <div className="preview-container">
                    <div className="preview">
                      {/*files.length > 0 && preview.length > 0 ?
                        files.map((file, index) => <img style={{ maxWidth: 90, maxHeight: 90, width: 'auto', height: 'auto' }} src={preview[index]} alt={file.name} key={index} />)
                        :
                        <span>Drag File ....</span>
                      */}
                      <Typography noWrap>
                        Add file ...
                      </Typography>
                    </div>
                  </div>
                </div>
              )
            }
          }
        </Dropzone>
        {touched &&
          error &&
          <span className="error">{error}</span>}
        {files && Array.isArray(files) && (
          <List component="ul" id="files">
            {files.map((file, index) =>
              <ListItem key={index} disableGutters>
                <Grid
                  container
                  spacing={0}
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                >
                  <Grid item xs={6} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                    <Document key={index} file={file} >
                      <Page pageNumber={1} width={150} height={100} />
                    </Document>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography noWrap>
                      {file.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography noWrap>
                      Size: {file.size}
                    </Typography>
                  </Grid>
                </Grid>
              </ListItem>
            )}
          </List>
        )}
      </div>
    )
  };
};

export default withStyles(styles)(uploadDropZone);
