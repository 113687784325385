import LogInPage from '../components/login';
import RecoveryPage from '../components/recovery';
import DashboardPage from '../components/dashboard';
import CustomersPage from '../components/customers';
import SellersPage from '../components/sellers';
import ProfilePage from '../components/profile';
import SettingsPage from '../components/settings';
import AppointmentsPage from '../components/appointments';
import CalendarPage from '../components/calendar';
import AdvanceSearchPage from '../components/advanceSearch'
import ErrorPage from '../components/404';

const indexRoutes = [
  {
    path: "/login",
    component: LogInPage
  },
  {
    path: "/recovery",
    component: RecoveryPage
  },
  {
    path: "/dashboard",
    component: DashboardPage
  },
  {
    path: "/customers",
    component: CustomersPage
  },
  {
    path: "/sellers",
    component: SellersPage
  },
  {
    path: "/profile",
    component: ProfilePage
  },
  {
    path: "/settings",
    component: SettingsPage
  },
  {
    path: "/appointments",
    component: AppointmentsPage
  },
  {
    path: "/calendar",
    component: CalendarPage
  },
  {
    path: "/advancesearch",
    component: AdvanceSearchPage
  },
  {
    path: "",
    component: ErrorPage
  }
];

export default indexRoutes;
