import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getInstallationSummary, generatePdf } from '../../../actions';
import { url_customer_report } from '../../../constants/links';
import ProfileForm from './profileForm';
import ProfileInfo from './profileInfo';
import RegisterCardDetail from '../register/registerCard/registerDetailCard'

import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import EditIcon from '@material-ui/icons/Edit';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Grid from '@material-ui/core/Grid';

import EmailIcon from '@material-ui/icons/Email';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhoneIcon from '@material-ui/icons/Phone';
import MapIcon from '@material-ui/icons/Map';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  root: {
    width: '100%',
    minHeight: "90vh", //Anteriormente 100vh
    [theme.breakpoints.up('sm')]: {
      //height: '132vh',
      overflowY: 'auto',
      height: "calc(100vh - 64px)"
    }
  },
  banner: {
    backgroundColor: 'white',
    paddingBottom: 8,
    paddingTop: 8,
    /* Banner fixed */
    width: '100%',
    top: '56px',
    zIndex: 2,
    position: 'fixed',
    /*--------------*/
    [theme.breakpoints.up('sm')]: {
      /* Banner fixed */
      top: '64px',
      width: 'calc(100% - 250px)',
      /*--------------*/
    }
  },
  title: {
    fontSize: 30
  },
  titleProfile: {
    fontSize: 25,
    //fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    // color: '#233b54'
  },
  subTitle: {
    fontSize: 17,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal'
  },
  profileArea: {
    marginTop: '68px',
    padding: '15px 15px 76px 15px',
    maxWidth: 1100,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      padding: 15
    }
  },
  divAnimation: {
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out'
  },
  paper: {
    padding: 15,
    marginBottom: 15
  },
  grid: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  infoGridItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  infoDiv: {
    marginTop: 5,
    height: 110,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 500
  },
  bigAvatar: {
    width: 90,
    height: 90,
    [theme.breakpoints.up('sm')]: {
      width: 120,
      height: 120,
      margin: 6
    }
  },
  buttons: {
    width: 250,
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 5,
    [theme.breakpoints.up('sm')]: {
      //display: 'none'
    }
  },
  iconButton: {
    width: 48,
    height: 48
  },
  buttonsLinks: {
    color: 'inherit'
  },
  expandMoreIcon: {
    top: '-9px',
    right: 8,
    position: 'absolute',
    transform: 'rotate(0deg)',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  expandMoreIconOpen: {
    transform: 'rotate(180deg)',
  },
  paperContainer: {
    minHeight: 48,
    padding: '0 24px 15px',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    position: 'relative',
    margin: '12px 0'
  },
  paperContainerTitle: {
    fontSize: 25
  },
  collapseContainer: {
    padding: '0 24px',
    //marginBottom: 12
  },
  editButton: {
    padding: 5,
    marginLeft: 5
  },
  editIcon: {
    fontSize: '1rem',
    //display: 'none'
  },
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    customer: state.customer.customer,
    installationSummary: state.customer.installationSummary
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getInstallationSummary: (token, customer) => dispatch(getInstallationSummary(token, customer)),
    generatePdf: (url, token, filename) => dispatch(generatePdf(url, token, filename))
  };
};

class ProfileCard extends React.Component {
  state = {
    show: false,
    collapse: false,
    installationSummary: false,
    data: [
      {
        location: 'living room',
        q_before: 22,
        q_after: 30,
        observations: 'Installation of the valve #2',
        valve: 'valve #2',
        initial_photo: '',
        end_photo: '',
        update: true
      },
      {
        location: 'bathroom',
        q_before: 42,
        q_after: 60,
        observations: 'Installation of the valve #1',
        valve: 'valve #1',
        initial_photo: '',
        end_photo: '',
        update: false
      },
      {
        location: 'kitchen',
        q_before: 42,
        q_after: 50,
        observations: 'Installation of the valve #1',
        valve: 'valve #1',
        initial_photo: '',
        end_photo: '',
        update: true
      },
      {
        location: 'basement',
        q_before: 11,
        q_after: 10,
        observations: 'Installation of the valve #2',
        valve: 'valve #2',
        initial_photo: '',
        end_photo: '',
        update: false
      }
    ]
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const id = this.props.match.params.id;
    const token = 'token '.concat(this.props.token);
    await this.props.getInstallationSummary(token, id);
    if (this.props.installationSummary.length > 0) {
      this.setState({
        installationSummary: this.props.installationSummary[0]
      });
    }
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleShow() {
    this.setState({
      show: !this.state.show
    });
  };

  handleCollapse() {
    this.setState({
      collapse: !this.state.collapse
    });
  };

  formatValues(customer) {
    const values = {
      ...customer,
      agreement_date: customer.agreement_date ? customer.agreement_date.split('-').join('/') : null
    }
    return values;
  };

  async generatePdf(id) {
    const token = 'token '.concat(this.props.token);
    const url = `${url_customer_report}&type=summary_installation&identifier=${id}`;
    const filename = 'Summary'
    await this.props.generatePdf(url, token, filename);
  };

  render() {
    const { intl: { formatMessage }, intl, classes, messages, role, match, customer } = this.props;
    const { show, collapse, installationSummary } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.banner}>
          <List component="ul">
            <ListItem >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                {formatMessage(messages.navBar.profile)}
              </Typography>} />
              <ListItemSecondaryAction style={{ marginRight: 10 }}>
                <IconButton aria-label="edit" onClick={this.handleShow.bind(this)}>
                  {role.update ?
                    show ?
                      <ArrowLeftIcon />
                      :
                      <EditIcon />
                    :
                    null
                  }
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
        <div className={classes.profileArea}>
          {show ?
            <ProfileForm
              history={this.props.history}
              intl={intl}
              messages={messages}
              match={match}
              fetchCustomerIndex={this.props.fetchCustomerIndex}
              showCard={this.handleShow.bind(this)}
              initialValues={this.formatValues(customer)}
            />
            :
            <div className={classes.divAnimation}>
              <Paper className={classes.paper}>
                <Grid
                  container
                  direction='row'
                  justify='space-between'
                  alignItems='center'
                  className={classes.grid}
                >
                  <Grid item xs={12} className={classes.infoGridItem}>
                    <Avatar alt="avatar" src={customer.photo} className={classes.bigAvatar} />
                    <div className={classes.infoDiv}>
                      <Typography className={classes.titleProfile}>
                        {this.capitalize(customer.name)}
                      </Typography>
                      <Typography className={classes.subTitle}>
                        {this.capitalize(customer.identification_type)} {customer.identification}
                      </Typography>
                      <div className={classes.buttons}>
                        <IconButton classes={{ root: classes.iconButton }} disabled={customer.email ? false : true}>
                          <a href={`mailto: ${customer.email}`} className={classes.buttonsLinks}>
                            <EmailIcon />
                          </a>
                        </IconButton>
                        <IconButton classes={{ root: classes.iconButton }} disabled={customer.phone ? false : true}>
                          <a href={`tel: ${customer.phone}`} className={classes.buttonsLinks}>
                            <PhoneIcon />
                          </a>
                        </IconButton>
                        <IconButton classes={{ root: classes.iconButton }} disabled={customer.address ? false : true}>
                          <a target="_blank" rel="noopener noreferrer" href={`https://maps.google.com/?q=${customer.__other_information__.city}`} className={classes.buttonsLinks}>
                            <MapIcon />
                          </a>
                        </IconButton>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <ProfileInfo
                  customer={customer}
                  intl={intl}
                  messages={messages}
                  city={this.capitalize(customer.__other_information__.city)}
                  statusValue={this.capitalize(customer.__other_information__.status)}
                  sellerValue={this.capitalize(customer.__other_information__.seller)}
                  businessType={this.capitalize(customer.__other_information__.bussines_type)}
                  serviceClass={this.capitalize(customer.__other_information__.service_class)}
                />
              </Paper>
              <Paper className={classes.paper}>
                <div className={classes.paperContainer}>
                  <Typography component="h2" variant="h2" className={classes.paperContainerTitle}>
                    Installation Summary
                  </Typography>
                  <div>
                    {installationSummary ?
                      <Typography variant="caption" color="textSecondary" style={{ /*marginRight: 4*/ }} onClick={this.handleCollapse.bind(this)}>
                        See Summary
                        </Typography>
                      :
                      <Typography variant="caption" color="textSecondary" >
                        Add Summary
                      </Typography>
                    }
                    {/* <IconButton
                      className={classnames(classes.expandMoreIcon, {
                        [classes.expandMoreIconOpen]: collapse,
                      })}
                      onClick={this.handleCollapse.bind(this)}
                      aria-expanded={collapse}
                      aria-label="Show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton> */}
                  </div>
                </div>
                {installationSummary ?
                  <Collapse in={collapse} timeout="auto" unmountOnExit className={classes.collapseContainer}>
                    <div>
                      <div style={{ display: 'flex', marginBottom: 16, alignItems: 'center' }}>
                        <Typography paragraph style={{ marginBottom: 0 }}>{installationSummary.summary_date}</Typography>
                        <IconButton aria-label="edit" classes={{ root: classes.editButton }} onClick={this.generatePdf.bind(this, installationSummary.id)}>
                          <PdfIcon classes={{ root: classes.editIcon }} />
                        </IconButton>
                      </div>
                      <Typography paragraph>
                        {installationSummary.products_and_services}
                      </Typography>
                      <Typography paragraph>
                        {installationSummary.installation_methodology}
                      </Typography>
                      <Typography paragraph>
                        {installationSummary.corrective_actions}
                      </Typography>
                      <Typography>
                        {installationSummary.water_inspections}
                      </Typography>
                    </div>
                  </Collapse>
                  :
                  null
                }
                <RegisterCardDetail data={this.state.data} withPadding="0 24px" collapseOpen={collapse ? 27 : null} />
              </Paper>
            </div>
          }
        </div>
      </div>
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileCard));
