import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Link } from 'react-router-dom';
import { getCompany } from '../../../actions';
import ProfileForm from './profileForm';

import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {
    width: '100%',
    minHeight: "90vh",
    [theme.breakpoints.up('sm')]: {
      //height: '132vh',
      overflowY: 'auto',
      height: "calc(100vh - 64px)"
    }
  },
  bannerRoot: {
    backgroundColor: 'white',
    paddingBottom: 8,
    paddingTop: 8,
    /* Banner fixed */
    width: '100%',
    top: '56px',
    zIndex: 1,
    position: 'fixed',
    /*--------------*/
    [theme.breakpoints.up('sm')]: {
      /* Banner fixed */
      top: '64px',
      width: 'calc(100% - 250px)',
      /*--------------*/
    }
  },
  banner: {
    backgroundColor: 'white',
    paddingBottom: 8,
    paddingTop: 8,
    maxWidth: 1200,
    /* Banner fixed */
    width: '100%',
    top: '56px',
    zIndex: 1,
    position: 'fixed',
    /*--------------*/
    [theme.breakpoints.up('sm')]: {
      top: '64px'
    }
  },
  title: {
    fontSize: 30
  },
  profileArea: {
    marginTop: '68px',
    padding: '15px 15px 76px 15px',
    maxWidth: 1100,
    margin: '0 auto',
    overflowY: 'auto',
    position: 'relative'
  },
});

function mapDispatchToProps(dispatch) {
  return {
    getCompany: (token, id) => dispatch(getCompany(token, id))
  }
};

function mapStateToProps(state) {
  return {
    role: state.auth.user_type,
    token: state.auth.token,
    company: state.company.company
  }
};

class Profile extends React.Component {
  state = {
    show: false,
    data: [],
    loading: true
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const id = this.props.match.params.id;
    const token = 'token '.concat(this.props.token);
    await this.props.getCompany(token, id);
    const company = { ...this.props.company, name: this.capitalize(this.props.company.name) };
    this.setState({
      data: company,
      loading: false
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleShow(reload) {
    if (reload) this.fetchGet.bind(this);
    this.setState({
      show: !this.state.show
    });
  };

  render() {
    const { classes, match, intl, messages, role } = this.props;
    const { show, loading, data } = this.state;
    return (
      <div className={classes.root}>
        <div /*className={role.select ? classes.bannerRoot : classes.banner}*/ className={classes.bannerRoot}>
          <List component="ul">
            <ListItem >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                Profile
              </Typography>} />
              <ListItemSecondaryAction style={{ marginRight: 10 }}>
                <IconButton aria-label="add" >
                  {show ?
                    <ArrowLeftIcon onClick={this.handleShow.bind(this, true)} />
                    :
                    match.url === window.location.pathname && role.update ?
                      <EditIcon onClick={this.handleShow.bind(this, false)} />
                      :
                      <Link to={`${match.url}`} style={{ height: 24, color: 'inherit' }}>
                        <ArrowLeftIcon onClick={this.fetchGet.bind(this)} />
                      </Link>
                  }
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
        <div className={classes.profileArea}>
          {loading ?
            <div style={{ position: "fixed", top: "50%", left: "50%" }}>
              <CircularProgress />
            </div>
            :
            show ?
              <Slide direction="left" in={show} mountOnEnter unmountOnExit timeout={500}>
                <ProfileForm
                  intl={intl}
                  messages={messages}
                  match={match}
                  showCard={this.handleShow.bind(this)}
                  fetchSettingsIndex={this.props.fetchSettingsIndex}
                  fetchCard={this.fetchGet.bind(this)}
                  initialValues={data}
                />
              </Slide>
              :
              <div>
                <p>Name: {data.name}</p>
                <p>Username Alegra: {data.user_name_alegra}</p>
                <p>Token: {data.token_alegra}</p>
                <p>Email: {data.email}</p>
                <p>Phone: {data.phone}</p>
              </div>
          }
        </div>
      </div>
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile));
