import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authentication';
import recoveryReducer from './recovery';
import languageReducer from './language';
import sideListReducer from './sideList';
import customerReducer from './customer';
import billReducer from './bill';
import loaderReducer from './loader';
import utilityCompanyReducer from './utilityCompany';
import companyReducer from './company';
import commissionReducer from './commission';
import businessTypeReducer from './businessType';
import valveReducer from './valve';
import cityReducer from './city';
import employeeReducer from './employee';
import serviceClassReducer from './serviceClass';
import accessoryReducer from './accessory';
import bookingReducer from './booking';
import analysisReducer from './analysis';
import registerReducer from './register';
import statusReducer from './status';
import invoiceReducer from './invoice';
import generatePdfReducer from './generatePdf';

const rootReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  recover: recoveryReducer,
  intl: languageReducer,
  sideList: sideListReducer,
  customer: customerReducer,
  employee: employeeReducer,
  bill: billReducer,
  loader: loaderReducer,
  utilityCompany: utilityCompanyReducer,
  company: companyReducer,
  commission: commissionReducer,
  businessType: businessTypeReducer,
  valve: valveReducer,
  city: cityReducer,
  serviceClass: serviceClassReducer,
  accessory: accessoryReducer,
  booking: bookingReducer,
  analysis: analysisReducer,
  register: registerReducer,
  status: statusReducer,
  invoice: invoiceReducer,
  generatePdf: generatePdfReducer
});

export default rootReducer;

