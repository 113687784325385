import React, { Fragment } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import AnalysisInfo from './analysisInfo';
import AnalysisPayment from './analysisPayment';
import AnalysisConsumption from './analysisConsumption';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  paper: {
    padding: 15,
    marginBottom: 15,
    display: 'block',
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  infoGridItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  infoDiv: {
    marginTop: 5,
    height: 110,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  bold: {
    fontWeight: 500
  },
  bigAvatar: {
    width: 90,
    height: 90,
    [theme.breakpoints.up('sm')]: {
      width: 120,
      height: 120,
      margin: 6
    }
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#233b54'
  },
  subTitle: {
    fontSize: 17,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    color: '#233b54'
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    customer: state.customer.customer
  }
};

class AnalysisCard extends React.Component {
  state = {
    location: {},
    status: {},
    businessType: {},
    utilityCompany: {},
    seller: {},
    serviceClass: {},
    show: false
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    axios.get(this.props.customer.city,
      {
        headers: { Authorization: token }
      })
      .then(response => {
        //console.log(response.data);
        this.setState({
          location: {
            state: this.capitalize(response.data.state),
            name: this.capitalize(response.data.name)
          }
        })
        return axios.get(this.props.customer.status,
          {
            headers: { Authorization: token }
          })
      })
      .then(response => {
        //console.log(response.data);
        this.setState({
          status: {
            label: this.capitalize(response.data.name),
            value: response.data.url
          }
        })
        return axios.get(this.props.customer.bussines_type,
          {
            headers: { Authorization: token }
          })
      })
      .then(response => {
        //console.log(response.data);
        this.setState({
          businessType: {
            label: this.capitalize(response.data.name),
            value: response.data.url
          }
        })
        return axios.get(this.props.customer.utility_company,
          {
            headers: { Authorization: token }
          })
      })
      .then(response => {
        //console.log(response.data);
        this.setState({
          utilityCompany: {
            label: this.capitalize(response.data.name),
            value: response.data.url
          }
        })
        return axios.get(this.props.customer.seller,
          {
            headers: { Authorization: token }
          })
      })
      .then(response => {
        //console.log(response.data);
        this.setState({
          seller: {
            label: this.capitalize(response.data.first_name) + ' ' + this.capitalize(response.data.last_name),
            value: response.data.url
          }
        })
        return axios.get(this.props.customer.service_class,
          {
            headers: { Authorization: token }
          })
      })
      .then(response => {
        //console.log(response.data);
        this.setState({
          serviceClass: {
            label: this.capitalize(response.data.name),
            value: response.data.url
          }
        })
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { intl: { formatMessage }, intl, classes, messages, role, customer } = this.props;
    const { serviceClass, status, utilityCompany, businessType, seller, location, show } = this.state;
    const info = [
      {
        label: 'Policy',
        value: customer.agreement_reference
      },
      {
        label: 'Hydrometer',
        value: customer.hydrometer
      },
      {
        label: 'Service Class',
        value: serviceClass.label
      },
      {
        label: 'Business Type',
        value: businessType.label
      }
    ];
    return (
      <Fragment>
        <Paper className={classes.paper}>
          <Grid
            container
            spacing={16}
            direction='row'
            justify='space-between'
            alignItems='center'
            className={classes.grid}
          >
            <Grid item xs={12} className={classes.infoGridItem}>
              <Avatar alt="avatar" src={customer.photo} className={classes.bigAvatar} />
              <div className={classes.infoDiv}>
                <Typography className={classes.title}>
                  {this.capitalize(customer.name)}
                </Typography>
                <Typography className={classes.subTitle}>
                  {this.capitalize(customer.identification_type)} {customer.identification}
                </Typography>
                <Typography className={classes.subTitle}>
                  {customer.email}
                </Typography>
                <Typography className={classes.subTitle}>
                  {customer.phone}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} >
              <AnalysisInfo
                info={info}
                intl={intl}
                messages={messages}
              />
            </Grid>
          </Grid>
        </Paper>
        <AnalysisPayment />
        <AnalysisConsumption />
      </Fragment>
    )
  }
};

export default connect(mapStateToProps)(withStyles(styles)(AnalysisCard));
