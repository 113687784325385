import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Link } from 'react-router-dom';
import { changeSideList } from '../../actions';
import { getEmployee } from '../../actions';
import BottomNav from '../bottomNav';
import PageInfo from '../pageInfo';

import { withStyles } from '@material-ui/core/styles';
import ProfileCard from './profile/profileCard';
import Grid from '@material-ui/core/Grid';
import PersonIcon from '@material-ui/icons/Person';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  sellerIndexContainer: {
    display: 'flex'
  },
  workArea: {
    //minHeight: '126vh',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    width: '100%'
  },
  bottomNavigation: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    employee: state.employee.employee
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getEmployee: (token, id) => dispatch(getEmployee(token, id)),
    changeSideList: (page, info, link) => dispatch(changeSideList(page, info, link))
  }
};

class SellerIndex extends React.Component {
  state = {
    seller: {},
    loading: true,
    value: 0,
    token: 'token '.concat(this.props.token),
    id: this.props.match.params.id
  };

  componentDidMount() {
    /*--- Focus the value in bottom navigation ---*/
    let value = 0;
    if (window.location.pathname.includes('/profile')) {
      value = 0;
    };
    this.setState({
      value: value
    });
    /*--------------------------------- */
    this.fetchGet();
  };
  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    const id = this.props.match.params.id;
    await this.props.getEmployee(token, id);
    this.setState({
      seller: this.props.employee,
      loading: false
    })
  };

  changeValue(index) {
    this.setState({
      value: index
    })
  };

  render() {
    const { history, match, classes, intl, intl: { formatMessage }, messages } = this.props;
    const { seller, loading, value, id, token } = this.state;
    const list = [
      {
        text: 'Profile',
        url: 'profile',
        icon: <PersonIcon />,
        label: 'Profile'
      }
    ];
    //this.props.changeSideList("seller", seller, match.url);//change sidelist from drawer
    return (
      <Fragment>
        {loading ?
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <CircularProgress />
          </div>
          :
          <div className={classes.sellerIndexContainer}>
            <PageInfo
              intl={intl}
              messages={messages}
              match={match}
              info={seller}
              list={list}
              changeValue={this.changeValue.bind(this)}
              root='sellers'
              value={value}
            />
            <Grid
              container
              spacing={0}
              direction='row'
              justify='space-evenly'
              alignItems='flex-start'
            >
              <Grid item xs={12} className={classes.workArea}>
                <Switch>
                  <Route exact path={`${match.path}`} render={(props) => <ProfileCard {...props} intl={intl} messages={messages} match={match} fetchSellerIndex={this.fetchGet.bind(this, token, id)} />} />
                  <Route path={`${match.path}/profile`} render={(props) => <ProfileCard {...props} intl={intl} messages={messages} match={match} fetchSellerIndex={this.fetchGet.bind(this, token, id)} />} />
                </Switch>
              </Grid>
            </Grid>
          </div>
        }
        <BottomNav value={value} data={list} match={match} history={history} changeValue={this.changeValue.bind(this)} />
      </Fragment>
    )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SellerIndex));
