import React from 'react';
import AnalysisChart from './analysisChart';
import { connect } from 'react-redux';

import { getCustomers, getAnalysisInfo } from '../../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RightArrowIcon from '@material-ui/icons/TrendingFlat';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  gridItem: {
    textAlign: 'end'
  },
  list: {
    width: '100%'
  },
  bigValue: {
    fontSize: 27,
    fontWeight: 'bold',
    lineHeight: 'normal',
  },
  expansionExpanded: {
    margin: '0px 0px 15px'
  },
  expansionTitle: {
    fontSize: 17,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.6,
    color: '#233b54',
  },
  subValue: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.4,
    color: '#000000'
  },
  mediumSubValue: {
    fontSize: 20,
    fontWeight: '500',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.6,
    color: '#233b54',
    marginTop: 9
  },
  caption: {
    opacity: '0.5',
    fontSize: 12,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.4,
    color: '#233b54'
  },
  line1: {
    width: '100%',
    height: 2,
    border: 'solid 5px #19d800',
    borderRadius: 10
  },
  line2: {
    width: '100%',
    height: 2,
    border: 'solid 5px #3e8dc9',
    borderRadius: 10
  },
  littleSpan: {
    fontSize: 13
  },
  arrowGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
function mapStateToProps(state) {
  return {
    token: state.auth.token,
    customer: state.customer.customer,
    analysisInfo: state.analysis.data,
    dominio: state.auth.dominio
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getCustomers: (token, company) => dispatch(getCustomers(token, company)),
    getAnalysisInfo: (token, customer) => dispatch(getAnalysisInfo(token, customer))
  }
};

class AnalysisConsumption extends React.Component {
  state = {
    expanded: true,
    analysisInfo: []
  };
  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const id = this.props.customer.id;
    const token = 'token '.concat(this.props.token);
    await this.props.getAnalysisInfo(token, id)
    //const show = this.props.bills.length === 0 ? true : false;
    this.setState({
      analysisInfo: this.props.analysisInfo
    });
  };
  handleExpand() {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  handleExpand() {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { intl, classes, messages, info } = this.props;
    const { expanded, analysisInfo } = this.state;
    const details = analysisInfo[analysisInfo.length - 1];
    if (analysisInfo.length > 0) {
      return (
        <ExpansionPanel
          square={false}
          expanded
          classes={{ expanded: classes.expansionExpanded }}
        >
          <ExpansionPanelSummary onClick={this.handleExpand.bind(this)} >
            <Typography className={classes.expansionTitle}>Consumption Forecast</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              spacing={0}
              direction='row'
              justify='space-between'
            >
              <Grid item xs={5} style={{ textAlign: 'center' }}>
                <Typography noWrap className={classes.bigValue} style={{ color: '#19d800' }}>
                  {details.average_consume}m<span className={classes.littleSpan}>3</span>
                </Typography>
                <Typography noWrap className={classes.subValue}>
                  Consumption Average
              </Typography>
              </Grid>
              <Grid item xs={2} className={classes.arrowGrid}>
                <RightArrowIcon style={{ fontSize: 44, color: '#979797' }} />
              </Grid>
              <Grid item xs={5} style={{ textAlign: 'center' }}>
                <Typography noWrap className={classes.bigValue} style={{ color: '#3e8dc9' }}>
                  {details.pronostic_consume}m<span className={classes.littleSpan}>3</span>
                </Typography>
                <Typography noWrap className={classes.subValue}>
                  Predicted Average
              </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: 25, marginBottom: 16 }}>
                <Typography noWrap className={classes.mediumSubValue}>
                  Monthly Comparison
              </Typography>
              </Grid>
              <Grid item xs={12}>
                <AnalysisChart />
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )
    } else {
      return (
        <p>Loading data...</p>
      )
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AnalysisConsumption));
