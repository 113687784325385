import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getCustomers } from '../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import GroupIcon from '@material-ui/icons/Group';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const styles = theme => ({
  paper: {
    width: '100%',
    overflowX: 'auto'
  },
  secondary: {
    marginRight: 10
  }
});

function mapDispatchToProps(dispatch) {
  return {
    getCustomers: (token, company) => dispatch(getCustomers(token, company))
  }
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    dominio: state.auth.dominio,
    customers: state.customer.customers
  }
};

class CustomerCard extends React.Component {
  state = {
    customers: []
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getCustomers(token, this.props.dominio);
    this.setState({
      customers: this.props.customers
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleRedirect(id) {
    if (typeof id === 'number') {
      this.props.history.push(`/customers/${id}`)
    } else {
      this.props.history.push(`/customers`)
    };
  };

  render() {
    const { intl: { formatMessage, formatNumber, formatDate }, classes, history } = this.props;
    const { customers } = this.state;
    console.log(customers)
    return (
      <Paper className={classes.paper} elevation={1}>
        <List component="ul">
          <ListItem>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={<Typography component="h2" variant="h6" noWrap>
              Customers
            </Typography>} />
          </ListItem>
        </List>
        <List component="ul" dense disablePadding>
          {customers.length > 0 ?
            customers.map((customer, index) =>
              index < 5 ?
                <Fragment key={index}>
                  <ListItem button onClick={this.handleRedirect.bind(this, customer.id)}>
                    <ListItemAvatar>
                      {customer.photo ?
                        <Avatar alt={customer.name} src={customer.photo} />
                        :
                        <Avatar alt={customer.name} src="/images/no-photo.png" />
                      }
                    </ListItemAvatar>
                    <ListItemText secondary={
                      <Typography variant="body2" noWrap>
                        {this.capitalize(customer.name)}
                      </Typography>
                    } />
                    {customer.alegra_id ?
                      <ListItemSecondaryAction className={classes.secondary}>
                        <img src="/images/alegra_green.png" width="50" alt="alegra" />
                      </ListItemSecondaryAction>
                      :
                      null
                    }
                  </ListItem>
                  <Divider />
                </Fragment>
                :
                null
            )
            :
            <ListItem >
              <Typography>
                No registered customers
              </Typography>
            </ListItem>
          }
        </List>
        <List component="ul" disablePadding>
          <ListItem button onClick={this.handleRedirect.bind(this)}>
            <ListItemText primary={<Typography variant="subtitle1" noWrap>
              Customers
            </Typography>} />
            <ListItemIcon >
              <ArrowIcon />
            </ListItemIcon>
          </ListItem>
        </List>
      </Paper>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerCard));
