import {
  GET_STATUS_LIST,
  GET_STATUS_LIST_ERROR,
  GET_STATUS,
  GET_STATUS_ERROR
} from '../constants/action_types';

const initialState = { statusList: [] };

const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATUS_LIST:
      return {
        ...state,
        error: false,
        statusList: action.payload
      };
    case GET_STATUS_LIST_ERROR:
      return {
        ...state,
        error: false,
      };
    case GET_STATUS:
      return {
        ...state,
        error: false,
        status: action.payload
      };
    case GET_STATUS_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default statusReducer;
