import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
  bottomNavigation: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
});

class BottomNav extends React.Component {
  state = {
    value: 0,
    data: [],
    newData: [],
    drawer: false
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value
    });
  };

  componentDidMount() {
    this.newData(this.props.data);
    this.setState({
      value: this.props.value,
      data: this.props.data
    });
  };

  handleChangeAndRedirect(page, index) {
    this.toggleDrawer(false)
    this.props.changeValue(index)
    if (page !== '') this.props.history.push(`${this.props.match.url}/${page}`)
  };

  toggleDrawer(open) {
    this.setState({
      drawer: open
    });
  };

  newData(data) {
    const newData = data.filter((item, index) => item.url !== 'profile' && item.id < 5);
    //console.log(newData)
    this.setState({
      newData: newData
    });
  };

  render() {
    const { value, data, newData } = this.state;
    const { classes } = this.props;
    return (
      <Fragment>
        <BottomNavigation
          value={value}
          showLabels
          className={classes.bottomNavigation}
        >
          {newData.map((item, index) => (
            item.id === 3 ?
              <BottomNavigationAction key={index} label={item.label} onClick={this.toggleDrawer.bind(this, true)} icon={item.icon} />
              :
              <BottomNavigationAction key={index} label={item.label} onClick={this.handleChangeAndRedirect.bind(this, item.url, item.id)} icon={item.icon} />
          ))}
        </BottomNavigation>
        <Drawer
          anchor="bottom"
          open={this.state.drawer}
          onClose={this.toggleDrawer.bind(this, false)}
        //style={{ height: '100vh' }}
        >
          <div
            tabIndex={0}
            role="button"
            //onClick={this.toggleDrawer.bind(this, false)}
            onKeyDown={this.toggleDrawer.bind(this, false)}
          >
            <List>
              {data.map((item, index) => (
                item.url !== '' ?
                  <ListItem button key={index} onClick={this.handleChangeAndRedirect.bind(this, item.url, item.id)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                  :
                  null
              ))}
            </List>
          </div>
        </Drawer>
      </Fragment>
    )
  };
};

export default withStyles(styles)(BottomNav);
