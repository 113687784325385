import React from 'react';
import { getEmployees, updateRegister, deleteRegister } from '../../../../actions';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import RegisterForm from './registerEdit';
import ModalDialog from '../../../modal';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import renderSelectFieldTest from '../../../ui/select_field_test';
import renderTextField from '../../../ui/text_field';
import universalDatePicker from '../../../ui/universal_date_picker';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  paper: {
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out'
  },
  form: {
    width: '100%',
    padding: '15px 24px 15px',
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    margin: '0 10px 0 0'
  },
  link: {
    color: theme.palette.primary.main,
    margin: '0 10px 0 0',
    cursor: 'pointer'
  },
  leftActions: {
    display: 'flex',
    alignItems: 'center'
  }
});

function mapStateToProps(state) {
  return {
    loading: state.loader.loading,
    token: state.auth.token,
    role: state.auth.user_type,
    sellers: state.employee.sellers,
    dominio: state.auth.dominio
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getEmployees: (token, company) => dispatch(getEmployees(token, company)),
    updateRegister: (data, token, id) => dispatch(updateRegister(data, token, id)),
    deleteRegister: (token, id) => dispatch(deleteRegister(token, id)),
  }
};

class EditForm extends React.Component {
  state = {
    sellers: [],
    base64: [],
    open: false
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getEmployees(token);

    const sellers = [];

    this.props.sellers.forEach(item =>
      sellers.push({
        label: this.capitalize(item.first_name) + ' ' + this.capitalize(item.last_name),
        value: item.url
      })
    );

    this.setState({
      sellers: sellers
    })
  };

  getBase64(file) {
    return new Promise(resolve => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        console.log('Error: ', error);
      };
    })

  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        if (key === 'registers') {
          fields[key].forEach(async (register, index) => {
            for (let item in register) {
              if (item === 'initial_photos' || item === 'end_photos') {
                const file = await this.getBase64(register[item]);
                form_data.append(`registers[${index}][${item}]`, file)
                console.log(`registers[${index}][${item}]`, file)
              } else {
                form_data.append(`registers[${index}][${item}]`, register[item])
                //console.log(register[item])
              }
            };
          })
        } else {
          form_data.append(key, fields[key])
        }
      }

      return form_data;
    }
  };

  async submit(values) {
    const val = values;

    if (val.report_date) {
      if (typeof val.report_date === 'object') {
        val.report_date = values.report_date.toISOString().slice(0, 10)
      } else {
        val.report_date = values.report_date.split('/').join('-')
      };
    };

    if (val.__other_information__) {
      delete val.__other_information__
    };

    val.registers.forEach(register => {
      if (register.initial_photos) {
        delete register.initial_photos
      };

      if (register.end_photos) {
        delete register.end_photos
      };
    });

    if (typeof val.initial_photos !== 'object' || val.initial_photos === '{}') {
      delete val.initial_photos
    };

    if (typeof val.end_photos !== 'object' || val.end_photos === '{}') {
      delete val.end_photos
    };

    /* Evitar valored nulos */
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    /*---------------------*/

    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;
    await this.validateErrorsApi(data, token, id);

    this.props.edit();
    this.props.fetchArea(this.props.indexPanel);
  };

  validateErrorsApi(data, token, id) {
    return this.props.updateRegister(data, token, id)
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  clickButton() {
    document.querySelector('button[id="prueba"]').click();
  };

  handleOpen(open) {
    this.setState({
      open: open
    })
  };

  async deleteData() {
    this.handleOpen(false)
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;
    await this.props.deleteRegister(token, id);
    this.props.edit();
    this.props.fetchArea();
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading, error, role } = this.props;
    const { sellers, open } = this.state;
    return (
      <div className={classes.paper}>
        <ModalDialog
          open={open}
          title="Are you sure you want to delete this register ?"
          description="This action directly affects the database and is irreversible."
          actionName="Delete"
          handleOpen={this.handleOpen.bind(this)}
          handleAction={this.deleteData.bind(this)}
        />
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} //encType="multipart/form-data"
        >
          <Grid container spacing={24}>
            {error ?
              <Grid item xs={12}>
                <Typography color="error">
                  {error}
                </Typography>
              </Grid>
              :
              null
            }
            <Grid item xs={12} sm={4}>
              <Field
                name="seller"
                component={renderSelectFieldTest}
                options={sellers}
                label='Employee'
                ref="seller"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="report_date"
                component={universalDatePicker}
                label="Report Date"
                ref="report_date"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field
                name="contact_name"
                component={renderTextField}
                label="Contact Name"
                ref="contact_name"
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FieldArray name="registers" component={RegisterForm} />
            </Grid> */}
          </Grid>
          <div className={classes.formMeta}>
            <div className={classes.leftActions}>
              <Typography variant='button' noWrap className={classes.link} onClick={this.props.edit}>
                Return to register
              </Typography>
              {/* <Button className={classes.button} color='primary' variant="outlined" onClick={this.clickButton.bind(this)}>
                Add
            </Button> */}
            </div>
            <div>
              {role.delete ?
                <Button className={classes.button} color='secondary' variant="contained" onClick={this.handleOpen.bind(this, true)} disabled={loading}>
                  <Typography variant='button' noWrap>
                    Delete
                </Typography>
                </Button>
                :
                null
              }
              <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
                <Typography variant='button' noWrap>
                  Submit
              </Typography>
              </Button>
            </div>
          </div>
        </form>
      </div>
    )
  }
};

const reduxFormUpdateRegister = reduxForm({
  form: 'updateregister'
})(EditForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateRegister));
