import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getCustomer } from '../../actions';
import { changeSideList } from '../../actions';
import BottomNav from '../bottomNav';
import PageInfo from '../pageInfo';
import BillArea from './bill/billArea';
import ProfileCard from './profile/profileCard';
import InvoiceArea from './invoice/invoiceArea';
import BookingArea from './booking/bookingArea';
import AnalysisArea from './analysis/analysisArea';
import RegisterArea from './register/registerArea';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import BallotIcon from '@material-ui/icons/Ballot';
import PageIcon from '@material-ui/icons/Pages';
import BookIcon from '@material-ui/icons/ChromeReaderMode';
import EventIcon from '@material-ui/icons/Event';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListIcon from '@material-ui/icons/ListAlt';
import PersonIcon from '@material-ui/icons/Person';
import MoreOptions from '@material-ui/icons/MoreVert';

const styles = theme => ({
  customerIndexContainer: {
    display: 'flex'
  },
  workArea: {
    //minHeight: '126vh',
    //[theme.breakpoints.up('sm')]: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    //},
    width: '100%'
  },
  bottomNavigation: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    customer: state.customer.customer
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getCustomer: (token, id) => dispatch(getCustomer(token, id)),
    changeSideList: (page, info, link) => dispatch(changeSideList(page, info, link))
  }
};

class CustomerIndex extends React.Component {
  state = {
    customer: {},
    loading: true,
    value: 0,
    token: 'token '.concat(this.props.token),
    id: this.props.match.params.id
  };

  componentDidMount() {
    /*--- Focus the value in bottom navigation ---*/
    let value = 0;
    if (window.location.pathname.includes('/register')) {
      value = 0;
    } else if (window.location.pathname.includes('/bills')) {
      value = 1;
    } else if (window.location.pathname.includes('/invoice')) {
      value = 2;
    } else if (window.location.pathname.includes('/booking')) {
      value = 6;
    } else if (window.location.pathname.includes('/analysis')) {
      value = 7;
    } else {
      value = null;
    }
    this.setState({
      value: value
    });
    /*--------------------------------- */
    //const token = 'token '.concat(this.props.token);
    //const id = this.props.match.params.id;
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    const id = this.props.match.params.id;
    await this.props.getCustomer(token, id);
    this.setState({
      customer: this.props.customer,
      loading: false
    })
  };

  handleChange(event, value) {
    if (value === 5) {
      this.setState({ value: null })
    } else {
      this.setState({ value })
    }
  };

  changeValue(index) {
    this.setState({
      value: index
    })
  };

  render() {
    const { history, match, classes, intl, intl: { formatMessage }, messages } = this.props;
    const { customer, loading, value, token, id } = this.state;
    const list = [
      {
        text: 'Profile',
        url: 'profile',
        icon: <PersonIcon />,
        label: 'Profile',
        id: 5
      },
      {
        text: 'Register',
        url: 'register',
        icon: <ListIcon />,
        label: 'Register',
        id: 0
      },
      {
        text: formatMessage(messages.customers.bills),
        url: 'bills',
        icon: <BallotIcon />,
        label: 'Bills',
        id: 1
      },
      {
        text: 'Invoice',
        url: 'invoice',
        icon: <PageIcon />,
        label: 'Invoice',
        id: 2
      },
      {
        text: 'More',
        url: '',
        icon: <MoreOptions />,
        label: 'More',
        id: 3
      },
      {
        text: 'Booking',
        url: 'booking',
        icon: <EventIcon />,
        label: 'Booking',
        id: 6
      },
      {
        text: 'Analysis',
        url: 'analysis',
        icon: <BookIcon />,
        label: 'Analysis',
        id: 7
      }
    ];
    //this.props.changeSideList("customer", customer, match.url);//change sidelist from drawer
    return (
      <Fragment>
        {loading ?
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <CircularProgress />
          </div>
          :
          <div className={classes.customerIndexContainer}>
            <PageInfo
              intl={intl}
              messages={messages}
              match={match}
              info={customer}
              list={list}
              changeValue={this.changeValue.bind(this)}
              root='customers'
              value={value}
            />
            <Grid
              container
              spacing={0}
              direction='row'
              justify='space-evenly'
              alignItems='flex-start'
            >
              <Grid item xs={12} sm={12} className={classes.workArea}>
                <Switch>
                  <Route exact path={`${match.path}`} render={(props) => <ProfileCard {...props} intl={intl} messages={messages} match={match} fetchCustomerIndex={this.fetchGet.bind(this, token, id)} />} />
                  <Route path={`${match.path}/profile`} render={(props) => <ProfileCard {...props} intl={intl} messages={messages} match={match} fetchCustomerIndex={this.fetchGet.bind(this, token, id)} />} />
                  <Route path={`${match.path}/bills`} render={(props) => <BillArea {...props} intl={intl} messages={messages} customer={customer} />} />
                  <Route path={`${match.path}/invoice`} render={(props) => <InvoiceArea {...props} intl={intl} messages={messages} />} />
                  <Route path={`${match.path}/booking`} render={(props) => <BookingArea {...props} intl={intl} messages={messages} customer={customer} />} />
                  <Route path={`${match.path}/analysis`} render={(props) => <AnalysisArea {...props} intl={intl} messages={messages} />} />
                  <Route path={`${match.path}/register`} render={(props) => <RegisterArea {...props} intl={intl} messages={messages} customer={customer} />} />
                </Switch>
              </Grid>
            </Grid>
          </div>
        }
        <BottomNav value={value} data={list} match={match} history={history} changeValue={this.changeValue.bind(this)} />
      </Fragment>
    )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerIndex));
