import React from 'react';
import { addAccessory } from '../../../actions'
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  banner: {
    paddingBottom: 8,
    paddingTop: 8
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  paper: {
    width: '100%',
    //padding: '0px 24px 15px',
    //height: '80vh',
    //display: 'flex',
    //alignItems: 'center'
  },
  form: {
    padding: '15px 24px 15px',
  },
  title: {
    fontSize: 25
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '24px'
  },
  link: {
    color: '#1562c6'
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    token: state.auth.token,
    loading: state.loader.loading
  }
};

function mapDispatchToProps(dispatch) {
  return {
    addAccessory: (values, token) => dispatch(addAccessory(values, token))
  }
};

const validate = (values, state) => {
  const { intl: { formatMessage }, messages } = state;
  const errors = {}

  if (!values.name) {
    errors.name = formatMessage(messages.signIn.required)
  }

  if (!values.quantity) {
    errors.quantity = formatMessage(messages.signIn.required)
  }
  return errors
};

class InventoryForm extends React.Component {
  async submit(values) {
    const val = {
      ...values,
      company: this.props.company.url,
      quantity: parseInt(values.quantity, 10)
    };

    const token = 'token '.concat(this.props.token);
    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso al Inventario
    4. Reload de Inventario*/


    await this.validateErrorsApi(val, token);
    this.props.showCard();
    this.props.fetchCard();
  };

  validateErrorsApi(data, token) {
    return this.props.addAccessory(data, token)
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading } = this.props;
    return (
      <Paper>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} /*enctype="multipart/form-data" id="myForm"*/>
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.head}>
              <List component="ul">
                <ListItem disableGutters>
                  <ListItemIcon>
                    <AssignmentIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography component="h2" variant="h2" className={classes.title}> Add Accessory </Typography>}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="name"
                component={renderTextField}
                label="Name"
                ref="name"
              />
            </Grid>
            <Grid item xs={12} sm={6} >
              <Field
                name="quantity"
                component={renderTextField}
                label="Quantity"
                ref="quantity"
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="description"
                component={renderTextField}
                isTextArea={true}
                label="Description"
                ref="description"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                {formatMessage(messages.customers.billFormSubmit)}
              </Typography>
            </Button>
          </div>
        </form>
      </Paper>
    )
  }
};

const reduxFormAddAccessory = reduxForm({
  form: 'addaccessory',
  validate
})(InventoryForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormAddAccessory));
