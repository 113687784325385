import React from 'react';
import { Link } from 'react-router-dom';
import { signOut, changeSideList } from '../../actions';
import { connect } from 'react-redux';
import MainSideList from './sideList/main';
import CustomersSideList from './sideList/customers';
import SellersSideList from './sideList/sellers';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import Drawer from "@material-ui/core/Drawer";
import Modal from '@material-ui/core/Modal';
import MoreOptions from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const styles = theme => ({
  root: {
    width: '100%',
    position: 'fixed',
    top: 0,
    zIndex: 4
  },
  grow: {
    flexGrow: 1,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 16px'
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block',
    }
  },
  logoImg: {
    width: 90,
    height: 37
  },
  burgerRoot: {
    display: 'inline-block',
    /*[theme.breakpoints.up('sm')]: {
      display: 'none',
    }*/
  },
  burger: {
    display: 'inline-block'
  },
  middle: {
    display: 'none',
    /*[theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    }*/
  },
  link: {
    color: 'inherit'
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  searchIcon: {
    width: theme.spacing.unit * 11,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    width: '100%'
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    width: '100%',
  },
  modal: {
    position: 'absolute',
    borderRadius: 5,
    width: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: '1.5%',
    left: '25%',
    [theme.breakpoints.up('sm')]: {
      top: '2%'
    }
  },
  menuLink: {
    color: 'black'
  }
});

function mapDispatchToProps(dispatch) {
  return {
    signOut: () => dispatch(signOut()),
    changeSideList: (page, info) => dispatch(changeSideList(page, info))
  }
};

function mapStateToProps(state) {
  return {
    page: state.sideList.page,
    role: state.auth.user_type
  }
};

class NavBar extends React.Component {
  state = {
    drawer: false,
    modal: false,
    menu: false,
    page: ''
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      page: nextProps.page
    })
  };

  toggleDrawer(open) {
    this.setState({
      drawer: open
    });
  };

  toggleModal(open) {
    this.setState({
      modal: open
    });
  };

  toggleMenu(open) {
    this.setState({
      menu: open
    })
  };

  handleSignOut() {
    this.props.signOut();
  };

  render() {
    const { classes, intl: { formatMessage }, intl, messages, history, role } = this.props;
    const { page } = this.state;

    const customers = formatMessage(messages.navBar.customers);
    const sellers = formatMessage(messages.navBar.sellers);
    const accounting = formatMessage(messages.navBar.accounting);
    const search = formatMessage(messages.navBar.search);
    const profile = formatMessage(messages.navBar.profile);
    const logout = formatMessage(messages.navBar.logout);

    let sideList;

    if (page === 'customer') {
      sideList = (
        <CustomersSideList intl={intl} messages={messages} history={history} handleCloseDrawer={this.toggleDrawer.bind(this, false)} />
      )
    } else if (page === 'seller') {
      sideList = (
        <SellersSideList intl={intl} messages={messages} history={history} handleCloseDrawer={this.toggleDrawer.bind(this, false)} />
      )
    } else {
      sideList = (
        <MainSideList intl={intl} messages={messages} history={history} history={history} handleDrawer={this.toggleDrawer.bind(this)} />
      )
    }

    return (
      <div className={classes.root}>
        <AppBar position="static" color="primary" elevation={1}>
          <Toolbar className={classes.toolBar} disableGutters>
            <Drawer
              open={this.state.drawer}
              onClose={this.toggleDrawer.bind(this, false)}
              style={{ height: '100vh' }}
            >
              <div
                tabIndex={0}
                role="button"
                //onClick={this.toggleDrawer.bind(this, false)}
                onKeyDown={this.toggleDrawer.bind(this, false)}
              >
                {sideList}
              </div>
            </Drawer>
            <div>
              <IconButton color="inherit" aria-label="Open drawer" className={classes.burger} onClick={this.toggleDrawer.bind(this, true)}>
                <MenuIcon />
              </IconButton>
              <Link to="/dashboard" className={classes.title} style={{ marginLeft: 12, verticalAlign: 'middle' }}>
                <img src='/images/ela-logo-2-white.png' alt="Ela Logo" alt="Ela Logo" className={classes.logoImg} />
              </Link>
            </div>
            <div className={classes.middle}>
              <Link to="/customers" className={classes.link}>
                <Button color="inherit" className={classes.button}>
                  <Typography variant="button" color="inherit" noWrap>
                    {customers}
                  </Typography>
                </Button>
              </Link>
              <Link to="/sellers" className={classes.link}>
                <Button color="inherit" className={classes.button}>
                  <Typography variant="button" color="inherit" noWrap>
                    {sellers}
                  </Typography>
                </Button>
              </Link>
              <Button color="inherit" className={classes.button}>
                <Typography variant="button" color="inherit" noWrap>
                  {accounting}
                </Typography>
              </Button>
            </div>
            <div>
              {/*<IconButton color="inherit">
                <SearchIcon onClick={this.toggleModal.bind(this, true)} />
              </IconButton>*/}
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.modal}
                onClose={this.toggleModal.bind(this, false)}
              >
                <div className={classes.modal}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder={`${search} ...`}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                  />
                </div>
              </Modal>
              <IconButton onClick={this.toggleMenu.bind(this, true)} color="inherit" buttonRef={node => {
                this.anchorEl = node;
              }}>
                <MoreOptions />
              </IconButton>
              <Popper open={this.state.menu} anchorEl={this.anchorEl} transition style={{ zIndex: 4 }}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={this.toggleMenu.bind(this, false)}>
                        <MenuList onClick={this.toggleMenu.bind(this, false)}>
                          <MenuItem disabled><Link to="/profile" className={classes.menuLink}>{profile}</Link></MenuItem>
                          {role.label === 'root' ?
                            <MenuItem ><Link to="/settings" className={classes.menuLink}>Settings</Link></MenuItem>
                            :
                            null
                          }
                          <MenuItem onClick={this.handleSignOut.bind(this)}>{logout}</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NavBar));
