import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { getInvoices } from '../../../actions';
import InvoiceCard from './invoiceCard';
import InvoiceForm from './form';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import AddIcon from '@material-ui/icons/Add';
import Fade from '@material-ui/core/Fade';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import PageIcon from '@material-ui/icons/Pages';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  root: {
    width: '100%',
    minHeight: "90vh",
    [theme.breakpoints.up('sm')]: {
      //height: '90vh',
      overflowY: 'auto',
      height: "calc(100vh - 64px)"
    }
  },
  banner: {
    backgroundColor: 'white',
    paddingBottom: 8,
    paddingTop: 8,
    /* Banner fixed */
    width: '100%',
    top: '56px',
    zIndex: 2,
    position: 'fixed',
    /*--------------*/
    [theme.breakpoints.up('sm')]: {
      /* Banner fixed */
      top: '64px',
      width: 'calc(100% - 250px)',
      /*--------------*/
    }
  },
  title: {
    fontSize: 30
  },
  invoices: {
    marginTop: '68px',
    padding: '15px 15px 76px 15px',
    maxWidth: 1100,
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      padding: 15
    }
  },
  expandPanel: {
    margin: 0
  },
  expansion: {
    marginBottom: '10px'
  },
  expansionDetails: {
    padding: 0
  },
  expansionTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  expansionSummary: {
    padding: 0,
    height: 70
  },
  expandIcon: {
    marginRight: 6
  },
  divAnimation: {
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out'
  }
});

function mapDispatchToProps(dispatch) {
  return {
    getInvoices: (token, customer) => dispatch(getInvoices(token, customer))
  }
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    invoices: state.invoice.invoices
  }
};

class InvoiceArea extends React.Component {
  state = {
    invoices: [],
    expanded: 'panel1',
    show: false
  };

  componentDidMount() {
    this.fetchGet();
  };

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  async fetchGet() {
    const id = this.props.match.params.id;
    const token = 'token '.concat(this.props.token);
    await this.props.getInvoices(token, id);
    const show = this.props.invoices.length === 0 ? true : false;
    const expanded = `panel0`;
    this.setState({
      invoices: this.props.invoices,
      show,
      expanded
    });
  };

  handleShow() {
    this.setState({
      show: !this.state.show
    });
  };

  render() {
    const { classes, intl: { formatMessage }, intl, messages, customer, role, match } = this.props;
    const { expanded, invoices, show } = this.state;
    console.log(invoices)
    return (
      <div className={classes.root}>
        <div className={classes.banner}>
          <List component="ul">
            <ListItem >
              <ListItemIcon>
                <PageIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                Invoice
              </Typography>} />
              <ListItemSecondaryAction style={{ marginRight: 10 }}>
                <IconButton aria-label="Add" onClick={this.handleShow.bind(this, true)}>
                  {role.create ?
                    show ?
                      <ArrowLeftIcon //onClick={this.fetchGet.bind(this)} 
                      />
                      :
                      <AddIcon />
                    :
                    null}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
        <div className={classes.invoices}>
          {show ?
            <div className={classes.divAnimation}>
              <InvoiceForm
                intl={intl}
                messages={messages}
                match={match}
                fetchCard={this.fetchGet.bind(this)}
                showCard={this.handleShow.bind(this)}
              />
            </div>
            :
            <div className={classes.divAnimation}>
              {invoices.map((invoice, index) =>
                <Fade in={!show} timeout={500} key={invoice.id}>
                  <InvoiceCard
                    index={index}
                    invoice={invoice}
                    match={match}
                    change={this.handleChange.bind(this)}
                    intl={intl}
                    messages={messages}
                    expanded={expanded}
                    fetchCardArea={this.fetchGet.bind(this)}
                  />
                </Fade>
              )}
            </div>
          }
        </div>
      </div>
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InvoiceArea));
