import React from 'react';
import { addValve } from '../../../actions'
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import renderSelectFieldTest from '../../ui/select_field_test';
import renderCheckboxField from '../../ui/checkbox_field';
import renderFileInput from '../../ui/upload_field';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  banner: {
    paddingBottom: 8,
    paddingTop: 8
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  paper: {
    width: '100%',
    //padding: '0px 24px 15px',
    //height: '80vh',
    //display: 'flex',
    //alignItems: 'center'
  },
  form: {
    padding: '15px 24px 15px',
  },
  title: {
    fontSize: 25
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '24px'
  },
  link: {
    color: '#1562c6'
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    token: state.auth.token,
    loading: state.loader.loading
  }
};

function mapDispatchToProps(dispatch) {
  return {
    addValve: (values, token) => dispatch(addValve(values, token))
  }
};

const validate = (values, state) => {
  const { intl: { formatMessage }, messages } = state;
  const errors = {}

  if (!values.name) {
    errors.name = formatMessage(messages.signIn.required)
  }

  return errors
};

class ValveForm extends React.Component {
  state = {
    valveParts: {
      springs: [
        {
          label: "Spring 1",
          value: "spring 1"
        },
        {
          label: "Spring 2",
          value: "spring 2"
        }
      ],
      pistons: [
        {
          label: "Piston 1",
          value: "piston 1"
        },
        {
          label: "Piston 2",
          value: "piston 2"
        }
      ]
    }
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        //console.log(key)
      }

      return form_data;
    }
  };

  async submit(values) {
    const val = {
      ...values,
      company: this.props.company.url
    };

    console.log(val)

    const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a las valvulas
    4. Reload de valvulas*/


    await this.validateErrorsApi(data, token);
    this.props.showCard();
    this.props.fetchCard();
  };

  validateErrorsApi(data, token) {
    return this.props.addValve(data, token)
  };

  render() {
    const { valveParts } = this.state;
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading } = this.props;
    return (
      <Paper>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} enctype="multipart/form-data" id="myForm">
          <Grid container spacing={16}>
            <Grid item xs={12} className={classes.head}>
              <List component="ul">
                <ListItem disableGutters>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography component="h2" variant="h2" className={classes.title}> Add Valve </Typography>}
                  />
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12}>
              <Field name="body" component={renderCheckboxField} label="Body" ref="body" disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="spring"
                component={renderSelectFieldTest}
                options={valveParts.springs}
                label='Springs'
                ref="spring"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="piston"
                component={renderSelectFieldTest}
                options={valveParts.pistons}
                label='Pistons'
                ref="piston"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="description"
                component={renderTextField}
                label="Description"
                isTextArea={true}
                ref="description"
              />
            </Grid>
            <Grid item xs={12} >
              <Field
                name="advices"
                component={renderTextField}
                label="Advices"
                isTextArea={true}
                ref="advices"
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name="photo"
                component={renderFileInput}
                ref="photo"
                label="Photo"
                inputid="photo"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                {formatMessage(messages.customers.billFormSubmit)}
              </Typography>
            </Button>
          </div>
        </form>
      </Paper>
    )
  }
};

const reduxFormAddValve = reduxForm({
  form: 'addvalve',
  validate
})(ValveForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormAddValve));
