import { AUTHENTICATED, AUTHENTICATION_ERROR, UNAUTHENTICATED } from '../constants/action_types';
import { roles } from '../constants/roles';

const initialState = { redirectToReferrer: false };

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATED:
      let role = [];
      if (action.payload.is_superuser) {
        role = roles.find(role => role.label === 'root');
      } else {
        role = roles.filter(role => role.label === action.payload.user_type.name)[0];
      };
      //console.log(role[0]);
      return {
        ...state,
        authenticated: true,
        error: false,
        redirectToReferrer: true,
        token: action.payload.token,
        name: action.payload.name,
        user_type: role,
        dominio: action.payload.dominio,
        test: role
      };
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        authenticated: false,
        error: action.payload,
        redirectToReferrer: false
      }
    case UNAUTHENTICATED:
      return {
        ...state,
        authenticated: false,
        redirectToReferrer: false
      }
    default:
      return state;
  }
};

export default authReducer;
