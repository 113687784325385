import React, { Fragment } from 'react';
import Form from './form';

function LogIn(props) {
  const { intl, messages, history } = props;
  return (
    <Fragment>
      <Form history={history} messages={messages} intl={intl} />
    </Fragment>
  )
};

export default LogIn;
