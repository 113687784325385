import React, { Component } from 'react';
import { connect } from 'react-redux';
import LangBar from '../components/langbar';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import HOC from '../hoc/main';

import Divider from '@material-ui/core/Divider';
import GlobeIcon from '@material-ui/icons/Public';

export default function (ComposedComponent) {
  const styles = theme => ({
    '@keyframes fadeInOut': {
      '0%': {
        opacity: 0
      },
      '100%': {
        opacity: 1
      }
    },
    root: {
      flexGrow: 1,
      backgroundColor: 'white'
    },
    title: {
      margin: '30px 0px 30px 10px',
      fontSize: 30
    },
    grid: {
      padding: '0px 10px',
      maxWidth: 1200,
      margin: '0 auto',
      marginTop: 56,
      [theme.breakpoints.up('sm')]: {
        marginTop: 64
      },
      animation: 'fadeInOut 0.6s',
      animationTimingFunction: 'ease-in-out'
    }
  });

  function mapStateToProps(state) {
    return {
      name: state.auth.name,
    }
  };

  class DashboardLayout extends Component {
    state = {
      hello: ''
    };

    componentDidMount() {
      this.setMessage();
    };

    setMessage() {
      const dateHour = new Date().getHours();
      let hello;
      if (dateHour >= 12 && dateHour < 18) {
        hello = this.props.messages.dashboard.greetingTwo;
      } else if (dateHour < 12) {
        hello = this.props.messages.dashboard.greetingOne;
      } else {
        hello = this.props.messages.dashboard.greetingThree;
      }

      this.setState({ hello: this.props.intl.formatMessage(hello) });
    };

    capitalize(string) {
      const array = string.split(' ');
      let upperCaseArray = [];

      if (array.length > 1) {
        array.forEach(item =>
          upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
        );
        return upperCaseArray.join(' ');
      }

      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    render() {
      const { classes, intl, messages, history, firstName, lastName, name } = this.props;
      return (
        <div className={classes.root}>
          <Grid
            container
            spacing={0}
            direction='row'
            justify='center'
            alignItems='center'
            className={classes.grid}
          >
            <Grid item xs={12}>
              <Typography component="h2" variant="h2" className={classes.title}>
                {this.state.hello}, {name ? this.capitalize(name) : `${this.capitalize(name)}`}
              </Typography>
            </Grid>
            <ComposedComponent {...this.props} />
            {/*<Grid item xs={12} style={{ margin: '15px 0' }}>
              <Divider style={{ marginBottom: 15 }} />
              <GlobeIcon style={{ verticalAlign: 'middle', color: '#484848' }} />: <LangBar />
      </Grid>*/}
          </Grid>
        </div>
      )
    }
  }

  return HOC(connect(mapStateToProps)(withStyles(styles)(DashboardLayout)));
}
