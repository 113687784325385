import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { getValves } from '../../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import renderTextField from '../../../ui/text_field';
import renderFileInput from '../../../ui/upload_field';
import renderSelectFieldTest from '../../../ui/select_field_test';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  subtitle: {
    marginRight: 8,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)'
  },
  subContainer: {
    marginTop: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  subtitleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    marginRight: 8
  },
  iconText: {
    color: 'white'
  },
  divider: {
    width: '90%',
    flexShrink: 1
  },
  button: {
    margin: '0 10px 0 0',
    display: 'none'
  },
  closeIcon: {
    cursor: 'pointer',
    fontSize: '1rem',
    marginLeft: 8,
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
});

function mapStateToProps(state) {
  return {
    valves: state.valve.valves,
    dominio: state.auth.dominio,
    token: state.auth.token,
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getValves: (token, company) => dispatch(getValves(token, company))
  }
};

class RegisterForm extends React.Component {
  state = {
    valves: []
  };

  componentDidMount() {
    //this.props.fields.push({});
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getValves(token, this.props.dominio);

    const valves = [];
    this.props.valves.forEach(item => {
      let label;
      if (item.spring && item.piston) {
        label = `${item.spring} + ${item.piston}`
      } else if (item.spring || item.piston) {
        label = item.spring || item.piston
      } else {
        label = 'housing'
      };

      valves.push({
        label: label,
        value: item.url
      })
    });

    this.setState({
      valves: valves
    });
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { valves } = this.state;
    const { fields, meta: { touched, error, submitFailed }, classes } = this.props;
    const normalizeNumber = (value, previousValue) => {
      const onlyNums = value.replace(/[^\d]/g, '')
      return onlyNums
    };
    return (
      <Fragment>
        <Button className={classes.button} id="prueba" color='primary' variant="contained" onClick={() => fields.push({})}>
          Add
        </Button>
        {fields.map((register, index) => (
          <Grid container spacing={24} key={index}>
            <Grid item xs={12} className={classes.subContainer}>
              <div className={classes.subtitleContainer}>
                <div className={classes.icon}>
                  <Typography variant="body2" className={classes.iconText}>
                    {index + 1}
                  </Typography>
                </div>
                <Typography variant="body2" className={classes.subtitle} >
                  Register
                </Typography>
              </div>
              <Divider className={classes.divider} />
              <CloseIcon onClick={() => fields.remove(index)} className={classes.closeIcon} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`${register}[location]`}
                component={renderTextField}
                label="Location"
                ref={`${register}[location]`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`${register}[q_before]`}
                component={renderTextField}
                label="Q Before"
                ref={`${register}[q_before]`}
                normalize={normalizeNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`${register}[q_after]`}
                component={renderTextField}
                label="Q After"
                ref={`${register}[q_after]`}
                normalize={normalizeNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name={`${register}[valve]`}
                component={renderSelectFieldTest}
                label="Valve Type"
                ref={`${register}[valve]`}
                options={valves}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={`${register}[tools]`}
                component={renderTextField}
                label="Tools"
                ref={`${register}[tools]`}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={`${register}[observations]`}
                component={renderTextField}
                label="Observation"
                isTextArea={true}
                ref={`${register}[observations]`}
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name={`${register}.initial_photos`}
                component={renderFileInput}
                ref={`${register}.initial_photos`}
                label="Initial Photos"
                inputid={`${register}.initial_photos`}
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name={`${register}.end_photos`}
                component={renderFileInput}
                ref={`${register}.end_photos`}
                label="End Photos"
                inputid={`${register}.end_photos`}
              />
            </Grid>
          </Grid>
        ))}
      </Fragment>
    )
  }
};

const reduxFormUpdateRegister = reduxForm({
  form: 'updateregister'
})(RegisterForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateRegister));
