import React from 'react';
import { connect } from 'react-redux';
import { changeLanguage } from '../../actions';

import Typography from '@material-ui/core/Typography';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import GlobeIcon from '@material-ui/icons/Public';

function mapStateToProps(state) {
  return {
    locale: state.intl.locale
  }
};

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (value) => dispatch(changeLanguage(value))
  }
};

class SelectLanguage extends React.Component {
  state = {
    lang: ''
  };

  componentDidMount() {
    let text;
    if (this.props.locale === 'en') {
      text = 'Español'
    } else {
      text = 'English'
    };
    this.setState({
      lang: text
    });
  };

  switchLanguage() {
    if (this.props.locale === 'en') {
      this.props.changeLanguage('es');
    } else {
      this.props.changeLanguage('en');
    }
  };

  render() {
    const { lang } = this.state;
    return (
      <List>
        <ListItem button onClick={this.switchLanguage.bind(this)}>
          <ListItemIcon><GlobeIcon /></ListItemIcon>
          <ListItemText primary={<Typography >{lang}</Typography>} />
        </ListItem>
      </List>
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectLanguage);
