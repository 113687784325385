import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { recoverMail } from '../../actions';
import { injectIntl } from 'react-intl';

import { withStyles } from '@material-ui/core/styles';
import renderTextField from '../ui/text_field';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  title: {
    padding: '20px 0px 30px',
    textAlign: 'center'
  },
  subtitle: {
    padding: '0px 0px 15px',
    textAlign: 'center'
  },
  form: {
    width: '100%',
    marginTop: '10px',
    padding: '5px 24px 15px',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  formField: {
    margin: '10px 0'
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center'
  },
  logoImg: {
    width: 130,
    height: 53
  },
  link: {
    color: theme.palette.primary.main
  }
});

function mapStateToProps(state) {
  return {
    error: state.recover.error
  }
};

function mapDispatchToProps(dispatch) {
  return {
    recoverMail: (values) => dispatch(recoverMail(values))
  }
}

const validate = (values, state) => {
  const { intl: { formatMessage }, messages } = state;
  const errors = {};

  if (!values.email) {
    errors.email = formatMessage(messages.signIn.required)
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = formatMessage(messages.general.invalidMail);
  };

  return errors
};

class Form extends React.Component {
  submit(values) {
    this.props.recoverMail(values);
  }

  render() {
    const { handleSubmit, pristine, submitting, intl: { formatMessage }, classes, messages } = this.props;
    return (
      <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))}>
        <div className={classes.logo}>
          <img src='/images/ela-logo-2.png' alt="Ela Logo" alt="Ela Logo" className={classes.logoImg} />
        </div>
        <Typography component="h1" variant="h5" className={classes.title}>
          {formatMessage(messages.recovery.title)}
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          {formatMessage(messages.recovery.subtitle)}
        </Typography>
        <div className={classes.formField}>
          <Field
            name="email"
            component={renderTextField}
            label={formatMessage(messages.general.email)}
            newstyle='login'
          />
        </div>
        <div className={classes.formMeta}>
          <Link to="/login">
            <Typography variant='button' noWrap className={classes.link}>
              {formatMessage(messages.signIn.title)}
            </Typography>
          </Link>
          <Button color='primary' variant='contained' type="submit" disabled={pristine || submitting}>
            <Typography variant='button'>
              {formatMessage(messages.general.send)}
            </Typography>
          </ Button>
        </div>
      </form>
    )
  }
};

const reduxFormRecover = reduxForm({
  form: 'recover',
  validate
})(Form);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withStyles(styles)(reduxFormRecover)));
