import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { addBooking, deleteBooking, updateBooking } from '../../actions';

import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../ui/text_field';
import renderSelectFieldTest from '../ui/select_field_test';
import universalDateTimePicker from '../ui/universal_date_time_picker';
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';

const styles = theme => ({
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    padding: '15px 24px 15px',
    overflowY: 'scroll'
  },
  title: {
    fontSize: 25
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    margin: '0 5px'
  },
  uploadButton: {
    marginTop: 18
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
});

function mapStateToProps(state) {
  return {
    role: state.auth.user_type,
    customers: state.customer.customers,
    sellers: state.employee.sellers,
    token: state.auth.token
  }
};

function mapDispatchToProps(dispatch) {
  return {
    addBooking: (values, token) => dispatch(addBooking(values, token)),
    deleteBooking: (token, id) => dispatch(deleteBooking(token, id)),
    updateBooking: (data, token, id) => dispatch(updateBooking(data, token, id))
  }
};

class ModalForm extends Component {
  state = {
    customers: [],
    sellers: [],
    customerLabel: '',
    sellerLabel: ''
  };

  componentDidMount() {
    this.fetchGet();
  };

  fetchGet() {
    const customers = [];
    const sellers = [];
    let sellerFilter = '';
    let customerFilter = '';

    this.props.customers.forEach(item =>
      customers.push({
        label: this.capitalize(item.name),
        value: item.url
      })
    );
    this.props.sellers.forEach(item =>
      sellers.push({
        label: `${this.capitalize(item.first_name)} ${this.capitalize(item.last_name)}`,
        value: item.url
      })
    );

    if (this.props.initialValues.customer !== '') {
      sellers.length > 1 ? sellerFilter = sellers.filter(seller => seller.value !== this.props.initialValues.seller)[0].label : sellerFilter = sellers[0].label;
      customers.length > 1 ? customerFilter = customers.filter(customer => customer.value !== this.props.initialValues.customer)[0].label : customerFilter = customers[0].label
    };

    this.setState({
      customers: customers,
      sellers: sellers,
      sellerLabel: sellerFilter,
      customerLabel: customerFilter
    });
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  async submit(values) {
    const token = 'token '.concat(this.props.token);

    if (this.props.initialValues.customer === '') {
      const val = {
        ...values,
        customer: values.customer.value,
        seller: values.seller.value,
        start_time: moment(values.start).format(),
        end_time: moment(values.end).format(),
        //date: '2000-10-10'
      };
      await this.validateErrorsApiPost(val, token);
      this.props.handleClose(); //cierro modal
    } else {
      const val = {
        ...values,
        start_time: moment(values.start).format(),
        end_time: moment(values.end).format()
      };
      /* Evitar valored nulos */
      for (const key in val) {
        let value = val[key];
        if (value === undefined || value === null) {
          delete val[key]
        };
      }
      await this.validateErrorsApiPut(val, token, this.props.identification);
      this.props.handleClose(); //cierro modal
    }
    this.props.fetchCalendar();
  };

  async deleteData() {
    const token = 'token '.concat(this.props.token);
    const id = this.props.identification;
    await this.props.deleteBooking(token, id);
    this.props.handleClose();
    this.props.fetchCalendar();
  };

  validateErrorsApiPost(data, token) {
    return this.props.addBooking(data, token);
  };

  validateErrorsApiPut(data, token, id) {
    return this.props.updateBooking(data, token, id);
  };

  render() {
    const { open, handleClose, classes, intl: { formatDate }, role, handleSubmit, pristine, submitting } = this.props;
    const { customers, sellers, sellerLabel, customerLabel } = this.state;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={isWidthDown('sm', this.props.width)}
      >
        <DialogContent style={isWidthDown('sm', this.props.width) ? { paddingTop: 10 } : {}}>
          <div className={classes.closeIcon}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))}>
            <Grid container spacing={24}>
              <Grid item xs={12} className={classes.head}>
                <List component="ul">
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                      Appointment
                  </Typography>} />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="type_booking"
                  component={renderTextField}
                  label="Type Booking"
                  ref="type_booking"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="description"
                  component={renderTextField}
                  label="Description"
                  ref="description"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="status"
                  component={renderTextField}
                  label="Status"
                  ref="status"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="customer"
                  component={renderSelectFieldTest}
                  options={customers}
                  label="Customer"
                  ref="customer"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="seller"
                  component={renderSelectFieldTest}
                  options={sellers}
                  label="Seller"
                  ref="seller"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="start"
                  component={universalDateTimePicker}
                  label='Start Date'
                  ref="start"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="end"
                  component={universalDateTimePicker}
                  label='End Date'
                  ref="end"
                />
              </Grid>
            </Grid>
            <div className={classes.formMeta}>
              {role.delete && this.props.initialValues.customer !== '' ?
                <Button className={classes.button} color='secondary' variant="contained" onClick={this.deleteData.bind(this)}>
                  <Typography variant='button' noWrap>
                    Delete
                  </Typography>
                </Button>
                :
                null
              }
              <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={(pristine || submitting)}>
                <Typography variant='button' noWrap>
                  Submit
                </Typography>
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    );
  };
};

const reduxFormCalendar = reduxForm({
  form: 'formcalendar'
})(ModalForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(reduxFormCalendar)));
