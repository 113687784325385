import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { updateInvoice, deleteInvoice, getBills } from '../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import renderSelectField from '../../ui/select_field';
import renderSelectFieldTest from '../../ui/select_field_test';
import renderFileInput from '../../ui/upload_field';
import Button from '@material-ui/core/Button';

import universalDatePicker from '../../ui/universal_date_picker';
import ModalDialog from '../../modal';

const styles = theme => ({
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    //padding: '0px 24px 15px',
  },
  expansionTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  button: {
    margin: '0 5px'
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    role: state.auth.user_type,
    token: state.auth.token,
    loading: state.loader.loading,
    bills: state.bill.bills
  }
};

function mapDispatchToProps(dispatch) {
  return {
    updateInvoice: (values, token, id) => dispatch(updateInvoice(values, token, id)),
    deleteInvoice: (token, id) => dispatch(deleteInvoice(token, id)),
    getBills: (token, customer) => dispatch(getBills(token, customer))
  }
};

class InvoiceEdit extends React.Component {
  state = {
    bills: [],
    open: false
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const id = this.props.match.params.id;
    const token = 'token '.concat(this.props.token);
    await this.props.getBills(token, id);

    const bills = [];
    this.props.bills.forEach(item =>
      bills.push({
        label: item.bill_date,
        value: item.url
      })
    );

    this.setState({
      bills
    });
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        //console.log(key)
      }

      return form_data;
    }
  };

  async submit(values) {
    const val = values;

    if (val.utility_company) {
      val.utility_company = values.utility_company
    };

    if (val.date) {
      if (typeof val.date === 'object') {
        val.date = values.date.toISOString().slice(0, 10)
      } else {
        val.date = values.date.split('/').join('-')
      };
    }

    if (val.due_date) {
      if (typeof val.due_date === 'object') {
        val.due_date = values.due_date.toISOString().slice(0, 10)
      } else {
        val.due_date = values.due_date.split('/').join('-')
      };
    }

    /* Evitar valored nulos */
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    /*---------------------*/

    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    //const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;

    await this.validateErrorsApi(val, token, id);
    this.props.edit();
    await this.props.fetchArea();
  };

  validateErrorsApi(val, token, id) {
    return this.props.updateInvoice(val, token, id);
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleOpen(open) {
    this.setState({
      open: open
    })
  };

  async deleteData() {
    this.handleOpen(false);
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;
    await this.props.deleteInvoice(token, id);
    this.props.fetchArea();
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading, role } = this.props;
    const { open, bills } = this.state;
    return (
      <Fragment>
        <ModalDialog
          open={open}
          title="Are you sure you want to delete this invoice ?"
          description="This action directly affects the database and is irreversible."
          actionName="Delete"
          handleOpen={this.handleOpen.bind(this)}
          handleAction={this.deleteData.bind(this)}
        />
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))}
        //encType="multipart/form-data" id="myForm"
        >
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <Field
                name="utility_bill"
                component={renderSelectFieldTest}
                options={bills}
                label="Utility Bill"
                ref="utility_bill"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="number"
                component={renderTextField}
                label="Number"
                ref="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="date"
                component={universalDatePicker}
                label="Date"
                ref="date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="due_date"
                component={universalDatePicker}
                label="Due Date"
                ref="due_date"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Typography variant='button' noWrap className={classes.link} onClick={this.props.edit}>
              Return to Invoice
            </Typography>
            <div>
              {role.delete ?
                <Button className={classes.button} color='secondary' variant="contained" onClick={this.handleOpen.bind(this, true)} disabled={loading}>
                  <Typography variant='button' noWrap>
                    Delete
                </Typography>
                </Button>
                :
                null
              }
              <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
                <Typography variant='button' noWrap>
                  Update
                </Typography>
              </Button>
            </div>
          </div>
        </form>
      </Fragment>
    )
  }
};

const reduxFormUpdateInvoice = reduxForm({
  form: 'updateinvoice',
})(InvoiceEdit);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateInvoice));
