import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import MapIcon from '@material-ui/icons/Map';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

const styles = theme => ({
  card: {
    /* Prueba */
    width: '100%',
    maxWidth: '250px',
    /*--------*/
    flexDirection: 'column',
    alignItems: 'center',
    //width: 320,
    //minHeight: '100vh',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      backgroundColor: 'white'
    }
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center'
  },
  title: {
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem'
    }
  },
  content: {
    flex: '1 0 auto',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bigAvatar: {
    margin: 10,
    width: 120,
    height: 120
  },
  links: {
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    padding: '5px 0px',
  },
  link: {
    padding: '15px'
  },
  linkIcon: {
    verticalAlign: 'middle',
    color: theme.palette.newGrey.main
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingBottom: '24px'
  },
  iconButton: {
    width: 48,
    height: 48
  },
  buttonsLinks: {
    color: 'inherit'
  },
  list: {
    width: '100%'
  },
  color: {
    color: theme.palette.primary.main
  }
});

class PageInfo extends React.Component {
  state = {
    match: '',
    info: {},
    list: [],
    newList: [],
    root: '',
    value: 0
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value,
      info: nextProps.info
    });
  };

  componentWillMount() {
    this.newList(this.props.list);
    this.setState({
      match: this.props.match,
      info: this.props.info,
      list: this.props.list,
      root: this.props.root,
      value: this.props.value
    })
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  newList(list) {
    const newList = list.filter(item => item.url !== 'profile' && item.url !== '');
    this.setState({
      newList: newList
    });
  };

  render() {
    const { classes, intl: { formatMessage }, messages } = this.props;
    const { match, info, list, root, value, newList } = this.state;
    //console.log(value)
    return (
      <Card className={classes.card} elevation={0} square>
        <div className={classes.links}>
          <Typography variant="subtitle1" className={classes.link}>
            <Link to={`/${root}`}>
              <ArrowLeftIcon classes={{ root: classes.linkIcon }} />
            </Link>
          </Typography>
        </div>
        <div className={classes.avatar}>
          <Link to={`${match.url}/profile`} onClick={this.props.changeValue.bind(this, 5)}>
            {info.photo ?
              <Avatar alt="avatar" src={info.photo} className={classes.bigAvatar} />
              :
              <Avatar alt="avatar" src="/images/no-photo.png" className={classes.bigAvatar} />
            }
          </Link>
        </div>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h2" variant="h5" className={classes.title}>
              {info.name ? this.capitalize(info.name) : `${this.capitalize(info.first_name)} ${this.capitalize(info.last_name)}`}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" >
              {info.identification_type.toUpperCase()} : {info.identification}
            </Typography>
          </CardContent>
        </div>
        <div className={classes.buttons}>
          <IconButton classes={{ root: classes.iconButton }} disabled={info.email ? false : true}>
            <a href={`mailto: ${info.email}`} className={classes.buttonsLinks}>
              <EmailIcon />
            </a>
          </IconButton>
          <IconButton classes={{ root: classes.iconButton }} disabled={info.phone ? false : true}>
            <a href={`tel: ${info.phone}`} className={classes.buttonsLinks}>
              <PhoneIcon />
            </a>
          </IconButton>
          <IconButton classes={{ root: classes.iconButton }} disabled={info.address ? false : true}>
            <a target="_blank" rel="noopener noreferrer" href="https://maps.google.com/?q=barranquilla" className={classes.buttonsLinks}>
              <MapIcon />
            </a>
          </IconButton>
        </div>
        <List className={classes.list}>
          {newList.map((item, index) => (
            <Fragment key={index} >
              <Divider />
              <Link to={`${match.url}/${item.url}`} onClick={this.props.changeValue.bind(this, item.id)}>
                <ListItem button  >
                  <ListItemIcon className={value === item.id ? classes.color : null} >{item.icon}</ListItemIcon>
                  <ListItemText classes={value === item.id ? { primary: classes.color } : null} primary={item.text} />
                </ListItem>
              </Link>
            </Fragment>
          ))}
        </List>
      </Card>
    )
  };
};

export default withStyles(styles)(PageInfo);
