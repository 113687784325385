import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { updateBill, deleteBill, getUtilityCompanies } from '../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import renderSelectField from '../../ui/select_field';
import renderSelectFieldTest from '../../ui/select_field_test';
import renderFileInput from '../../ui/upload_field';
import Button from '@material-ui/core/Button';

import universalDatePicker from '../../ui/universal_date_picker';
import ModalDialog from '../../modal';

const styles = theme => ({
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    //padding: '0px 24px 15px',
  },
  expansionTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  button: {
    margin: '0 5px'
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    role: state.auth.user_type,
    token: state.auth.token,
    loading: state.loader.loading,
    utilityCompanies: state.utilityCompany.utilityCompanies
  }
};

function mapDispatchToProps(dispatch) {
  return {
    updateBill: (values, token, id) => dispatch(updateBill(values, token, id)),
    deleteBill: (token, id) => dispatch(deleteBill(token, id)),
    getUtilityCompanies: (token) => dispatch(getUtilityCompanies(token))
  }
};

class BillEdit extends React.Component {
  state = {
    utilityCompanies: [],
    open: false
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getUtilityCompanies(token);

    const utilityCompanies = [];
    this.props.utilityCompanies.forEach(item =>
      utilityCompanies.push({
        label: this.capitalize(item.name),
        value: item.url
      })
    );

    this.setState({
      utilityCompanies: utilityCompanies
    });
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        //console.log(key)
      }

      return form_data;
    }
  };

  async submit(values) {
    const val = values;

    if (val.utility_company) {
      val.utility_company = values.utility_company
    };

    if (val.bill_date) {
      if (typeof val.bill_date === 'object') {
        val.bill_date = values.bill_date.toISOString().slice(0, 10)
      } else {
        val.bill_date = values.bill_date.split('/').join('-')
      };
    }

    if (val.cut_off_date) {
      if (typeof val.cut_off_date === 'object') {
        val.cut_off_date = values.cut_off_date.toISOString().slice(0, 10)
      } else {
        val.cut_off_date = values.cut_off_date.split('/').join('-')
      };
    }

    if (val.cubic_meters) {
      val.cubic_meters = parseInt(values.cubic_meters, 10)
    };

    if (typeof val.bill_support !== 'object') {
      delete val.bill_support
    }

    if (val.charges) {
      val.charges = '{ "a": "a" }'
    };

    /* Evitar valored nulos */
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    /*---------------------*/

    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;

    await this.validateErrorsApi(data, token, id);
    this.props.edit();
    await this.props.fetchArea();
  };

  validateErrorsApi(val, token, id) {
    return this.props.updateBill(val, token, id);
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleOpen(open) {
    this.setState({
      open: open
    })
  };

  async deleteData() {
    this.handleOpen(false)
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;
    await this.props.deleteBill(token, id);
    this.props.fetchArea();
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading, role } = this.props;
    const { open, utilityCompanies } = this.state;
    const normalizeNumber = (value, previousValue) => {
      const onlyNums = value.replace(/[^\d]/g, '')
      return onlyNums
    }
    return (
      <Fragment>
        <ModalDialog
          open={open}
          title="Are you sure you want to delete this bill ?"
          description="This action directly affects the database and is irreversible."
          actionName="Delete"
          handleOpen={this.handleOpen.bind(this)}
          handleAction={this.deleteData.bind(this)}
        />
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} encType="multipart/form-data" id="myForm">
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <Field
                name="bill_number"
                component={renderTextField}
                label="Bill Number"
                ref="bill_number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="bill_reference"
                component={renderTextField}
                label="Bill Reference"
                ref="bill_reference"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="bill_date"
                component={universalDatePicker}
                label="Bill Date"
                ref="bill_date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="cut_off_date"
                component={universalDatePicker}
                label="Cut Off Date"
                ref="cut_off_date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="billing_cycle"
                component={renderTextField}
                label="Billing Cycle"
                ref="billing_cycle"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="cubic_meters"
                component={renderTextField}
                label={formatMessage(messages.customers.billFormCubic)}
                ref="cubic_meters"
                normalize={normalizeNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="hydrometer"
                component={renderTextField}
                label="Hydrometer"
                ref="hydrometer"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="utility_company"
                component={renderSelectFieldTest}
                options={utilityCompanies}
                clearable={true}
                label={formatMessage(messages.customers.billFormUtility)}
                ref="utility_company"
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name="bill_support"
                component={renderFileInput}
                ref="bill_support"
                label="Bill Support"
                inputid="bill_support"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Typography variant='button' noWrap className={classes.link} onClick={this.props.edit}>
              Return to Bill
            </Typography>
            <div>
              {role.delete ?
                <Button className={classes.button} color='secondary' variant="contained" onClick={this.handleOpen.bind(this, true)} disabled={loading}>
                  <Typography variant='button' noWrap>
                    Delete
                </Typography>
                </Button>
                :
                null
              }
              <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
                <Typography variant='button' noWrap>
                  Update
                </Typography>
              </Button>
            </div>
          </div>
        </form>
      </Fragment>
    )
  }
};

const reduxFormUpdateBill = reduxForm({
  form: 'updatebill',
})(BillEdit);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateBill));
