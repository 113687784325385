import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { updateBooking, deleteBooking, getEmployees } from '../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import Button from '@material-ui/core/Button';
import renderSelectFieldTest from '../../ui/select_field_test';
import universalDateTimePicker from '../../ui/universal_date_time_picker';
import ModalDialog from '../../modal';

const styles = theme => ({
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    //padding: '0px 24px 15px',
  },
  expansionTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  button: {
    margin: '0 5px'
  }
});

function mapStateToProps(state) {
  return {
    error: state.booking.error,
    role: state.auth.user_type,
    token: state.auth.token,
    loading: state.loader.loading,
    dominio: state.auth.dominio,
    sellers: state.employee.sellers
  }
};

function mapDispatchToProps(dispatch) {
  return {
    updateBooking: (values, token, id) => dispatch(updateBooking(values, token, id)),
    deleteBooking: (token, id) => dispatch(deleteBooking(token, id)),
    getEmployees: (token, company) => dispatch(getEmployees(token, company))
  }
};

class BookingEdit extends React.Component {
  state = {
    open: false,
    sellers: [],
    options: [
      {
        label: 'Pending',
        value: 'Pending'
      },
      {
        label: 'Done',
        value: 'Done'
      }
    ]
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const token = 'token '.concat(this.props.token);
    await this.props.getEmployees(token, this.props.dominio);

    const sellers = [];
    this.props.sellers.forEach(item =>
      sellers.push({
        label: this.capitalize(item.first_name) + ' ' + this.capitalize(item.last_name),
        value: item.url
      })
    );

    this.setState({
      sellers
    });
  };

  async submit(values) {
    const val = values;

    /* Evitar valored nulos */
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    /*---------------------*/

    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso a los bills
    4. Reload de bills*/

    const token = 'token '.concat(this.props.token);
    const id = this.props.index;

    await this.validateErrorsApi(val, token, id);
    this.props.edit();
    this.props.fetchArea();
  };

  validateErrorsApi(val, token, id) {
    return this.props.updateBooking(val, token, id);
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleOpen(open) {
    this.setState({
      open: open
    })
  };

  async deleteData() {
    this.handleOpen(false)
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;
    await this.props.deleteBooking(token, id);
    this.props.fetchArea();
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading, role, error } = this.props;
    const { open, options, sellers } = this.state;
    console.log(this.props.initialValues)
    return (
      <Fragment>
        <ModalDialog
          open={open}
          title="Are you sure you want to delete this appointment ?"
          description="This action directly affects the database and is irreversible."
          actionName="Delete"
          handleOpen={this.handleOpen.bind(this)}
          handleAction={this.deleteData.bind(this)}
        />
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} /*encType="multipart/form-data"*/>
          <Grid container spacing={24}>
            {error ?
              <Grid item xs={12}>
                <Typography color="error">
                  error
              </Typography>
              </Grid>
              :
              null
            }
            {/*<Grid item xs={12} sm={6}>
              <Field
                name="status"
                component={renderSelectField}
                options={options}
                clearable={true}
                label="Status"
                ref="status"
                is={statusValue}
              />
            </Grid>*/}
            <Grid item xs={12}>
              <Field
                name="type_booking"
                component={renderTextField}
                label="Type of booking"
                ref="type_booking"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="description"
                component={renderTextField}
                label="Description"
                ref="description"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="status"
                component={renderTextField}
                label="Status"
                ref="status"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="seller"
                component={renderSelectFieldTest}
                options={sellers}
                label="Seller"
                ref="seller"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="start_time"
                component={universalDateTimePicker}
                label='Start Date'
                ref="start_time"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name="end_time"
                component={universalDateTimePicker}
                label='End Date'
                ref="end_time"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Typography variant='button' noWrap className={classes.link} onClick={this.props.edit}>
              Return to Booking
            </Typography>
            <div>
              {role.delete ?
                <Button className={classes.button} color='secondary' variant="contained" onClick={this.handleOpen.bind(this, true)} disabled={loading}>
                  <Typography variant='button' noWrap>
                    Delete
                </Typography>
                </Button>
                :
                null
              }
              <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
                <Typography variant='button' noWrap>
                  Update
                </Typography>
              </Button>
            </div>
          </div>
        </form>
      </Fragment>
    )
  }
};

const reduxFormUpdateBooking = reduxForm({
  form: 'updatebooking',
})(BookingEdit);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateBooking));
