import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
//import { changeSideList } from '../../../actions';
import { url_employees } from '../../../constants/links';
import ListCard from './listCard';

import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import BuildingIcon from '@material-ui/icons/AccountBalance';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
  root: {
    padding: '5px 10px 15px',
    maxWidth: 1200,
    margin: '0 auto'
  },
  icon: {
    color: theme.palette.newGrey.main,
    marginLeft: '3px'
  },
  tableWrapper: {
    overflowX: 'auto',
    //height: '80vh'
  },
  tableWrapperLoading: {
    overflowX: 'auto',
    //height: '80vh',
    position: 'relative',
    minHeight: '75vh'
  },
  circularProgress: {
    position: 'absolute',
    top: "50%",
    margin: '0px 50%'
  },
  toolbar: {
    //padding: theme.spacing.unit,
    padding: 0,
    whiteSpace: 'nowrap'
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    marginLeft: 10,
    fontSize: 30
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    transition: theme.transitions.create('width'),
    width: 0,
    '&:focus': {
      width: 50,
      [theme.breakpoints.up(370)]: {
        width: 90
      },
      [theme.breakpoints.up('sm')]: {
        width: 200
      }
    }
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    dominio: state.auth.dominio
  }
};

/*function mapDispatchToProps(dispatch) {
  return {
    changeSideList: (page, info) => dispatch(changeSideList(page, info))
  }
};*/

class ListSellers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      data: [],
      loading: true,
      page_size: 5,
      next: `${url_employees}?page_size=100`,
      previous: '',
      scrolling: false,
      noResult: false
    };
    this.textInput = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  };

  componentDidMount() {
    this.fetchGet();
    window.addEventListener('scroll', this.handleScroll);

    //console.log(document.querySelector('div#list'))
  };

  componentWillUnmount() {
    //console.log('desmontado');
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = () => {
    const { scrolling, count, data } = this.state;
    //if (scrolling) return console.log('ya');
    if (count <= data.length) return
    const lastItem = document.querySelector('ul#sellers > div:last-child');
    const lastItemOffset = lastItem.offsetTop + lastItem.clientHeight;
    const pageOffset = window.pageYOffset + window.innerHeight;
    let bottomOffset = 200;
    if (pageOffset > lastItemOffset - bottomOffset) this.loadMore();
  };

  fetchGet() {
    const { page_size, data, next } = this.state;
    const token = 'token '.concat(this.props.token);
    axios.get(next,
      {
        headers: { Authorization: token },
        params: { company: this.props.dominio }
      })
      .then(response => {
        if (response.data.results.length === 0) { this.setState({ noResult: true }) }
        this.setState({
          data: [...data, ...response.data.results],
          page_size: page_size + 5,
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          scrolling: false,
          loading: false
        })
      })
      .catch((error) => {
        this.setState({ loading: false, noResult: true });
        console.log('error ' + error);
      });
  };

  loadMore() {
    this.setState(prevState => ({
      scrolling: true
    }), this.fetchGet)
  };

  handleSearch(event) {
    event.persist();
    this.setState({ loading: true });
    let params;
    const token = 'token '.concat(this.props.token);
    if (event.target.value === '') {
      params = {
        headers: { Authorization: token },
        params: { page_size: 100, company: this.props.dominio }
      }
    } else {
      params = {
        headers: { Authorization: token },
        params: { query: event.target.value, company: this.props.dominio }
      }
    }
    axios.get(url_employees, params)
      .then(response => {
        //console.log(response.data.results);
        if (response.data.results.length === 0) {
          this.setState({
            noResult: true,
            loading: false
          })
          //console.log('aja')
        } else {
          this.setState({
            data: response.data.results,
            loading: false,
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            noResult: false
          })
        }
      })
      .catch((error) => {
        console.log('error ' + error);
        this.setState({ loading: false });
      });
  };

  focus() {
    //console.log('intentando')
    this.textInput.current.focus();
    //this.toggleMenu(false)
  };

  render() {
    const { intl: { formatMessage }, intl, classes, match, messages, role, history } = this.props;
    const { loading, data, noResult } = this.state;
    const sellers = formatMessage(messages.navBar.sellers);
    //console.log(data)
    //this.props.changeSideList('dashboard', {});//update drawer navbar
    return (
      <div className={classes.root} >
        <Toolbar className={classes.toolbar}>
          <BuildingIcon className={classes.icon} />
          <Typography component="h2" variant="h2" className={classes.title}>
            {sellers}
          </Typography>
          <div className={classes.spacer} />
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={this.handleSearch.bind(this)}
            inputRef={this.textInput}
          />
          <Tooltip title="Sort">
            <IconButton aria-label="Sort" >
              <SearchIcon onClick={this.focus.bind(this)} />
            </IconButton>
          </Tooltip>
          {role.create ?
            <Tooltip title="Add seller">
              <Link to={`${match.url}/signup`}>
                <IconButton aria-label="Add seller">
                  <AddIcon />
                </IconButton>
              </Link>
            </Tooltip>
            :
            null
          }
        </Toolbar>
        <div className={loading ? classes.tableWrapperLoading : classes.tableWrapper} id="list">
          {loading ?
            <CircularProgress className={classes.circularProgress} />
            :
            noResult ?
              <Typography variant="h5" style={{ position: "absolute", top: "50%", left: '45%', textAlign: "center" }}>
                No results
              </Typography>
              :
              <ListCard intl={intl} messages={messages} data={data} history={history} />
          }
        </div>
      </div>
    );
  }
}


export default connect(mapStateToProps)(withStyles(styles)(ListSellers));
