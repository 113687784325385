import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import BillForm from './billForm';
import BillUpload from './billUpload';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import RotateIcon from '@material-ui/icons/RotateRight';
import AddIcon from '@material-ui/icons/Add';

import { url_bills } from '../../../../constants/links';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  paper: {
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out'
  },
  div: {
    width: '100%',
    padding: '15px 24px 15px'
  },
  bigButtons: {
    cursor: 'pointer',
    borderRadius: 4,
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    //border: `1px dashed ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.primaryRgba.light
    }
  },
  icon: {
    color: theme.palette.newGrey.main,
    fontSize: '2rem'
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    customer: state.customer.customer,
  }
};

class BillOptions extends React.Component {
  state = {
    show: '',
    utilityCompany: {},
    previousBill: {}
  };

  componentDidMount() {
    this.fetchGet();
    this.getPrevBill();
  };

  fetchGet() {
    const token = 'token '.concat(this.props.token);
    axios.get(this.props.customer.utility_company,
      {
        headers: { Authorization: token }
      })
      .then(response => {
        //console.log(response.data);
        this.setState({
          utilityCompany: {
            label: this.capitalize(response.data.name),
            value: response.data.url
          }
        })
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  };

  async getPrevBill() {
    const token = 'token '.concat(this.props.token);
    const id = this.props.customer.id;
    const params = { headers: { Authorization: token }, params: { customer: id } }
    await axios.get(url_bills, params)
      .then(response => {
        const data = response.data.results[0];
        if (data) {
          this.setState({
            previousBill: data
          });
        } else {
          this.setState({
            previousBill: ''
          });
        }
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleShow(show) {
    this.setState({
      show: show
    });
  };

  render() {
    const { utilityCompany, previousBill } = this.state;
    const { messages, intl, classes, customer } = this.props;
    return (
      <Paper className={classes.paper}>
        {this.state.show === 'add' ?
          <BillForm
            intl={intl}
            messages={messages}
            customer={customer}

            initialValues={previousBill !== '' ? {
              ...previousBill,
              bill_number: '',
              billing_cycle: '',
              bill_date: moment(previousBill.bill_date).add(1, 'month'),
              cut_off_date: moment(previousBill.cut_off_date).add(1, 'month')
            } : {
                ...customer,
              }}
            utilityCompanyValue={utilityCompany.label}
            fetchCard={this.props.fetchCardArea}
            showCard={this.props.showCardArea}
            showOptions={this.handleShow.bind(this)}
          />
          :
          this.state.show === 'upload' ?
            <BillUpload
              intl={intl}
              messages={messages}
              showOptions={this.handleShow.bind(this)}
            />
            :
            <div className={classes.div}>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={6} >
                  <div className={classes.bigButtons} /*onClick={this.handleShow.bind(this, 'upload')}*/>
                    <RotateIcon className={classes.icon} />
                    <Typography style={{ padding: 10 }}>
                      Upload PDF Files (This section will be available soon)
                  </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.bigButtons} onClick={this.handleShow.bind(this, 'add')}>
                    <AddIcon className={classes.icon} />
                    <Typography style={{ padding: 10 }}>
                      Add Bill
                  </Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
        }
      </Paper>
    )
  }
};

export default connect(mapStateToProps)(withStyles(styles)(BillOptions));
