import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { updateAccessory, deleteAccessory } from '../../../actions';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ModalDialog from '../../modal';

const styles = theme => ({
  icon: {
    color: theme.palette.newGrey.main
  },
  banner: {
    paddingBottom: 8,
    paddingTop: 8
  },
  title: {
    fontSize: 25
  },
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    padding: '0px 24px 15px',
  },
  expansionTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    color: '#1562c6',
    cursor: 'pointer'
  },
  button: {
    margin: '0 5px'
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type
  }
};

function mapDispatchToProps(dispatch) {
  return {
    updateAccessory: (values, token, id) => dispatch(updateAccessory(values, token, id)),
    deleteAccessory: (token, id) => dispatch(deleteAccessory(token, id))
  }
};

class InventoryEdit extends React.Component {
  state = {
    open: false
  };

  async submit(values) {
    const val = values;

    if (val.quantity) {
      val.quantity = parseInt(values.quantity, 10)
    };

    /* Evitar valores nulos*/
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    //console.log(val);
    /*---------------------*/

    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso al inventario
    4. Reload de inventario*/

    const token = 'token '.concat(this.props.token);
    const id = this.props.index;

    await this.validateErrorsApi(val, token, id);
    this.props.edit();
    this.props.fetchCard();
  };

  validateErrorsApi(val, token, id) {
    return this.props.updateAccessory(val, token, id);
  };

  handleOpen(open) {
    this.setState({
      open: open
    })
  };

  async deleteData() {
    this.handleOpen(false)
    const token = 'token '.concat(this.props.token);
    const id = this.props.index;
    await this.props.deleteAccessory(token, id);
    this.props.fetchCard();
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading, userType, role } = this.props;
    const { open } = this.state;
    return (
      <Fragment>
        <ModalDialog
          open={open}
          title="Are you sure you want to delete this accessory ?"
          description="This action directly affects the database and is irreversible."
          actionName="Delete"
          handleOpen={this.handleOpen.bind(this)}
          handleAction={this.deleteData.bind(this)}
        />
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} /*encType="multipart/form-data" id="myForm"*/>
          <div item xs={12} className={classes.banner}>
            <List component="ul">
              <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
                <ListItemIcon>
                  <AssignmentIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography component="h2" variant="h2" className={classes.title}> Add Valve </Typography>}
                />
              </ListItem>
            </List>
          </div>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={6}>
              <Field
                name="name"
                component={renderTextField}
                label="Name"
                ref="name"
              />
            </Grid>
            <Grid item xs={12} sm={6} >
              <Field
                name="description"
                component={renderTextField}
                label="Description"
                ref="description"
              />
            </Grid>
            <Grid item xs={12} sm={6} >
              <Field
                name="quantity"
                component={renderTextField}
                label="Quantity"
                ref="quantity"
                type="number"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Typography variant='button' noWrap className={classes.link} onClick={this.props.edit}>
              Return to Valve
            </Typography>
            <div>
              {role.delete ?
                <Button className={classes.button} color='secondary' variant="contained" onClick={this.handleOpen.bind(this, true)} disabled={loading}>
                  <Typography variant='button' noWrap>
                    Delete
                </Typography>
                </Button>
                :
                null
              }
              <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
                <Typography variant='button' noWrap>
                  Update
                </Typography>
              </Button>
            </div>
          </div>
        </form>
      </Fragment>
    )
  }
};

const reduxFormUpdateAccessory = reduxForm({
  form: 'updateaccessory',
})(InventoryEdit);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateAccessory));
