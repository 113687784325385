import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import BookingEdit from './bookingEdit';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 18,
    padding: "0px 7px",
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  expansion: {
    marginBottom: '10px',
    '&:before': {
      display: 'none',
    }
  },
  expansionExpanded: {
    margin: '0 0 10px'
  },
  expansionTitle: {
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  titleSwitch: {
    height: 45, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
  },
  subTitle: {
    display: "inline"
  },
  summary: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  secondaryHeading: {
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  detailsTitle: {
    display: 'inline'
  },
  editButton: {
    padding: 5,
    marginLeft: 5
  },
  editIcon: {
    fontSize: '1rem'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type
  }
};

class BillCard extends React.Component {
  state = {
    editBooking: false,
    values: {}
  };

  handleEdit() {
    this.setState({
      editBooking: !this.state.editBooking
    });
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  formatValues(booking) {
    //console.log(moment(booking.start_time).format());
    const values = {
      ...booking,
      //date: booking.start_time.split('-').join('/')
    }

    return values;
  };

  render() {
    const { editBooking, seller } = this.state;
    const { expanded, booking, intl: { formatMessage, formatNumber, formatDate }, intl, messages, classes, index, loading, role } = this.props;
    return (
      <Fragment>
        {loading && editBooking ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <ExpansionPanel expanded={expanded === `panel${index}`} onChange={this.props.change(`panel${index}`)} square={false} classes={{ root: classes.expansion, expanded: classes.expansionExpanded }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.summary}>
              {editBooking ?
                <div className={classes.titleContainer}>
                  <AssignmentIcon className={classes.icon} />
                  <Typography component="h2" variant="h2" className={classes.title}>
                    Update Booking
                  </Typography>
                </div>
                :
                <Fragment>
                  <Typography variant="h3" className={classes.expansionTitle}>
                    <span>
                      {/*formatMessage(messages.customers.abill)*/}
                    </span>
                    <div className={classes.titleSwitch}>
                      <b className={classes.subTitle}>{booking.type_booking}</b>
                      <Typography variant="body2" color="textSecondary" className={classes.secondaryHeading}>
                        Status: {booking.status}
                      </Typography>
                    </div>
                  </Typography>
                  <Typography style={{ display: 'flex', alignItems: 'center ' }} className={classes.secondaryHeading} color="textSecondary" noWrap>{formatDate(booking.start_time, { year: "numeric", month: "long", day: "2-digit", timeZone: 'GMT' })}</Typography>
                </Fragment>
              }
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              spacing={24}
              direction='row'
              justify='space-between'
              alignItems='flex-start'
            >
              {editBooking ?
                <Grid item xs={12}>
                  <BookingEdit
                    intl={intl}
                    messages={messages}
                    initialValues={this.formatValues(booking)}
                    edit={this.handleEdit.bind(this)}
                    fetchArea={this.props.fetchCard}
                    index={booking.id}
                  />
                </Grid>
                :
                <Fragment>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.detailsTitle}>
                      Booking details
                    </Typography>
                    {role.update ?
                      <IconButton aria-label="edit" classes={{ root: classes.editButton }}>
                        <EditIcon classes={{ root: classes.editIcon }} onClick={this.handleEdit.bind(this)} />
                      </IconButton>
                      :
                      null
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" >
                      {booking.description}
                    </Typography>
                  </Grid>
                </Fragment>
              }
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Fragment>
    )
  }
};

export default connect(mapStateToProps)(withStyles(styles)(BillCard));
