import React from 'react';
import BillForm from './billForm';
import BookingForm from './appointmentForm';
import axios from 'axios';
import moment from 'moment';
import { url_bills } from '../../../../constants/links';

import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = theme => ({
  tabContainer: {
    //margin: '20px 0',
    overflowY: 'auto'
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
});

class ModalForm extends React.Component {
  state = {
    value: 0,
    previousBill: {}
  };

  componentWillReceiveProps(nextProps) {
    this.getPrevBill(nextProps.data);
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  async getPrevBill(customer) {
    const token = 'token '.concat(this.props.token);
    const id = customer.id;
    const params = { headers: { Authorization: token }, params: { customer: id } }
    await axios.get(url_bills, params)
      .then(response => {
        const data = response.data.results[0];
        if (data) {
          this.setState({
            previousBill: data,
            value: 0
          });
        } else {
          this.setState({
            previousBill: '',
            value: 0
          });
        }
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  };

  render() {
    const { open, data, classes, intl, messages } = this.props;
    const { value, previousBill } = this.state;
    return (
      <div>
        <Dialog
          open={open}
          onClose={this.props.handleModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen={isWidthDown('sm', this.props.width)}
        >
          <DialogContent style={isWidthDown('sm', this.props.width) ? { paddingTop: 10 } : {}}>
            <div className={classes.closeIcon}>
              <IconButton onClick={this.props.handleModal}>
                <CloseIcon />
              </IconButton>
            </div>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleChange}
              centered
            >
              <Tab label="Add Appointment" />
              <Tab label="Add Bill" />
            </Tabs>
            {value === 0 && <div className={classes.tabContainer}>
              <BookingForm
                intl={intl}
                messages={messages}
                customer={data}
              />
            </div>}
            {value === 1 && <div className={classes.tabContainer}>
              <BillForm
                customer={data}
                intl={intl}
                messages={messages}
                initialValues={previousBill !== '' ? {
                  ...previousBill,
                  bill_number: '',
                  billing_cycle: '',
                  bill_date: moment(previousBill.bill_date).add(1, 'month'),
                  cut_off_date: moment(previousBill.cut_off_date).add(1, 'month')
                } : {
                    ...data,
                  }}
              />
            </div>}
            {value === 2 && <div className={classes.tabContainer}>Item Three</div>}
          </DialogContent>
        </Dialog>
      </div>
    );
  };
};

export default withStyles(styles)(withWidth()(ModalForm));
