import {
  GET_BILLS,
  GET_BILLS_ERROR,
  ADD_BILL,
  ADD_BILL_ERROR,
  UPDATE_BILL,
  UPDATE_BILL_ERROR,
  DELETE_BILL,
  DELETE_BILL_ERROR
} from '../constants/action_types';

const initialState = {};

const billReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BILLS:
      return {
        ...state,
        bills: action.payload,
      };
    case GET_BILLS_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_BILL:
      return {
        ...state,
        error: false,
      };
    case ADD_BILL_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_BILL:
      return {
        ...state,
        error: false,
      }
    case UPDATE_BILL_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_BILL:
      return {
        ...state,
        error: false,
      }
    case DELETE_BILL_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default billReducer;
