export const AUTHENTICATED = "AUTHENTICATED";
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR";
export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const RECOVERY_SUCCESS = "RECOVERY_SUCCESS";
export const RECOVERY_ERROR = "RECOVERY_ERROR";
export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const CHANGE_SIDE_LIST = "CHANGE_SIDE_LIST";
export const CHANGE_SELECT_COMPANY = "CHANGE_SELECT_COMPANY";
export const SWITCH_LOADER = "SWITCH_LOADER";
export const GET_EMPLOYEE = "GET_EMPLOYEE";
export const GET_EMPLOYEE_ERROR = "GET_EMPLOYEE_ERROR";
export const SIGN_UP_EMPLOYEE = "SIGN_UP_EMPLOYEE";
export const SIGN_UP_EMPLOYEE_ERROR = "SIGN_UP_EMPLOYEE_ERROR";
export const UPDATE_EMPLOYEE_PROFILE = "UPDATE_EMPLOYEE_PROFILE";
export const UPDATE_EMPLOYEE_PROFILE_ERROR = "UPDATE_EMPLOYEE_PROFILE_ERROR";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE_ERROR = "DELETE_EMPLOYEE_ERROR";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_ERROR = "GET_CUSTOMERS_ERROR";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_ERROR = "GET_CUSTOMER_ERROR";
export const SIGN_UP_CUSTOMER = "SIGN_UP_CUSTOMER";
export const SIGN_UP_CUSTOMER_ERROR = "SIGN_UP_CUSTOMER_ERROR";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_ERROR = "DELETE_CUSTOMER_ERROR"
export const UPDATE_CUSTOMER_PROFILE = "UPDATE_CUSTOMER_PROFILE";
export const UPDATE_CUSTOMER_PROFILE_ERROR = "UPDATE_CUSTOMER_PROFILE_ERROR";
export const DELETE_BILL = "DELETE_BILL";
export const DELETE_BILL_ERROR = "DELETE_BILL_ERROR";
export const GET_BILLS = "GET_BILLS";
export const GET_BILLS_ERROR = "GET_BILLS_ERROR";
export const ADD_BILL = "ADD_BILL";
export const ADD_BILL_ERROR = "ADD_BILL_ERROR";
export const UPDATE_BILL = "UPDATE_BILL";
export const UPDATE_BILL_ERROR = "UPDATE_BILL_ERROR";
export const ADD_UTILITY_COMPANY = "ADD_UTILITY_COMPANY";
export const ADD_UTILITY_COMPANY_ERROR = "ADD_UTILITY_COMPANY_ERROR";
export const DELETE_UTILITY_COMPANY = "DELETE_UTILITY_COMPANY";
export const DELETE_UTILITY_COMPANY_ERROR = "DELETE_UTILITY_COMPANY_ERROR";
export const UPDATE_UTILITY_COMPANY = "UPDATE_UTILITY_COMPANY";
export const UPDATE_UTILITY_COMPANY_ERROR = "UPDATE_UTILITY_COMPANY_ERROR";
export const GET_COMPANY = "GET_COMPANY";
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR";
export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_ERROR = "ADD_COMPANY_ERROR";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const UPDATE_COMPANY_ERROR = "UPDATE_COMPANY_ERROR";
export const GET_COMMISSIONS = "GET_COMMISSIONS";
export const GET_COMMISSIONS_ERROR = "GET_COMMISSIONS_ERROR";
export const ADD_COMMISSION = "ADD_COMMISSION";
export const ADD_COMMISSION_ERROR = "ADD_COMMISSION_ERROR";
export const UPDATE_COMMISSION = "UPDATE_COMMISSION";
export const UPDATE_COMMISSION_ERROR = "UPDATE_COMMISSION_ERROR";
export const DELETE_COMMISSION = "DELETE_COMMISSION";
export const DELETE_COMMISSION_ERROR = "DELETE_COMMISSION_ERROR";
export const ADD_BUSINESS_TYPE = "ADD_BUSINESS_TYPE";
export const ADD_BUSINESS_TYPE_ERROR = "ADD_BUSINESS_TYPE_ERROR";
export const UPDATE_BUSINESS_TYPE = "UPDATE_BUSINESS_TYPE";
export const UPDATE_BUSINESS_TYPE_ERROR = "UPDATE_BUSINESS_TYPE_ERROR";
export const DELETE_BUSINESS_TYPE = "DELETE_BUSINESS_TYPE";
export const DELETE_BUSINESS_TYPE_ERROR = "DELETE_BUSINESS_TYPE_ERROR";
export const GET_VALVE = "GET_VALVE";
export const GET_VALVE_ERROR = "GET_VALVE_ERROR";
export const GET_VALVES = "GET_VALVES";
export const GET_VALVES_ERROR = "GET_VALVES_ERROR";
export const ADD_VALVE = "ADD_VALVE";
export const ADD_VALVE_ERROR = "ADD_VALVE_ERROR";
export const UPDATE_VALVE = "UPDATE_VALVE";
export const UPDATE_VALVE_ERROR = "UPDATE_VALVE_ERROR";
export const DELETE_VALVE = "DELETE_VALVE";
export const DELETE_VALVE_ERROR = "DELETE_VALVE_ERROR";
export const GET_ACCESSORIES = "GET_ACCESSORIES";
export const GET_ACCESSORIES_ERROR = "GET_ACCESSORIES_ERROR";
export const ADD_ACCESSORY = "ADD_ACCESSORY";
export const ADD_ACCESSORY_ERROR = "ADD_ACCESSORY_ERROR";
export const UPDATE_ACCESSORY = "UPDATE_ACCESSORY";
export const UPDATE_ACCESSORY_ERROR = "UPDATE_ACCESSORY_ERROR";
export const DELETE_ACCESSORY = "DELETE_ACCESSORY"
export const DELETE_ACCESSORY_ERROR = "DELETE_ACCESSORY_ERROR";
export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_ERROR = "GET_CITIES_ERROR";
export const GET_BUSINESS_TYPES = "GET_BUSINESS_TYPES";
export const GET_BUSINESS_TYPES_ERROR = "GET_BUSINESS_TYPES_ERROR";
export const GET_STATUS_LIST = 'GET_STATUS_LIST';
export const GET_STATUS_LIST_ERROR = 'GET_STATUS_LIST_ERROR';
export const GET_STATUS = "GET_STATUS";
export const GET_STATUS_ERROR = "GET_STATUS_ERROR";
export const GET_SELLER_SELECT = "GET_SELLER_SELECT";
export const GET_SELLER_SELECT_ERROR = "GET_SELLER_SELECT_ERROR";
export const GET_SERVICE_CLASSES = "GET_SERVICE_CLASSES";
export const GET_SERVICE_CLASSES_ERROR = "GET_SERVICE_CLASSES_ERROR";
export const GET_UTILITY_COMPANIES = "GET_UTILITY_COMPANIES";
export const GET_UTILITY_COMPANIES_ERROR = "GET_UTILITY_COMPANIES_ERROR";
export const GET_BOOKINGS = "GET_BOOKINGS";
export const GET_BOOKINGS_ERROR = "GET_BOOKINGS_ERROR"
export const ADD_BOOKING = "ADD_BOOKING";
export const ADD_BOOKING_ERROR = "ADD_BOOKING_ERROR";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const UPDATE_BOOKING_ERROR = "UPDATE_BOOKING_ERROR"
export const DELETE_BOOKING = "DELETE_BOOKING";
export const DELETE_BOOKING_ERROR = "DELETE_BOOKING_ERROR";
export const GET_ANALYSIS_INFO = "GET_ANALYSIS_INFO";
export const GET_ANALYSIS_INFO_ERROR = "GET_ANALYSIS_INFO_ERROR";
export const GET_REGISTERS = "GET_REGISTERS";
export const GET_REGISTERS_ERROR = "GET_REGISTERS_ERROR";
export const ADD_REGISTER = "ADD_REGISTER";
export const ADD_REGISTER_ERROR = "ADD_REGISTERS_ERROR";
export const UPDATE_REGISTER = "UPDATE_REGISTER";
export const UPDATE_REGISTER_ERROR = "UPDATE_REGISTER_ERROR";
export const DELETE_REGISTER = "DELETE_REGISTER";
export const DELETE_REGISTER_ERROR = "DELETE_REGISTER_ERROR";
export const GET_VALVE_PARTS = "GET_VALVE_PARTS";
export const GET_VALVE_PARTS_ERROR = "GET_VALVE_PARTS_ERROR";
export const ADD_VALVE_PART = "ADD_VALVE_PART";
export const ADD_VALVE_PART_ERROR = "ADD_VALVE_PART_ERROR";
export const UPDATE_VALVE_PART = "UPDATE_VALVE_PART";
export const UPDATE_VALVE_PART_ERROR = "UPDATE_VALVE_PART_ERROR";
export const DELETE_VALVE_PART = "DELETE_VALVE_PART";
export const DELETE_VALVE_PART_ERROR = "DELETE_VALVE_PART_ERROR";
export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICES_ERROR = "GET_INVOICES_ERROR";
export const ADD_INVOICE = "ADD_INVOICE";
export const ADD_INVOICE_ERROR = "ADD_INVOICE_ERROR";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const UPDATE_INVOICE_ERROR = "UPDATE_INVOICE_ERROR";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICE_ERROR = "DELETE_INVOICE_ERROR";
export const UPDATE_INSTALLATION_REPORT_DETAIL = "UPDATE_INSTALLATION_REPORT_DETAIL";
export const UPDATE_INSTALLATION_REPORT_DETAIL_ERROR = "UPDATE_INSTALLATION_REPORT_DETAIL_ERROR";
export const DELETE_INSTALLATION_REPORT_DETAIL = "DELETE_INSTALLATION_REPORT_DETAIL";
export const DELETE_INSTALLATION_REPORT_DETAIL_ERROR = "DELETE_INSTALLATION_REPORT_DETAIL_ERROR";
export const GET_INSTALLATION_SUMMARY = "GET_INSTALLATION_SUMMARY";
export const GET_INSTALLATION_SUMMARY_ERROR = "GET_INSTALLATION_SUMMARY_ERROR";
export const GENERATE_PDF = "GENERATE_PDF";
export const GENERATE_PDF_ERROR = "GENERATE_PDF_ERROR";
export const CHANGE_CUSTOMERS_STATE = "CHANGE_CUSTOMERS_STATE";
