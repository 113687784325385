import React from 'react';

class ErrorPage extends React.Component {
  render() {
    return (
      <h1>ERROR 404</h1>
    )
  };
};

export default ErrorPage;
