import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Link } from 'react-router-dom';
import { getCompany } from '../../actions';
import BottomNav from '../bottomNav';
import Profile from './profile';
import UtilityCompany from './utilityCompany';
import BusinessType from './businessType';
import Valves from './valves';
import Commissions from './commissions';
import Inventory from './inventory';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import BusinessIcon from '@material-ui/icons/Business';
import CompanyIcon from '@material-ui/icons/BusinessCenter';
import ValveIcon from '@material-ui/icons/DeviceHub';
import CommissionIcon from '@material-ui/icons/AccountBalanceWallet';
import InventoryIcon from '@material-ui/icons/Layers';
import CircularProgress from '@material-ui/core/CircularProgress';
import PersonIcon from '@material-ui/icons/Person';
import MoreOptions from '@material-ui/icons/MoreVert';

const styles = theme => ({
  indexContainerRoot: {
    display: 'flex'
  },
  indexContainer: {
    //padding: '5px 10px 15px',
    maxWidth: 1200,
    margin: '0 auto'
  },
  cardRoot: {
    /* Prueba */
    width: '100%',
    maxWidth: '250px',
    /*--------*/
    flexDirection: 'column',
    alignItems: 'center',
    //width: 320,
    //minHeight: '100vh',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      backgroundColor: 'white'
    }
  },
  card: {
    display: 'none'
  },
  title: {
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem'
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    padding: '30px 0'
  },
  bigAvatar: {
    margin: '0 10px',
    width: 60,
    height: 60
  },
  list: {
    width: '100%'
  },
  workArea: {
    //minHeight: '126vh',
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    width: '100%'
  },
  color: {
    color: theme.palette.primary.main
  }
});

function mapDispatchToProps(dispatch) {
  return {
    getCompany: (token, id) => dispatch(getCompany(token, id))
  }
};

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    company: state.company.company
  }
};

class SettingsIndex extends React.Component {
  state = {
    company: {},
    loading: false,
    value: 0,
    newList: []
  };

  componentDidMount() {
    /*--- Focus the value in bottom navigation ---*/
    let value = null;
    if (window.location.pathname.includes('/utilityCompanies')) {
      value = 0;
    } else if (window.location.pathname.includes('/businessType')) {
      value = 1;
    } else if (window.location.pathname.includes('/valves')) {
      value = 2;
    } else if (window.location.pathname.includes('/commissions')) {
      value = 6;
    } else if (window.location.pathname.includes('/inventory')) {
      value = 7;
    };
    this.setState({
      value: value
    });
    /*--------------------------------- */
    this.fetchGet();
  };

  async fetchGet() {
    const id = this.props.match.params.id;
    const token = 'token '.concat(this.props.token);
    await this.props.getCompany(token, id);
    const company = { ...this.props.company, name: this.capitalize(this.props.company.name) };
    this.setState({
      company,
      loading: false
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  changeValue(index) {
    this.setState({
      value: index
    })
  };

  newList(list) {
    const newList = list.filter(item => item.url !== 'profile' && item.url !== '');
    return newList
  };

  render() {
    const { history, match, classes, intl, intl: { formatMessage }, messages, role } = this.props;
    const { company, loading, value } = this.state;

    const list = [
      {
        text: 'Profile',
        url: 'profile',
        icon: <PersonIcon />,
        label: 'Profile',
        id: 5
      },
      {
        text: 'Utility Companies',
        url: 'utilityCompanies',
        icon: <CompanyIcon />,
        label: 'Companies',
        id: 0
      },
      {
        text: 'Business Types',
        url: 'businessTypes',
        icon: <BusinessIcon />,
        label: 'B. Types',
        id: 1
      },
      {
        text: 'Valves',
        url: 'valves',
        icon: <ValveIcon />,
        label: 'Valves',
        id: 2
      },
      {
        text: 'More',
        url: '',
        icon: <MoreOptions />,
        label: 'More',
        id: 3
      },
      {
        text: 'Commissions',
        url: 'commissions',
        icon: <CommissionIcon />,
        label: 'Commissions',
        id: 6
      },
      {
        text: 'Inventory',
        url: 'inventory',
        icon: <InventoryIcon />,
        label: 'Inventory',
        id: 7
      },
    ];

    const newList = this.newList(list);

    return (
      <Fragment>
        {loading ?
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <CircularProgress />
          </div>
          :
          <div /*className={role.select ? classes.indexContainerRoot : classes.indexContainer}*/ className={classes.indexContainerRoot}>
            <Card /*className={role.select ? classes.cardRoot : classes.card}*/ elevation={0} square className={classes.cardRoot}>
              <CardContent className={classes.content}>
                <Link to={match.url}>
                  <Avatar alt="avatar" src={company.photo} className={classes.bigAvatar} />
                </Link>

                <Typography noWrap component="h2" variant="h5" className={classes.title}>
                  {company.name}
                </Typography>
              </CardContent>
              <List className={classes.list}>
                {newList.map((item, index) => (
                  <Fragment key={index}>
                    <Divider />
                    <Link to={`${match.url}/${item.url}`} onClick={this.changeValue.bind(this, item.id)}>
                      <ListItem button  >
                        <ListItemIcon className={value === item.id ? classes.color : null}>{item.icon}</ListItemIcon>
                        <ListItemText classes={value === item.id ? { primary: classes.color } : null} primary={item.text} />
                      </ListItem>
                    </Link>
                  </Fragment>
                ))}
              </List>
            </Card>
            <Grid
              container
              spacing={0}
              direction='row'
              justify='space-evenly'
              alignItems='flex-start'
            >
              <Grid item xs={12} sm={12} className={classes.workArea}>
                <Switch>
                  <Route exact path={`${match.path}`} render={(props) => <Profile {...props} intl={intl} messages={messages} fetchSettingsIndex={this.fetchGet.bind(this)} />} />
                  <Route path={`${match.path}/utilityCompanies`} render={(props) => <UtilityCompany {...props} intl={intl} messages={messages} />} />
                  <Route path={`${match.path}/businessTypes`} render={(props) => <BusinessType {...props} intl={intl} messages={messages} company={company} />} />
                  <Route path={`${match.path}/valves`} render={(props) => <Valves {...props} intl={intl} messages={messages} company={company} />} />
                  <Route path={`${match.path}/commissions`} render={(props) => <Commissions {...props} intl={intl} messages={messages} company={company} />} />
                  <Route path={`${match.path}/inventory`} render={(props) => <Inventory {...props} intl={intl} messages={messages} company={company} />} />
                </Switch>
              </Grid>
            </Grid>
          </div>
        }
        <BottomNav value={value} data={list} match={match} history={history} changeValue={this.changeValue.bind(this)} />
      </Fragment>
    )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SettingsIndex));
