import {
  GET_BOOKINGS,
  GET_BOOKINGS_ERROR,
  ADD_BOOKING,
  ADD_BOOKING_ERROR,
  UPDATE_BOOKING,
  UPDATE_BOOKING_ERROR,
  DELETE_BOOKING,
  DELETE_BOOKING_ERROR
} from '../constants/action_types';

const initialState = {bookings:[]};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BOOKINGS:
      return {
        ...state,
        bookings: action.payload,
      };
    case GET_BOOKINGS_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_BOOKING:
      return {
        ...state,
        error: false,
      };
    case ADD_BOOKING_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_BOOKING:
      return {
        ...state,
        error: false,
      }
    case UPDATE_BOOKING_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_BOOKING:
      return {
        ...state,
        error: false,
      }
    case DELETE_BOOKING_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state;
  }
};

export default bookingReducer;
