import React from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import BusinessIcon from '@material-ui/icons/Business';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
  toolbar: {
    //padding: theme.spacing.unit,
    padding: 0,
    whiteSpace: 'nowrap'
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    marginLeft: 10,
    fontSize: 30
  },
  icon: {
    color: theme.palette.newGrey.main,
    marginLeft: '3px'
  },
  arrowIcon: {
    fontSize: '18px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px'
    }
  }
});

class ToolBarTitle extends React.Component {
  render() {
    const { classes, title, url, tooltip } = this.props;
    return (
      <Toolbar className={classes.toolbar}>
        <BusinessIcon className={classes.icon} />
        <Typography component="h2" variant="h2" className={classes.title}>
          {title}
        </Typography>
        <div className={classes.spacer} />
        <Tooltip title={tooltip}>
          <Link to={url}>
            <IconButton aria-label={tooltip}>
              <AddIcon />
            </IconButton>
          </Link>
        </Tooltip>
      </Toolbar>
    )
  };
};

export default withStyles(styles)(ToolBarTitle);
