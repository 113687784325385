export const roles = [
  {
    label: 'root',
    create: true,
    update: true,
    delete: true,
    select: true,
    selectCalendar: true
  },
  {
    label: 'admin',
    create: true,
    update: true,
    delete: true,
    select: false,
    selectCalendar: true
  },
  {
    label: 'employee1',
    create: true,
    update: true,
    delete: false,
    select: false,
    selectCalendar: false
  }
];  
