import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormFirstPage from './formFirstPage';
import FormSecondPage from './formSecondPage';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  paper: {
    width: '100%',
    padding: '5px 24px 15px',
    maxWidth: 1200,
    margin: '0 auto'
  },
  head: {
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    marginTop: 30,
    fontSize: 30
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  }
});

function mapStateToProps(state) {
  return {
    error: state.form.signupCustomer,
    loading: state.loader.loading
  }
};

class Form extends Component {
  state = {
    step: 0,
    steps: ['Basic Information', 'Analysis Information']
  };

  nextStep() {
    this.setState({
      step: this.state.step + 1
    });
  };

  prevStep() {
    this.setState({
      step: this.state.step - 1
    });
  };

  handleStep(step) {
    this.setState({
      step: step
    });
  };


  isStepFailed(step) {
    const { error } = this.props;
    if (error) {
      if (error.submitErrors) {
        if ((error.submitErrors.identification && step === 0) || (error.submitErrors.identification_type && step === 0)) {
          return true
        };
      }
    }
  };

  componentWillUnmount() {
    //Limpiar el form en el store
  };

  render() {
    const { step, steps, open } = this.state;
    const { intl, intl: { formatMessage }, messages, error, classes, loading } = this.props;
    return (
      <div>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <div className={classes.paper}>
          <div className={classes.head}>
            <Typography component="h2" variant="h2" className={classes.title}>
              {formatMessage(messages.customers.formTitle)} Customer
            </Typography>
          </div>
          <Stepper nonLinear activeStep={step} style={{ width: '100%', maxWidth: 800, margin: '0 auto' }}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel error={this.isStepFailed(index)} /*onClick={this.handleStep.bind(this, index)}*/>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {step === 0 && <FormFirstPage onSubmit={this.nextStep.bind(this)} intl={intl} messages={messages} />}
            {step === 1 && <FormSecondPage history={this.props.history} onSubmit={this.nextStep.bind(this)} intl={intl} messages={messages} previousStep={this.prevStep.bind(this)} />}
          </div>
        </div>
      </div>
    );
  };
};

export default connect(mapStateToProps)(withStyles(styles)((Form)));
