import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { changeLanguage } from '../../actions';

import withStyles from '@material-ui/core/styles/withStyles';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';

function mapStateToProps(state) {
  return {
    locale: state.intl.locale
  }
};

function mapDispatchToProps(dispatch) {
  return {
    changeLanguage: (value) => dispatch(changeLanguage(value))
  }
};

const styles = theme => ({
  div: {
    margin: '0px 24px',
    [theme.breakpoints.up('sm')]: {
      margin: '10px 0px 0px'
    }
  },
  langBar: {
    fontSize: 12,
    margin: 0
  },
  input: {
    '&:after': {
      borderBottom: 'none',
    },
    '&:before': {
      borderBottom: 'none',
    }
  }
});

class LangBar extends React.Component {
  handleChange(event) {
    this.props.changeLanguage(event.target.value);
  };

  render() {
    const { classes, page } = this.props;
    return (
      <div id="language" style={{ display: 'inline-block' }} className={page === 'login' ? classes.div : null}>
        <Select
          disableUnderline
          value={this.props.locale === 'en' ? 'English' : 'Español'}
          onChange={this.handleChange.bind(this)}
          name="language"
          renderValue={value => `${value}`}
          className={classes.langBar}
          input={<Input classes={{ underline: classes.input }} />}
        >
          <MenuItem value='es'>
            <Typography variant='caption'>
              Español
            </Typography>
          </MenuItem>
          <MenuItem value='en'>
            <Typography variant='caption'>
              English
            </Typography>
          </MenuItem>
        </Select>
      </div >
    )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)((LangBar)));
