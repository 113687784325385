import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import CommissionEdit from './commissionEdit';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import EditIcon from '@material-ui/icons/Edit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import RecIcon from '@material-ui/icons/FiberManualRecord';

const styles = theme => ({
  '@keyframes dotScale': {
    '0%': {
      transform: 'scale(1)'
    },
    '25%': {
      transform: 'scale(1.1)'
    },
    '50%': {
      transform: 'scale(1)'
    },
    '75%': {
      transform: 'scale(1.1)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  },
  greenDot: {
    animation: '4.0s infinite dotScale',
    //color: '#1ec69f',
    color: 'green'
  },
  redDot: {
    color: theme.palette.secondary.main,
    animation: '4.0s infinite dotScale'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 18,
    padding: "0px 7px",
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  expansion: {
    marginBottom: '10px',
    '&:before': {
      display: 'none',
    }
  },
  expansionExpanded: {
    margin: '0 0 10px'
  },
  expansionDetails: {
    padding: 0
  },
  expansionTitle: {
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  expansionSummary: {
    padding: 0,
    height: 70
  },
  expandIcon: {
    marginRight: 6
  },
  titleSwitch: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  },
  subTitle: {
    //display: "inline",
    marginLeft: 10
  },
  summary: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  secondaryHeading: {
    fontSize: 12,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  lists: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  billDetailsTitle: {
    display: 'inline'
  },
  editButton: {
    padding: 5,
    marginLeft: 5
  },
  editIcon: {
    fontSize: '1rem',
    display: 'none'
  },
  lastGridItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type
  }
};

class CommissionsCard extends React.Component {
  state = {
    edit: false,
    values: {},
    userType: {}
  };

  componentDidMount() {
    this.fetchGet();
  };

  fetchGet() {
    const token = 'token '.concat(this.props.token);
    axios.get(this.props.data.user_type,
      {
        headers: { Authorization: token }
      })
      .then(response => {
        //console.log(response.data);
        this.setState({
          userType: {
            label: this.capitalize(response.data.name),
            value: response.data.url
          }
        })
      })
      .catch((error) => {
        console.log('error ' + error);
      });
  };

  handleEdit() {
    this.setState({
      edit: !this.state.edit
    });
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { edit, userType } = this.state;
    const { expanded, data, intl: { formatMessage, formatNumber, formatDate }, intl, messages, classes, index, loading, role } = this.props;
    return (
      <Fragment>
        {loading && edit ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <ExpansionPanel expanded={expanded === `panel${index}`} onChange={this.props.change(`panel${index}`)} square={false} classes={{ root: classes.expansion, expanded: classes.expansionExpanded }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.summary}>
              {edit ?
                <div className={classes.titleContainer}>
                  <AssignmentIcon className={classes.icon} />
                  <Typography component="h2" variant="h2" className={classes.title}>
                    Update Commission
                  </Typography>
                </div>
                :
                <Fragment>
                  <Typography variant="h3" className={classes.expansionTitle}>
                    {/*<span className={classes.titleSwitch}>
                      Type:
                    </span>*/}
                    <RecIcon className={data.active ? classes.greenDot : classes.redDot} />
                    <b className={classes.subTitle}>{userType.label}</b>
                  </Typography>
                  <Typography className={classes.secondaryHeading} color="textSecondary" noWrap>
                    {formatNumber(data.value / 100, { style: "percent" })}
                  </Typography>
                </Fragment>
              }
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid
              container
              spacing={24}
              direction='row'
              justify='space-between'
              alignItems='flex-start'
            >
              {edit ?
                <Grid item xs={12}>
                  <CommissionEdit
                    intl={intl}
                    messages={messages}
                    initialValues={data}
                    userType={userType.label}
                    edit={this.handleEdit.bind(this)}
                    fetchCard={this.props.fetchCard}
                    index={data.id}
                  />
                </Grid>
                :
                <Fragment>
                  <Grid item xs={12}>
                    <Typography variant="body2" className={classes.billDetailsTitle}>
                      {data.description}
                    </Typography>
                    <IconButton aria-label="edit" classes={{ root: classes.editButton }}>
                      <EditIcon classes={{ root: classes.editIcon }} onClick={this.handleEdit.bind(this)} />
                    </IconButton>
                  </Grid>
                  {role.update ?
                    <Grid item xs={12} className={classes.lastGridItem}>
                      <Typography variant='button' noWrap className={classes.link} onClick={this.handleEdit.bind(this)}>
                        Edit
                    </Typography>
                    </Grid>
                    :
                    null
                  }
                </Fragment>
              }
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Fragment>
    )
  }
};

export default connect(mapStateToProps)(withStyles(styles)(CommissionsCard));
