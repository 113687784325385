import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getBookings, getCustomers, getEmployees } from '../../actions';
import CalendarView from './calendarView'

import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import EventIcon from '@material-ui/icons/Event';

const styles = theme => ({
  root: {
    padding: '5px 10px 15px',
    maxWidth: 1200,
    margin: '0 auto'
  },
  icon: {
    color: theme.palette.newGrey.main,
    marginLeft: '3px'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  toolbar: {
    padding: 0,
    whiteSpace: 'nowrap'
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    marginLeft: 10,
    fontSize: 30
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    bookings: state.booking.bookings,
    dominio: state.auth.dominio
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getBookings: (token) => dispatch(getBookings(token)),
    getEmployees: (token, company) => dispatch(getEmployees(token, company)),
    getCustomers: (token, company) => dispatch(getCustomers(token, company))
  }
};

class Calendar extends Component {
  state = {
    loading: false,
    appointments: [],
    selectCompany: 3
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectCompany: nextProps.dominio
    });
  };

  componentDidMount() {
    if (this.props.dominio) {
      this.setState({
        selectCompany: this.props.dominio
      });
    };
    this.fetchGet(this.state.selectCompany);
  };

  async fetchGet(dominio) {
    this.setState({
      loading: true
    })
    const token = 'token '.concat(this.props.token);
    if (this.props.role.selectCalendar) {
      await Promise.all([
        this.props.getCustomers(token, dominio),
        this.props.getEmployees(token, dominio)
      ]);
    };
    await this.props.getBookings(token);
    this.setState({
      appointments: this.props.bookings,
      loading: false
    });
  };

  render() {
    const { classes, match, intl } = this.props;
    const { appointments, loading } = this.state;
    return (
      <div className={classes.root} >
        <Toolbar className={classes.toolbar}>
          <EventIcon className={classes.icon} />
          <Typography component="h2" variant="h2" className={classes.title}>
            Calendar
          </Typography>
          {/*<div className={classes.spacer} />
          <Tooltip title="Add appointment">
              <IconButton aria-label="Add appointment">
                <AddIcon />
              </IconButton>
         </Tooltip>*/}
        </Toolbar>
        <div className={classes.tableWrapper}>
          {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
          <CalendarView intl={intl} appointments={appointments} fetchCalendar={this.fetchGet.bind(this)} />
        </div>
      </div>
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Calendar));
