import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import { url_bookings } from '../../constants/links';
import AppointmentList from './appointmentList';

import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import GroupIcon from '@material-ui/icons/Group';
import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
  root: {
    padding: '5px 10px 15px',
    maxWidth: 1200,
    margin: '0 auto'
  },
  icon: {
    color: theme.palette.newGrey.main,
    marginLeft: '3px'
  },
  tableWrapper: {
    overflowX: 'auto',
    //height: '80vh'
  },
  toolbar: {
    //padding: theme.spacing.unit,
    padding: 0,
    whiteSpace: 'nowrap'
  },
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    marginLeft: 10,
    fontSize: 30
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    transition: theme.transitions.create('width'),
    width: 0,
    '&:focus': {
      width: 50,
      [theme.breakpoints.up(370)]: {
        width: 90
      },
      [theme.breakpoints.up('sm')]: {
        width: 200
      }
    }
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type
  }
};

class Appointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      data: [],
      loading: true,
      page_size: 5,
      next: `${url_bookings}?page_size=10`,
      previous: '',
      scrolling: false,
      noResult: false
    };
    this.textInput = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  };

  componentDidMount() {
    this.fetchGet();
    window.addEventListener('scroll', this.handleScroll);
    //console.log(document.querySelector('div#list'))
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = () => {
    const { scrolling, count, data } = this.state;
    if (count <= data.length) return
    const lastItem = document.querySelector('ul#appointments > div:last-child');
    const lastItemOffset = lastItem.offsetTop + lastItem.clientHeight;
    const pageOffset = window.pageYOffset + window.innerHeight;
    let bottomOffset = 200;
    if (pageOffset > lastItemOffset - bottomOffset) this.loadMore();
  };

  fetchGet() {
    const { page_size, data, next } = this.state;
    const token = 'token '.concat(this.props.token);
    axios.get(next,
      {
        headers: { Authorization: token },
        params: { company: this.props.dominio }
      })
      .then(response => {
        if (response.data.results.length === 0) { this.setState({ noResult: true }) }
        this.setState({
          data: [...data, ...response.data.results],
          page_size: page_size + 5,
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          scrolling: false,
          loading: false
        })
      })
      .catch((error) => {
        this.setState({ loading: false, noResult: true });
        console.log('error ' + error);
      });
  };

  loadMore() {
    this.setState(prevState => ({
      scrolling: true
    }), this.fetchGet)
  };

  handleSearch(event) {
    event.persist();
    this.setState({ loading: true });
    let params;
    const token = 'token '.concat(this.props.token);
    if (event.target.value === '') {
      params = {
        headers: { Authorization: token },
        params: { page_size: 8, company: this.props.dominio }
      }
    } else {
      params = {
        headers: { Authorization: token },
        params: { query: event.target.value, company: this.props.dominio }
      }
    }
    axios.get(url_bookings, params)
      .then(response => {
        if (response.data.results.length === 0) {
          this.setState({
            noResult: true,
            loading: false
          })
        } else {
          this.setState({
            data: response.data.results,
            loading: false,
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            noResult: false
          })
        }
      })
      .catch((error) => {
        console.log('error ' + error);
        this.setState({ loading: false });
      });
  };

  focus() {
    this.textInput.current.focus();
  };

  render() {
    const { classes, match, intl, messages } = this.props;
    const { data, loading, noResult } = this.state;
    console.log(data);
    return (
      <div className={classes.root} >
        <Toolbar className={classes.toolbar}>
          <GroupIcon className={classes.icon} />
          <Typography component="h2" variant="h2" className={classes.title}>
            Appointments
          </Typography>
          <div className={classes.spacer} />
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onChange={this.handleSearch.bind(this)}
            inputRef={this.textInput}
          />
          <Tooltip title="Sort">
            <IconButton aria-label="Sort" >
              <SearchIcon onClick={this.focus.bind(this)} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add appointment">
            <Link to={`/calendar`}>
              <IconButton aria-label="Add appointment">
                <AddIcon />
              </IconButton>
            </Link>
          </Tooltip>
        </Toolbar>
        <div className={classes.tableWrapper}>
          <AppointmentList data={data} intl={intl} messages={messages} />
          {/*loading ?
            <CircularProgress style={{ position: "absolute", top: "50%", margin: '0 50%' }} />
            :
            noResult ?
              <Typography variant="h5" style={{ position: "absolute", top: "50%", left: '45%', textAlign: "center" }}>
                No results
              </Typography>
              :
              <Basic />
          */}
        </div>
      </div>
    );
  };
};

export default connect(mapStateToProps)(withStyles(styles)(Appointments));
