import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import { getBills, getAnalysisInfo } from '../../../actions';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import renderCheckbox from '../../ui/checkbox_field';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import analysisInfo from './card/analysisInfo';

const styles = theme => ({
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  banner: {
    paddingBottom: 8,
    paddingTop: 8
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  paper: {
    animation: 'fadeInOut 0.6s',
    animationTimingFunction: 'ease-in-out'
  },
  form: {
    padding: '15px 24px 15px',
  },
  title: {
    fontSize: 25
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '24px'
  },
  link: {
    color: '#1562c6'
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    token: state.auth.token,
    loading: state.loader.loading,
    bills: state.bill.bills,
    customer: state.customer.customer,
    analysisInfo: state.analysis.data
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getBills: (token, customer) => dispatch(getBills(token, customer)),
    getAnalysisInfo: (token, customer) => dispatch(getAnalysisInfo(token, customer))

  }
};

class AnalysisForm extends React.Component {
  state = {
    bills: [],
    analysisInfo: []
  };

  componentDidMount() {
    this.fetchGet();
  };

  async fetchGet() {
    const id = this.props.customer.id;
    const token = 'token '.concat(this.props.token);
    await this.props.getBills(token, id);
    await this.props.getAnalysisInfo(token, id)
    //const show = this.props.bills.length === 0 ? true : false;
    this.setState({
      bills: this.props.bills,
      analysisInfo: this.props.analysisInfo
    });
  };

  async submit(values) {
    /*const val = {
      ...values,
      company: this.props.company.url,
      quantity: parseInt(values.quantity, 10)
    };

    const token = 'token '.concat(this.props.token);
    /*
    1. Espero respuesta del servidor
    2. Quito el linear loader
    3. Regreso al Inventario
    4. Reload de Inventario*/
    /*

    await this.validateErrorsApi(val, token);
    this.props.showCard();
    this.props.fetchCard();*/
    //console.log(values);
    this.props.handleInfo();
    this.props.handleShow();
  };

  validateErrorsApi(data, token) {
    //return this.props.addAccessory(data, token)
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading } = this.props;
    const { bills, analysisInfo } = this.state;
    console.log(analysisInfo)
    return (
      <Paper className={classes.paper}>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} /*enctype="multipart/form-data" id="myForm"*/>
          <Grid container spacing={16}>
            <Grid item xs={12} className={classes.head}>
              <List component="ul">
                <ListItem disableGutters>
                  <ListItemIcon>
                    <AssignmentIcon className={classes.icon} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography component="h2" variant="h2" className={classes.title}> Generate Analysis </Typography>}
                  />
                </ListItem>
              </List>
            </Grid>
            {bills.map((bill, index) =>
              <Grid item xs={12} sm={6} key={bill.id}>
                <Field name={index} component={renderCheckbox} label={`Bill ${bill.id}`} ref={index} />
              </Grid>
            )}
          </Grid>
          <div className={classes.formMeta}>
            <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                Generate
              </Typography>
            </Button>
          </div>
        </form>
      </Paper>
    )
  }
};

const reduxFormGenrateAnalysis = reduxForm({
  form: 'generateanalysis'
})(AnalysisForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormGenrateAnalysis));
