import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return { authenticated: state.auth.authenticated };
};

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={props => (rest.authenticated ? (<Component {...props} />) : (
      <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
    )
    )} />
  );
};

export default connect(mapStateToProps)(ProtectedRoute);
