import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { updateCompany } from '../../../actions'

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import renderFileInput from '../../ui/upload_field';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = theme => ({
  banner: {
    paddingBottom: 8,
    paddingTop: 8
  },
  icon: {
    color: theme.palette.newGrey.main
  },
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  paper: {
    width: '100%'
  },
  form: {
    paddingBottom: 15
  },
  grid: {
    padding: '5px 24px 0px',
  },
  head: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    fontSize: 25
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: '24px'
  },
  link: {
    color: theme.palette.primary.main
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.bill.error,
    token: state.auth.token,
    loading: state.loader.loading
  }
};

function mapDispatchToProps(dispatch) {
  return {
    updateCompany: (values, token, id) => dispatch(updateCompany(values, token, id))
  }
};

class ProfileForm extends React.Component {
  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        //console.log(key)
      }

      return form_data;
    }
  };

  async submit(values) {
    const val = values;

    if (val.sequency) {
      val.sequency = parseInt(values.sequency, 10)
    };

    if (typeof val.photo !== 'object') {
      delete val.photo
    };

    /* Evitar valores nulos */
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    //console.log(val);
    /*---------------------*/

    const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    const id = this.props.match.params.id;

    await this.validateErrorsApi(data, token, id);
    this.props.fetchSettingsIndex();
    this.props.showCard();
    this.props.fetchCard();
  };

  validateErrorsApi(data, token, id) {
    return this.props.updateCompany(data, token, id)
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading } = this.props;
    return (
      <Paper>
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} encType="multipart/form-data" id="myForm">
          <div item xs={12} className={classes.banner}>
            <List component="ul">
              <ListItem >
                <ListItemIcon>
                  <AssignmentIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography component="h2" variant="h2" className={classes.title}> Edit Profile </Typography>}
                />
              </ListItem>
            </List>
          </div>
          <Grid container spacing={24} className={classes.grid}>
            <Grid item xs={12} sm={6}>
              <Field
                name="name"
                component={renderTextField}
                label={formatMessage(messages.customers.formName)}
                ref="name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="email"
                component={renderTextField}
                label={formatMessage(messages.general.email)}
                ref="email"
                type='email'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="address"
                component={renderTextField}
                label={formatMessage(messages.customers.formAddress)}
                ref="address"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="phone"
                component={renderTextField}
                label={formatMessage(messages.general.phone)}
                ref="phone"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="sequency"
                component={renderTextField}
                label="Sequency"
                ref="sequency"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="token_alegra"
                component={renderTextField}
                label="Token Alegra"
                ref="token_alegra"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="user_name_alegra"
                component={renderTextField}
                label="Username Alegra"
                ref="user_name_alegra"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="company_description"
                component={renderTextField}
                isTextArea={true}
                label="Company description"
                ref="company_description"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="company_vision"
                component={renderTextField}
                isTextArea={true}
                label="Company vision"
                ref="company_vision"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="company_proposal"
                component={renderTextField}
                isTextArea={true}
                label="Company proposal"
                ref="company_proposal"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="company_model"
                component={renderTextField}
                isTextArea={true}
                label="Company model"
                ref="company_model"
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name="photo"
                component={renderFileInput}
                ref="photo"
                label="Photo"
                inputid="photo"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            <Button color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                Update
              </Typography>
            </Button>
          </div>
        </form>
      </Paper>
    )
  }
};

const reduxFormUpdateCompany = reduxForm({
  form: 'updatecompany'
})(ProfileForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateCompany));
