import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Form from './form/form';
import TableSellers from './table';
import ListSellers from './list/listSellers';
import SellerIndex from './sellerIndex';

class Sellers extends React.Component {
  render() {
    const { intl, messages, match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.path}`} render={(props) => <ListSellers {...props} intl={intl} messages={messages} />} />
        <Route path={`${match.path}/signup`} render={(props) => <Form {...props} intl={intl} messages={messages} />} />
        <Route path={`${match.path}/:id`} render={(props) => <SellerIndex {...props} intl={intl} messages={messages} />} />
      </Switch>
    );
  }
};

export default Sellers;
