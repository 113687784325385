import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { InlineDatePicker } from "material-ui-pickers";
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Typography } from '@material-ui/core';

const navigate = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE'
};

const propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired
};

const styles = theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 10px'
  }
});

class Toolbar extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.state = {
      newViews: []
    };

    this.myRef = React.createRef();
  };

  componentWillReceiveProps(nextProps) {
    const newViews = nextProps.views.filter(view => view !== 'agenda');
    this.setState({
      newViews
    });
  };

  toThisDay(date) {
    this.props.onView('day');
    // give it just a tick to 'set' the view, prior to navigating to the proper date
    setTimeout(() => {
      this.props.onNavigate(navigate.DATE, date);
    }, 100);
  };

  navigate(action) {
    this.props.onNavigate(action);
  };

  view(view) {
    console.log(view)
    this.props.onView(view);
  };

  open(event) {
    this.myRef.current.open(event)
  };

  handleChange(event) {
    this.props.onView(event.target.value)
  };

  render() {
    const { localizer: { messages }, label, date, classes, view } = this.props;
    const { newViews } = this.state;
    return (
      <div className={classes.wrapper}>
        <div style={{ display: 'none' }}>
          <Button onClick={this.navigate.bind(this, navigate.TODAY)}>
            {messages.today}
          </Button>
        </div>

        <div>
          <InlineDatePicker
            onlyCalendar
            label="Open me from button"
            format="d MMM yyyy"
            value={date}
            onChange={(val) => this.props.onNavigate(navigate.DATE, val)}
            style={{ display: 'none' }}
            ref={this.myRef}
          />
          <Button date={date} onChange={this.toThisDay.bind(this)} onClick={this.open.bind(this)}>
            {label.toUpperCase()}
          </Button>
          <IconButton aria-label="previous" onClick={this.navigate.bind(this, navigate.PREVIOUS)}>
            <ArrowLeftIcon />
          </IconButton>
          <IconButton aria-label="next" onClick={this.navigate.bind(this, navigate.NEXT)}>
            <ArrowRightIcon />
          </IconButton>
        </div>

        <div>
          <FormControl className={classes.formControl}>
            <Select
              disableUnderline
              value={messages[this.props.view]}
              renderValue={value => {
                return (
                  <Typography variant="button">
                    {value.toUpperCase()}
                  </Typography>
                )
              }}
              onChange={this.handleChange.bind(this)}
            >
              {newViews.map(name => (
                <MenuItem
                  key={name}
                  value={name}
                >
                  <Typography variant="button">
                    {messages[name].toUpperCase()}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
    );
  };
};

export default withStyles(styles)(Toolbar);
