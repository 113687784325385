import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
  updateCustomerProfile,
  deleteCustomer,
  getCities,
  getBusinessTypes,
  getEmployees,
  getServiceClasses,
  getUtilityCompanies
} from '../../../actions';
import SelectCompany from '../../selectCompany';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import renderFileInput from '../../ui/upload_field';
import renderSelectFieldTest from '../../ui/select_field_test';
import universalDatePicker from '../../ui/universal_date_picker';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputBase from '@material-ui/core/InputBase';

import ModalDialog from '../../modal';

const styles = theme => ({
  '@keyframes growScale': {
    '0%': {
      transform: 'scale(0)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  },
  '@keyframes fadeScale': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  paper: {
    animation: 'fadeScale 0.6s',
    animationTimingFunction: 'ease'
  },
  head: {
    display: 'flex',
    alignItems: 'baseline'
  },
  form: {
    width: '100%',
    padding: '15px 24px 15px',
  },
  title: {
    //margin: '30px 0px 30px 10px',
    fontSize: 25
  },
  expansionTitle: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    margin: '0 5px'
  },
  loader: {
    borderRadius: '3px 3px 0px 0px'
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.customer.error,
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type,
    dominio: state.auth.dominio,
    cities: state.city.cities,
    businessTypes: state.businessType.businessTypes,
    sellers: state.employee.sellers,
    serviceClasses: state.serviceClass.serviceClasses,
    utilityCompanies: state.utilityCompany.utilityCompanies,
    test: state.auth.test
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getUtilityCompanies: (token) => dispatch(getUtilityCompanies(token)),
    getCities: (token, company) => dispatch(getCities(token, company)),
    getBusinessTypes: (token, company) => dispatch(getBusinessTypes(token, company)),
    getEmployees: (token, company) => dispatch(getEmployees(token, company)),
    getServiceClasses: (token, company) => dispatch(getServiceClasses(token, company)),
    updateCustomerProfile: (values, token, id) => dispatch(updateCustomerProfile(values, token, id)),
    deleteCustomer: (token, id, history) => dispatch(deleteCustomer(token, id, history))
  }
};

class ProfileForm extends React.Component {
  state = {
    cities: [],
    utilityCompanies: [],
    businessTypes: [],
    status: [],
    sellers: [],
    serviceClasses: [],
    open: false,
    selectCompany: 3
  };

  componentDidMount() {
    this.fetchGet(this.state.selectCompany);
  };

  async fetchGet(dominio) {
    const token = 'token '.concat(this.props.token);

    if (this.props.dominio) {
      this.setState({
        selectCompany: this.props.dominio
      });
    } else {
      this.setState({
        selectCompany: dominio
      });
    }

    await Promise.all([
      this.props.getCities(token, this.state.selectCompany),
      this.props.getBusinessTypes(token, this.state.selectCompany),
      this.props.getEmployees(token, this.state.selectCompany),
      this.props.getServiceClasses(token, this.state.selectCompany),
      this.props.getUtilityCompanies(token, this.state.selectCompany)
    ]);

    /* Status fetch */
    await axios.get('https://api.elasustentable.com/rest/general/status',
      {
        headers: { Authorization: token }
      })
      .then(response => {
        //console.log(response.data);
        const status = [];
        response.data.results.forEach(item =>
          status.push({
            label: this.capitalize(item.name),
            value: item.url
          })
        );
        this.setState({
          status: status
        })
      })
      .catch((error) => {
        console.log('error ' + error);
      });
    /*-----------------------*/

    const cities = [];
    const utilityCompanies = [];
    const businessTypes = [];
    const sellers = [];
    const serviceClasses = [];

    this.props.cities.forEach(city =>
      cities.push({
        label: this.capitalize(city.name),
        value: city.url
      })
    );
    this.props.utilityCompanies.forEach(item =>
      utilityCompanies.push({
        label: this.capitalize(item.name),
        value: item.url
      })
    );
    this.props.businessTypes.forEach(item =>
      businessTypes.push({
        label: this.capitalize(item.name),
        value: item.url
      })
    );
    this.props.sellers.forEach(item =>
      sellers.push({
        label: this.capitalize(item.first_name) + ' ' + this.capitalize(item.last_name),
        value: item.url
      })
    );
    this.props.serviceClasses.forEach(item =>
      serviceClasses.push({
        label: this.capitalize(item.name),
        value: item.url
      })
    );

    this.setState({
      cities: cities,
      businessTypes: businessTypes,
      sellers: sellers,
      serviceClasses: serviceClasses,
      utilityCompanies: utilityCompanies
    })
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        //console.log(key)
      }

      return form_data;
    }
  };

  handleOpen(open) {
    this.setState({
      open: open
    })
  };

  deleteData() {
    this.handleOpen(false)
    const token = 'token '.concat(this.props.token);
    const id = this.props.match.params.id;
    return this.props.deleteCustomer(token, id, this.props.history);
  };

  async submit(values) {
    const val = {
      ...values
    };

    /* Evitar valores nulos */
    for (const key in val) {
      let value = val[key];
      if (value === undefined || value === null) {
        delete val[key]
      };
    }
    /*---------------------*/

    if (val.agreement_date) {
      if (typeof val.agreement_date === 'object') {
        val.agreement_date = values.agreement_date.toISOString().slice(0, 10)
      } else {
        val.agreement_date = values.agreement_date.split('/').join('-')
      }
    }

    if (val.cubic_meters_avg) {
      val.cubic_meters_avg = parseInt(values.cubic_meters_avg, 10)
    };

    if (val.seller) {
      val.seller = values.seller
    };

    if (val.city) {
      val.city = values.city
    };

    if (val.status) {
      val.status = values.status
    };

    if (val.bussines_type) {
      val.bussines_type = values.bussines_type
    };

    if (val.utility_company) {
      val.utility_company = values.utility_company
    };

    if (val.service_class) {
      val.service_class = values.service_class
    };

    if (typeof val.photo !== 'object') {
      delete val.photo
    }

    if (typeof val.agreement !== 'object') {
      delete val.agreement
    }

    if (val.inventory) {
      val.inventory = '{ "a": "a" }'
    };

    const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    const id = this.props.match.params.id;

    await this.validateErrorsApi(data, token, id);
    await this.props.fetchCustomerIndex();
    this.props.showCard();
  };

  validateErrorsApi(data, token, id) {
    return this.props.updateCustomerProfile(data, token, id);
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleChange(event) {
    this.fetchGet(event.target.value);
  };

  render() {
    const { messages, intl: { formatMessage }, pristine, submitting, classes, handleSubmit, loading, role } = this.props;
    const { open, cities, businessTypes, sellers, serviceClasses, utilityCompanies, status } = this.state;
    const normalizeNumber = (value, previousValue) => {
      const onlyNums = value.replace(/[^\d]/g, '')
      return onlyNums
    }
    return (
      <Paper className={classes.paper}>
        <ModalDialog
          open={open}
          title="Are you sure you want to delete this customer ?"
          description="This action directly affects the database and is irreversible."
          actionName="Delete"
          handleOpen={this.handleOpen.bind(this)}
          handleAction={this.deleteData.bind(this)}
        />
        {loading ? <LinearProgress className={classes.loader} /> : <div style={{ height: 5 }}></div>}
        <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} encType="multipart/form-data" id="myForm">
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.head}>
              <List component="ul">
                <ListItem disableGutters>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={<Typography component="h2" variant="h2" className={classes.title}>
                    Update profile
                  </Typography>} />
                </ListItem>
              </List>
            </Grid>
            {role.select ?
              <Grid item xs={12} sm={6}>
                <SelectCompany
                  select={this.state.selectCompany}
                  handleChange={this.handleChange.bind(this)}
                />
              </Grid>
              :
              null
            }
            <Grid item xs={12} sm={6}>
              <Field
                name="status"
                component={renderSelectFieldTest}
                options={status}
                label='Status'
                ref="status"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="name"
                component={renderTextField}
                label={formatMessage(messages.customers.formName)}
                ref="name"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="email"
                component={renderTextField}
                label={formatMessage(messages.general.email)}
                ref="email"
                type='email'
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="identification_type"
                component={renderTextField}
                label="Identification Type"
                ref="identification_type"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="identification"
                component={renderTextField}
                label={formatMessage(messages.customers.formIdentification)}
                ref="identification"
                normalize={normalizeNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="address"
                component={renderTextField}
                label={formatMessage(messages.customers.formAddress)}
                ref="address"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="phone"
                component={renderTextField}
                label={formatMessage(messages.general.phone)}
                ref="phone"
                normalize={normalizeNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="city"
                component={renderSelectFieldTest}
                options={cities}
                label={formatMessage(messages.customers.formCity)}
                ref="city"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="bussines_type"
                component={renderSelectFieldTest}
                options={businessTypes}
                label='Bussines Type'
                ref="bussines_type"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="service_class"
                component={renderSelectFieldTest}
                options={serviceClasses}
                label='Service Class'
                ref="service_class"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="utility_company"
                component={renderSelectFieldTest}
                options={utilityCompanies}
                label='Utility Company'
                ref="utility_company"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="utility_reference"
                component={renderTextField}
                label='Utility Reference'
                ref="utility_reference"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="seller"
                component={renderSelectFieldTest}
                options={sellers}
                label='Seller'
                ref="seller"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="cubic_meters_avg"
                component={renderTextField}
                label="Cubic Meters Avg"
                ref="cubic_meters_avg"
                type="number"
                inputMode="numeric"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="agreement_reference"
                component={renderTextField}
                label='Agreement Reference'
                ref="agreement_reference"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="agreement_date"
                component={universalDatePicker}
                label="Agreement Date"
                ref="agreement_date"
              />
            </Grid>
            <Grid item xs={12} className={classes.uploadButton}>
              <Field
                name="agreement"
                component={renderFileInput}
                ref="agreement"
                label="Agreement"
                inputid="agreement"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="photo"
                component={renderFileInput}
                ref="photo"
                label="Photo"
                inputid="photo"
              />
            </Grid>
          </Grid>
          <div className={classes.formMeta}>
            {role.delete ?
              <Button className={classes.button} color='secondary' variant="contained" onClick={this.handleOpen.bind(this, true)} disabled={loading}>
                <Typography variant='button' noWrap>
                  Delete
              </Typography>
              </Button>
              :
              null
            }
            <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                Update
              </Typography>
            </Button>
            <InputBase style={{ display: "none" }} />
          </div>
        </form>
      </Paper>
    )
  }
};

const reduxFormUpdateProfile = reduxForm({
  form: 'updateprofile'
})(ProfileForm);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormUpdateProfile));
