const url = process.env.REACT_APP_PROD_API_URL;

export const url_signIn = `${url}/rest/auth/`;
export const url_customers = `${url}/rest/customer/customer`;
export const url_bills = `${url}/rest/customer/utilitybill`;
export const url_companies = `${url}/rest/company/company`;
export const url_utilityCompanies = `${url}/rest/general/utilitycompany`;
export const url_commissions = `${url}/rest/company/commission`;
export const url_businessTypes = `${url}/rest/general/bussinestype`;
export const url_valves = `${url}/rest/company/valve`;
export const url_accessories = `${url}/rest/company/accesory`;
export const url_cities = `${url}/rest/general/city`;
export const url_employees = `${url}/rest/employee/employee`;
export const url_serviceClasses = `${url}/rest/general/serviceclass`;
export const url_bookings = `${url}/rest/customer/booking`;
export const url_analysis = `${url}/rest/customer/analysis`;
export const url_registers = `${url}/rest/customer/installationreport`;
export const url_index = `${url}/rest/user/user/info`;
export const url_valve_parts = `${url}/rest/company/valvepart`;
export const url_status = `${url}/rest/general/status`;
export const url_invoices = `${url}/rest/customer/invoice`;
export const url_customer_report = `${url}/rest/report/customer?model=customer`;
export const url_installation_summary = `${url}/rest/customer/installationsummary`;
export const url_installation_location = `${url}/rest/customer/installationreportlocation`;

// const url = 'http://192.168.0.20:8000';

// export const url_signIn = `${url}/rest/auth/`;
// export const url_customers = `${url}/rest/customer/customer`;
// export const url_bills = `${url}/rest/customer/utilitybill`;
// export const url_companies = `${url}/rest/company/company`;
// export const url_utilityCompanies = `${url}/rest/general/utilitycompany`;
// export const url_commissions = `${url}/rest/company/commission`;
// export const url_businessTypes = `${url}/rest/general/bussinestype`;
// export const url_valves = `${url}/rest/company/valve`;
// export const url_accessories = `${url}/rest/company/accesory`;
// export const url_cities = `${url}/rest/general/city`;
// export const url_employees = `${url}/rest/employee/employee`;
// export const url_serviceClasses = `${url}/rest/general/serviceclass`;
// export const url_bookings = `${url}/rest/customer/booking`;
// export const url_analysis = `${url}/rest/customer/analysis`;
// export const url_registers = `${url}/rest/customer/installationreport`;
// export const url_index = `${url}/rest/user/user/info`;
// export const url_valve_parts = `${url}/rest/company/valvepart`;
// export const url_status = `${url}/rest/general/status`;
// export const url_invoices = `${url}/rest/customer/invoice`;
// export const url_customer_report = `${url}/rest/report/customer?model=customer`;
// export const url_installation_summary = `${url}/rest/customer/installationsummary`;
// export const url_installation_location = `${url}/rest/customer/installationreportlocation`;
