import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { destroy } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  signUpCustomer,
  getBusinessTypes,
  getEmployees,
  getServiceClasses,
  getUtilityCompanies
} from '../../../actions';
import SelectCompany from '../../selectCompany';
import validate from './validate';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import renderTextField from '../../ui/text_field';
import renderSelectField from '../../ui/select_field';
import renderSelectFieldTest from '../../ui/select_field_test';
import universalDatePicker from '../../ui/universal_date_picker';
import renderFileInput from '../../ui/upload_field';

const styles = theme => ({
  form: {
    //padding: '5px 24px 15px',
  },
  formMeta: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  link: {
    color: theme.palette.primary.main
  },
  button: {
    margin: '0 5px'
  },
  uploadButton: {
    marginTop: 18
  }
});

function mapStateToProps(state) {
  return {
    error: state.customer.error,
    token: state.auth.token,
    loading: state.loader.loading,
    role: state.auth.user_type,
    dominio: state.auth.dominio,
    businessTypes: state.businessType.businessTypes,
    sellers: state.employee.sellers,
    serviceClasses: state.serviceClass.serviceClasses,
    utilityCompanies: state.utilityCompany.utilityCompanies,
  }
};

function mapDispatchToProps(dispatch) {
  return {
    getUtilityCompanies: (token) => dispatch(getUtilityCompanies(token)),
    getBusinessTypes: (token, company) => dispatch(getBusinessTypes(token, company)),
    getEmployees: (token, company) => dispatch(getEmployees(token, company)),
    getServiceClasses: (token, company) => dispatch(getServiceClasses(token, company)),
    signUpCustomer: (values, token, history) => dispatch(signUpCustomer(values, token, history)),
    destroy: (form) => dispatch(destroy(form))
  }
};

class FormFirstPage extends Component {
  state = {
    utilityCompanies: [],
    businessTypes: [],
    sellers: [],
    serviceClasses: [],
    error: '',
    selectCompany: 3
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      selectCompany: nextProps.dominio
    });
  };

  componentDidMount() {
    this.fetchGet(this.state.selectCompany);
  };

  async fetchGet(dominio) {
    const token = 'token '.concat(this.props.token);

    if (this.props.dominio) {
      this.setState({
        selectCompany: this.props.dominio
      });
    } else {
      this.setState({
        selectCompany: dominio
      });
    };

    await Promise.all([
      this.props.getUtilityCompanies(token),
      this.props.getBusinessTypes(token, this.state.selectCompany),
      this.props.getEmployees(token, this.state.selectCompany),
      this.props.getServiceClasses(token, this.state.selectCompany)
    ]);

    const utilityCompanies = [];
    const businessTypes = [];
    const sellers = [];
    const serviceClasses = [];

    this.props.utilityCompanies.forEach(item =>
      utilityCompanies.push({
        label: this.capitalize(item.name),
        value: item.url
      })
    );
    this.props.businessTypes.forEach(item =>
      businessTypes.push({
        label: this.capitalize(item.name),
        value: item.url
      })
    );
    this.props.sellers.forEach(item =>
      sellers.push({
        label: this.capitalize(item.first_name) + ' ' + this.capitalize(item.last_name),
        value: item.url
      })
    );
    this.props.serviceClasses.forEach(item =>
      serviceClasses.push({
        label: this.capitalize(item.name),
        value: item.url
      })
    );

    this.setState({
      businessTypes: businessTypes,
      sellers: sellers,
      serviceClasses: serviceClasses,
      utilityCompanies: utilityCompanies
    })
  };

  convertToFormData(fields) {
    if (fields) {
      const form_data = new FormData();

      for (let key in fields) {
        form_data.append(key, fields[key]);
        //console.log(key)
      }

      return form_data;
    }
  };

  async submit(values) {
    console.log(values);
    //const val = values;
    const val = {
      ...values,
      city: values.city,
      status: values.status,
      //status: 'https://api.elasustentable.com/rest/general/status/1',
      bussines_type: values.bussines_type,
      seller: values.seller,
      service_class: values.service_class,
      utility_company: values.utility_company,
      cubic_meters_avg: parseInt(values.cubic_meters_avg, 10),
      agreement_date: values.agreement_date ? values.agreement_date.toISOString().slice(0, 10) : ''
    }
    //console.log(val)

    const data = this.convertToFormData(val);
    const token = 'token '.concat(this.props.token);
    await this.validateErrorsApi(data, token, this.props.history);
    this.props.destroy('signupCustomer');
  };

  validateErrorsApi(data, token, history) {
    return this.props.signUpCustomer(data, token, this.props.history);
  };

  capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    const { intl: { formatMessage }, messages, pristine, submitting, classes, handleSubmit, loading, role } = this.props;
    const { utilityCompanies, businessTypes, sellers, serviceClasses } = this.state;
    const normalizeNumber = (value, previousValue) => {
      const onlyNums = value.replace(/[^\d]/g, '')
      return onlyNums
    }
    return (
      <form className={classes.form} onSubmit={handleSubmit(this.submit.bind(this))} encType="multipart/form-data">
        <Grid container spacing={24}>
          {role.select ?
            <Grid item xs={12} sm={6}>
              <SelectCompany
                select={this.state.selectCompany}
                handleChange={this.handleChange.bind(this)}
              />
            </Grid>
            :
            null
          }
          <Grid item xs={12} sm={6}>
            <Field
              name="bussines_type"
              component={renderSelectFieldTest}
              options={businessTypes}
              label='Bussines Type'
              ref="bussines_type"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="service_class"
              component={renderSelectFieldTest}
              options={serviceClasses}
              label='Service Class'
              ref="service_class"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="utility_company"
              component={renderSelectFieldTest}
              options={utilityCompanies}
              label='Utility Company'
              ref="utility_company"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="utility_reference"
              component={renderTextField}
              label='Utility Reference'
              ref="utility_reference"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="seller"
              component={renderSelectFieldTest}
              options={sellers}
              label='Seller'
              ref="seller"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="cubic_meters_avg"
              component={renderTextField}
              label="Cubic Meters Avg"
              ref="cubic_meters_avg"
              type="number"
              normalize={normalizeNumber}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="hydrometer"
              component={renderTextField}
              label="Hydrometer"
              ref="hydrometer"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="agreement_reference"
              component={renderTextField}
              label='Agreement Reference'
              ref="agreement_reference"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="agreement_date"
              component={universalDatePicker}
              label="Agreement Date"
              ref="agreement_date"
            />
          </Grid>
          <Grid item xs={12} className={classes.uploadButton}>
            <Field
              name="agreement"
              component={renderFileInput}
              ref="agreement"
              label="Agreement"
              inputid="agreement"
            />
          </Grid>
        </Grid>
        <div className={classes.formMeta}>
          <Link to="/customers">
            <Typography variant='button' noWrap className={classes.link}>
              {formatMessage(messages.customers.formLink)}
            </Typography>
          </Link>
          <div>
            <Button className={classes.button} onClick={this.props.previousStep} disabled={loading}>
              <Typography variant='button' noWrap color="primary">
                Previous
              </Typography>
            </Button>
            <Button className={classes.button} color='primary' variant="contained" type="submit" disabled={loading || (pristine || submitting)}>
              <Typography variant='button' noWrap>
                {formatMessage(messages.customers.formTitle)}
              </Typography>
            </Button>
          </div>
        </div>
      </form >
    );
  }
};

const reduxFormSignUp = reduxForm({
  form: 'signupCustomer',
  destroyOnUnmount: false,
  validate
})(FormFirstPage);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(reduxFormSignUp));
