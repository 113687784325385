import React, { Fragment } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import { getCompanies } from '../../actions';

import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem';


const styles = theme => ({
  div: {
    margin: '0px 24px',
    [theme.breakpoints.up('sm')]: {
      margin: '10px 0px 0px'
    }
  },
  langBar: {
    fontSize: 12,
    //color: '#484848',
    margin: 0,
    /*margin: '0px 24px',
    [theme.breakpoints.up('sm')]: {
      margin: '10px 0px 0px'
    }*/
  }
});

function mapDispatchToProps(dispatch) {
  return {
    getCompanies: (token) => dispatch(getCompanies(token))
  }
};

function mapStateToProps(state) {
  return {
    companies: state.company.companies,
    token: state.auth.token
  }
};

class SelectCompany extends React.Component {
  state = {
    labelWidth: 0,
    companies: [],
    select: 3,
    value: ''
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      select: nextProps.select ? nextProps.select : 3
    });
  };

  componentDidMount() {
    this.fetch();
  };

  capitalize(string) {
    const array = string.split(' ');
    let upperCaseArray = [];

    if (array.length > 1) {
      array.forEach(item =>
        upperCaseArray.push(item.charAt(0).toUpperCase() + item.slice(1))
      );
      return upperCaseArray.join(' ');
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  async fetch() {
    const token = 'token '.concat(this.props.token);
    await this.props.getCompanies(token);

    const companies = [];
    this.props.companies.forEach(item => {
      companies.push({
        label: this.capitalize(item.name),
        value: item.url,
        id: item.id
      })
    });

    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
      companies: companies
    });
  };

  handleValue() {
    const { companies } = this.state;
    const value = companies.filter(item => item.id === this.state.select)[0].label;
    this.setState({
      value: value
    });
    return this.state.value
  };

  render() {
    const { classes } = this.props;
    const { companies, value } = this.state;
    console.log(this.state)
    console.log(companies.filter(item => item.id === this.state.select))
    return (
      <FormControl style={{ width: '100%' }}>
        <InputLabel
          htmlFor="company"
          ref={ref => {
            this.InputLabelRef = ref;
          }}
        >
          Company
        </InputLabel>
        <Select
          inputProps={{
            name: "company",
            id: "company",
          }}
          value={companies.length === 0 ? '' : companies.filter(item => item.id === this.state.select)[0].label}
          //value={this.state.select}
          renderValue={value => `${value}`}
          onChange={this.props.handleChange.bind(this)}
        >
          {companies.map((item, index) =>
            <MenuItem value={item.id} key={index}>{item.label}</MenuItem>
          )}
        </Select>
      </FormControl>
    )
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectCompany));
