import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { changeSideList } from '../../actions';
import { url_customers } from '../../constants/links';
import ListCard from '../customers/list/listCard';

import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import GroupIcon from '@material-ui/icons/Group';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
  root: {
    padding: '5px 10px 15px',
    maxWidth: 1200,
    margin: '0 auto'
  },
  icon: {
    color: theme.palette.newGrey.main,
    marginLeft: '3px'
  },
  tableWrapper: {
    overflowX: 'auto',
    //height: '80vh',
    position: 'relative',
    minHeight: 100
  },
  circularProgress: {
    position: 'absolute',
    top: "50%",
    margin: '0px 50%'
  },
  toolbar: {
    //padding: theme.spacing.unit,
    padding: 0,
    whiteSpace: 'nowrap'
  },
  title: {
    marginLeft: 10,
    fontSize: 30
  },
  bottomMessage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20
  },
  searchInput: {
    marginTop: 10,
    display: 'flex'
  },
  input: {
    width: '95%'
  }
});

function mapStateToProps(state) {
  return {
    token: state.auth.token,
    role: state.auth.user_type,
    dominio: state.auth.dominio
  }
};

function mapDispatchToProps(dispatch) {
  return {
    changeSideList: (page, info) => dispatch(changeSideList(page, info))
  }
};

class ListCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      data: [],
      loading: true,
      page_size: 5,
      next: `${url_customers}?page_size=25`,
      previous: '',
      scrolling: false,
      noResult: false,
      displayResult: false,
    };
    this.textInput = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  };

  componentDidMount() {
    this.fetchGet();
    window.addEventListener('scroll', this.handleScroll);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = () => {
    const { scrolling, count, data } = this.state;
    if (count <= data.length) return
    const lastItem = document.querySelector('ul#customers > div:last-child');
    const lastItemOffset = lastItem.offsetTop + lastItem.clientHeight;
    const pageOffset = window.pageYOffset + window.innerHeight;
    let bottomOffset = 200;
    if (pageOffset > lastItemOffset - bottomOffset) this.loadMore();
  };

  fetchGet() {
    const { page_size, data, next } = this.state;
    const token = 'token '.concat(this.props.token);
    axios.get(next,
      {
        headers: { Authorization: token },
        params: { company: this.props.dominio }
      })
      .then(response => {
        if (response.data.results.length === 0) { this.setState({ noResult: true }) }
        this.setState({
          data: [...data, ...response.data.results],
          page_size: page_size + 5,
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
          scrolling: false,
          loading: false
        })
      })
      .catch((error) => {
        this.setState({ loading: false, noResult: true });
        console.log('error ' + error);
      });
  };

  loadMore() {
    this.setState(prevState => ({
      scrolling: true
    }), this.fetchGet)
  };

  handleSearch(event) {
    event.persist();
    this.setState({ loading: true });
    let params;
    const token = 'token '.concat(this.props.token);
    if (event.target.value === '') {
      params = {
        headers: { Authorization: token },
        params: { page_size: this.state.page_size, company: this.props.dominio }
      }
    } else {
      params = {
        headers: { Authorization: token },
        params: { query: event.target.value, company: this.props.dominio }
      }
    }
    axios.get(url_customers, params)
      .then(response => {
        //console.log(response.data.results);
        if (response.data.results.length === 0) {
          this.setState({
            noResult: true,
            loading: false
          })
          //console.log('aja')
        } else {
          this.setState({
            loading: false,
            data: response.data.results,
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
            noResult: false,
            displayResult: true
          })
        }
      })
      .catch((error) => {
        console.log('error ' + error);
        this.setState({ loading: false });
      });
  };

  focus() {
    //console.log('intentando')
    this.textInput.current.focus();
    //this.toggleMenu(false)
  };

  render() {
    const { intl: { formatMessage }, intl, classes, match, messages, role, history } = this.props;
    const { loading, data, noResult, bussinesType, displayResult } = this.state;
    const customers = formatMessage(messages.navBar.customers);
    return (
      <div className={classes.root} >
        <Toolbar className={classes.toolbar}>
          <GroupIcon className={classes.icon} />
          <Typography component="h2" variant="h2" className={classes.title}>
            {customers}
          </Typography>
        </Toolbar>
        <Link to="/customers">
          <Typography variant='button' noWrap className={classes.link}>
            {formatMessage(messages.customers.formLink)}
          </Typography>
        </Link>
        <Paper className={classes.searchInput}>
          <IconButton aria-label="Sort">
            <SearchIcon />
          </IconButton>
          <InputBase
            placeholder="Search"
            className={classes.input}
            onChange={this.handleSearch.bind(this)}
            inputRef={this.textInput}
          />
        </Paper>
        <div className={classes.tableWrapper} id="list">
          {loading ?
            <CircularProgress className={classes.circularProgress} />
            :
            noResult ?
              <Link to={`customers/signup`} className={classes.bottomMessage}>
                <AddIcon />
                <Typography variant="h5" >
                  Add new Client
                </Typography>
              </Link>
              :
              displayResult === true ?
                <div style={{ marginTop: 20 }}>
                  <ListCard intl={intl} messages={messages} data={data} history={history} />
                  <Link to={`customers/signup`} className={classes.bottomMessage}>
                    <AddIcon />
                    <Typography variant="h5" >
                      Add new Client
                    </Typography>
                  </Link>
                </div>
                :
                <Typography variant="h5" className={classes.bottomMessage}>
                  Search Client
                </Typography>
          }
        </div>
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListCustomers));
